import React, { useEffect, useState, useContext } from 'react';

import { API } from "aws-amplify";
import GraphQlApi from '../mobile/MyGraphql';

import { useTranslation,  Trans  } from 'react-i18next';
import { useLocation, useHistory } from "react-router-dom";

import MyForm from '../components/MyForm'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';


import GeneralContext from '../components/GeneralContext'
import { askForRefreshingCustomer, toSnack, validateEmail} from '../Common';
import WithBackdrop from '../components/WithBackdrop'
import AreYouSure from '../components/AreYouSure';

const useStyles = makeStyles(theme => ({
    root: {
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    minHeight: {
        minHeight: 20, // ou 66
    },
    textFieldLogo: {
        width: '70ch',
    },   
    customerLogo: {
        maxWidth: 46,
        flexGrow: 1,
        marginTop: 7
    },   
    button: {
        margin: theme.spacing(1),
    },
    userList: {

    },
    cardHeader: {
        //backgroundColor: "lightskyblue",
        //color: "white",
        margin: "20px 20px 0px 20px",
        padding: "10px",
        fontWeight: "bold",
        fontSize: "18px"
    },  
    InviteMailTextField: {
        minWidth: '26ch',
    }             
  }));

export default function AdminCustomerUsers() {

    const { portal, user, customer: customerCtx } = useContext(GeneralContext);

    const { t }  = useTranslation();

    const [companyLogo, setCompanyLogo] = useState("");

    const [userInvitation, setUserInvitation] = useState("");
    const [backdropNewUserOpen, setBackdropNewUserOpen] = useState(false);
    const [urlHash, setUrlHash] = useState();

    const [customer, setCustomer] = useState(); //init avec customerCtx
    
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        async function fetchData(customerId) {
            console.log("Customer loading for (%s) ...",customerId);
            const getQuery = `query GetCustomer($id: ID!) {
                getCustomer(id: $id) {
                    id   
                    name
                    logoUrl 
                    accessType
                    invitationCode
                    users {
                        id
                        user {
                            id
                            email
                        }
                        userRole
                    }
                    guests {
                        id
                        owner
                        userRole
                        user {
                          id
                          email
                        }
                    }                     
                    groupsSvc {
                        items {
                          id
                          name
                        }
                    }                                                                                                       
                }
            }
            `;
            try {
                //const result = await API.graphql(graphqlOperation(getQuery, { id: customerId }));
                const result = await GraphQlApi.getInstance().graphqlOperation( getQuery, {  id: customerId });
                if (result.data && result.data.getCustomer) {
                    console.log("Result customer:", result.data.getCustomer);

                    const customer = result.data.getCustomer;
                    if (customer && customer.logoUrl) setCompanyLogo(customer.logoUrl)
                    setCustomer(customer);

                } else {
                    console.log("Data not found");
                }
            } 
            catch(resultErrors) {
                const errors = resultErrors.errors?resultErrors.errors:(resultErrors.graphQLErrors?resultErrors.graphQLErrors:null);
                console.log("Graphql errors: ",errors);                
            }
        }
       
        if (customerCtx) {
             // chargement initial à partir du context
            if (customerCtx.logoUrl) { 
                setCompanyLogo(customerCtx.logoUrl)
            } 
            console.log("Charge Customer from db for", customerCtx.id);
            fetchData(customerCtx.id);    
            return function cleanup() {
                console.log(`useEffect() cleanup.`)
            };            
        }  
    },[customerCtx])

    async function customerEdit() {
        console.log("modification du customer")
        const customerObj = { logo: companyLogo}
        setLoading(true);
        await updateCustomerMutation(customerCtx.id, customerObj)
        setLoading(false);
        toSnack("CustomerUpdated","La société a bien été modifiée");
    }  

    async function updateCustomerMutation(customerId,customer) {
        const toUpdate = {
            id: customerId
        };
        if (customer.logo) { toUpdate["logoUrl"] = customer.logo }

        console.log("toUpdate:",toUpdate);
        const updateMutation = `mutation updateCustomer($input: UpdateCustomerInput!) {
          updateCustomer( input: $input) {
              id
          }
        }`;
        //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
        console.log("customer updated", response.data.updateCustomer);
    }   

    //----
    // effacement Guest avec dialogBox de confirmation
    const [deleteGuestOpenConfirmQuestion, setDeleteGuestOpenConfirmQuestion] = useState(false);
    const [deleteGuestIdToDel, setDeleteGuestIdToDel] = useState();
    // lancement de la demande de confirmation
    function deleteGuestConfirm(id) {
        setDeleteGuestIdToDel(id) // mémorisarion des param
        setDeleteGuestOpenConfirmQuestion(true); // ouveture de la dialogBox AreYouSure
    }
    // 
    function deleteGuestAnswerCallBack(closeState) {
        console.log("Effacement Guest:", closeState)
        setDeleteGuestOpenConfirmQuestion(false); // permettre une reouverture de la fenetre
        if (closeState) {
            console.log("Effacement guest",deleteGuestIdToDel)
            handleGuestDelete(deleteGuestIdToDel);
        } else {
            console.log("Cancel")
        }
    }
    //-----
    // effacement User avec dialogBox de confirmation
    const [deleteUserOpenConfirmQuestion, setDeleteUserOpenConfirmQuestion] = useState(false);
    const [deleteUserIdToDel, setDeleteUserIdToDel] = useState();
    // lancement de la demande de confirmation
    function deleteUserConfirm(id) {
        setDeleteUserIdToDel(id) // mémorisarion des param
        setDeleteUserOpenConfirmQuestion(true); // ouveture de la dialogBox AreYouSure
    }
    // 
    function deleteUserAnswerCallBack(closeState) {
        console.log("Effacement user:", closeState)
        setDeleteUserOpenConfirmQuestion(false); // permettre une reouverture de la fenetre
        if (closeState) {
            console.log("Effacement User",deleteUserIdToDel)
            handleUserDelete(deleteUserIdToDel);
        } else {
            console.log("Cancel")
        }
    }
    //-----  
    async function handleUserDelete(userId) {
        console.log("handleUserDelete:", userId);
        setLoading(true); 
        try {
            await restDeleteUser(userId);
            askForRefreshingCustomer(customer.id);
        } 
        catch (error) {
            console.log("error:",error)
            toSnack("userDelete",error);
        }
        setLoading(false); 
    }     
    async function handleGuestDelete(userId) {
        console.log("handleGuestDelete:",userId);
        setLoading(true); 
        try {
            restDeleteGuest(userId)
            askForRefreshingCustomer(customer.id);
        } 
        catch (error) {
            console.log("error:",error)
            toSnack("userDelete",error);
        }
        setLoading(false); 
    }
    async function restDeleteUser(userId) {
        // effacement du user dans la table de liaison n-n entre user et customer
        //console.log("deleteUser");   
        console.log("Delete - User - appel REST : ")
        var response;
        try {
            response = await API.del("apiRestCustomer", "/customers/"+customer.id+"/user/"+userId+"", {});
             if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    } 
    async function restDeleteGuest(email) {
        // effacement du user dans la table de liaison n-n entre user et customer
        //console.log("deleteUser");   
        console.log("Delete - Guest - appel REST : ")
        var response;
        try {
            response = await API.del("apiRestCustomer", "/customers/"+customer.id+"/guest/"+email+"", {});
             if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    }        

    async function handleTestRest() {
        console.log("GET - User - appel REST : ")
        var response;
        try {
            response = await API.get("apic6a3d867", "/items/", {});
             if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    }

  
    async function handleInviteUser() {
        setLoading(true); 
        try {
            await inviteUserRest();
            askForRefreshingCustomer(customer.id);
            setUserInvitation("");
        }
        catch(error) {
            var message;
            if (error==='UserNotFound') {
                message = "Utilisateur non trouvé"
            } else {
                message = error
            }
            toSnack("UserInvitation",message);
        }
        setLoading(false);        
    }

    async function inviteUserRest() {
        console.log("userInvitation");

        //TODO verifier que lformat du user est bien un mail / oun un userId

        let myInit = {
        body: {
            param: "valeur", 
        }, 
        headers: {} // OPTIONAL
        }    
        
        console.log("Activation- appel REST : ",myInit)
        var response;
        try {
            response = await API.put("apiRestCustomer", "/customers/"+customer.id+"/invitation/"+userInvitation+"?portal="+(portal?portal.id:"home"), myInit);
            //response = await API.put("apiRestCustomer", "/customers/123/joinrequest", myInit);
            if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;

            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
    }     

    // callback de filter sur les users d'un customer
    // supprimer de la liste le current user
    function removeOwner(item) {
        return item.user.id !== user.id
    }

    useEffect( () => {
        console.log("LOCATION:",location);
        setUrlHash(location.hash);
        if (location.hash=="#newuser") {
            setBackdropNewUserOpen(true);
        }
    },[location]);

    function backdropClose() {
        setBackdropNewUserOpen(false)
        history.push("/home");
    }    

    return (
        <div>
            <div className={classes.minHeight}></div>            
            <div className={classes.alignItemsAndJustifyContent}>
              {loading && <CircularProgress size={40} />}
            </div>
{/* 
            <Card className={classes.cardHeader} variant="outlined" >
                <Grid container   direction="row" justifyContent="space-between" alignItems="center" > 
                    <Grid item>{customerCtx.name}</Grid>
                </Grid>
            </Card>
 */}
            <WithBackdrop open={backdropNewUserOpen} isOpen={backdropNewUserOpen}>
            <MyForm title={t("screen.customer-admin.users") } closeAction={backdropClose} isBackdropOpen={backdropNewUserOpen} >
                <div>
                    {customer?.accessType==='PUBLIC' &&
                        <div>
                            <div>{t("screen.customer-admin.group-mode-public")}</div>
                            <div>{t("screen.customer-admin.group-mode-public-1")}</div>
                            <div>{t("screen.customer-admin.group-mode-publi2")}</div>

                        </div>
                    }
                    {customer?.accessType==='OPEN' &&
                        <div>
                            <div>{t("screen.customer-admin.group-mode-open-1")}</div>
                            <div>{t("screen.customer-admin.group-mode-open-2")}{customer?.invitationCode}</div>
                            <div>{t("screen.customer-admin.group-mode-open-3")}</div>
                        </div>
                    }
                    {(customer?.accessType===null || customer?.accessType==='PRIVATE') &&
                        <div>
                            <div>{t("screen.customer-admin.group-mode-private")}</div>
                            <div>
                            <Typography variant="subtitle1">{t("screen.customer-admin.invite-user")}</Typography>
                            <Typography variant="body2">
                                <Trans i18nKey="screen.customer-admin.invitaton-help">
                                    by pressing <PersonAddIcon /> the user will receive a mail invitation 
                                </Trans>
                            </Typography>
                            <TextField  
                                value={userInvitation}
                                className={classes.InviteMailTextField}
                                onChange={(e) => {setUserInvitation(e.target.value)}}
                                id="userLogin" 
                                label={t("screen.customer-admin.user-login")} />    
                
                                <Button
                                    onClick={handleInviteUser}
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<PersonAddIcon />}
                                >
                                    {t("screen.customer-admin.invite")}
                                </Button>                                                           
                            </div>  
                            <Divider/>  
                            <Typography variant="subtitle1">{t("screen.customer-admin.invitations")}</Typography>
                            <List component="nav" className={classes.root} aria-label="users" dense>
                                {customer?.guests?.map( (item, key) => 
                                    <ListItem dense={true} key={key}>
                                        <ListItemIcon><PersonIcon fontSize="small"/></ListItemIcon>
                                        <ListItemText primary = {item.user.id}  />
                                        <ListItemText primary = {t("global.role."+(item.userRole).toLowerCase())}  />
                                        <ListItemIcon>
                                            <DeleteIcon onClick={()=>{deleteGuestConfirm(item.user.id)}} />
                                        </ListItemIcon>
                                    </ListItem>                              
                                )}
                                {customer?.guests.length===0 &&
                                    <ListItem dense={true}>
                                        <ListItemText primary = "Pas d'invitation en attente" />
                                    </ListItem>                              
                                }
                            </List>                  
                            <Divider/>                            
                        </div>                       
                    }                      
                </div>
  
 
                <Typography variant="subtitle1">{t("screen.customer-admin.users")}</Typography>                  

                <div  className={classes.userList}>
                {customer?.users?.filter(removeOwner).map( (item, key) => 
                    <Grid   key={key}  container  direction="row" justifyContent="space-between" alignItems="center">
                        <Grid xs={1} item><PersonIcon fontSize="small"/></Grid>
                        <Grid xs={3}item>{item.user.id}</Grid>
                        <Grid xs={6}item>
                            {!validateEmail(item.user.id) &&
                                <div> {item.user.email} </div>
                            }
                        </Grid>
                        <Grid xs={1}item>{t("global.role."+(item.userRole).toLowerCase())}</Grid>
                        <Grid xs={1}item>
                            <DeleteIcon onClick={()=>{deleteUserConfirm(item.user.id)}}  />                                 
                        </Grid>
                    </Grid>
                )}
                </div>

            </MyForm>
            </WithBackdrop>
            {/* <QuestionTest /> */}
            <AreYouSure title={t('screen.device-admin.warning')}  open={deleteGuestOpenConfirmQuestion} onClose={deleteGuestAnswerCallBack} >    
                    <div>{t('screen.customer-admin.invite-delete-warning')}</div>       
            </AreYouSure>
            <AreYouSure title={t('screen.device-admin.warning')}  open={deleteUserOpenConfirmQuestion} onClose={deleteUserAnswerCallBack} >    
                    <div>{t('screen.customer-admin.user-delete-warning')}</div>       
            </AreYouSure>               
      </div>

  );
}

