import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";

import GraphQlApi from '../mobile/MyGraphql';

import { API } from "aws-amplify";

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import ProbeIcon from '@material-ui/icons/FilterTiltShift';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import EditIcon from '@material-ui/icons/Edit';

import { toSnack } from '../Common';
import LockAdminAccessAdd from './LockAdminAccessAdd'


const useStyles = makeStyles(theme => ({
    root: {
        margin: "20px"
    },
    paragraph: {
        margin: 20,
        padding: 5
    },    
    card: {
        maxWidth: "800px"
    },
    loading: {
        marginTop: 50
    },    
    fab: {
        position: 'absolute',
        top: 70,
        //bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    noAlert: {
        marginTop: 30
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
}));

export default function LockAdminAccess(props) {
    const { data } = props;

    let { id: serviceId, probe: probeId } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [accessList, setAccessList] = useState([]);

    const [openAccess, setOpenAccess] = useState(false);
    const [accessMode, setAccessMode] = useState();
    
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [access, setAccess] = useState();

    useEffect(() => {
        console.log("data:",data)
    }, [data]);

    // recuperation du service concerné
    useEffect(() => {
        let ignore = false;
        async function fetchData(serviceId) {
            console.log("getting lock access for %s ...", serviceId);   
            const access = await getLockRest(serviceId);
            console.log("result:", access);   
            if (access) {
                setAccessList(access.data);
            }
        }  
        fetchData(serviceId);
        return () => { ignore = true; }

    }, [serviceId]);     

    // Appels aux API REST
    async function getLockRest(lockId) {
        console.log("getting Lock current access ... ", lockId);
        setLoading(true);
        setErrorMsg("");
        let myInit = {
            queryStringParameters: {
            type: "auth" // liste des autorisations en cours
            }
        }    
        try {
            const response = await API.get("iotLockAdmin", "/lock/" + lockId, myInit)
            //console.log("api:", response)
            setLoading(false);
            if (response.error) {
                setErrorMsg("Erreur lors de la récupération des information de la serrure : "+response.message);
                
            } else {
                return response;
            }
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            setLoading(false);
            setErrorMsg("Erreur lors de la récupération des information de la serrure : "+error.message)
        }
    }

    const handleAccess =  (async (mode, accessObj) => {
        console.log("Handle access: ", mode, accessObj)

        accessObj["serviceID"] = serviceId;

        if (mode==='new') {
            setAccessMode(mode)
            setAccess(accessObj)
            setOpenAccess(true);    

        } else {
            setAccessMode(mode)
            setAccess(accessObj)
            setOpenAccess(true); 
        }
    })

    const classes = useStyles();
    return (
        <div className={classes.root}>
                <div className={classes.alignItemsAndJustifyContent} >
                    {loading &&
                        <div  className={classes.loading}>
                            <CircularProgress size={40} />
                        </div>
                    }
                </div>         
                {!loading &&  
                    <>
                        <div> Liste des autorisations</div>

                        {accessList.map( (access, key) => 
                            <div  key={key}>
                                <Grid container   direction="row" justifyContent="center" alignItems="center">
                                    
                                    <Grid item xs={1}>
                                        <div onClick={()=>{handleAccess('edit', access)}}> 
                                            <EditIcon/>   
                                        </div>                                
                                                                    
                                    </Grid>
                                    <Grid item xs={11}>
                                        <div onClick={()=>{handleAccess('edit', access)}}> 
                                            {access.name}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {accessList.length===0 &&
                            <div className={classes.noAlert} >Pas encore d'autorisation</div>
                        }
                    </>
                }
            <Fab 
                onClick={()=>{handleAccess('new', {})}}
                color="primary" 
                aria-label="Add" 
                className={classes.fab}>
                    <AddIcon />
            </Fab>  
            <LockAdminAccessAdd isVisible={openAccess} access={access} title="new" mode={accessMode} onClose={()=> setOpenAccess(false)} />              
        </div>
  );
}
    
