import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import AdjustIcon from '@material-ui/icons/Adjust';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { useTranslation } from 'react-i18next';

import { mapToArray } from '../Common';
import GenericSensorImg from '../media/generic-sensor.jpg'

const useStyles = makeStyles({
    root: {
      width: "100%"
    },
    picture: {
      height: 70,
    },
    manufacturer: {
        fontSize: 10
    },
    device:{
        marginLeft: 8,
        marginRight: 8
    },
    icon: {
        minWidth: 30
    },
    row: {
        paddingTop: 0,
        paddingBottom: 0
    },
    demodevice: {
        backgroundColor: "#edbe3e",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center"
    }
});

export default function DeviceDetail(props) {
    const { device, callBackFunction, isDemoDevice } = props;
    const { t } = useTranslation();
    //console.log(JSON.stringify(device))
    //console.log(device)

    const [probes, setProbes] = useState([]);

    const [devicePictureUrl, setDevicePictureUrl] = useState();

    //const [isDemoDevice, setIsDemoDevice] = useState(false);

    useEffect(()=> {
        const probesDisplay  = JSON.parse(device.template.probesDisplay);
        const probes = mapToArray(probesDisplay); 
        probes.sort((a, b) => a.id.localeCompare(b.id));
        console.log("probes (sorted):",probes);
        setProbes(probes); 

/*         if (device.dataSource && device.dataSource==="CLONE") {
            setIsDemoDevice(true);
        } */
        
        // setDevicePictureUrl(device.template.pictureUrl
        //     ?device.template.pictureUrl
        //     :"https://device-pictures.s3-eu-west-1.amazonaws.com/generic-sensor.jpg");
        setDevicePictureUrl(device.template.pictureUrl
            ?device.template.pictureUrl
            :GenericSensorImg);            
            
    },[device])

    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Card >
            <Grid container>
                <Grid item>
                    <div className={classes.device}>
                        <img src={devicePictureUrl} className={classes.picture} alt="device" />
                    </div>
                </Grid>
                <Grid item xs>
                    <Typography variant="h6" component="h2">
                        {device.template.productName}
                    </Typography>
                    <Typography className={classes.manufacturer}>
                        {device.manufacturer}
                    </Typography>                    
                    <Typography variant="body2" color="textSecondary" component="p">
                        {device.template.description}
                    </Typography>
                </Grid>
            </Grid>
            {isDemoDevice &&
                <div className={classes.demodevice}>
                    Capteur de démonstration
                    <div>Les données seront fictives</div>
                </div>
            }
            <List className={classes.list}>
            {probes.map((probe,index) => 
                <div key={index}>    
                    {(probe.type !== "STATE_OUT") &&
                    <>
                    <Divider/>
                    <ListItem className={classes.row}>
                        <ListItemIcon className={classes.icon}>
                            <AdjustIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary={t("sensor.probe-type."+probe.type) + " (" +probe.unit + ")"}  /> 
                    </ListItem>
                    </>
                    }
                    {(probe.type === "STATE_OUT") &&
                    <>
                        {/* TODO Afficher plutot un bouton */}
                        <Divider/>
                        <ListItem className={classes.row}>
                            <ListItemIcon className={classes.icon}>
                                <AdjustIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText primary={t("sensor.probe-type."+probe.type) + " (" +probe.unit + ")"}  /> {/* EVOL1 */}
                        </ListItem>
                    </>
                    }                    
                </div>
            )}             
            </List>
        </Card>
      </div>
    )
  }