import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
    root: {
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
});

export default function Loading(props) {
    const { isLoading } = props;
    const classes = useStyles();
    return (
        <div className={classes.alignItemsAndJustifyContent}>
        {isLoading && <CircularProgress size={40} />}
        </div> 
  );
}

