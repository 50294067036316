import React, { useEffect, useState, useContext } from 'react';
import { API, graphqlOperation, Analytics } from "aws-amplify";
import GraphQlApi from '../mobile/MyGraphql';
import publicIp from 'public-ip';

import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import GeneralContext from '../components/GeneralContext'

import ServiceDisplay from '../components/ServiceDisplay'
import ServiceDisplayTest from '../components/ServiceDisplayTest'

import { isEmptyObject } from '../Common';
import ScanNewDevice from './ScanNewDevice'

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
    },
    minHeader: {
        height: 30, // ou 45,  ou 66
    },
    tempBuffer: {
        marginTop: 50,
    },
    main: {
        padding: "2px",
        backgroundColor: "red"
    },
    mainGrid: {
        textAlign: 'center'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },

    pos: {
        marginBottom: 12,
      },
    fab: {
      //margin: theme.spacing.unit,
      margin: 0,
      top: 'auto',
      //left: 20,
      bottom: 20,
      right: 'auto',
      position: 'fixed',      
    },
    service: {
        height: "100%",
        paddingRight: "2px",
        backgroundColor: "#fff"
    }
}));

function ServicesGrid(props) {

    const { mode, customerID } = props;

    let { id: groupId } = useParams();
    const { t } = useTranslation();

    const { user, customer } = useContext(GeneralContext);

    const [services, setServices] = useState();
    const [loading, setLoading] = useState(false);
    const [openDeviceScan, setOpenDeviceScan] = useState(false);

    const classes = useStyles();
    let history = useHistory();

    useEffect(() => {
        async function sendRecord() {
            console.log("ANALYTICS record Services grid end update endpoint infos");
            const ip = await publicIp.v4();
            console.log("ANALYTICS record Services grid");  
            Analytics.record({
                name: 'MainPage',
                attributes: { customer: customer.name, userName: user.id, ipAdr: ip },
                metrics: { pageLoad: 1 }
            });
        }
        //sendRecord();
    },[customer]);


    function scanDeviceCallBack(response) {
        setOpenDeviceScan(false);
        if (response && response.action && response.action==="attached") {
            history.push("/services/all");
        }
    }

/*     useEffect(() => {
        console.log("ServicesGRID INIT");
        return function cleanup() { 
            console.log("ServicesGRID CLEANUP INIT");
        }
    });
    useEffect(() => {
        console.log("ServicesGRID CROCHER");
        return function cleanup() { 
            console.log("ServicesGRID CLEANUP CROCHER");
        }
    },[]);     */


    useEffect(() => {
        console.log("processing services for mode ",mode)
        console.log("Customer: ",customer)
        console.log("User: ",user)
        let ignore = false;
        async function fetchCustomerServicesAll(customerId) {
            console.log("Fetching for all services of the Customer '%s'  is processing ...",  customerId);
            setLoading(true);
            const querielistServices = `query ListServices($id: ID!) {
                getCustomer(id : $id) {
                    id
                    services {
                        items {
                            id
                            name
                            serviceType
                            globalConfig
                            timeZone
                            device {
                                id
                                idMqtt
                                manufacturer
                                operator
                                status
                                dataSource
                                networkStatus
                                template {
                                    id
                                    probesDisplay
                                    displayConfig
                                }          
                            }
                            lastValue {
                                id
                                dateEvent
                                probes
                                slidingCumul
                                commOperator
                                customerId
                            }
                            probesConfig
                            dailyStats(limit:1, sortDirection:DESC) {
                                items {
                                    id
                                    eventDay
                                    values
                                }
                            }
                            alerts {
                                items {
                                    id
                                    probeID
                                    status                              
                                }
                            }                                                  
                        }
                    }
                }
            }`;
            //const result = await API.graphql(graphqlOperation(querielistServices,  { id: customerId} ));
            const result = await GraphQlApi.getInstance().graphqlOperation( querielistServices, { id: customerId});
            setLoading(false);
            if (result && result.data && result.data.getCustomer) {
                const services = result.data.getCustomer.services
                console.log("services from Db:", services);
                if (!ignore) {
                    setServices(services.items);
                }
            } else {
                console.log("Services non trouvé en base pour customer", customerId);
            }

        }          
        async function fetchServicesGroup(groupId) {
            console.log("Fetching for services of Group %s is processing ...", groupId);
            setLoading(true);
            const queryServicesFromGroup = `query getGrpLoc($id: ID!) {
                getGroupSvc(id : $id) {
                    id
                    name
                    services(limit: 50) {
                        items {
                            service {
                                id
                                name
                                serviceType
                                globalConfig
                                timeZone
                                device {
                                    id
                                    idMqtt
                                    manufacturer
                                    operator
                                    status
                                    template {
                                        id
                                        probesDisplay
                                        displayConfig
                                    }          
                                }
                                lastValue {
                                    id
                                    dateEvent
                                    probes
                                    commOperator
                                    customerId
                                    slidingCumul                               
                                }
                                probesConfig
                                dailyStats(limit:1, sortDirection:DESC) {
                                    items {
                                        id
                                        eventDay
                                        values
                                    }
                                }   
                                alerts {
                                    items {
                                        id
                                        probeID   
                                        status                           
                                    }
                                }                                                                        
                            }
                        }
                  }
                }
            }`;
            //const result = await API.graphql(graphqlOperation(queryServicesFromGroup,  { id: groupId }));
            const result = await GraphQlApi.getInstance().graphqlOperation( queryServicesFromGroup,  { id: groupId });
            setLoading(false);
            if (result.data.getGroupSvc) {
                const group = result.data.getGroupSvc
                console.log("group info from Db:", group);
                if (!ignore) {
                    if (group && group.services && group.services.items) {
                        setServices(group.services.items);
                    } else {
                        console.log("pas de service dans ce groupe");
                    }
                }
            } else {
                console.log("Groupe non trouvé en base");
            }

        }          
        async function fetchUserFavorites(userId, customerId) {
            console.log("Fetching for user  favorites services is processing ... ", userId, customerId);
            setLoading(true);
            const queryUserFavortites = `query UserServiceFavorites($id: ID!, $custId: ID!) {
                getUser(id : $id) {
                    id
                    favorites(
                            limit:10,
                            filter : { 
                                customerID: { eq: $custId}
                            }
                        ) {
                        items {
                            id
                            name
                            service {
                                id
                                name
                                serviceType
                                globalConfig                          
                                device {
                                    id
                                    idMqtt
                                    manufacturer
                                    operator
                                    status
                                    template {
                                        id
                                        probesDisplay
                                        displayConfig
                                    }          
                                }
                                probesConfig
                                lastValue {
                                    id
                                    dateEvent
                                    probes
                                    commOperator
                                    customerId  
                                    slidingCumul                                                                         
                                } 
                                dailyStats(limit:1, sortDirection:DESC) {
                                    items {
                                        id
                                      eventDay
                                      values
                                    }
                                }   
                                alerts {
                                    items {
                                      id
                                      probeID
                                      status
                                    }
                                }                                                                                             
                            }
 
                        }
                    }
                }
            }`;
            //const result = await API.graphql(graphqlOperation(queryUserFavortites,  { id: userId,  custId: customer.id  }));
            const result = await GraphQlApi.getInstance().graphqlOperation( queryUserFavortites,   { id: userId,  custId: customerId  });
            setLoading(false);
            if (result.errors) {
                console.log("result error from Db:", result.errors);
            }
            if (result.data.getUser) {
                const user = result.data.getUser
                console.log("user from Db:", user);
                if (!ignore) {
                    if (user && user.favorites && user.favorites.items) {
                        setServices(user.favorites.items);
                    } else {
                        console.log("Pas de favoris pour ce user");
                    }
                }
            } else {
                console.log("User non trouvé en base");
            }

        }     
        if (mode==="all") {
            if (customerID) {
                fetchCustomerServicesAll(customerID);
            } else {
                console.log("Attention : Customer est vide !")
            }
        } else  if (mode==="favorites") {
            if (!isEmptyObject(user) && user.id  && customerID ) {
                fetchUserFavorites(user.id, customerID);
            } else {
                console.log("Impossible d'afficher les services favories : le user n'est pas encore ready");
            }
        } else  if (mode==="group") {
            fetchServicesGroup(groupId);
        } else {
            console.log("Unknown mode");
        }
        return () => { ignore = true; }

    }, [mode, groupId, customerID]);

    // si le customer change, on rafraichi la liste des services ne mode All
/*     useEffect(() => {
        console.log("New Customer ...");
    }, [customer]);
 */
    return (
        <div className={classes.localHeader}>

            <div className={classes.minHeader}>
            {isEmptyObject(services) &&
                <div className={classes.tempBuffer}>
                    <div>&nbsp;</div>
                    {mode==="all" &&
                        <>
                            <div>{t('screen.devices-display.no-device-display')}</div>
                            {customer.isUserOwner &&
                                <div>
                                    <div>{t('screen.devices-display.owner-can-add')}
                                    <Button
                                        variant="contained"
                                        disabled={!user.ownCustomer}
                                        color="primary"
                                        className={classes.button}
                                        startIcon={<PlaylistAddIcon />}
                                        onClick={() => setOpenDeviceScan(true)}
                                    >
                                    {/*   Ajout */}
                                    </Button> 
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {mode==="favorites" &&
                     <div>{t('screen.devices-display.no-favorite-display')}</div>
                    }   
                    {mode==="group" &&
                     <div>{t('screen.devices-display.no-group-display')}</div>
                    }                                 
                </div>
            }
            </div>

            {/* 
            Qq soit la taille de l'ecran, il est divisé en 12 colonnes
            Si un, deux ou trois objet : centrée avec une largeur de 3 col (sur 12) au centre sur les grands ecrans
            Si plus de 4 objets a afficher : 
            1 objet (de 12 col) sur taille xs (smartphone)
            2 objet (de 6 col) sur taille sm et md (tablette)
            4 objet (de 3 col) sur taille lg et xl (ordinateur)
            */}



            <Grid container spacing={2} alignItems="stretch" justifyContent="space-evenly" className={classes.mainGrid}>
                {services?.map( (service, key) => 
                    <Grid item xs={12} sm={6} lg={3} key={key} >
                        <div className={classes.service}>
                           {/*  TEMPO  {service !== null && service.serviceType==='MEDIA_STREAM' && */}
                           {service !== null  &&

                            <ServiceDisplay customerId={customerID} data={service} /> 
                           } 
                        </div>
                    </Grid>
                )}
            </Grid> 


            {customer.isUserOwner &&
            <>
                <ScanNewDevice  openState={openDeviceScan}  toClose={scanDeviceCallBack}  />
                <Fab 
                    onClick={()=>{setOpenDeviceScan(true)}}
                    color="primary" 
                    aria-label="Add" 
                    className={classes.fab}>
                    <AddIcon />
                </Fab>
            </>
            }
        </div>
  );
}

export default ServicesGrid;