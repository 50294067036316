import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { askForChangingCustomer} from '../Common';
//import color from '@material-ui/core/colors/amber';

const useStyles = makeStyles(theme => ({
  root: {
    //display: 'flex',
  },
  formControl: {
   // margin: theme.spacing(1), 
   // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },  
  custSelect: {
    color: "white"
  }
}))

export default function SwitchCustomer(props) {

  const { customers: userCustomers, defaultCustId } = props;
//probes.sort((a, b) => a.id.localeCompare(b.id));

  const { t } = useTranslation();
  const classes = useStyles();
  const greaterBig = useMediaQuery('(min-width:700px)');
  const greaterMedium = useMediaQuery('(min-width:350px)');

  const [newCustomerId, setNewCustomerId] = useState('');
  const [nameLength, setNameLength] = useState(12);

  useEffect(() => {
    if (defaultCustId) {
      setNewCustomerId(defaultCustId)
    }
  },[defaultCustId])

  useEffect( () => {
    //console.log("breakpoint changed : ", greaterBig, greaterMedium, greaterDrawerBrkPt, greaterSmall)
    // vide > tronqué (drawer) vide > tronqué > complet 
    if (greaterBig) {
      //console.log("customer big")  
      setNameLength(40); 
    } else if (greaterMedium) {
      //console.log("customer Medium")  
      setNameLength(13); 
    } else { 
      //console.log("customer small")
      setNameLength(9);
    }      
  },[greaterBig, greaterMedium]);  

  const handleChange = event => {
    const newCustId = event.target.value;
    setNewCustomerId(newCustId);
    console.log("setNewCustomerId :",newCustId)

    askForChangingCustomer(newCustId);
  };

  const isOwner = ( (userRole) => {
    if (userRole==="OWNER") 
      return '*';
    else 
      return '';
  })

  const leftPart = ((fullCustomer) => {
    // TODO Recuperer la taille de l'ecran (mobile ou pc)
    // voir pour ne tronquer que le titre et pas la liste
    //  const isWideScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const maxCar = nameLength;
    if (fullCustomer.length<maxCar) {
      return fullCustomer
    } else {
      return fullCustomer.substring(0,maxCar)+"..."
    }
  })
  
  function sortByName(customers) {
    return customers.sort((a, b) => a.customer.name.localeCompare(b.customer.name));
  }

  return (
    <div className={classes.root} >

        <FormControl className={classes.formControl}>
          <Select
            labelId="current-customer-choice"
            id="customer-select"
            value={newCustomerId}
            displayEmpty
            autoWidth
            onChange={handleChange}
            className={classes.custSelect}
          >
          <MenuItem value="" disabled> {t('global.menu.select')} </MenuItem>
            {sortByName(userCustomers).map( (item, key) => 
              <MenuItem  key={key} value={item.customer.id} >{leftPart(item.customer.name)+isOwner(item.userRole)}</MenuItem>   
            )}      
          </Select>
        </FormControl>

    </div>
  );
}

