const config = {
    "ludophone" : {
        "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/ludophone/logo.png",
        "www" : "www.ludophone.com",
        "title": "Ludophone",
        "authent": {
            "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/ludophone/logo.png",
            "title": "Ludophone",
            "theme": {
                "formContainer": { 'marginTop': 0 },
                "formSection": { 'marginTop': 20 },
            }
        }       
    },
    "ozone" : {
        "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/silvercom/silvercom_50x50.png",
        "www" : "www.ozone.fr",
        "title": "Ozone",
        "authent": {
            "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/silvercom/silvercom_50x50.png",
            "title": "Ozone",
            "theme": {
                "formContainer": { 'marginTop': 0 },
                "formSection": { 'marginTop': 20 },
            }
        }       
    },     
    "visio-green-africa" : {
        "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/visio-green-africa/logo.png",
        "www" : "www.visiogreen.io",
        "title": "Visiogreen Africa",
        "authent": {
            "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/visio-green-africa/logo.png",
            "title": "", // le titre est dans le logo
            "theme": {
                "formContainer": { 'marginTop': 0 },
                "formSection": { 'marginTop': 20 },
            }
        }       
    },
   "home" : {
        "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/home/logo_new_bleu.png",
        "www" : "www.myiotplatform.com",
        "title": "My Iot Platform",
        "authent": {
            "logo": "https://my-iot-portal.s3-eu-west-1.amazonaws.com/home/logo_new_bleu.png",
            "title": "My Iot Platform",
            "theme": {
 //               "container": { 'backgroundColor': "green"},
                "formContainer": { 'marginTop': 0 },
                "formSection": { 'marginTop': 20 },
            }
        }
   }
}

export default config;