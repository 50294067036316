import React, {useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { API } from "aws-amplify";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { isHex } from '../Common';
import Loading from './Loading';

const useStyles = makeStyles(theme => ({
    root: {
    },
    displayError: {
        color: "red"
    }
}));

export default function SwapDeviceDialog(props) {
    const { open, service, onClose } = props 

    const { t } = useTranslation();
    const [openState, setOpenState] = useState(false);
    const [deveuiField, setDeveuiField ] = useState("");

    const [restErrorDisplay, setRestErrorDisplay ] = useState("");
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setOpenState(open)
    }, [open])

    const handleCancel = () => {
        //setOpenState(false);
        onClose(false)
    };  
    const handleConfirm = async () => {
        if (!deveuiField) {
            setRestErrorDisplay("L'identifiant doit être saisi");
            return;
        }
        if (!isHex(deveuiField)) {
            setRestErrorDisplay("L'identifiant doit être au format hexadecimal (0-9abcdf)");
            return;
        }        
        
        try {
            setLoading(true);
            const response = await swapServiceRest(service.device.id, deveuiField)
            setLoading(false);
            if (!response) {
                // tout s'est bien passé
                onClose(true);
            } else {
                setRestErrorDisplay(response)
            }


        } catch (error) {
            console.log(error)
            setLoading(false);
            setRestErrorDisplay(error.message)
        }
    };    

    async function swapServiceRest(deveuiOrigin, deveuiTarget) {
        console.log("Swap service...",deveuiOrigin, deveuiTarget);
        var error;
        let myInit = {}    
        try {
            const response = await API.post("iotAttachDevice", "/devices/" + deveuiOrigin +"/swap/"+ deveuiTarget +"", myInit)
            console.log("api:", response)
            if (!response.error) {
                // tout est ok
            } else {
                error = response.message
            }
        }
        catch (error) {
          //console.log(error.response)
          console.log(error)
          error = error.message;
        }
        return error;
      }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openState}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
             <DialogTitle id="alert-dialog-title">Changement de matériel</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Vous souhaitez remplacer le capteur tout en gardant les données et la configuration associée
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Identifiant actuel : {service?.device.id}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Modèle : {service?.device.template.id} / {service?.device.template.productName}
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                        Ce nouveau capteur doit être du même modèle que le précédent (même nombre de sonde) 
                        et l'identifiant ne doit contenir les caractères 0123456789abcdef)
                    </Typography>                                                

      
                    <form className={classes.root} noValidate autoComplete="off">
                        <TextField 
                            id="standard-basic" 
                            label="Identifiant (deveui)" 
                            value= {deveuiField}
                            onChange={e => setDeveuiField(e.target.value)}
                            />      
                    </form>
                    <div className={classes.displayError}>
                        {restErrorDisplay}
                    </div>
                    <Loading isLoading={loading} />
                </DialogContent>   
            <DialogActions>
                <Button onClick={handleCancel} color="primary" autoFocus>
                    {t('global.button.cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" >
                    {t('global.button.confirm')}
                </Button>
            </DialogActions>
        </Dialog>  
    )
}