import React, { useEffect, useState, useContext } from 'react';
//import { useParams } from "react-router-dom";

import { API, Auth } from "aws-amplify";
import GraphQlApi from '../mobile/MyGraphql';

import { useLocation, useHistory } from "react-router-dom";
import { useTranslation} from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import BusinessIcon from '@material-ui/icons/Business';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import CancelPresentationRoundedIcon from '@material-ui/icons/CancelPresentationRounded';
import CheckIcon from '@material-ui/icons/Check';

import GeneralContext from '../components/GeneralContext'

import { askForRefreshingUser, askForSettingFirstCustomer, toSnack, validateEmail} from '../Common';
import MyForm from '../components/MyForm'
import AreYouSure from '../components/AreYouSure'
import WithBackdrop from '../components/WithBackdrop'
 
const useStyles = makeStyles(theme => ({
    root: {
    },
    textFieldMail: {
        width: '40ch',
    },
    detail: {
        fontStyle: "italic"
    },
    button: {
        margin: theme.spacing(1),
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },   
    createCustWarning: {
        color: "orange",
        //padding: "10px",
        margin: "0px 20px 0px 20px" 
    } ,
    expansionHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },  
    expansionDetail: {
        dislay: "block"
    },
    editField: {
        marginTop: 20
    },
    insideTitle: {
        marginTop: 20
    },
    companyName: {
        width: '40ch',
    },
    companyAddress: {
        width: '40ch',
    }

  }));



export default function UserProfile(props) {

    //const {  } = props;
   
    //const { exe_env_name } = awsconfig;

    const { user, customer } = useContext(GeneralContext);
    const { t } = useTranslation();

    const [openAYSRemoveCust, setOpenAYSRemoveCust] = useState(false);
    const [customerIdToDel, setCustomerIdToDel] = useState();

    const [activationCode, setActivationCode] = useState("");

    const [userID, setUserID] = useState("");
    const [userMail, setUserMail] = useState("");
    const [userPhone, setUserPhone] = useState("");

    const [cognitoCodeSent, setCognitoCodeSent] = useState(false);
    const [cognitoCode, setCognitoCode] = useState("");

    const [userLangue, setUserLangue] = useState("");
    const [userCountry, setUserCountry] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companySiret, setCompanySiret] = useState("");

    const [ownCustomer, setOwnCustomer] = useState();

    const [invitations, setInvitations] = useState();

    const [loading, setLoading] = useState(false);
    const [backdropActivationOpen, setBackdropActivationOpen] = useState(false);
    const [backdropNewCustomerOpen, setBackdropNewCustomerOpen] = useState(false);
    const [urlHash, setUrlHash] = useState();

    let history = useHistory()
    let location = useLocation();


    const classes = useStyles();
    const inputPropsFullLine = {
        fullWidth: true,
    };
    const inputPropsMultiline = {
        multiline: true,
    };      

    function handleCodeChange(e) {
        setActivationCode(e.target.value)
    }

    useEffect(() => {
        console.log("get invitations")
        let ignore = false;
        async function fetchUserInvitations(userId) {
            console.log("Fetching for user invitations ...");
            setLoading(true);
            const theQuery = `query GetUser($id: ID!) {
                getUser(id: $id) {
                    id
                    invitations {
                        id
                        userRole
                        customer {
                            id
                            name
                            description
                        }
                    }
                }
            }`;
            //const result = await API.graphql(graphqlOperation(querielistServices,  { id: customerId} ));
            const result = await GraphQlApi.getInstance().graphqlOperation( theQuery, { id: userId});
            setLoading(false);
            if (result && result.data && result.data.getUser) {
                const invitations = result.data.getUser.invitations
                if (invitations) {
                    console.log("invitations:", invitations);
                    if (!ignore) {
                        setInvitations(invitations);
                    }
                }
            } else {
                console.log("User non trouvé en base");
            }
        }
        const userId = user.id;
        fetchUserInvitations(userId);
        return () => { ignore = true; }
    }, [user]);

    async function handleCustomerInvitation(customerId, action) {
        setLoading(true);
        try {
            if (action==="accept") {
                await joinCustomerRequestAcceptRest(customerId);    
                toSnack("UpdateInvitation","L'acceptation de l'invitation a réussie"); 

            } else if (action==="cancel") {
                await joinCustomerRequestCancelRest(customerId, user.id);
                toSnack("UpdateInvitation","L'annulation de l'invitation a réussie");

            }
            askForRefreshingUser(user.id);   
            setLoading(false);
        }
        catch (error) {
            console.log("Error when updateUserInvitation:",error);
            setLoading(false);
            toSnack("UpdateInvitation","Erreur lors du traitement de l'invitation");
        }
    }

    async function joinCustomerRequestAcceptRest(customerId) {
        console.log("joinCustomerRequestRest:", customerId);

        var apiPath = "/customers/"+customerId+"/joinrequest"

        console.log("API REST - Join request :",apiPath)
        var response;
        try {
            response = await API.put("apiRestCustomer", apiPath, {});
            console.log("response:",response);
            if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
    }
    async function joinCustomerRequestCancelRest(customerId, userId) {
        console.log("joinCustomerRequestRest:", customerId);

        var apiPath = "/customers/"+customerId+"/guest/"+userId;

        console.log("API REST - Join request :",apiPath)
        var response;
        try {
            response = await API.del("apiRestCustomer", apiPath, {});
            console.log("response:",response);
            if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
    }    

    async function customerCreate() {
        console.log("customerCreate:",companyName)
        if (!companyName) return;
        setLoading(true);
        const newCustomerId = await customerNewRest({name: companyName, address: companyAddress, siret: companySiret }); // et refresh customer 
        setLoading(false);
        toSnack("CustomerCreated",t('screen.user-admin.customer-wellcreated'));
        // le customer de l'utilisateur est crée. Forcement pas encore de device. On part sur / pour l'accueil
        //history.push("/")
        if (newCustomerId) {
            console.log("Actualisation du 1er customer courant")
            askForSettingFirstCustomer(newCustomerId);    
            askForRefreshingUser(user.id);      
        }
    }
    
    async function customerEdit() {
        console.log("modification du ownCustomer")
        const customer = { name: companyName, address: companyAddress, siret: companySiret}
        setLoading(true);
        await updateCustomerMutation(user.ownCustomer.id, customer)
        setLoading(false);
        toSnack("CustomerUpdated",t('screen.user-admin.customer-wellupdated')); 
    }  

    async function userEdit() {
        return;
        setLoading(true);
        const modifiedUser = { userMail: userMail,  langue: userLangue, country: userCountry }
        await updateUserMutation(user.id, modifiedUser)
        setLoading(false);
        toSnack("UserUpdated",t('screen.user-admin.info-wellupdated'));
    }  

    async function handleEditMailRequestCode() {
        setLoading(true);
        console.log("change mail request");
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log("currentAuthenticatedUser:",user);
            console.log("userMail:",userMail);
            await Auth.updateUserAttributes(user, { email: userMail });
            await Auth.verifyCurrentUserAttribute('email');
            //await Auth.updateUserAttributes(user, { nickname: "joe" });
            setLoading(false);
            setCognitoCodeSent(true);

        } catch (error) {
            console.log("Error when updateUserAttributes:",error);
            setLoading(false);
            toSnack("UpdateMail","Erreur lors de la modification du mail ");
        }        
    }

    async function handleEditMailConfirmCode() {
        const code = cognitoCode;
        setLoading(true);
        try {
            await Auth.verifyCurrentUserAttributeSubmit("email", code);
            console.log("email in Cognito well updated");
            await updateMailInDb(userMail);
            console.log("email in DB well updated with ",userMail);
            setLoading(false);
            toSnack("UpdateMail","L'adresse mail a bien été modifée");
            setCognitoCodeSent(false);

        } catch (error) {
            console.log("Error when updateUserAttributes:",error);
            setLoading(false);
            toSnack("UpdateMail","Erreur lors de la modification du mail ");
        }  
    }

    async function updateMailInDb(userMail) {
        const modifiedUser = { userMail: userMail }
        await updateUserMutation(user.id, modifiedUser)
    }

    async function privateCustomerRequest() {
        const genericCode = activationCode;
        // a ce stade, on ne connait pas le nom du customer
        history.push("/invitation/private?code="+genericCode+"&customer="+genericCode)

/*         if (genericCode) {
            console.log("activationCode:",genericCode);
            await joinCustomerRequestRest({ mode: 'open', activationCode : genericCode }) 
            toSnack("UserUpdated","Inscription à l'organisation reussie");
        } */
         // refresh customer ...
    }

    async function handleInvitationDelete(id) {
        console.log("delete invitation : ", id);
    }



    async function handleCustomerDelete(id) {
        console.log("Customer delete :", id);
        setCustomerIdToDel(id)
        setOpenAYSRemoveCust(true)
    }

    async function handleCustomerDeleteConfirmed(responseOk) {
        if (responseOk) {
            console.log("handleCustomerDelete:", customerIdToDel)
            try {
                await customerDeleteRest(customerIdToDel);
                setOpenAYSRemoveCust(false)
                askForRefreshingUser(user.id);            
            }
            catch (error) {
                toSnack("CustomerDelete",t('screen.user-admin.error-whiledelete'));
                setOpenAYSRemoveCust(false)
            }
        } else {
            console.log("canceled");
            setOpenAYSRemoveCust(false)
        }
    }
    async function customerDeleteRest(customerId) {
        console.log("customerDeleteRest : ",customerId);       
        var response;
        try {
            response = await API.del("apiRestCustomer", "/customers/"+customerId+"/joinrequest", {});
             if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    }


    async function TO_DELETEjoinCustomerRequestRest(data) {
        console.log("joinCustomerRequestRest:", data);

        var apiPath;

        if (data.mode === 'public') {
            apiPath= "/customers/"+data.customerId+"/joinrequest";

        } else if (data.mode==='open') {
            apiPath= "/customers/generic/joinrequest/"+"?code="+data.activationCode;

        } else if (data.mode==='private') {
            apiPath= "/customers/"+data.customerId+"/joinrequest"

        }
 
        //TODO verifier que lformat du user est bien un mail / oun un userId

        let myInit = {
        body: {
            param: "valeur", 
        }, 
        headers: {} // OPTIONAL
        }    
        
        console.log("API REST - Join request :",apiPath)
        var response;
        try {
            response = await API.put("apiRestCustomer", apiPath, myInit);
            if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;

            } else {
                console.log("api:", response)
                
            } 
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
    }

    useEffect( () => {
        console.log("LOCATION:",location);
        setUrlHash(location.hash);
        if (location.hash==="#newcustomer") {
            setBackdropNewCustomerOpen(true);
        }
        else if (location.hash==="#orgaccess") {
            setBackdropActivationOpen(true);
        }
    },[location]);


    function backdropClose() {
        setBackdropActivationOpen(false);
        setBackdropNewCustomerOpen(false)
        history.push("/home");
    }

    useEffect( () => {
        async function fetchData(customerId) {
                console.log("Customer loading for (%s) ...",customerId);
                const getQuery = `query GetCustomer($id: ID!) {
                    getCustomer(id: $id) {
                        id   
                        name
                        address 
                        siret                                                                 
                    }
                }
                `;
                try {
                    //const result = await API.graphql(graphqlOperation(getQuery, { id: customerId }));
                    const result = await GraphQlApi.getInstance().graphqlOperation( getQuery, { id: customerId });
                    if (result.data && result.data.getCustomer) {
                        console.log("Result customer:", result.data.getCustomer);

                        const ownCustomer = result.data.getCustomer;
                        setCompanyName(ownCustomer.name)
                        setCompanyAddress(ownCustomer.address? ownCustomer.address : "");
                        setCompanySiret(ownCustomer.siret? ownCustomer.siret : "");

                        setOwnCustomer(ownCustomer); // est-ce utile ?

                    } else {
                        console.log("Data not found");
                    }
                } 
                catch(resultErrors) {
                    const errors = resultErrors.errors?resultErrors.errors:(resultErrors.graphQLErrors?resultErrors.graphQLErrors:null);
                    console.log("Graphql errors: ",errors);
                }
        }
        setUserID(user.id?user.id:"");
        // chargement initial à partir du context
        if (user.email) setUserMail(user.email)
        if (user.phone) setUserPhone(user.phone)
        if (user.langue) setUserLangue(user.langue)
        if (user.country) setUserCountry(user.country)

        if (user.ownCustomer) {
            console.log("Le user possède un customer %s, on le charge from db...", user.ownCustomer.id);
            fetchData(user.ownCustomer.id);    
            return function cleanup() {
                console.log(`useEffect() cleanup.`)
            };            
        }        

    }, [user]);

    async function updateCustomerMutation(customerId,customer) {
        const toUpdate = {
            id: customerId
        };
        if (customer.name) { toUpdate["name"] =customer.name }
        if (customer.address) { toUpdate["address"] =customer.address }
        if (customer.siret) { toUpdate["siret"] =customer.siret }

        console.log("toUpdate:",toUpdate);
        const updateMutation = `mutation updateCustomer($input: UpdateCustomerInput!) {
          updateCustomer( input: $input) {
              id
          }
        }`;
        //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
        console.log("customer updated", response.data.updateCustomer);
    }

    async function customerNewRest(newCustomer) {
        // API REST : 
        //      Transaction (creation de trois en enreg dans 3 tables)
        //      Recuperation du userId de celui qui lance la transaction
        console.log("POST customer api width : ",newCustomer);
        
        let myInit = {
          body: {
            name: newCustomer.name,
          }, 
          headers: {} // OPTIONAL
        } 

        if (newCustomer.siret) {
            myInit.body['siret'] = newCustomer.siret;
        }
        if (newCustomer.address) {
            myInit.body['address'] = newCustomer.address;
        }       
        //console.log(myInit);        
        var response;
        try {
          // creation d'un Customer
          response = await API.post("apiRestCustomer", "/customers", myInit);
          console.log("api response:", response)
          
          if (response && response.status && response.status.id) {
            const newCustomerId = response.status.id;
            console.log("Customer well created : ", newCustomerId)
            return newCustomerId;
            //console.log("Actualisation du customer courant")
            //askForChangingCustomer(newCustomerId);    
          } else {
              console.log("Erreur : Impossible de recuperer l'identifiant du customer créé. Impossible de switcher vers le customer");
          }
        }
        catch (error) {
          console.log("Erreur lors de la creation d'un customer : ", error);
        }
    }

    async function updateUserMutation(userId, user) {
        const toUpdate = {
            id: userId
        };
        if (user.userMail) { toUpdate["email"] = user.userMail }
        if (user.langue) { toUpdate["langue"] = user.langue }
        if (user.country) { toUpdate["country"] = user.country }

        console.log("toUpdate:",toUpdate);
        const updateMutation = `mutation updateUser($input: UpdateUserInput!) {
          updateUser( input: $input) {
              id
          }
        }`;
        //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
        console.log("user updated", response.data.updateUser);
    }

    // prevu pour factoriser les champs du formulaire en mode creation/edit/view
    // non utilisé : pbs sur les setXXX qui ne sont pas dans le même composant (pere/fils)
    function CustomerForm() {
        return (
            <div>
                <div>
                    <TextField 
                        value={companyName}
                        onInput={ e=>setCompanyName(e.target.value)}                    
                        id="company-name" 
                        label="Nom société" />
                </div>
                <div>
                    <TextField 
                            value={companyAddress}
                            onInput={ e=>setCompanyAddress(e.target.value)}                    
                            id="postal-adress" 
                            label="Adresse postale" />
                </div>                    
                <div>
                    <TextField 
                            value={companySiret}
                            onInput={ e => setCompanySiret(e.target.value)}                    
                            id="siret-siren" 
                            label="SIRET / SIREN" />
                </div>
            </div>
        )
    }
    function isEmailFormat(userId) {
        return validateEmail(userId);
    }

    return (
        <div>
            <div className={classes.alignItemsAndJustifyContent}>
              {loading && <CircularProgress size={40} />}
            </div>

            <MyForm title={t('screen.user-admin.user-title')}  >
                <div className={classes.editField}>
                    <TextField 
                    disabled 
                    id="standard-disabled" 
                    label={t('screen.user-admin.ident')} 
                    className={classes.textFieldMail} 
                    value={userID} />
                </div>
                {!isEmailFormat(userID) &&
                    <>
                    {!cognitoCodeSent &&
                        <Box display="flex" flexDirection="row" alignItems="center" className={classes.editField}>
                            <Box>
                                <TextField 
                                    id="email" 
                                    onInput={ e =>setUserMail(e.target.value)}     
                                    label={t('screen.user-admin.mail')} 
                                    value={userMail} 
                                    className={classes.textFieldMail}  />                               
                            </Box>
                            <Box alignSelf="flex-end">
                                <EditIcon onClick={handleEditMailRequestCode}/>
                            </Box>
                        </Box>                              
                    }
                    {cognitoCodeSent &&
                        <div className={classes.editField}>
                            <div>{t('screen.user-admin.user-code-mail')}({userMail})</div>
                            <Box display="flex" flexDirection="row" alignItems="center" >
                                <Box>
                                    <TextField 
                                        id="Code-mail" 
                                        onInput={ e=>setCognitoCode(e.target.value)}     
                                        label={"Code "} 
                                        value={cognitoCode} 
                                        className={classes.textFieldMail}  />
                                </Box>
                                <Box alignSelf="flex-end">
                                    <SaveIcon onClick={handleEditMailConfirmCode}/>
                                </Box>
                            </Box>
                        </div> 
                    }
                    </>      
                }
{/*                
                <div className={classes.editField}>
                    <TextField disabled id="standard-disabled" label={t('screen.user-admin.phone')} value={userPhone} />
                </div>
                <div className={classes.editField}>
                    <TextField 
                            disabled
                            value={userLangue}
                            onInput={ e=>setUserLangue(e.target.value)}                    
                            id="standard-disabled" 
                            label={t('screen.user-admin.language')} />
                </div>
                <div className={classes.editField}>
                  <TextField  id="standard-disabled" label="Pays" defaultValue="France"/> 
                    <div>Pays</div>
                    <CountrySelect></CountrySelect>
                </div>
 */}

            </MyForm>

            {!ownCustomer && 
                <div >
                    <div></div>
                    <div className={classes.createCustWarning}>
                        <div>
                        {t('screen.user-admin.txt-desc1')}
                        </div>
{/*                         <div>
                        {t('screen.user-admin.txt-desc2')}
                        </div>   
                        <div>
                        {t('screen.user-admin.txt-desc3')} 
                        </div>  */}   
                    </div>  

                    <WithBackdrop open={backdropNewCustomerOpen} isOpen={backdropNewCustomerOpen}>
                    <MyForm title={t('screen.user-admin.customer-create')} save action={customerCreate} closeAction={backdropClose} isBackdropOpen={backdropNewCustomerOpen}>
                    <div>
                        <div>
                            <TextField 
                                value={companyName}
                                onInput={ e=>setCompanyName(e.target.value)}                    
                                id="company-name" 
                                fullWidth
                                label={t('screen.user-admin.compnany-name')} />
                        </div>
                        <div>
                            <TextField 
                                    value={companyAddress}
                                    onInput={ e=>setCompanyAddress(e.target.value)}     
                                    multiline
                                    fullWidth               
                                    id="postal-adress" 
                                    label={t('screen.user-admin.address')} />
                        </div>                    
                        <div>
                            <TextField 
                                    value={companySiret}
                                    onInput={ e=>setCompanySiret(e.target.value)}                    
                                    id="siret-siren" 
                                    label={t('screen.user-admin.siret')}/>
                        </div>
                    </div>
                    </MyForm>
                    </WithBackdrop>    
                </div>
            }

            <MyForm title={t('screen.user-admin.other-cust-title')}>

                {user.customers?.length===0 &&
                    <div className={classes.detail}>
                        {t('screen.user-admin.cust-empty')}
                    </div>
                }
                {user.customers?.length>0 &&
                    <>
                        <div className={classes.detail}>
                        {t('screen.user-admin.cust-your-cust')}
                        </div>
                        <div>
                        {user.customers?.map( (item, key) => 
                            <Grid   key={key}  container  direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={1} item><BusinessIcon fontSize="small"/></Grid>
                                <Grid xs={6}item>{item.customer.name}</Grid>
                                <Grid xs={4}item>{item.userRole}</Grid>
                                <Grid xs={1}item>
                                    {item.userRole !=='OWNER' &&
                                        <DeleteIcon onClick={()=>{handleCustomerDelete(item.customer.id)}} />
                                    }
                                    {item.userRole ==='OWNER' &&
                                        <div></div>
                                    }                                    
                                </Grid>
                            </Grid>
                        )}
                        </div>
                    </>
                }


                <Typography variant="body2">
                    {t('screen.user-admin.cust-private-txt')}
                </Typography>
                <hr/>
                {invitations?.length===0 &&
                    <div className={classes.detail}>
                        {t('screen.user-admin.no-pending-invitation')}
                    </div>
                }
                {invitations?.length>0 &&
                    <>
                        <div className={classes.detail}>
                        {t('screen.user-admin.your-invitations')}
                        </div>
                        <div>
                        {invitations?.map( (item, key) => 
                            <Grid   key={key}  container  direction="row" justifyContent="space-between" alignItems="center">
                                <Grid xs={1} item><BusinessIcon fontSize="small"/></Grid>
                                <Grid xs={8}item>{item.customer.name}</Grid>
                                <Grid xs={3}item>
                                    <Grid   key={key}  container  direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid  item>
                                            <IconButton onClick={()=>{handleCustomerInvitation(item.customer.id,"cancel")}} aria-label="cancel">
                                                <CancelPresentationRoundedIcon  />
                                            </IconButton>                                              
                                        </Grid>
                                        <Grid  item>
                                            <IconButton onClick={()=>{handleCustomerInvitation(item.customer.id, "accept")}} aria-label="accept" >
                                                <CheckIcon  />
                                            </IconButton>   
                                        </Grid>                                        
                                    </Grid>                           
                                </Grid>
                            </Grid>
                        )}
                        </div>
                    </>
                }

            </MyForm>

            <WithBackdrop open={backdropActivationOpen} isOpen={backdropActivationOpen}>
            <MyForm title={t('screen.user-admin.code-generic-access')} closeAction={backdropClose} isBackdropOpen={backdropActivationOpen}>
                <div className={classes.ExpansionDetail}>
                    <Typography variant="body2">
                    {t('screen.user-admin.cust-generic-txt')}
                    </Typography>
                    <div>
                        <TextField  
                            value={activationCode}
                            onChange={(e) => {setActivationCode(e.target.value)}}
                            id="standard-disabled" 
                            label= {t('screen.user-admin.cust-activation-code')} />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<SendIcon/>}
                            onClick={privateCustomerRequest}
                        >
                            {t('screen.user-admin.button.cust-request-send')}
                        </Button>                    
                    </div>       
                </div>                   
            </MyForm>
            </WithBackdrop>

            {ownCustomer && 

                <MyForm title={t('screen.user-admin.cust-own')} edit action={customerEdit} >
                    <div>
                        <div>
                            <TextField 
                                value={companyName}
                                onInput={ e=>setCompanyName(e.target.value)}                    
                                id="company-name" 
                                fullWidth
                                label={t('screen.user-admin.cust-own-txt')} />
                        </div>
                        <div>
                            <TextField 
                                    value={companyAddress}
                                    onInput={ e=>setCompanyAddress(e.target.value)}                    
                                    id="postal-adress" 
                                    multiline
                                    fullWidth
                                    label={t('screen.user-admin.cust-own-address')} />
                        </div>                    
                        <div>
                            <TextField 
                                    value={companySiret}
                                    onInput={ e=>setCompanySiret(e.target.value)}                    
                                    id="siret-siren" 
                                    label={t('screen.user-admin.cust-own-siret')} />
                        </div>
                    </div>
                </MyForm>
       
            }


            <AreYouSure open={openAYSRemoveCust} title={t('screen.user-admin.warnig-delete')} onClose={handleCustomerDeleteConfirmed} >    
                <div>{t('screen.user-admin.warning')}</div>           
            </AreYouSure>
        </div>

  );
}

