import React, {useContext, useState, Suspense} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import GeneralContext from '../components/GeneralContext'
//import PdfViewer from "../components/PdfViewer";

// creation d'un bundle séparé
const PdfViewer = React.lazy(() => import('../components/PdfViewer')); 

//import samplePDF from "./sample.pdf";
//const samplePDF = "https://my-iot-portal.s3.eu-west-1.amazonaws.com/home/myIOTplatformHelpDoc.pdf";
const samplePDF = "https://docs.google.com/document/d/1QeCnWYJcdHvL7s5oP7Qt42U0QJPn0j8X6wng9Bn0jBE/export?format=pdf";
//https://docs.google.com/document/d/1QeCnWYJcdHvL7s5oP7Qt42U0QJPn0j8X6wng9Bn0jBE/export?format=pdf  #bookmark=id.naxqp7vjpx0j

const useStyles = makeStyles({
    root: {
        alignItems: 'center',
    },
});

export default function AboutPage() {
    const { portal } = useContext(GeneralContext);

    const [numPages, setNumPages] = useState(null);
    //const [pageNumber, setPageNumber] = useState(1);

/*     function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    } */
    
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Suspense fallback={<div>Chargement...</div>}>
                <PdfViewer pdf={samplePDF} />
           </Suspense>
        </div>
  );
}

