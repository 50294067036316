import React, { useContext, useState, useEffect} from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles';

import {Switch, Route, useHistory, useLocation} from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MyModalDialog from '../components/MyModalDialog'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Hub } from '@aws-amplify/core';
import moment from 'moment'

import { useTranslation } from 'react-i18next';

import GeneralContext from '../components/GeneralContext'
import {isEmptyObject, getPathFromHomepage} from '../Common';

import ServicesGrid from '../pages/ServicesGrid'
import HomePage from '../pages/HomePage'
import AdminCustomerMain from '../pages/AdminCustomerMain'
import AdminCustomerUsers from '../pages/AdminCustomerUsers'
import AboutPage from '../pages/AboutPage'

import UserProfile from '../pages/UserProfile'
import ProbePage from '../pages/ProbePage'

import ServiceAdmin from '../pages/ServiceAdmin'
import ProbesEditDetail from '../components/ProbesEditDetail'
import ServiceProbeAlerts from '../pages/ServiceProbeAlerts'

import ServiceViewPage from '../pages/ServiceViewPage'
import InvitationPage from '../pages/InvitationPage'
import HelpPage from '../pages/HelpPage'

import ServiceGraphPage from '../pages/ServiceGraphPage'
import CustomHomePage from './home/CustomHomePage'
import LockAdminAccess from '../pages/LockAdminAccess'
import LockAccessDisplay from '../pages/LockAccessDisplay';



const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    myHeader: {
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));
  
  export default function AppMain({customerID}) {

  const { portal, user, customer } = useContext(GeneralContext);

  const history = useHistory();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    console.log("AppMain (routage) init");
    Hub.listen('toSnack', handleSnackEvents);
    return function cleanup() {
      console.log(`Main useEffect() cleanup.`)
      Hub.remove('toSnack', handleSnackEvents)
    };    
  }, []) 
  
  useEffect(() => {
    const langue = i18n.language;
    console.log("Le language a changé: ", langue);  
    moment.locale(langue);

  }, [i18n]) 

  useEffect(() => {
    console.log("Current location:", location);  
  },[location]) 

  // Snack
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const handleSnackEvents = data => {
    //console.log(`handleSnackEvents() for snacking.`,data)
    const { payload } = data;
    console.log('A new event has happened: \'', payload.event + '\' has ', payload.data);
    setSnackMessage(payload.data);
    setOpenSnack(true);
}
  // fenetre modal 
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedValue, setSelectedValue] = React.useState("test");
   
    return (
      <div className={classes.myHeader} >  
        <Switch>         
          <Route path="/home">
            <HomePage customerID={customerID}/>
          </Route>          
          <Route path="/invitation">
            <InvitationPage/>
          </Route>           
          <Route path="/services/favorites">
            <ServicesGrid mode="favorites" customerID={customerID}/>
          </Route>           
          <Route path="/services/all">
            <ServicesGrid mode="all" customerID={customerID}/>
          </Route>              
          <Route path="/services/group/:id">
            <ServicesGrid mode="group" customerID={customerID} />
          </Route>   
          <Route exact path="/service/:id">
            <MyModalDialog fullScreen={fullScreen} noHeader={true}  title="-" selectedValue={selectedValue} onClose={()=> history.goBack()}><ServiceViewPage onClose={()=> history.goBack()}/></MyModalDialog>
          </Route>           
          <Route exact path="/service/:id/graph">
            <MyModalDialog fullScreen={fullScreen} noHeader={true}  title="-" selectedValue={selectedValue} onClose={()=> history.goBack()}><ServiceGraphPage onClose={()=> history.goBack()}/></MyModalDialog>
          </Route>                     
          <Route exact path="/service/:id/:probe">
            <MyModalDialog fullScreen={fullScreen} noHeader={true}  title="-" selectedValue={selectedValue} onClose={()=> history.goBack()}><ProbePage onClose={()=> history.goBack()}/></MyModalDialog>
          </Route> 
          <Route exact  path="/service/:id/:probe/alerts">
            <MyModalDialog  title={t("screen.probe-alerts.alerts-title")} selectedValue={selectedValue} onClose={()=> history.goBack()}><ServiceProbeAlerts/></MyModalDialog>
          </Route>            
             
          <Route exact path="/serviceadmin/:id">
            <MyModalDialog fullScreen={fullScreen} title={t("screen.device-admin.device-main-title")} selectedValue={selectedValue} onClose={()=> history.goBack()}><ServiceAdmin/></MyModalDialog>
          </Route>    
          <Route exact  path="/serviceadmin/:id/:probe">
            <MyModalDialog fullScreen={fullScreen} title={t("screen.probes-admin.probe-title")} selectedValue={selectedValue} onClose={()=> history.goBack()}><ProbesEditDetail/></MyModalDialog>
          </Route> 
          <Route exact  path="/lock/:id">
            <MyModalDialog fullScreen={fullScreen} title={"Visualisation des utilisations de la serrure"} selectedValue={selectedValue} onClose={()=> history.goBack()}><LockAccessDisplay/></MyModalDialog>
          </Route>           
          <Route exact  path="/lockadmin/:id">
            <MyModalDialog fullScreen={fullScreen} title={"Gestion des accès à la serrure"} selectedValue={selectedValue} onClose={()=> history.goBack()}><LockAdminAccess/></MyModalDialog>
          </Route> 

          {/*                          
          <Route path="/admin">
            <AdminCustomerMain/>
          </Route>
          <Route path="/customer">
            <CustomerAdmin data={customer}/>
          </Route> */}
          <Route path="/userProfile">
            <MyModalDialog fullScreen={fullScreen} title={t('screen.user-admin.user-main-title')} selectedValue={selectedValue} onClose={()=> history.goBack()}><UserProfile /></MyModalDialog>
          </Route>            
          <Route path="/adminCustomer">
            {/*            <MyModalDialog fullScreen={fullScreen} title={t("screen.customer-admin.customer-admin-title")} selectedValue={selectedValue} onClose={()=> history.goBack()}><AdminCustomerMain/></MyModalDialog> */}  
            <AdminCustomerMain/>      
          </Route>  
          <Route path="/adminCustomerUsers">
            {/*     <MyModalDialog fullScreen={fullScreen} title={t("screen.customer-admin.customer-admin-title")} selectedValue={selectedValue} onClose={()=> history.goBack()}><AdminCustomerUsers/></MyModalDialog> */}           
            <AdminCustomerUsers/>
          </Route>                     
          <Route path="/about">
            <MyModalDialog fullScreen={fullScreen} title={t('screen.about.main-title')+portal.title} selectedValue={selectedValue} onClose={()=> history.goBack()}><AboutPage/></MyModalDialog>
          </Route>   
          {/*  
          <Route path="/help">
            <MyModalDialog fullScreen={fullScreen} title={t('screen.about.main-title')+portal.title} selectedValue={selectedValue} onClose={()=> history.goBack()}><HelpPage/></MyModalDialog>
          </Route> 
          */}  
          <Route path="/withenedis">
            <AdminCustomerMain openDialog={"newLinky"}  />
          </Route> 
          <Route path="/withnuki">
            <AdminCustomerMain openDialog={"newNuki"}  />
          </Route>           

          <Route path="/help">
            <HelpPage/>
          </Route>  

          {/* default page */}
{/*           <Route path="/">
            <FindPageToRedirect />
          </Route>   */}  

{/*           <Route path="/custom">
            <CustomHomePage customerID={customerID}/> 
          </Route>   
*/}          

          <Route path={[ "/", "/custom"]} >
            {/* alors si le customer posede une customPage */}
            {customer.homePage &&
              <CustomHomePage customerID={customerID} />
            }
            {/* Pas de custom page mais au moins un capteur */}
            {(!customer.homePage && (customer.devicesCounter && customer.devicesCounter>0)) &&
              <ServicesGrid mode="all" customerID={customerID} />
            }
            {/* pas de custome page et pas encore de capteur  > on se positionne sur la home page */}
            {(!customer.homePage && (!customer.devicesCounter || customer.devicesCounter==0)) &&
              <HomePage customerID={customerID}/>
            }            
          </Route>   
{/* 
          <Route>
              // A traiter : 
              //   isFirstCustomer) c'est le premier customer (juste après la creation du user). Il n'a pas encore de device  
              //   pageToGo) console.log("(switch custo avec page to go) history.push->",pageToGo);
              //     /home+ goToViewMySensor : switchCustomer + /services/all
              //     /home+ gotoToAdminUsers : switchCustomer + /adminCustomer#newUser
          </Route>  
 */}

        </Switch>

        <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message={snackMessage}
          />


      </div>
    )
}

