import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';

import BackIcon from '@material-ui/icons/ArrowBack';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 700,
    margin: "auto"
  },
  card: {
    flexGrow: 1,
  },  
  appBarParam: {
    position: 'relative',
  },
  titleParam: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }  
})
)


export default function MyModalDialog(props) {
  const classes = useStyles();
  const { title, onClose, selectedValue, children: childrenComponent, fullScreen, noHeader} = props;

  //console.log("props:",props)

  const handleClose = () => {
      console.log("handle close")
    onClose(selectedValue);
  };

  return (
    <Dialog  fullScreen={fullScreen} className={classes.root}  fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={true}>
      {!noHeader &&
        <AppBar className={classes.appBarParam}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <BackIcon />
              </IconButton>
              <Typography variant="h6" className={classes.titleParam}>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
        }
        {childrenComponent}

    </Dialog>
  );
}

MyModalDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
//  openBis: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
