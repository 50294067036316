import React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
//        maxWidth: 700
    },
    card: {
        padding: "10px",
        margin: "0px 5px 10px 5px"
    },
    cardHeader: {
        backgroundColor: "lightskyblue",
        color: "white",
        margin: "10px 5px 0px 5px",
        padding: "10px",
        fontWeight: "bold",
        flexGrow: 1,
    },
    button: {
        margin: 0
    },  
  }));

export default function MyForm(props) {
    const { title, children, save, edit, action, closeAction, isBackdropOpen} = props;
    const { t } = useTranslation();
    const classes = useStyles();
    return (
    <div className={classes.root}>
        <Card className={classes.cardHeader} variant="outlined">
            <Grid container   direction="row" justifyContent="space-between" alignItems="center" > 
                <Grid item> {title}</Grid>

                <Grid item>
                    {save &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={action}
                            startIcon={<SaveIcon />}
                        >
                            {t('global.button.create')}
                        </Button>
                    }
                   {edit &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={action}
                            startIcon={<SaveIcon />}
                        >
                            {t('global.button.save')}
                        </Button>
                    } 
                   {closeAction && isBackdropOpen &&
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={closeAction}
                            startIcon={<CloseIcon />}
                        >
                            {t('global.button.cancel')}
                        </Button>
                    }                                       
                </Grid>
            </Grid>
        </Card>
        <Card className={classes.card} variant="outlined">
            <form className={classes.root} noValidate autoComplete="off"> 
            {children}
            </form>
        </Card> 
    </div>       
    )
}