import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

import { makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import BarChartIcon from '@material-ui/icons/BarChart';

import { mapToArray, findTempHumidity} from '../Common';

import MyLink from './MyLink';

import ProbeDisplayDefault     from "./probeDisplayDefault";
import ProbeDisplayETo         from "./probeDisplayETo";
import ProbeDisplayStateIn     from "./probeDisplayStateIn";
import ProbeDisplayStateOut    from "./probeDisplayStateOut";
import ProbeDisplayRain        from "./probeDisplayRain";
import ProbeDisplayCO2         from "./probeDisplayCO2";
import ProbeDisplayJson        from "./probeDisplayJson";

import DewPoint                from "./DewPoint";

import AccessTimeIcon from '@material-ui/icons/AccessTime';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    sensor: {
       // backgroundColor: "grey"
    },
    header: {
        paddingRight: 6
    },
    freshEvent: {
        backgroundColor: '#c5eac5'
    },
    recentEvent: {
        backgroundColor: 'transparent'
    },
    oldEvent: {
        backgroundColor: '#eab4b2'
    },
    divBreak: {
        display: 'block',
        backgroundColor: 'green'
    },
    actionButton: {
        margin: "1px;",
    }
}));

export default function ProbesDisplay(props) {
    const { struct /* template.probesDisplay */ , values, stats, date: eventDate , serviceId, probesCfg /* from service */, serviceType } = props;   

    const [probes, setProbes] = useState();

    const [tempHum, setTempHum] = useState();

    const [ageEventStart, setAgeEventStart] = useState(0); // age de l'event au moment de la creation de la page
    const [refreshTime, setRefreshTime] = useState(0); // +1 / minute

    const [ageEventDisplay, setAgeEventDisplay] = useState(""); // durée total depuis l'event (actualisée)
    const [dateEventDisplay, setDateEventDisplay] = useState("");  // date de l'event (heure ou date)

    const [stateButtons, setStateButtons] = useState({});

    const classes = useStyles();
    const { t } = useTranslation();
 
    // age de l'evenement
    function displayEventAge(ageEvent /* moment duration */ ) {
        if (eventDate===0) {
            // pas encore de donnée reçue
            return t('screen.device-display.no-data-yet');
        } else {
            return ageEvent.humanize()
        }
    }

    // date de l'evement (heure ou jours)
    function displayEventDate(eventDate) {
        if (eventDate===0) {
            // pas encore de donnée reçue
            return "";
        } else {
            const duration = moment.duration(moment().diff(moment(eventDate)));
            if (duration.as('hours')<24) {
                return moment(eventDate).format("HH[h]mm");
            } else {
                return moment(eventDate).format("DD/MM/yy");
            }
        }
    }

    // au démarrage on init l'eventAge de départ et l'affichage
    useEffect(()=> {
        const ageEvent = moment.duration(moment().diff(moment(eventDate)));
        setAgeEventStart(ageEvent.as('milliseconds'));
        setAgeEventDisplay(ageEventDisplay => displayEventAge(ageEvent));
        setRefreshTime(0); // en cas de recpetion de lastValue, on repart à zero
    },[eventDate]);

    // refresh toutes les secondes
    useEffect(() => {
        const interval = setInterval(() => {
            setRefreshTime(refreshTime => refreshTime + 1);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    // toutes les minutes on refresh le display eventAge
    useEffect(() => {
        if (refreshTime>0) {
            const updatedEventAge = moment.duration(ageEventStart+refreshTime*60*1000);
            setAgeEventDisplay(ageEventDisplay => displayEventAge(updatedEventAge));
            console.log("+1 min refresh event Age (min) ", updatedEventAge/1000/60);
        } 
    }, [refreshTime]);
          
    // on vient greffer sur la structure de sonde, les valeurs et les stats
    useEffect(()=> {
        const probesStruct = JSON.parse(struct);
        console.log()

        // formatage de l'heure de l'evenement 
        setDateEventDisplay(displayEventDate(eventDate));

        //const probesValue = JSON.parse(values);
        const probesValue = values;
        const probesSvcCfg = JSON.parse(probesCfg); // config gérée par l'utilisateur issue de service

        // on ajoute les stats (min/max)
        var statsValue;
        if (stats && stats.length>0) {
            const serviceStats = stats[0];
            //On verifie que la derniere stat enregistrée correspond bien à la date de la mesure affichée
            if (moment(eventDate).isSame(moment(serviceStats.eventDay)),'day') {
                statsValue = JSON.parse(serviceStats.values);
            } else {
                console.log("Attention date min,max ne correspond pas au jour de date mesure", eventDate, serviceStats.eventDay)
            }
        }

        var allProbes = attachValueToProbe(probesStruct, probesValue, statsValue , probesSvcCfg);
        var probesArray = mapToArray(allProbes); 
        probesArray.sort((a, b) => a.id.localeCompare(b.id));
        //console.log("allProbes:", probesArray);
        setProbes(probesArray);

        // point de rosée
        // si la liste des probes contient bien une temperature et une humidité
        // et si la temperature posséde l'attribut   "dewPoint": true
        const tempHum = findTempHumidity(probesStruct);
        if (tempHum) {
            setTempHum(tempHum); //  tempHum = { h: value, t: value }
        }

    },[values, stats])

/*
    function lastDateTimeFormatHtmlOld(eventDate) {

        //const eventTime = new Date(eventDate).toLocaleString('fr-FR', {timeStyle:"short"});
        var displayString;
        
        const eventTime = moment(eventDate).format("HH[h]mm");
        const duration = moment.duration(moment(eventDate).diff(moment()));

        if (eventDate===0) {
            displayString = t('screen.device-display.no-data-yet');
        }
        else if ((duration.as('days')>-1)) {
            // donnée du jour, on affiche l'heure/minute
            displayString = <span><b>{eventTime}</b> ({duration.humanize()})  </span>

        } else {
            // donnée plus ancienne que 1 jour, on affiche un durée
            displayString = duration.humanize();
        }

        if ((duration.as('minutes')>-1)) {
            setAgeEventState("new"); // a l'instant

        } else if ((duration.as('days')<-2)) {
            setAgeEventState("old"); // trop vieux

        } else {
            setAgeEventState("nominal"); // recent / normal
        }

        //return <span style="background-color:{backgroundColor};"><b>{displayString}</b></span>;
        return displayString;
    }
    */

    // parcourt de la structure de sondes pour ajouter les valeurs, les stats, les cumuls
    function attachValueToProbe(allProbes /* structure */ , values, stats, probesCfg) {
        console.log("***********************")
        console.log("allProbes:",allProbes);
        console.log("values:",values);
        console.log("probesCfg:",probesCfg);
        for (const probeKey in allProbes) {
            const probe = allProbes[probeKey];

            var probeCfg;
            if (probesCfg) probeCfg = probesCfg[probeKey]; // config propre au capteur issue de service (et pas de template)

            if (values[probeKey]) {
                
                probe.value = values[probeKey].value;
                probe.type = values[probeKey].type;

                // affiche du nombre de chiffre après la virgule
                // les données sont stocké en table sur 2 , par défaut on affiche sur 1
                probe.decimal =  probe.hasOwnProperty("decimal")?probe.decimal:1
               
                probe.isAlarm =  values[probeKey].isAlarm; // un tableau de statut d'alerte pour cette probe
                //moment.locale();  
                probe.time = moment(values[probeKey].timeEvent).format('DD/MM/YY HH:mm:ss');

                if(values[probeKey].more) {
                    probe.more = values[probeKey].more;
                }

                // si un nom de probe est présent dans le service
                // on le prends à la place de celui du template
                // TODO supprimer le nom dans le template prope (ajouter le type)
                if (probeCfg) {
                    if (probeCfg.name) {
                        probe.name = probeCfg.name;
                    }   
                    // actuellement l'inversion de l'etat est fait en dur en table et non en visualisation
/*                     if (probeCfg.hasOwnProperty("stateOn") && probeCfg.stateOn===0) {
                        console.log("stateOn=0")
                        // pour les sonde de type IO
                        // si l'etat 'On' correspond à 0, on inverse la valeur
                        values[probeKey].state = (values[probeKey].state === 0)?1:0;
                    }   */       
                }

                if (stats) {
                    probe.stats = stats[probeKey];
                }
                
                if (values[probeKey].cumuls) {
                    probe.cumuls = values[probeKey].cumuls;
                }

                if (values[probeKey].type === "STATE_IN") {

                    // pour pouvoir gerer un etat 'inconnu' / ?
                    probe.state = String(values[probeKey].state);

                    probe.stateNameOn = probeCfg ? probeCfg.stateNameOn : "stateNameOn";
                    probe.stateNameOff = probeCfg ? probeCfg.stateNameOff : "stateNameOff";
                }

                if (values[probeKey].type === "STATE_OUT") {

                    probe.state = String(values[probeKey].state);

                    // libellé des boutons d'action pour cette probe
                    // soit dans la traduction soit dans service
                    // pour chaque action du modele, 
                    if (probeCfg?.buttons) { // il y a des noms definis par l'utilisateur
                        probe.buttons.map( button => {
                            // on retrouve le nom dans le service 
                            button['display'] = probeCfg.buttons[button['action']];
                        })
                    } else if (probe.buttons) {
                        probe.buttons.map( button => {
                            button['display'] = t('actuator.defaultActionName.'+button['action']+'');
                        })
                    } else { // sinon on prend le nom par defaut dans la traduction (et non dans le template)
                        console.log("Pas de boutons!")
                    }

                    const currentStates = stateButtons;
                    currentStates[probeKey] = false;
                    setStateButtons(currentStates);
                }                

                if (values[probeKey].type === "STATE_IN" && values[probeKey].previousStateDuration ) {
                //probe.lastDuration = Math.round(values[probeKey].previousStateDuration / 1000 / 60);

                    //moment.locale("FR");  
                    probe.lastDuration = moment.duration(values[probeKey].previousStateDuration / 1000 / 60, "minutes").humanize();
                    probe.lastStart = values[probeKey].previousStateStart
                    probe.lastEnd = values[probeKey].previousStateEnd
                }

                // il faudrait pendre le timestamp de la sonde. Pour l'istant on prend le time du device
                // le seul cas ou ce n'est pas pareil, c'est quand les valeurs arrivent probe par probe
                //var duration = moment.duration(moment(values[probeKey].time).diff(moment()));
                //probe.time = duration.humanize();
            }

        }
        //console.log("probes  ready : ",allProbes)
        return allProbes;
    }




    function getClasseEventAge(ageEvent) {
        var returnClass;
        switch(ageEvent) {
            case "new":
                returnClass  = classes.freshEvent;
                break;

            case "old":
                returnClass  = classes.oldEvent;
                break;                

            default:
                //nominal
                returnClass  = classes.recentEvent;
        }
        return returnClass;
    }

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" className={classes.header} >
                <Grid item> </Grid>
                <Grid item>
                    <Grid container direction="row"  justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item><AccessTimeIcon/></Grid>
                        <Grid><b>{ageEventDisplay}</b></Grid>
                        <Grid item>({dateEventDisplay})</Grid>

                    </Grid>
                </Grid>
                <Grid item >
                    <MyLink to={"/service/"+serviceId+"/graph"}  style={{ textDecoration: 'none' }}>
                        <BarChartIcon /> 
                    </MyLink>
                </Grid>
            </Grid>

            <Grid container direction="column"  className={classes.root} >

            {probes?.map( (probe, key) => 
                <Grid item  className={classes.sensor} key={key}>
                    <Card variant="outlined">
                        {/* Sauf STATE_IN, STATE_OUT, STATE_OUT_IN */}
                        {probe.type.substr(0,5)!=='STATE' && probe.type!=='et0' && probe.type!=='pluvio' && probe.type!=='co2' && probe.type!=='DATA' && probe.type.toUpperCase() !=='BATTERY' &&
                            <ProbeDisplayDefault serviceId={serviceId} probe={probe} />
                        } 
                        {probe.type==='temp' && tempHum && 
                            <DewPoint tempHum={tempHum} />
                        }
                        {probe.type==='pluvio' &&
                            <ProbeDisplayRain serviceId={serviceId} probe={probe} />
                        }

                        {probe.type==='STATE_OUT' &&
                            <ProbeDisplayStateOut serviceId={serviceId} probe={probe} />
                        }  

                        {probe.type==='STATE_IN' &&
                            <ProbeDisplayStateIn serviceId={serviceId} probe={probe} />
                        } 

                        {probe.type==='et0' &&
                            <ProbeDisplayETo serviceId={serviceId} probe={probe} />
                        }
                        {probe.type==='co2' &&
                            <ProbeDisplayCO2 serviceId={serviceId} probe={probe} />
                        } 
                        {probe.type==='DATA' &&
                            <ProbeDisplayJson serviceId={serviceId} probe={probe} serviceType={serviceType}/>
                        }                         
                    </Card>
                </Grid>
            )}
            </Grid>              
      </div>
  );
}
