import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

//import { mapToArray, round, toSnack} from '../Common';

import { createTheme, makeStyles} from '@material-ui/core/styles';

import MyLink from './MyLink';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));
const theme = createTheme({
    palette: {
      //primary: green,
    },
  });
  

export default function ProbeDisplayStateIn({ serviceId, probe }) {

    
    const classes = useStyles();

    // determine le nom de l'etat actuel de la  sonde 
    // soit le nom issue de lac traduction d'après le type de la sonde
    // soit un nom indiqué par l'utilisateur dans admin stocké dans le service
    function getStateName(probe) {

        var probeStateName;
        if (probe.state==='0') {
            if (probe.stateNameOff) {
                probeStateName = probe.stateNameOff;
                
            } else {
                probeStateName = t('screen.device-display.state-off')
            }
        } else if (probe.state==='1') {
            if (probe.stateNameOn) {
                probeStateName = probe.stateNameOn;
            } else {
                probeStateName = t('screen.device-display.state-on')
            }
        } else {
            //state === '?'
        }
        return probeStateName;
    }

    const { t } = useTranslation();

    return (
    <>
        <MyLink to={"/service/"+serviceId+"/"+probe.id}  style={{ textDecoration: 'none' }}>
            <Grid container direction="row" justifyContent="space-around" alignItems="stretch">
            {probe.state==='?' &&
                <div>
                ⦿ {probe.name} [{t('screen.device-display.state-unknown')}]
                </div>
            }

            {probe.state==='0' &&
                <div>
                ⦿ {probe.name} {getStateName(probe)}
                   {/*  <div >{probe.lastDuration})</div> */}
                </div>
            }

            {probe.state==='1' &&
                <div>
                ⦿ {probe.name} {getStateName(probe)}
                </div>
            }

            {probe.state==="undefined" &&
                <div>
                ⦿ {probe.name} ?
                </div>
            }            
            </Grid>
        </MyLink>
    </>
    );
}
