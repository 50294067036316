import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GraphQlApi from '../mobile/MyGraphql';
import moment from 'moment';

import Card from '@material-ui/core/Card';

import { formatNumber } from '../Common';

const useStyles = makeStyles({
    root: {
    },
    card: {
        maxWidth: "800px"
    },
    statsHeader: {
        marginTop: 10,
        //fontWeight: "bold"
    },
    statsValue: {
        fontWeight: "bold"
    }    
});

export default function EnergyDayCumul(props) {
    const { serviceId, probeId } = props;

    const [dayCumul, setDayCumul] = useState();

    useEffect(() => {
        async function fetchData(serviceId, probeId) {
            console.log("fetchData serviceId:",serviceId, probeId)
            const measure = await getHistoAggregateCurrentDay(serviceId, probeId);
            
            if (measure.eachHour) {
                let dayCumul = 0;
                const eachHour = JSON.parse(measure.eachHour);
                for (let h = 0; h < 24; h++) {
                    if (eachHour.hasOwnProperty("h"+h)) {
                        if (eachHour["h"+h].avg) {
                            dayCumul = dayCumul +eachHour["h"+h].avg
                        }                    
                    }
                }
                setDayCumul(dayCumul)
            }
        }
        fetchData(serviceId, probeId);
    }, [serviceId, probeId]);

    async function getHistoAggregateCurrentDay(serviceId, probeId) {
        console.log("getHistoAggregateCurrentDay...");  
        var measure;
  
        const idKey = serviceId+"-"+probeId;
        const theDay = moment().format('YYYY-MM-DD');
        const inputData = { "id": idKey, "theDay": theDay}
  
        //console.log("inputData:",inputData)
        const getMeasure = `query getMeasureFromDay($id: ID!, $theDay: String) {
          listMeasureDailyStatsLocals(id: $id, dayEvent: {eq: $theDay}) {
            items {
              id
              dayEvent
              allDay
              eachHour
            }
          }
        }`;
        const response = await GraphQlApi.getInstance().graphqlOperation( getMeasure, inputData);
        if (response.errors) {
          console.log("response:",response.errors)
        }
        if (response.data) {
          console.log("response:",response.data)
          const measures = response.data.listMeasureDailyStatsLocals.items;
          if (measures.length>0) {
            measure = measures[0];
          }
        }
        return measure;
    }

    const classes = useStyles();
    return (
        <>
            {dayCumul &&
                <div>
                    <div className={classes.statsHeader}>Cumul jour</div>
                    <div className={classes.statsValue}>{formatNumber(dayCumul/1000,2)} kWh/m2</div>
                    <div className={classes.statsValue}>{formatNumber(dayCumul*3600/1000)} kJ/m2</div>
                </div>
            }        
        </>
  );
}

