import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },        
}));


export default function WithBackdrop(props) {
    const {children, isOpen} = props;
    const classes = useStyles();

    return (
        <div>
            {isOpen && 
                <div>
                    <Backdrop className={classes.backdrop} open={isOpen} >
                        {children}
                    </Backdrop>
                </div>
            }
            {!isOpen && 
                <div>
                    {children}
                </div>
            }
        </div>
    )
}


