import React, {useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import MyLink from '../components/MyLink';
import InputIcon from '@material-ui/icons/Input';
import Card from '@material-ui/core/Card';

import GeneralContext from './GeneralContext'
import ProbesDisplay from './ProbesDisplay'
 
const useStyles = makeStyles(theme => ({
    root: {
    }
  }));

export default function Service( {data:service} ) {

    const { user, customer } = useContext(GeneralContext);

    const classes = useStyles();
    let history = useHistory()

    return (
        <> 
            <ProbesDisplay 
                serviceId={service.id}
                date={service.lastValue.dateEvent}
                struct={service.device.template.probesDisplay} 
                values={service.lastValue.probes} 
                stats={service.dailyStats.items}
                probesCfg={service.probesConfig}
            /> 
            <div>
            </div>
            <Card>
                <MyLink to={"/service/"+service.globalConfig?.eto?.weatherStation?.serviceId}  style={{ textDecoration: 'none' }}>
                    Station météo <InputIcon fontSize='small' />
                </MyLink>
            </Card>
            <Card>
                <MyLink to={"/service/"+service.globalConfig?.eto?.pyranometer?.serviceId}  style={{ textDecoration: 'none' }}>
                    Pyranomètre<InputIcon fontSize='small' />
                </MyLink>
            </Card>
        </>
    );
}


