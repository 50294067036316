import React , { useState, useContext, useEffect } from 'react';

import { useLocation, useHistory  } from "react-router-dom";
import queryString from 'query-string'

import { API } from "aws-amplify";
import GraphQlApi from '../mobile/MyGraphql';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import awsconfig from '../aws-exports';
import GeneralContext from '../components/GeneralContext'
import { askForRefreshingCustomer, toSnack } from '../Common';

const { exe_env_name } = awsconfig;




const hostname = window.location.hostname;
console.log("hostname:",hostname);

const NUKI_SCOPES="account%20notification%20smartlock%20webhook.central%20smartlock.readOnly%20smartlock.action%20smartlock.auth%20smartlock.config%20smartlock.log";
const NUKI_ENDPOINT = "https://api.nuki.io";
const NUKI_API_AUTHORIZE_V1 = NUKI_ENDPOINT+"/oauth/authorize";

const useStyles = makeStyles(theme => ({
    root: {
    },
    textNote: {
        fontStyle: "italic"
    }
}));

export default function AdminNuki(props) {

    const { openState, toClose, currentStep } = props;
    const { user, customer } = useContext(GeneralContext);

    const pathname = useLocation().pathname;
    const { search } = useLocation();
    let history = useHistory();
    const query = queryString.parse(search)


    const { t } = useTranslation();

    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [messageToDisplay, setMessageToDisplay] = useState("");
    const [ legalOk, setLegalOk ] = useState(false);
    const [ nukiConfig, setNukiConfig ] = useState({});

    const [ deviceInfo, setDeviceInfo ] = useState();
    const [ serviceName, setServiceName ] = useState("Ma serrure NUKI");

    const [ retailerNukiConfig, setRetailerNukiConfig ] = useState();
    

    const classes = useStyles();

    useEffect(() => {
        console.log("currentStep:",currentStep);
        if (currentStep && currentStep === "fromNukiGet") {
            setStep(3)
        } else {
            setStep(0)
        }
    },[currentStep]);

    useEffect(() => {
        async function getRetailer(id) {
            console.log("Retailer is loading for %s ...", id);
            setLoading(true);
            const getQuery = `query GetRetailer($id: ID!) {
                    getRetailer(id: $id) {
                        id
                        name
                        thirdPartyConfig
                    }
                }`;
            const result = await GraphQlApi.getInstance().graphqlOperation( getQuery, { id: id });
            setLoading(false);
            if (result.data.getRetailer) {
                const retailer = result.data.getRetailer
                console.log("retailer :", retailer);
                if (retailer.thirdPartyConfig) {
                    setRetailerNukiConfig(JSON.parse(retailer.thirdPartyConfig))
                }
                //setRetailer(retailer)         
            } else {
                console.log("Retailer non trouvé en base");
            }
        } 
        console.log("User:",user)
        if (user.retailer) {
            //TODO get retailer, pour avoir la config nuki
            getRetailer(user.retailer.id);
        }
    },[user]);

    useEffect(() => {
        console.log("step:",step);
        async function postProcessing() {
            console.log(">Appel du POST OAUTH2 (avec les params issues du GET) ...");

            if (!user.retailer) {
                console.log("Erreur L'utilisateur n'est pas retailer");
                return;
            }
            const retailerId = user.retailer.id
            let code = query.code;  
            console.log("code:",code);

            var response;
            let myInit = {};
            try {
                response = await API.put("iotLockAdmin", "/lock/"+retailerId+"/registration/"+code, myInit);
                console.log("Response positive du POST NUKI:",response);
                //TODO si response === ok
                setStep(4);
            }
            catch (error) {
                console.log("Erreur from POST OAuth2 : ", error)
                setMessageToDisplay("Erreur lors de l'appel à NUKI : " + error.message)
            }
        }

        if (step===3) {
            let currentState = query.state;  
            //TODO reprendre le constrole de state
            const originaltSessionState = sessionStorage.getItem("sessionState");
            if (currentState !== originaltSessionState) {
                console.log("Pb de secu:", currentState, originaltSessionState);
                //throw("Error");
            } else {
               
            }
            postProcessing(); // appel du post Nuki

        } else if (step===4) {
            // retour du post (avec ou sans token)

        }
    },[step]);


    async function toCloseLocal(data) {
        console.log("local closed en phase : ", step, data);
        if ( step >= 1 && deviceInfo) {
            if ( !data |  !data.action | data.action !== "attached" ) {
                // on est en phase 1 : visualisation du détail du capteur
                // ou phase 2 : case a cocher
                // deviceInfo existe : en retour de l'api call GET /iotAttachDevice
                // il faut aussi que l'attachement n'est pas reussi (<>attached)
                // alors si deviceInfo.dataSource === CLONE, il  s'agit d'un device de demo 
                // --> effacement du deveui
                if (deviceInfo.dataSource && deviceInfo.dataSource==='CLONE') {
                    console.log("Effacement....");
                   //const response = await deleteDeveuiRest(deviceInfo.id);
                   //console.log(response);
                }
            }
        }
        toClose(data);
    }

    const processActivation = async () => {

        var probesDetail;
 
        console.log("Appel de WS")
        setLoading(true);
        try {
            const response = await associateDeviceStationRest({ name : serviceName, probes: probesDetail, config: nukiConfig});
            console.log("associateDeviceStationRest:",response)
            if (response) {
                if (response.success) {
                    //toSnack("DeviceLink", t('screen.scan-device.well-link'));
                    toSnack("DeviceLink", response.message); // TODO pour la traduction, gérer un code d'erreur dans l'api et retrouver le code dans la traduction
                    askForRefreshingCustomer(user.ownCustomer.id);
                    toCloseLocal({action:"attached"});
                    history.push("/services/all");
                } else {
                    //response.error
                    toSnack("DeviceLink", response.message);
                    toCloseLocal({action:"error"});
                    history.push("/adminCustomer");
                }
            } else {
                toSnack("DeviceLink", t('screen.scan-device.error-whilelink'));
                toCloseLocal({action:"error"})
                history.push("/adminCustomer");
            }
        }
        catch (error) {
            toSnack("DeviceLink", t('screen.scan-device.error-whilelink')+error);
            toCloseLocal({action:"error"})
            history.push("/adminCustomer");
        }
        setLoading(false);
        setStep(0);
    }; 

    async function associateDeviceStationRest(service) {
        console.log("Service activation  : ", service);

        let myInit = {
            body: {
                name: service.name, // le nom du capteur indiqué par l'utilisateur
                forService: "LINKY"
            }
        }    

        if(service.probes) {
            myInit.body['probes'] = service.probes; // nom des sondes dans la langue actuelle de l'utilisateur
        }

        if (service.config) {
            myInit.body['usage_point_id'] = service.config.usage_point_id;
            myInit.body['refresh_token'] = service.config.refresh_token;
            myInit.body['refresh_token_issued_at'] = service.config.refresh_token_issued_at;
            myInit.body['access_token'] = service.config.access_token;
            myInit.body['access_token_expires_at'] = service.config.access_token_expires_at;
        }

        if (customer.id) {
            myInit.body['customerId'] = customer.id; 
        }
        
        console.log("Activation- appel REST : ",myInit)
        var response;
        try {
            response = await API.post("iotAttachDevice", "/devices/" + service.deveui, myInit);

        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
        return response;
    }

    const askForNukiConsent = () => {

        console.log("Etape 1 - Envoi du GET vers Nuki");

        if (!retailerNukiConfig) {
            alert("impossible de trouver les informations pour la connexion à Nuki Web");
        }
        var clientId = retailerNukiConfig.nuki.clientId;
        var redirectUri = retailerNukiConfig.nuki.redirectUri;

        console.log("hostname&redirectUri:", hostname,redirectUri);

        if (hostname.endsWith("ngrok.io") || hostname.endsWith("localhost")) {
            console.log("Execution en mode LOCAL");
            clientId = "Hmc7qRFvuMcRscafmR-6HA"; // Pascal
            // A changer au moment du test
            redirectUri = "https://02a8-2a01-e0a-28d-73b0-5400-a9c4-db2-e1f1.ngrok.io/withnuki/";
        } 

        setStep(1); // avant envoi du GET à Nuki
        let testClientId = query.testClientId; 

        const newSessionState = (Math.random() + 1).toString(36).substring(2);
        console.log("newSessionState:",newSessionState);
        var sessionState;
        if (testClientId) {
            sessionState = newSessionState + testClientId;
        } else {
            sessionState = newSessionState+'0';
        }
        console.log("sessionState:",sessionState);
        sessionStorage.setItem("sessionState", sessionState);

        const redirectUrl =
            NUKI_API_AUTHORIZE_V1 +
            '?' +
            `client_id=${clientId}` +
            `&state=${sessionState}` +
            '&response_type=code' +
            '&scope='+NUKI_SCOPES+
            `&redirect_uri=${redirectUri}`;

        console.log('sessionState: ' + sessionState);
        console.log('Redirect URL : ' + redirectUrl);

        // demande d'autorisation à NUKI
        alert(redirectUrl);
        window.location.href=redirectUrl;
        setStep(2); // en attente réponse GET
        console.log("Etape 2 - en attente de réponse du GET ");
    }


    function onEnteredDialog() {
        console.log("onEntered Dialog");
        //setDeveuiField("020000ffff00b923");
        //setDeveuiField("");
        setMessageToDisplay("");
        setLegalOk(false)
        //setGeolocOk(false)
        //setStep(0)
    }

    function onExitDialog() {
        console.log("Exit Dialog");
        setStep(0)
    }

    return (
        <Dialog open={openState} onClose={toCloseLocal} aria-labelledby="form-dialog-title" TransitionProps={{onEntered: onEnteredDialog, onExit: onExitDialog }}  >
        <DialogTitle id="form-dialog-title" classes={{ root: classes.root}} > 
            Associer la serrure NUKI à myIOTplatform
        </DialogTitle>
        <DialogContent>
        {!user.ownCustomer &&
            <div>
               
            </div>
        }
        {user.ownCustomer &&
        <>
            <div className={classes.alignItemsAndJustifyContent}>
                {loading && <CircularProgress size={40} />}
            </div>
            {step===0 &&
                <div>     
                    <Grid container  direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid xs={5} item>
                            <a hre="#"  onClick={askForNukiConsent} >
                                <img src="/nuki_img.jpeg" alt="image" width="200" />
                            </a>
                        </Grid>
                        <Grid xs={7} item>
                            En cliquant sur ce bouton, vous allez accéder à votre compte personnel sur le site NUKI 
                            où vous pourrez donner votre accord pour que NUKI nous transmette vos données.                        
                        </Grid>                        
                    </Grid>   
                </div>
            }
            {step===3 &&
                <div>
                    Retour de l'appel au service de NUKI : 
                </div>
            }
            {step===4 &&
                <div>
                    <div>
                        Félicitations, la demande d'autorisation a été acceptée.
                    </div>
                    <br/>
                    <div>
                        Vous allez maintenant créer un capteur virtuel qui recueillera les données de votre compteur
                    </div>
                    <br/>
                    <Divider />
                    <TextField 
                        value={serviceName}
                        className={classes.textFieldBig}
                        onInput={ e=>setServiceName(e.target.value)}                    
                        id="sensorName" 
                        label={t('screen.scan-device.device-name')} />

                    <Card className={classes.checkbox}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={legalOk} 
                                    onChange={(event)=>setLegalOk(event.target.checked)} 
                                    color="primary" 
                                    name="checkedB" />}
                            label={t('screen.scan-device.accept-conditions')}
                            />                        
                    </Card>                      

               </div>
            } 
            <div>
                {messageToDisplay}
            </div>           
        </>
        }        
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>toCloseLocal({action:"cancel"})} color="primary">
                {t('global.button.cancel')}
            </Button>
            {step===1 && deviceInfo &&
                <Button  color="primary">
                    {t('global.button.continue')}
                </Button>
            }
            {step===4 && legalOk &&
                <Button onClick={processActivation} color="primary">
                    {t('global.button.activate')}
                </Button>
            }                        
        </DialogActions>
    </Dialog>
    )
}