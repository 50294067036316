import React, { useContext, useState, useEffect , useRef} from 'react'

import fetch from 'node-fetch';

import { API, Storage } from "aws-amplify";

import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import { makeStyles } from '@material-ui/core/styles';

import { toSnack } from '../Common';

const ImageLengthMax = 100;
const MaxUploadImages = 3;

const useStyles = makeStyles(theme => ({
    root: {
    },
    imagesContainer: {
        width: "50%"
    }
}));  

export default function UploaddImages({customerId}) {

    const [images, setImages] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);
    const [fileList, setFileList] = useState([]);

    const imageInputRef = React.useRef(); 

    const classes = useStyles();

    useEffect(() => {
        if (images.length <1 || images.length > MaxUploadImages) return;
        const newImageUrls = [];
        images.forEach(image => { 
            newImageUrls.push(URL.createObjectURL(image))
            console.log("%s size:%s", image.name, image.size)
        });
        setImageURLs(newImageUrls);

    }, [images])

    useEffect( ()=> {
        if (customerId) {
            fetchBucketImageFilenames(customerId)
        }
      },[customerId])

    const fetchBucketImageFilenames = async (customerId) => {
        console.log("Images for customer homepage loading for (%s) ...",customerId);
        const files = await getImagesListRest(customerId);
        setFileList(files)
    }

    function onImagesChange(e) {
        console.log(e.target.files)
        setImages([...e.target.files]);
    }

    function onImageChange(e) {
        console.log(e.target.files[0])
    }

    const handleSubmission = async () => {
        if (images.length>0) {

            console.log("images[0]:",images[0].name) ; 

            const formData = new FormData();

            formData.append("filename",images[0] )
            formData.append("name", images[0].name);
            formData.append("destination", "images");

            for (var data of formData.entries()) {
                console.log("formData:",data[0]+ ', ' + data[1]); 
            }

            //await postImageRest( images[0]);
            //const result = await Storage.put( images[0], "Hello");

            try {
                const result = await Storage.put(customerId+"/"+images[0].name, images[0], {
                    level: "private",
                    contentType: "image/png"
                  });
    
                console.log("result:",result)
                setImageURLs([]);
                imageInputRef.current.value = "";
                toSnack("uploadImage", "L'image a bien été envoyée.");
                fetchBucketImageFilenames(customerId);

            } catch (error) {
                console.log(error)
                toSnack("uploadImage", "Erreur lors de l'envoi de l'image : ");
            }

        }

	};

    async function fetchPostImage(formData) {
        // 
        console.log("FTECH POST ",formData)
        const response = await fetch('https://x6g27mylp0.execute-api.eu-west-1.amazonaws.com/dev/upload', {
            method: 'post',
            body: formData,
            headers: {
                "Content-Type": "image/jpeg",
                //'Content-Type': "multipart/form-data",
            }
        });
        const data = await response.json();

        console.log("Fetch response:",data);

    }

    async function postImageRest(formData) {
        // impossible d'uploader des objet binnaire avec API.post
        console.log("POST - appel REST : ",formData)

        // for (var data of formData.entries()) {
        //     console.log("formDataRest:",data[0]+ ', ' + data[1]); 
        // }
    
        let params = {
            responseType: 'blob',
            //body: { "data": formData},
            body: formData,
            //body: { "data": "ok"},
            headers: {
                //'Content-Type': 'application/json',
                'Accept': 'image/jpeg',
                //'Content-Type': "multipart/form-data",
                'Content-Type': "image/jpeg",
                //'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            } 
          }
        console.log("params:",params);
        var response;
        try {
            response = await API.post("apiGetImage", "/customer/12345/images", params);
            if (response.error) {
                console.log("Error (test1):",response.error, response.message )
                throw response.message;
            } else {
                console.log("apiGetImage:ok", response)
                const responseString =  Buffer.from(response, 'base64').toString('utf-8');
                console.log("responseString", responseString)
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    }

    async function getImagesListRest(customerId) {
        console.log("REST - getImagesListRest for ",customerId)

        let params = {
            headers: {
                'Content-Type': 'application/json',
            } 
          }
        console.log("params:",params);
        var response;
        try {
            response = await API.get("apiS3files", "/homepages/customer/"+customerId, params);
            if (response.error) {
                console.log("Error (test1):",response.error, response.message )
                throw response.message;
            } else {
                console.log("apiGetImagesList:ok", response)
                return response.data.files;
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    }

    return (
        <div>
            <Grid container direction="column">
                <Grid>
                    Images disponibles 
                    <RefreshIcon onClick={() => fetchBucketImageFilenames(customerId)}/>
                </Grid>
                <Grid item>
                    <div  className={classes.imagesContainer} >
                        <ul>
                            {fileList.map((filename, index) => 
                                <li key={index} >{filename}</li>
                            )}
                        </ul>
                    </div>
                </Grid>
            </Grid>

            <Grid container direction="column">
                <Grid item>

                        <input type="file" multiple accept="image/*" ref={imageInputRef}  onChange={onImagesChange} />
                        <button onClick={handleSubmission}>Envoyer</button>

                </Grid>
                <Grid item>
                    <div  className={classes.imagesContainer} >
                        {imageURLs.map((imageSrc, index) => <img key={index} src={imageSrc} width="400px" />)}
                    </div>
                </Grid>
            </Grid>
            <hr/>
            <div>
              <div>Exemples de fonds d'écran</div>
                <ul>
                  <li>https://device-pictures.s3.eu-west-1.amazonaws.com/custom-pages/plan2d_avec_garage.jpeg</li>
                  <li>https://device-pictures.s3.eu-west-1.amazonaws.com/custom-pages/plan2d_muet.png</li>
                  <li>https://device-pictures.s3.eu-west-1.amazonaws.com/custom-pages/vue3d_avec_terrasse.jpeg</li>
                  <li>https://device-pictures.s3.eu-west-1.amazonaws.com/custom-pages/vue3d_simple.jpeg</li>
                </ul>
            </div>  

        </div>
    )
}
