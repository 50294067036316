import React, { useEffect, useState, useContext } from 'react';
import { API, graphqlOperation, Analytics } from "aws-amplify";
import GraphQlApi from '../mobile/MyGraphql';
import publicIp from 'public-ip';

import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import GeneralContext from '../components/GeneralContext'
import ServiceDisplay from '../components/ServiceDisplay'
import { isEmptyObject } from '../Common';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        width: "100%"
    },
    minHeader: {
        height: 30, // ou 45,  ou 66
    },
    tempBuffer: {
        marginTop: 50,
    },
    main: {
        padding: "2px",
        backgroundColor: "red"
    },
    mainGrid: {
        textAlign: 'center'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },

    pos: {
        marginBottom: 12,
      },
    service: {
        height: "100%",
        paddingRight: "2px",
        backgroundColor: "#fff"
    }
}));

function ServicesGridCustom(props) {

    const { mode, customerID } = props;

    let { id: groupId } = useParams();
    const { t } = useTranslation();

    const { user, customer } = useContext(GeneralContext);

    const [services, setServices] = useState();
    const [loading, setLoading] = useState(false);
    const [openDeviceScan, setOpenDeviceScan] = useState(false);

    const classes = useStyles();
    let history = useHistory();

    useEffect(() => {
        console.log("processing services ")
        console.log("Customer: ",customer)
        console.log("User: ",user)
        let ignore = false;
        async function fetchCustomerServicesAll(customerId) {
            console.log("Fetching for all services of the Customer '%s'  is processing ...",  customerId);
            setLoading(true);
            const querielistServices = `query ListServices($id: ID!) {
                getCustomer(id : $id) {
                    id
                    services(filter: {
                        or:  [
                          { serviceType: {eq: LOCK}  },
                          {serviceType: {eq: MEDIA_STREAM}}
                        ]
                            }) {
                        items {
                            id
                            name
                            serviceType
                            globalConfig
                            device {
                                id
                                idMqtt
                                manufacturer
                                operator
                                status
                                dataSource
                                networkStatus
                                template {
                                    id
                                    probesDisplay
                                    displayConfig
                                }          
                            }
                            lastValue {
                                id
                                dateEvent
                                probes
                                slidingCumul
                                commOperator
                                customerId
                            }
                            probesConfig
                            dailyStats(limit:1, sortDirection:DESC) {
                                items {
                                    id
                                    eventDay
                                    values
                                }
                            }
                            alerts {
                                items {
                                    id
                                    probeID
                                    status                              
                                }
                            }                                                  
                        }
                    }
                }
            }`;
            //const result = await API.graphql(graphqlOperation(querielistServices,  { id: customerId} ));
            const result = await GraphQlApi.getInstance().graphqlOperation( querielistServices, { id: customerId});
            setLoading(false);
            if (result && result.data && result.data.getCustomer) {
                const services = result.data.getCustomer.services
                console.log("services from Db:", services);
                if (!ignore) {
                    setServices(services.items);
                }
            } else {
                console.log("Services non trouvé en base");
            }

        }    
        fetchCustomerServicesAll(customer.id);
        return () => { ignore = true; }

    }, [mode, groupId, customer]);

    // si le customer change, on rafraichi la liste des services ne mode All
/*     useEffect(() => {
        console.log("New Customer ...");
    }, [customer]);
 */
    return (
        <div className={classes.root}>

            <div className={classes.minHeader}>
            {isEmptyObject(services) &&
                <div className={classes.tempBuffer}>
                    <div>&nbsp;</div>
                    {mode==="all" &&
                        <>
                            <div>{t('screen.devices-display.no-device-display')}</div>
                            {customer.isUserOwner &&
                                <div>
                                    <div>{t('screen.devices-display.owner-can-add')}
                                    <Button
                                        variant="contained"
                                        disabled={!user.ownCustomer}
                                        color="primary"
                                        className={classes.button}
                                        startIcon={<PlaylistAddIcon />}
                                        onClick={() => setOpenDeviceScan(true)}
                                    >
                                    {/*   Ajout */}
                                    </Button> 
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {mode==="favorites" &&
                     <div>{t('screen.devices-display.no-favorite-display')}</div>
                    }   
                    {mode==="group" &&
                     <div>{t('screen.devices-display.no-group-display')}</div>
                    }                                 
                </div>
            }
            </div>

            {/* 
            Qq soit la taille de l'ecran, il est divisé en 12 colonnes
            Si un, deux ou trois objet : centrée avec une largeur de 3 col (sur 12) au centre sur les grands ecrans
            Si plus de 4 objets a afficher : 
            1 objet (de 12 col) sur taille xs (smartphone)
            2 objet (de 6 col) sur taille sm et md (tablette)
            4 objet (de 3 col) sur taille lg et xl (ordinateur)
            */}

            <Grid container spacing={2} alignItems="stretch" justifyContent="space-evenly" className={classes.mainGrid}>
                {services?.map( (service, key) => 
                    <Grid item xs={12} sm={6} lg={3} key={key} >
                        <div className={classes.service}>
                           {service !== null &&
                            <ServiceDisplay customerId={customerID} data={service} /> 
                           } 
                        </div>
                    </Grid>
                )}
            </Grid> 
        </div>
  );
}

export default ServicesGridCustom;