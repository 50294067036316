import React, {useContext, useState, useEffect } from 'react';

import { API } from "aws-amplify";

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import InputIcon from '@material-ui/icons/Input';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CircularProgress from '@material-ui/core/CircularProgress';

import {dateUtcToLocal, lockActionToText} from '../Common';

import GeneralContext from './GeneralContext'
import ProbesDisplay from './ProbesDisplay'
import MyLink from './MyLink';
 
const useStyles = makeStyles(theme => ({
    root: {
    },
    button: {
        margin: theme.spacing(1),
    },
    loading: {
        marginTop: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'      
    }    
  }));

export default function ServiceLock( {data:service} ) {

    const { user, customer } = useContext(GeneralContext);

    const [ lockState, setLockState ] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonState, setButtonState] = useState("off");
    const [errorMsg, setErrorMsg] = useState("");
    const [lockInfo, setLockInfo] = useState();
    const [lockAccessLog, setLockAccessLog] = useState();
    const [manufacturer, setManufacturer] = useState(false);

    const classes = useStyles();
    let history = useHistory()

    useEffect(() => {
        let ignore = false;
        console.log("service:",service);
        async function fetchData(serviceId) {
            setLockInfo({});
            setManufacturer(service.device.manufacturer);
            //const accessHistory = await getLockRest(serviceId)
            var accessHistory;
            console.log("accessHistory:",accessHistory);
            if (accessHistory && accessHistory.data) {
                setLockAccessLog(accessHistory.data)
            }
        }
        if (service) {
            fetchData(service.id);
        }
        return () => { ignore = true; }
    }, [service]);  

    const handleLockOpen = async () => {
        console.log("open lock");
        setButtonState("processing");
        setErrorMsg("");
        await closeLockRest(service.device.id)
        setButtonState("on");      
    };
    const handleLockClose = async ()  => {
        console.log("close lock");
        setButtonState("processing");
        setErrorMsg("");
        await openLockRest(service.device.id)
        setButtonState("off"); 
    };

    const toAdminLock = () => {
        history.push("/lockadmin/" + service.id );
    }

    const toLockDisplay = () => {
        history.push("/lock/" + service.id );
    }
    

    // Appels aux API REST
    async function getLockRest(lockId) {
        console.log("getting Lock info ... ", lockId);
        setLoading(true);
        setErrorMsg("");
        let myInit = {
          queryStringParameters: {
            type: "access", // histo des derniers accès 
            limit: 1
          } 
        }    
        try {
            setProcessing(true);
            const response = await API.get("iotLockAdmin", "/lock/" + lockId, myInit)
            //console.log("api:", response)
            setLoading(false);
            if (response.error) {
                setErrorMsg("Erreur lors de la récupération des information de la serrure : "+response.message);
                
            } else {
                return response;
            }
        }
        catch (error) {
          //console.log(error.response)
          console.log(error)
          setLoading(false);
          setErrorMsg("Erreur lors de la récupération des information de la serrure : "+error.message)
        }
    } 
    async function closeLockRest(lockId) {
        console.log("Lock closing ... ", lockId);
        let myInit = {
          body: { action: "close"}, 
        }    
        try {
            setProcessing(true);
            const response = await API.put("iotLockAdmin", "/lock/" + lockId, myInit)
            console.log("api:", response)
            setProcessing(false);
        }
        catch (error) {
          //console.log(error.response)
          console.log(error)
          setButtonState("error"); 
          setErrorMsg("Erreur lors de la fermeture de la serrure : "+error.message);
          throw "error";
        }
    }
    async function openLockRest(lockId) {
        console.log("Lock opening ... ", lockId);   
        let myInit = {
            body: { action: "open"}, 
        }         
        try {
            setProcessing(true);
            const response = await API.put("iotLockAdmin", "/lock/" + lockId, myInit)
            console.log("api:", response)
            setProcessing(false);
        }
        catch (error) {
          //console.log(error.response)
          console.log(error)
          setButtonState("error"); 
          setErrorMsg("Erreur lors de l'ouverture de la serrure : "+error.message)
          throw "error";
        }
    }    

    return (
        <> 
              {loading &&
                    <div  className={classes.loading}>
                        <CircularProgress size={40} />
                    </div>
                }

            { manufacturer==='THEKEYS' && 
                <>
                    <div>
                        {lockInfo?.name} / {lockInfo?.description}
                    </div>                
                    <div>
                        état : {lockInfo?.state}
                    </div>
                    <div>
                        dernière info : {(lockInfo?.lastUpdate)?(lockInfo?.lastUpdate).slice(0,19):""}
                    </div>
                    <div>
                        Batterie:{lockInfo?.battery}
                    </div>
                    <Card>
                        <MyButton state={buttonState} displayOn={"Ouvrir"} displayOff={"Fermer"} onCallback={handleLockOpen} offCallback={handleLockClose} ></MyButton>
                        <div>
                        {errorMsg}
                        </div>
                    </Card>
                    {lockInfo?.logs &&
                        <>
                        {lockInfo.logs.map( (item, key) => 
                            <Grid container direction="row" spacing={0} alignItems="stretch" justifyContent="space-evenly" key={key} >
                                <Grid item xs={6}  >
                                    <div>{item.utilisateur}</div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div>{item.action}</div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div>{(item.action_at.date).slice(0,19)}</div>
                                </Grid>                    
                            </Grid> 
                        )}
                        </>
                    }                
                </>
            }
            { manufacturer==='NUKI' && 
                <>
                    <Grid container direction="row" justifyContent="space-evenly">
 {/*                        <Grid item xs={6}  >
                            <img src="/nuki_img.jpeg" alt="image" width="50" />
                        </Grid>
 */}                        <Grid item xs={4}>
                            
                        </Grid>
                        {customer.isUserAdmin &&
                            <Grid item xs={8}>
                                <MyLink to={"/lockadmin/"+service.id+"/"} className={classes.linkAlert}>
                                    <div>
                                        <VpnKeyIcon  />
                                        <div>Gestion des accès</div>
                                    </div>
                                </MyLink>
                            </Grid> 
                        }                   
                    </Grid>  
                    <hr/>               
{/*                     {lockAccessLog &&
                        <>
                        {lockAccessLog.map( (item, key) => 
                            <MyLink to={"/lock/"+service.id+"/"} className={classes.linkAlert} key={key}  >
                            <Grid container direction="row" spacing={0} alignItems="stretch" justifyContent="space-evenly" >
                                <Grid item xs={6}  >
                                    <div>{dateUtcToLocal(item.date)}</div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div>{item.name}</div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div>{lockActionToText(item.action, item.state)}</div>
                                </Grid>                    
                            </Grid> 
                            </MyLink>
                        )}
                        </>
                    }  */}
                    <ProbesDisplay 
                        serviceId={service.id}
                        serviceType={service.serviceType}
                        date={service.lastValue.dateEvent}
                        struct={service.device.template.probesDisplay} 
                        values={service.lastValue.probes} 
                        stats={service.dailyStats.items}
                        probesCfg={service.probesConfig}
                    />  
                </>
            }


        </>
    );
}

function MyButton(props) {
    const { state, displayOn, displayOff, onCallback, offCallback } = props;

    const classes = useStyles();

    useEffect(() => {
        console.log("button state:",state);
    }, [state]);  

    const handleClick = async ()  => {
        console.log("click");
        if (state==="on") {
            console.log("start Off")
            offCallback();

        } else if (state==="off") {
            console.log("start On")
            onCallback();

        } else {
            console.log("state not on/off : ", state)
        }
    };
    return (
        <>  
            <Button
                variant="contained"
                color="default"
                onClick={handleClick}
                className={classes.button}
                >
                {state==="on" &&
                    <div>{displayOn}</div>
                }
                {state==="off" &&
                    <div>{displayOff}</div>
                }
                {state==="processing" &&
                    <CircularProgress size={40} />
                } 
                {state==="error" &&
                   <div>Erreur</div>
                }                
            </Button> 
        </>
    );
}
