import React, { useEffect, useState, useContext } from 'react';

import GraphQlApi from '../mobile/MyGraphql';
import { API, graphqlOperation } from "aws-amplify";

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import StarIcon from '@material-ui/icons/Star';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {useHistory } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import ReactCardFlip from 'react-card-flip';

import { askForRefreshingUser } from '../Common';
import GeneralContext from './GeneralContext'
import ServiceStandard from './ServiceStandard'
import ServiceEto from './ServiceEto'
import ServiceLock from './ServiceLock'
import ServiceMediaServer from './ServiceMediaServer'
import MyBatteryLevel from './MyBatteryLevel'

 
const useStyles = makeStyles(theme => ({
    root: {
        //minWidth: 275,
        paddingRight:"1px",
        height: "100%",
    },
    content: {
        height: "80%",
        padding: "0px",
        marginTop : "2px"
    },
    title: {
        // fontSize: 14,
        backgroundColor: "#87CEEB",
        fontWeight: "bold",
        margin:0
     },
    cardTop: {
        height: "80%"
    },
    headerIcon: {
        margin: "5px"
    },
    actions: {
        // display: "flex",
        // alignSelf: "end",
        // alignItem: "right",
        // align: "end",
        textAlign: "center",
        height: "20%",
    },
    headerDemoDevice: {
        backgroundColor: "#edbe3e",
        fontSize:"small"
    },
    headerNoNetwork: {
        backgroundColor: "red",
    },    
  }));

export default function ServiceDisplay(props) {
    const { data: rawService, customerId } = props;   
    const { t } = useTranslation();

    const { user, customer } = useContext(GeneralContext);
    const [ service, setService] = useState(null);
    const [ isFavorite, setIsFavorite ] = useState(false);

    const [ isDemoDevice, setIsDemoDevice ] = useState(false);
    const [ isNoNetwork, setIsNotNetwork] = useState(false);

    const [ iconTypeUrl, setIconTypeUrl] = useState();
    const [ headerColor, setHeaderColor] = useState("#87CEEB"); // bleu clair


    const [flipstate, setFlipstate] = useState(false);

    const [batteryLevel, setBatteryLevel] = useState();

    const classes = useStyles();
    let history = useHistory()

    useEffect(() => {
        // l'objet service est different suivant sa provenance (fetchServicesAll vs fetchServicesGroup)
        // AWSAppSyncClient semble renvoyer directement le json alors que API.graphql renvoi une string ?
        const serviceObj = getServiceObj(rawService);
        if(serviceObj.globalConfig  && (typeof serviceObj.globalConfig === 'string')) {
            serviceObj.globalConfig = JSON.parse(serviceObj.globalConfig)
        }
        //conversion en json de lastValue.probes
        if(serviceObj.lastValue && (typeof serviceObj.lastValue.probes === 'string')) {
            serviceObj.lastValue.probes = JSON.parse(serviceObj.lastValue.probes)
        }
        //conversion en json de lastValue.slidingCumul
        // concerne les cumuls glissants de qte issue d'index comme la pluie pour une station météo
        if(serviceObj.lastValue && (typeof serviceObj.lastValue.slidingCumul === 'string')) {
            serviceObj.lastValue.slidingCumul = JSON.parse(serviceObj.lastValue.slidingCumul)
            // on dispach les cumul au niveau de chaque probe
            updateProbesWithCumuls(serviceObj)
        } 
        if (serviceObj.lastValue) {
            // rechercher si le device contient un sonde de type Battery
            const probes = serviceObj.lastValue.probes;
            Object.keys(probes).map( (key) => {
                const probe = probes[key];
                if ('BATTERY' === (probe.type).toUpperCase()) {
                    console.log("probe with battery:",probe)
                    //TODO eventuelelement 
                    if (!isNaN(probe.value)) {
                        setBatteryLevel(probe.value);
                    }
                  }                
            })
            //
        }       
        // si il existe des alertes pour ce service, on les dispatche dans les probes concernées
        if(serviceObj.alerts && serviceObj.alerts.items.length>0) {
            updateProbesWithAlerts(serviceObj)
        }
        if(serviceObj.device) {
            if (serviceObj.device.dataSource) {
                if (serviceObj.device.dataSource==="CLONE") {
                    console.log("*** Device de demonstration *******");
                    setIsDemoDevice(true);
                }
            }
            if (serviceObj.device.networkStatus) {
                if (!serviceObj.device.networkStatus || (serviceObj.device.networkStatus!=="ACTIVATED" && serviceObj.device.networkStatus!=="NONE" )) {
                    console.log("*** Device non activé sur un réseau *******")
                    setIsNotNetwork(true);
                }
            } else {
                // si pas d'info sur le statut, on n'affiche rien
                setIsNotNetwork(false);
            }
            // header background et icon du device tel que défini dans le template du device
            if (serviceObj.device.template && serviceObj.device.template.displayConfig) {
                //console.log("displayConfig:",serviceObj.device.template.displayConfig )
                const displayCfg = JSON.parse(serviceObj.device.template.displayConfig);
                if (displayCfg.iconUrl) {
                    setIconTypeUrl(displayCfg.iconUrl);
                }
                if (displayCfg.headerColor) {
                    setHeaderColor(displayCfg.headerColor);
                }                

            }
        }
        //console.log("serviceObj:",serviceObj);
        //console.log("rawService:",rawService);
        setService(serviceObj);
    },[rawService])

    // pour rafraichir l'etoile du favori
    useEffect(()=> {
        // le user peut arriver plus tard que le service
        // normalement ici, on a un refresh avec les dernieres versions des deux
        if (user.id) {
            const serviceObj = getServiceObj(rawService);
            if (user.favorites && user.favorites.items) {
                //console.log("user:",user)
                setIsFavorite(isUserFavorite(user.favorites.items, serviceObj.id))
            }
        }
    }, [user.favorites?.items, rawService])


    const onUpdateLastValue = /* GraphQL */ `
        subscription OnUpdateLastValue($customerId: String!) {
            onUpdateLastValue(customerId: $customerId) {
                id
                dateEvent
                customerId
                commOperator
                probes            
            }
        }
        `;

    useEffect(() => {
        // subscription possible alors que les droits sont en read dans graphql : 
        // https://github.com/aws-amplify/amplify-cli/issues/2636#issuecomment-567286984i
        var subscription;
        function subscribeForCustomerEvent(customerId) {
            // Subscribe to creation of Todo
            subscription = API.graphql(
                graphqlOperation(onUpdateLastValue,{customerId : customerId})
            ).subscribe({
                next: ({ provider, value }) => {
                     const lastValEvent = value.data.onUpdateLastValue;
                    // chaque service reçoit tous les events sur lastValue pour le customer
                    // si c'est pour lui, il traite ...
                    if (lastValEvent && service && lastValEvent.id == service.id) {
                        console.log("************ NewValue from lastValue for serviceid %s *************************", lastValEvent.id );
                        console.log("event:", lastValEvent)    
                        //console.log("for service:",service );
                        if (lastValEvent.probes) {
                            const lastProbes = JSON.parse(lastValEvent.probes);

                            // pour forcer le refresh du composant Service
                            const serviceUpdated = JSON.parse(JSON.stringify(service));

                            serviceUpdated.lastValue.dateEvent = lastValEvent.dateEvent;
                            serviceUpdated.lastValue.probes = lastProbes;
                            //serviceUpdated["test"] = "true";

                            setService(serviceUpdated);
                            console.log("new lastvalue event for Service : ",serviceUpdated);
                        } else {
                            console.log("lastValEvent.probes is null ", lastValEvent);
                        }
                    } 
                },
                error: error => console.warn("Subscription error :"+error)
            });
        }
        if (service && customer) {
            console.log("SUBSCRIPTION SUR LASTVALUE pour service  ", service.id)
            subscribeForCustomerEvent(customerId);
            return function cleanup() {
                console.log("UNSUBSCRIBE pour service  ", service.id)
                subscription.unsubscribe();
            };
        }
    },[service]);



    function editService() {
        history.push("/serviceadmin/" + service.id );
    }

    function getServiceObj(service) {
        return service.hasOwnProperty('service')?service.service:service;
    }

    // on parcours les alertes pour mettre un isAlarm avec son statut si necessaire dans la probe concernée
    function updateProbesWithAlerts(serviceObj) {
        const alerts = serviceObj.alerts.items;
        //console.log("serviceObj.lastValue:",serviceObj.lastValue);
        const probes = serviceObj.lastValue.probes;

        // raz des alertes
        Object.keys(probes).map( probeId => { probes[probeId].isAlarm = []})

        alerts.map( alert => {
            if (alert.probeID && alert.status) {
                // il peut y avoir plusieurs alertes pour une même probe pour un même user
                if (probes[alert.probeID].isAlarm) {
                    probes[alert.probeID].isAlarm.push(alert.status)
                } else {
                    probes[alert.probeID].isAlarm = [alert.status];
                }
            } 
/*             
            const statut = alert.status;
            if (status==='inactive') {
                probes[probeId].isAlarm = ""; // yes
            } else if (status==='active') {
                probes[probeId].isAlarm = ""; // yes
            } else if (status==='triggered') {
                probes[probeId].isAlarm = ""; // yes
            } else {
                // pas d'alerte definie pour ce service
                probes[probeId].isAlarm = "no"; 
            } */
        })
    }
    // on parcourt les cumul glissants pour les attacher à leur probe respective dans lastValue.probes
    function updateProbesWithCumuls(serviceObj) {
        const probes = serviceObj.lastValue.probes;
        const cumuls = serviceObj.lastValue.slidingCumul;
        // on supprime le updatedAt pour pouvoir parcourir les probes
        if (cumuls.hasOwnProperty("updatedAt")) {
            delete cumuls.updatedAt;
        }
        for(const probeKey in cumuls) {
           // on a un cumul pour cette probe, on le ratache à la mêm probe dans lastValue.probes
           if (probes.hasOwnProperty(probeKey)) {
               probes[probeKey]["cumuls"] = cumuls[probeKey];
           } else {
               console.log("Il existe un cumul glissant (ex pluie 1/8/24h) impossible a ratacher à une probe de lastvalue")
           }
        }
    }    

    function isUserFavorite(favorites,serviceId) {
        return favorites.some( favorite => {
            if (favorite) return favorite.service.id === serviceId
        })
    }

    function getFavoriteId(userFavorites, serviceId) {
        const favorite = userFavorites.find( favorite => {
            return favorite.service.id === serviceId
        });
        return favorite.id;
    }

    async function toAddFavorite() {
        console.log("adding favorite");
        const id = await addFavoriteMutation();
        askForRefreshingUser(user.id, customer.id);
    }

    async function toDeleteFavorite() {
        const favoriteId = getFavoriteId(user.favorites.items, service.id)
        console.log("removing favorite:",favoriteId);
        await deleteUserFavoriteMutation(favoriteId);
        askForRefreshingUser(user.id, customer.id);
    }

    async function addFavoriteMutation() {

        const createMutation = `mutation createFavorite($input: CreateFavoriteSvcInput!) {
            createFavoriteSvc( input: $input) {
                id
            }
        }`;
        const toAdd = {
            userID: user.id,
            serviceID: service.id,
            customerID: customer.id
            //name : "test"
        };
        console.log("Adding favorite", toAdd);
        //const response = await API.graphql(graphqlOperation(createMutation, { input: toAdd }));
        const response = await GraphQlApi.getInstance().graphqlOperation( createMutation, { input: toAdd });
        console.log("Favorite added", response.data.createFavoriteSvc);
        if (response.data && response.data.createFavoriteSvc) {
            const newId = response.data.createFavoriteSvc.id
            return newId;
        }
    }

    async function deleteUserFavoriteMutation(favoriteId) {
        const toUpdate = {
          id : favoriteId,
        };
        const theMutation = `mutation deleteFavorite($input: DeleteFavoriteSvcInput!) {
            deleteFavoriteSvc( input: $input) {
              id
          }
        }`;
        //console.log("Deleting favorite", favoriteId) ;
        //const response = await API.graphql(graphqlOperation(theMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( theMutation, { input: toUpdate });
        console.log("Favorite deleted", response.data.deleteFavoriteSvc);
    }    

    const headerBackgroundStyle = {
        backgroundColor: headerColor,
        fontWeight: "bold",
        margin: 0
    };
 
    return (
        <>
        {service && 
            <Card className={classes.root}>
                <div className={classes.cardTop}>
                <div>
                    {iconTypeUrl &&
                        <Grid   container direction="row" justifyContent="space-between" 
                                alignItems="center" style={headerBackgroundStyle}>
                            <Grid  xs={2} item>

                                <div  className={classes.headerIcon}>
                                    <img src={iconTypeUrl} alt="image" height="50" />
                                </div>

                            </Grid>
                            {batteryLevel &&
                                <>
                                    <Grid  xs={8} item>
                                        <Typography  color="primary" gutterBottom>
                                        {service.name}
                                        </Typography>
                                    </Grid>
                                    <Grid  xs={2} item>
                                        <MyBatteryLevel value={batteryLevel} />
                                    </Grid> 
                                </>                           
                            }
                            {!batteryLevel &&
                                <Grid  xs={10} item>
                                    <Typography  color="primary" gutterBottom>
                                    {service.name}
                                    </Typography>
                                </Grid>                            
                            }
                        </Grid>                            
                    }
                    {!iconTypeUrl &&
                        <Grid   container direction="row" justifyContent="center" 
                                alignItems="center" style={headerBackgroundStyle}>                        
                            <Grid item>
                                <Typography  color="primary" gutterBottom>
                                {service.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    }                        
                    {isDemoDevice &&
                        <div className={classes.headerDemoDevice}>Capteur de démo</div>
                    }
                    {isNoNetwork &&
                        <div className={classes.headerNoNetwork}>Réseau non activé</div>
                    }
                </div>

                <ReactCardFlip isFlipped={flipstate} >

                    <CardContent className={classes.content}>

                       {service.serviceType!=='ETO' && service.serviceType!=='LOCK' && service.serviceType!=='MEDIA_STREAM' &&
                            <ServiceStandard data={service} />
                        }
                        {service.serviceType==='ETO' &&
                            <ServiceEto data={service} />
                        }    
                        {service.serviceType==='LOCK' &&
                            <ServiceLock data={service} /> 
                        } 
                        
                        {service.serviceType==='MEDIA_STREAM' &&
                            <ServiceMediaServer data={service}  /> 
                        }                         
                    </CardContent>

                    <CardContent className={classes.content}>
                        <div>{service.device.manufacturer}</div>
                        <div>{service.device.id}</div> 
                        {service.device.idMqtt &&
                            <div>{service.device.idMqtt}</div>    
                        }
                        <div>{service.device.template.id}</div>
                        <div>{service.lastValue.commOperator}</div>    
                        <div>{service.id}</div>   
                        <div>{service.timeZone}</div>         
                    </CardContent>

                </ReactCardFlip>  
                </div>
                <CardActions  className={classes.actions} >
                   
                        {!isFavorite && 
                            <IconButton aria-label="add to favorites" onClick={toAddFavorite}>
                                <StarIcon /> 
                            </IconButton>
                        }
                        {isFavorite && 
                            <IconButton aria-label="delete to favorites" onClick={toDeleteFavorite}>
                               <StarIcon style={{ color: 'blue' }}/> 
                            </IconButton>                            
                        }
                    
                    <Button  size="small" color="primary" onClick={e => setFlipstate( !flipstate )}> {t('screen.device.button.two-sided')} </Button>

                    {customer.isUserAdmin &&
                        <Button size="small" color="primary" onClick={editService}> {t('screen.device.button.admin')} </Button>
                    }
                </CardActions>

            </Card>
        }
        </>
    );
}


