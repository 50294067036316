import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { round } from '../Common';

import GeneralContext from '../components/GeneralContext'

const useStyles = makeStyles({
    root: {
        alignItems: 'center',
    },
    sensorSub: {
        // backgroundColor: "grey"
        paddingRight: "10px"
    }, 
    sensorValue: {
        fontSize: "24px"
    },
    sensorMinmax: {
       width: 100
    },
    sensorMore: {
        fontSize: "13px"
    }
});

export default function DewPoint({tempHum}) {
    const { portal } = useContext(GeneralContext);
    
    const [dewPoint, setdewPoint] = useState();

    useEffect(() => {
        if (tempHum && tempHum.hasOwnProperty('t') && tempHum.hasOwnProperty('h')) {
            const humidity = tempHum.h;
            const temperature = tempHum.t;
            const dewPoint = computeDewPoint(temperature,humidity);
            setdewPoint(dewPoint);
        }
    }, [tempHum]);

    function computeDewPoint(temp, hum) {
        var dewPointTemp;
        const a = 17.27;
        const b = 237.7;
        const alpha = ((a * temp ) / ( b + temp )) 
        if (temp > 0 || temp < 60) {
            dewPointTemp =  ( b * (alpha + Math.log(hum / 100))) / ( a - (alpha + Math.log(hum / 100))) 
        }
        //+ ( Math.log(hum / 100))
        return round(dewPointTemp,1);
    }

    const { t } = useTranslation();
    const classes = useStyles();
    return (
/*         <div className={classes.root}>
            Point de rosée : {dewPoint} °C
        </div> */
        <Grid container direction="row" justifyContent="space-around" alignItems="stretch"  spacing={1} className={classes.sensorSub}>
            <Grid item xs>
                    <div>
                        <div>
                            Point de rosée
                        </div>
                        <div className={classes.sensorValue}>
                            {dewPoint} °C
                        </div>  
                    </div>                          
            </Grid>
            <Grid item  className={classes.sensorMinmax}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>&nbsp;</Grid>
                    <Grid item>&nbsp;</Grid>
                </Grid>                                       
                <Grid container justifyContent="space-between">
                    &nbsp;
                </Grid>                                    
                <Grid container justifyContent="space-between">
                    &nbsp;
            </Grid>
            </Grid>
        </Grid>           
  );
}

