import { Hub } from '@aws-amplify/core';
import awsconfig from './aws-exports';

import chartConfig from './chart-config'; // configuration générale du graphique (noms possibles des axes Y (droite/gauche) couleurs)
import moment from 'moment';

export function askForRefreshingUser(userId, customerId) {
  const params = { userId: userId };
  if (customerId) {
    params['customerId'] = customerId
  }
  askForRefresh("toRefreshUser", params) 
}
export function askForChangingCustomer(customerId, pageToGo) {
  var params = { customerId : customerId};
  if (pageToGo) {
    params['pageToGo'] = pageToGo;
  }
    askForRefresh("toChangeCustomer", params);
}
export function askForRefreshingCustomer(customerId) {
    askForRefresh("toRefreshCustomer", { customerId : customerId}) 
}

export function askForSettingFirstCustomer(customerId) {
  askForRefresh("toChangeCustomer", { customerId : customerId, isFirst:true}) 
}

export function findTempHumidity(allProbes) {
  var temperature, humidity;
  for (const probeKey in allProbes) {
      const probe = allProbes[probeKey];
      if (probe.type==="temp") {
          if (probe.dewPoint) {
              // est-ce que l'option point de rosée est présente
              temperature = probe.value;
          }
      }
      if (probe.type==="hygro") {
          humidity = probe.value;
      }
  }
  if (!isNaN(temperature) && !isNaN(humidity)) {
      return { t: temperature, h:humidity}
  } else {
      return;
  }
}


export function getPathFromHomepage(homePage) {
  var pathToGo;
  if (homePage==="ALL_DEVICES") {
    pathToGo = "/services/all";

  } else if (homePage==="FAVORITES") {
    pathToGo = "/services/favorites";

  } else if (homePage==="NEW_USER") {
    pathToGo = "/home";

  } else {
      // CUSTOM_APART, CUSTOM_HOUSE ...
      // pour pourrait diriger directement sur /homepage/myapartment
      pathToGo = "/custom";
  }
  return pathToGo;
}



function askForRefresh(targetEvent, event) {

    Hub.dispatch( targetEvent,
    {
      event: event,
      data: {},
      message: ''
    }); 
}


export function toSnack(sourceEvent, message) {
    Hub.dispatch( 'toSnack',
    {
      event: sourceEvent,
      data: message,
      message: ''
    }); 
}

/* export function  handleCloseModal(value) {
  console.log("handleClickOpenModal")
  const history = useHistory();
  history.goBack()
  //history.push('/')
};
 */

export async function publicCustomerRequest(history, demoType) {
  const { exe_env_name } = awsconfig;
  var demoCustomerId, demoCustomerName;

  if (exe_env_name && exe_env_name === 'dev') {
    //pour test
    demoCustomerId = "7d0adc6d-0d87-425b-bf6d-091b762d98ad"; // en dev
    demoCustomerName = "Démonstration";

  } else {
    if (demoType==='farm') {
    demoCustomerId = "ff28851c-cf37-4505-967f-c275f31af6b5"; // 
    demoCustomerName = "monExploitation";
    }
    else if (demoType==='home') {
      demoCustomerId = "e529fd93-ece3-4edb-9eb9-ca23f045b600"; //
      demoCustomerName = "ma résidence ";
    }
    else if (demoType==='office') {
      demoCustomerId = "25b9ea20-778f-43e6-ae92-0ab93574a3f5"; // 
      demoCustomerName = "MonBureauDémo";

    } else {
      console.log("Erreur sur le demoType");
      demoCustomerId = "b7ff40fb-5e56-4e55-a024-46da5be1f3a7"; // en prod
      demoCustomerName = "Démonstration";
    }
  }
  history.push("/invitation/"+demoCustomerId+"?code=PUBLIC&customer="+demoCustomerName)
  //await joinCustomerRequestRest({ mode : 'public', customerId : demoCustomerId}) 
  //toSnack("UserUpdated","Inscription à Démo ok");
  // refresh customer ...
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function truncate(str, n) {
  if (!str) return;
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

// transformation de l'objet d'objet en tableau d'objet
export function mapToArray(mapObj) {
  return Object.keys(mapObj).map(function (key) { var arrayObj = mapObj[key]; arrayObj["id"] = key; return arrayObj; });
}

// transformation d'un tableau d'objet en objet d'objet
export function arrayToMap(array, key) {
  return array.reduce(function(map, obj) {
      map[obj[key]] = obj;
      return map;
  }, {});
}

export function lastObject(theObject) {
  const mapArray = Object.keys(theObject);
  console.log("mapArray:",mapArray);
  return mapArray[mapArray.length-1];
}


export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function formatNumber(rawValue, decimal) {
  if (rawValue<10) {
    return String(round(rawValue, 2));
  } else {
    // sans décimale avec 1 blanc tous les 1000
    return String(round(rawValue, decimal)).replace(/(.)(?=(\d{3})+$)/g,'$1 ')
  }
}

export function sum(a, b) {
  return a + b;
}

export function isEmptyObject(obj) {
  return (obj && (Object.keys(obj).length === 0));
}
export function isEmptyList(list) {
  return (list.length === 0);
}

export function isHex(h) {
  return /^[0-9a-fA-F]+$/i.test(h);
}

export function addingRightsFromIdTokenToUser(payload,cognitoUser) {
  // maj des user avec ses droits ce le customer issue du token
  // appeler depuis
  // - le login courrant (dans mayAutentification)
  // - le cas ou le customer vient d'être créer au moment de l'inscription du user
  // - au moment d'un changement de customer
  // - A verifier dans fromMobile
  const {currentCustomerId, currentCustomerIdAdmin, currentCustomerIdOwner} = payload;
  console.log("currentCustomerId (in token) : ", currentCustomerId)
  cognitoUser["currentCustomerId"] = currentCustomerId;
  cognitoUser["isCustAdmin"] = (currentCustomerIdAdmin && currentCustomerId === currentCustomerIdAdmin) ? true : false;
  cognitoUser["isCustOwner"] = (currentCustomerIdOwner && currentCustomerId === currentCustomerIdOwner) ? true : false;
      
  //userWithToken.groups = tokenPayload['cognito:groups']; //TODO voir si ol n'y a pas mieux pour recuperer les groupes

}


// configuration des sondes ....

// aller chercher le detail d'une sonde dans le template/modele 
// et dans le service pour les infos modifiable par l'utilisateur (nom, config graphique)
export const getProbeDetail = ((service, probeId)=> {
  const probesStructRaw = service.device.template.probesDisplay;
  const probeStruct = JSON.parse(probesStructRaw);
  var probeDetail = probeStruct[probeId];

  // si un nom existe on le prends 
  if (service.probesConfig) {
    const probesSvcCfg = JSON.parse(service.probesConfig);  // config gérée par l'utilisateur issue de service
    if (probesSvcCfg && probesSvcCfg[probeId] ) { 
      const probeCfg = probesSvcCfg[probeId]; 
      if (probeCfg.name) {
        probeDetail.name = probeCfg.name;
      } else {
        probeDetail.name = probeDetail.type; // a la cible, le nom n'existera plus dans template, on prends le type sinon
      }
      if (probeCfg.graph) {
        probeDetail.graph = probeCfg.graph;
      }
      if (probeCfg.hasOwnProperty("stateOn")) {
        probeDetail.stateOn = probeCfg.stateOn;
      }      
    }
  }
  //probeDetail['type'] = findTypeFromUnit(probeDetail.unit) // pour l'instant on ne reomonte pas le type dans probesDisplay
  return probeDetail;
})
  
// recherche d'un type de sonde normalisé en fonction d'une unité
// peut-être ajouter un type dans probesDsiplay du template ?
// n'est plus utilisé pour l'instant (le type etant intégré dzns le template.probesDisplay)
const findTypeFromUnit = ((unit) => {
  // cf le fichier js de config (chartConfig.js)
  const probesType = chartConfig.probesType;

  var findType;
  for(const key in probesType) {
    const type = probesType[key];
    //if (type.unit.includes(unit)) {
    if (type.unit.toUpperCase() === unit.toUpperCase()) {  //{/* EVOL1 */}
      findType = key;
      break;
    }
  }
  return findType;
})

// ----

export const getPeriodFromStart = ((rangeType, startDate) => {

  const today = new Date(startDate).toISOString();
  var start, end;

  if (rangeType==="yesterday") {
    start = moment(today).subtract(1, 'days').startOf('day').toISOString();   
    end = moment(today).subtract(1, 'days').endOf('day').toISOString();     

  } else if (rangeType==="last7days") {
    end = moment(today).endOf('day').toISOString();   
    start = moment(today).subtract(6, 'days').endOf('day').toISOString();   

  } else if (rangeType==="last30days") {
    end = moment(today).endOf('day').toISOString();   
    start = moment(today).subtract(29, 'days').endOf('day').toISOString(); 

  } else if (rangeType==="oneDay") {
    start = moment(today).startOf('day').toISOString(); 
    end = moment(today).endOf('day').toISOString();   

  } else {
    //today, 
    start = moment(today).startOf('day').toISOString();   
    end = moment(today).endOf('day').subtract(1,'minutes').toISOString(); 
    // le substract de 1 min pour contourner un 'bug' du client graphql AWSAppSyncClient
  }
  return { start: start, end: end}

})

export const getPeriodUntilNow = ((rangeType) => {
  return getPeriodFromStart(rangeType, new Date().toISOString());
})

// -------------------------  graphique (monoAxe / MultiAxes)

export const buildAnnotations = ((dataSets) => {

  // il peut y avoir plusieurs courbes
  // bien que sur un seul axe
  var array = [];
  for(const key in dataSets) {
      const dataSet = dataSets[key];

      //console.log("dataSet:",dataSet);
      // si la courbe n'est pas affiché, on de prends pas les annotations
      if (dataSet.show && dataSet.show !== "no") {

          if (dataSet.graphCfg && dataSet.graphCfg.notes) {
              const notes = dataSet.graphCfg.notes; // array de { text: '', value: 0 }

              for(const key in notes) {
                const note = notes[key];
                array.push(buildOneAnnotation(dataSet.show, note)) //// show contient la position de l'axe des y : left/right
              }
          }
      }
  }
  return array;
})

/**
* lignes horizontales superposeré sur le chart
* @param {*} position  left/right
* @param {*} element  { value: 10, text : "min" }
*/
const buildOneAnnotation = ((position, element ) => {
  return  {
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y-axis-'+position,
    value: element.value,
    borderColor: 'red',
    borderWidth: 1,
    label: {
      backgroundColor: 'red',
      content: element.text,
      enabled: true,
    },
  }
})


export const  dateUtcToLocal = (dateUtc) => {
  return new Date(dateUtc).toLocaleString();
}

export const localDateTimeIsoString = () => {
  const now = new Date();
  const offsetLocalFromUtc = now.getTimezoneOffset() * 60 * 1000; // en ms
  const datetimeLocal = new Date(now-offsetLocalFromUtc);
  const datetimeLocalIsoString = datetimeLocal.toISOString().slice(0,16);
  return datetimeLocalIsoString; // ex 2022-03-31T15:21
}

export const hhmmStringToSecond = (timeString /* ex 06:00 */ ) => {
  return timeString.split (':').reduce (function (seconds, v) {
    return +v + seconds * 60;
  }, 0) ;
}

export const minutesToHHmm = (timeInMin ) => {
  var hours = (timeInMin / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return ("00"+rhours).slice(-2)+":"+("00"+rminutes).slice(-2) ;
}

export const  lockActionToText = (action, state) => {
  var textAction;

  if (state === 0) {
    // action terminée
    switch (action) {
        case 1:
            textAction= "Ouvert"; //unlock
            break;
        case 2:
            textAction= "Fermé"; //lock
            break;
        case 3:
            textAction= "Ouvrir"; //unlatch
            break;
        case 4:
            textAction= "verrouiller"; //lock'n'go
            break;
        case 5:
            textAction= "verrouiller"; // lock'n'go with unlatch
            break;   

        default:
            textAction= "?";
    } 
  } else {
    switch (state) {
      case 1:
          textAction= "Moteur bloqué"; 
          break;
      case 2:
          textAction= "Annulé";
          break;
      case 3:
          textAction= "Trop récent"; 
          break;
      case 4:
          textAction= "Occupé"; 
          break;
      case 5:
          textAction= "Batterie faible"; 
          break;   
      case 6:
        textAction= "Echec de la serrure";
        break;     
      case 7:
        textAction= "Problème de batterie";
        break;             
      case 8:
        textAction= "Incomplete";
        break;        
      case 9:
          textAction= "Non autorisé"; 
          break; 
      case 10:
          textAction= "Non autorisé la nuit"; 
          break; 
      case 254:
        textAction= "Erreur non connue"; 
        break; 
      case 255:
        textAction= "Erreur non connue"; 
        break; 
    
      default:
          textAction= "?";
    }      
  }
  return textAction;
}