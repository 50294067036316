import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";

import { API } from "aws-amplify";
import GraphQlApi from '../mobile/MyGraphql';

import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory } from "react-router-dom";

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import DeleteIcon from '@material-ui/icons/Delete';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import GetApp from '@material-ui/icons/GetApp'; 

import MyForm from '../components/MyForm';
import ProbesEditList from '../components/ProbesEditList';
import AreYouSure from '../components/AreYouSure';
import SwapDeviceDialog from '../components/SwapDeviceDialog';

import GeneralContext from '../components/GeneralContext';
import { toSnack, isEmptyList } from '../Common';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        flexGrow: 1,
    },   
    button: {
        margin: theme.spacing(1),
    },    
    textFieldBig: {
      width: '40ch',
    },
    textFieldExport: {
      width: '30ch',
    },
    textFieldLonLat: {
      width: '20ch',
      marginRight: 5,
    },
    textFieldGlobalConfig: {
      width: '50ch',
    },
    lonLatLib: {
      color: "rgba(0, 0, 0, 0.54)",
      padding: 0,
      fontSize: "1rem",
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: "0.00938em",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },    
    formParagraph: {
      marginBottom: 10
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id, idList, theme) {
  return {
    fontWeight:
      (idList.find( item => item.id=== id))
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ServiceAdmin(props) {

    const {} = props;
    const { user, customer } = useContext(GeneralContext);
    let { id: serviceId } = useParams();
    const { t } = useTranslation();

    const [service, setService] = useState();
    const [newServiceName, setNewServiceName] = useState("");

    const [probes, setProbes] = useState();

    const [needServiceUpdate, setNeedServiceUpdate ] = useState(false);
    const [someFieldUpdated, setSomeFieldUpdated ] = useState(false);

    const [longitude, setLongitude] = useState("");
    const [latitude, setLatitude] = useState("");

    const [globalConfig, setGlobalConfig] = useState("");
    const [adminConfig, setAdminConfig] = useState("");

    const [loading, setLoading] = useState(false);

    const [appList, setAppList] = useState([]);
    const [appIds, setAppIds] = useState([]);

    const [groupList, setGroupList] = useState([]);
    const [groupIds, setGroupIds] = useState([]);

    const [openServiceDeleteConfirm, setOpenServiceDeleteConfirm] = useState(false);
    const [openSwapDeviceDialog, setOpenSwapDeviceDialog] = useState(false);

    const handleExportChange = (event) => {
      console.log("handleExportChange:",event.target.value);
      setAppIds(event.target.value);
    };
    // retourne un obj app correspondant à l'appId
    function getApp(appId) {
      return appList.find( app => {
        return app.id ===appId
      })
    }

    const handleGroupChange = (event) => {
      console.log("handleGroupChange:",event.target.value);
      setGroupIds(event.target.value);
    };
    function getGroup(groupId) {
      return groupList.find( grp => {
        return grp.id ===groupId
      })
    }

    let history = useHistory()
    const classes = useStyles();
    const theme = useTheme();

    // maj le customer des qu'il est disponible
    useEffect(() => {
      if (customer && customer.id) {
        console.log("customer available:", customer);
        setGroupList(getProperGroupList(customer.groupsSvc))
      }
    }, [customer]);

    // Récuperation des applications d'export possible
    useEffect(() => {
      let ignore = false;
      async function fetchData() {
          console.log("Export apps list is loading ...");
          setLoading(true);
          const listQuery = `query getAppsList {
            listApplications {
              items {
                id
                name
                title
              }
            }
          }`;  
          //const result = await API.graphql(graphqlOperation(listQuery));
          const result = await GraphQlApi.getInstance().graphqlOperation(listQuery);
          setLoading(false);
          if (!ignore && result.data.listApplications) {
              const exportApps = result.data.listApplications.items
              console.log("exportApps :", exportApps);
              setAppList(exportApps)
          } else {
              console.log("Export apps list non trouvées en base");
          }
      }      
      fetchData();
      return () => { ignore = true; }

    }, []);   



    // recuperation du service concerné
    useEffect(() => {
          let ignore = false;
          async function fetchData(serviceId) {
              console.log("Service is loading for %s ...", serviceId);
              setLoading(true);
              const getServiceQuery = `query GetService($id: ID!) {
                  getService(id: $id) {
                    id
                    name
                    serviceType
                    device {
                      id
                      manufacturer
                      status
                      template {
                        id
                        productName
                        probesDisplay
                      }         
                    }
                    customer {
                      id
                    }
                    groupsSvc {
                      items {
                        id
                        group {
                          id
                          name
                        }
                      }
                    }
                    probesConfig
                    globalConfig   
                    adminConfig                 
                    applications {
                      items {
                        id
                        application {
                          id
                          name
                        }
                      }
                    }                     
                    location
                    status
                  }
                }`;
              //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
              const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery, { id: serviceId });
              setLoading(false);
              if (result.data.getService) {
                  const theService = result.data.getService
                  console.log("service :", theService);
                  setSomeFieldUpdated(false);
                  setService(theService)
                  setProbes(buildProbes(theService.device.template.probesDisplay,theService.probesConfig))
                  setNewServiceName(theService.name)
                  setAppIds(getProperAppIds(theService.applications.items))
                  setGroupIds(getProperGroupIds(theService.groupsSvc.items))
                  updateLocation(theService.location)
                  setGlobalConfig(theService.globalConfig || "{\"eto\":{}}");
                  setAdminConfig(theService.adminConfig || "{\"eto\":{\"recipients\":\"\"}}")

                  console.log("initial appIds:",getProperAppIds(theService.applications.items))
                  console.log("initial groupIds:",getProperGroupIds(theService.groupsSvc.items))
              } else {
                  console.log("Service non trouvé en base");
              }
          }      
          fetchData(serviceId);
          return () => { ignore = true; }
  
    }, [serviceId]);   
      
//---- 
    function handleNameChange(e) {
        setNeedServiceUpdate(true);
        setNewServiceName(e.target.value);
    }

    function handleGeoloc() {
      navigator.geolocation.getCurrentPosition(function(position) {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
          setNeedServiceUpdate(true);
      });  
    }


    function updateLocation(location) {
      console.log("location:",location);
      if (location) {
        const locat =JSON.parse(location);
        console.log("location:",locat.lon, locat.lat);
        setLongitude(locat.lon)
        setLatitude(locat.lat)
      }
    }

    // construction d'une liste de sondes
    // sur la structure de sonde du model,
    // on vient greffer les elements propre à ce capteur issue de service
    const buildProbes = ((templateProbesRaw, serviceProbesRaw) => {
      const templateProbes = JSON.parse(templateProbesRaw);
      const serviceProbes = JSON.parse(serviceProbesRaw);

      console.log(templateProbes, serviceProbes);

      var probes = [];
      for(const probeKey in templateProbes) {
        const probeTmplt = templateProbes[probeKey];
        var probe;
        // pour l'instant aucun des services n'a de nom de sondes
        // après msj, la structure pourr  se baser sur probesConfig de service
        if (serviceProbes && serviceProbes[probeKey]) {
          probe= serviceProbes[probeKey];
          //, type: probeTmplt.type, graph: probe.graph, fct: probe.fct, stateOn: probe
        } else { // a priori il y aura toujours un serviceProbes (créé au scan du capteur (creation du service))
          probe = {  };
        }
        probe["id"] = probeKey;
        probe["type"] = probeTmplt.type;
        
        if (!probe.name) {
          probe['name'] = t("sensor.probe-type."+probeTmplt.type)
        }

        probes.push(probe)
      }
      probes.sort((a, b) => a.id.localeCompare(b.id));
      return probes;
    })

    async function handleUpdateService() {

      // si au moins un des champs a changé
      // TODO un boolean needUpdate si au moins un des champs doit être maj
      // (Hyp : c'est le même coût si maj un champ ou plusieurs champs)    
      if (needServiceUpdate) {
        setLoading(true);
        await updateServiceMutation(service)
        setLoading(false);
        setNeedServiceUpdate(false);
        toSnack("ServiceUpdated",t('screen.device-admin.device-wellmodified'));
      }    
      console.log("Fin de la maj de l'ensemble du service")
  }

  async function handleUpdateExport() {
    // list export apps change
    setLoading(true);
    const isUpdated = await updateExportApps();
    setLoading(false);
    if (isUpdated) {
      toSnack("ServiceExportUpdated",t('screen.device-admin.export-wellmodified'));
    }
  }

    async function handleUpdateGroup() {
      console.log("updating groups ...")
      setLoading(true)
      const isUpdated = await updateGroups();
      setLoading(false)
      if (isUpdated) {
        toSnack("ServiceGroupUpdated",t('screen.device-admin.group-wellmodified'));
      }      
    }
    //-----------------
    async function handleUpdateConfig() {
      console.log("updating global config (& adminConfig ) ...")
      setLoading(true)
      const isUpdated = await updateGlobalConfig();
      setLoading(false)
      if (isUpdated) {
        toSnack("ServiceConfigUpdated",t('screen.device-admin.config-wellmodified'));
      }      
    }
    // 
    async function updateGlobalConfig() {

      await updateServiceConfigMutation(globalConfig, adminConfig);
      return true;
    }
    // 
    async function updateServiceConfigMutation(globalConfig, adminConfig) {
      const toUpdate = {
          id: service.id,
          globalConfig: globalConfig
      };
      if (adminConfig) {
        toUpdate['adminConfig'] = adminConfig
      }
      console.log("toUpdate:",toUpdate);
      const updateMutation = `mutation updateService($input: UpdateServiceInput!) {
        updateService( input: $input) {
            id
        }
      }`;
      console.log("Updating Service: ", toUpdate);
      //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
      const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation,{ input: toUpdate });
      console.log("service updated", response.data.updateService);
  }   
    //---------------
    async function handleRemoveService() {
      setOpenServiceDeleteConfirm(true);
    }

    async function removeServiceProcessing(responseOk) {
       
        if (!responseOk) {
          console.log("Cancelled");
          setOpenServiceDeleteConfirm(false);
          return;
        } else {
          setOpenServiceDeleteConfirm(false);
        }

        await removeServiceRest(service.id, service.device.id)
        //toRefreshParent();
        //askForRefreshingFarm("updatedStation", { stationId: service.id });
        toSnack("ServiceRemove",t('screen.device-admin.service-delete'));
        history.goBack();
    }
    
    async function updateServiceMutation(service) {

        const toUpdate = {
            id: service.id,
            name: newServiceName,
        };

        if (latitude) {
          const location = { lon: longitude, lat: latitude};
          toUpdate['location'] = JSON.stringify(location);
        }

        console.log("toUpdate:",toUpdate);
        const updateMutation = `mutation updateService($input: UpdateServiceInput!) {
          updateService( input: $input) {
              id
          }
        }`;
        console.log("Updating Service: ", toUpdate);
        //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation,{ input: toUpdate });

        console.log("service updated", response.data.updateService);

    }

    async function handleSwapService() {
      setOpenSwapDeviceDialog(true)
    }

    async function swapDeviceSuccess(responseOk) {
      setOpenSwapDeviceDialog(false);
      if (responseOk) {
        toSnack("ServicSwap", "Le remplacement du capteur est réussi");
        history.goBack();
      }
    }

    // Export Application : Ajoute ou retranche en fonction de la liste initiale
    async function updateExportApps() {
      var isUpdated = false;

      const initialList = getProperAppIds(service.applications.items);
      const currentList = appIds;

      // on retranche les items existants de ceux présents dans current
      const addToList = currentList.filter( item => {
        return ! initialList.includes(item)
      })
      const toRemoveList = initialList.filter( item => {
        return ! currentList.includes(item)
      })

      //TODO A faire en Back ?
      if (addToList.length>0) { 
        await addingExportApps(addToList)
        isUpdated = true
      }
      if (toRemoveList.length>0) {
        await removingExortApps(toRemoveList);
        isUpdated = true;
      }
      console.log("fin de la maj des export apps")
      return isUpdated;
    }

    // creation d'un enreg app pour chaque element de la liste
    async function addingExportApps(addToListIds) {
      console.log("addingExportApps:",addToListIds)
      for(const key in addToListIds ) {
        const itemId = addToListIds[key];
        await addExportAppMutation(itemId);
      }
    }
    async function removingExortApps(toRemoveListIds) {
      console.log("removingExortApps:",toRemoveListIds)
      for(const key in toRemoveListIds ) {
        const itemId = toRemoveListIds[key];
        await removeExportAppMutation(itemId);
      }      
    }

    async function removeServiceRest(serviceId, deveui) {
        console.log("Removing service...");
        let myInit = {
          body: { param1: "ok"}, // replace this with attributes you need
          headers: {} // OPTIONAL
        }    
        var response;
        try {
          const response = await API.del("iotAttachDevice", "/devices/" + deveui +"/"+ serviceId +"?q=test", myInit)
          console.log("api:", response)
        }
        catch (error) {
          //console.log(error.response)
          console.log(error)
        }
    }

    async function ForceLinkySynchroRest() {
      console.log("ForceLinkySynchro service...");    
      const deviceId = service.device.id;
      var response;
      try {
        response = await API.put("apiRestCustomer", "/customers/"+customer.id+"/device/"+deviceId+"/calllinky", {});
        console.log("api:", response)
      }
      catch (error) {
        //console.log(error.response)
        console.log(error)
      }
    }

    async function addExportAppMutation(appId) {

      const entry = {
        serviceAndApplicationServiceId: service.id,
        serviceAndApplicationApplicationId: appId,
      };

      const createServiceAndApplication = `mutation CreateServiceAndApplication($input: CreateServiceAndApplicationInput!) {
        createServiceAndApplication(input: $input) {
          id
          name
        }
      }
      `;       

      try {
        //  liaison n-n entre service et application
        //const response = await API.graphql(graphqlOperation(createServiceAndApplication, { input: entry }));
        const response = await GraphQlApi.getInstance().graphqlOperation( createServiceAndApplication, { input: entry });
        console.log("Add export ok :",response.data.createServiceAndApplication);
      }
      catch (error) {
        //console.log(error.response)
        console.log(error)
      }  
    }
  
    async function removeExportAppMutation(appId) {

      const tableId = getServiceLinkAppId(appId).id; // id de la table intermédiaire

      const deleteMutation = `mutation DeleteServiceAndApplication($input: DeleteServiceAndApplicationInput!) {
        deleteServiceAndApplication(input: $input) {
          id
          name
        }
      }
      `;      
      console.log(appId, tableId);
      try {
        // suppression de la liaison n-n entre service et application
        //const response = await API.graphql(graphqlOperation(deleteMutation, { input: { id: tableId } }));
        const response = await GraphQlApi.getInstance().graphqlOperation( deleteMutation, { input: { id: tableId }});
        console.log("remove ok :",response.data.deleteServiceAndApplication);
      }
      catch (error) {
        //console.log(error.response)
        console.log(error)
      } 
    }

    function getServiceLinkAppId(appId) {
      const currentExportApps  = service.applications.items;
      return currentExportApps.find( item => {
        return item.application.id ===appId
      })
    }

    // Gestion des les groupes 


    // Groupe pour ce serice : Ajoute ou retranche en fonction de la liste initiale
    async function updateGroups() {
      var isUpdated = false;

      const initialList = getProperGroupIds(service.groupsSvc.items);
      const currentList = groupIds;

      // on retranche les items existants de ceux présents dans current
      const addToList = currentList.filter( item => {
        return ! initialList.includes(item)
      })
      const toRemoveList = initialList.filter( item => {
        return ! currentList.includes(item)
      })

      //TODO A faire en Back ?
      if (addToList.length>0) { 
        await addingGroups(addToList)
        isUpdated = true;
      }
      if (toRemoveList.length>0) {
        await removingGroups(toRemoveList);
        isUpdated = true;
      }
      console.log("fin de la maj des groups")
      return isUpdated;
    }    
    // creation d'un enreg app pour chaque element de la liste
    async function addingGroups(addToListIds) {
      console.log("addingGroups:",addToListIds)
      for(const key in addToListIds ) {
        const itemId = addToListIds[key];
        await addGroupMutation(itemId);
      }
    }
    async function removingGroups(toRemoveListIds) {
      console.log("removingGoups:",toRemoveListIds)
      for(const key in toRemoveListIds ) {
        const itemId = toRemoveListIds[key];
        await removeGroupMutation(itemId);
      }      
    }

    async function addGroupMutation(groupId) {

      const entry = {
        groupID : groupId,
        serviceID: service.id,
        customerID: customer.id,
        owner: user.id        
      };

      const createMutation = `mutation CreateGroupSvcMember($input: CreateGroupSvcMemberInput!) {
        createGroupSvcMember(input: $input) {
          id
        }
      }
      `;       

      try {
        //  liaison n-n entre service et application
        //const response = await API.graphql(graphqlOperation(createMutation, { input: entry }));
        const response = await GraphQlApi.getInstance().graphqlOperation( createMutation, { input: entry });
        console.log("Add export ok :",response.data.createGroupSvcMember);
      }
      catch (error) {
        //console.log(error.response)
        console.log(error)
      }  
    }
  
    async function removeGroupMutation(groupId) {

      const tableId = getServiceLinkGroupId(groupId).id; // id de la table intermédiaire

      const deleteMutation = `mutation DeleteGroupSvcMember($input: DeleteGroupSvcMemberInput!) {
        deleteGroupSvcMember(input: $input) {
          id
        }
      }
      `;      
      console.log(groupId, tableId);
      try {
        // suppression de la liaison n-n entre service et application
        //const response = await API.graphql(graphqlOperation(deleteMutation, { input: { id: tableId } }));
        const response = await GraphQlApi.getInstance().graphqlOperation( deleteMutation, { input: { id: tableId }});
        console.log("remove ok :",response.data.deleteGroupSvcMember);
      }
      catch (error) {
        //console.log(error.response)
        console.log(error)
      } 
    }

    function getServiceLinkGroupId(groupId) {
      const currentGroups  = service.groupsSvc.items;
      return currentGroups.find( item => {
        return item.group.id ===groupId
      })
    }

  // Fin - mutation sur les groupes 

    // du fait de la structure de données graphql (table n-n) 
    // il faut supprimer la propriété intermediaire application
    function getProperAppIds(rawAppIds) {
      return rawAppIds.map( item => item.application.id)
    }

    // du fait de la structure de données graphql (table n-n) 
    // il faut supprimer la propriété intermediaire group
    function getProperGroupIds(rawGroupIds) {
      return rawGroupIds.map( item => item.group.id)
    }


    function getProperGroupList(groupsSvc) {
      if (groupsSvc && groupsSvc.items) {
        console.log("Group:",groupsSvc.items)
        return groupsSvc.items
      } else {
        return [];
      }
    }


    return (
    <div>
        <div className={classes.alignItemsAndJustifyContent}>
          {loading && <CircularProgress size={40} />}
        </div>
        <MyForm title={t('screen.device-admin.device-title')} edit action={handleUpdateService}>
            <div className={classes.formParagraph}>
              <TextField  
                    className={classes.textFieldBig} 
                    value={newServiceName}
                    onChange={handleNameChange}
                    id="sensor-name" 
                    label={t('screen.device-admin.device-name')} />
            </div>
            <div>
              <div className={classes.lonLatLib} >
              {t('screen.device-admin.device-localisation')}
             </div>                
              <TextField  
                  value={latitude}
                  onChange={(e) => {setLatitude(e.target.value)}}
                  className={classes.textFieldLonLat} 
                  id="standard-disabled" 
                  label={t('screen.device-admin.device-lat')} />
              <TextField  
                  value={longitude}
                  onChange={(e) => {setLongitude(e.target.value)}}
                  className={classes.textFieldLonLat} 
                  id="standard-disabled" 
                  label={t('screen.device-admin.device-lon')} />
              <Button
                  variant="contained"
                  color="default"
                  onClick={handleGeoloc}
                  className={classes.button}
                  startIcon={<GpsFixedIcon />}
                >
                  {t('screen.device-admin.current-position')}
                </Button>                  
            </div>
        </MyForm>

        <MyForm title={t('screen.device-admin.probes-title')} >
            <div>
              <ProbesEditList service={service} probes={probes} />
            </div>
        </MyForm>

        {(service && service.serviceType==='ETO') &&
        <MyForm title={t('screen.device-admin.config-title')} edit action={handleUpdateConfig} >

            <TextField  
              value={globalConfig}
              onChange={(e) => {setGlobalConfig(e.target.value)}}
              id="global-config" 
              className={classes.textFieldGlobalConfig}
              multiline
              rows={4}
              label={t('screen.device-admin.config-global')} />

            <TextField  
              value={adminConfig}
              onChange={(e) => {setAdminConfig(e.target.value)}}
              id="global-config" 
              className={classes.textFieldGlobalConfig}
              multiline
              rows={4}
              label={t('screen.device-admin.config-admin')} />              

        </MyForm>
        }

        <MyForm title={t('screen.device-admin.groups-title')} edit action={handleUpdateGroup}>
            <Typography variant="body2" gutterBottom>
            {t('screen.device-admin.groups-select')}
            </Typography>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">{t('screen.device-admin.groups-field')}</InputLabel>
                {!isEmptyList(groupList) &&
                    <Select
                        labelId="service-groups"
                        id="service-groups"
                        multiple
                        value={groupIds}
                        onChange={handleGroupChange}
                        className={classes.textFieldExport}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((item) => (
                              <Chip key={item} label={getGroup(item).name} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >

                      {groupList.map((group,index) => (
                        <MenuItem key={index} value={group.id} style={getStyles(group.id, groupIds, theme)}>
                          {group.name} 
                        </MenuItem>
                      ))}
                    </Select>
                  }
              </FormControl>
            </div>  
        </MyForm>

        { user?.isBetaTester && service?.serviceType==="LINKY" &&
          <MyForm title={"Linky"} >
            <Grid container  direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid xs={1} item>
                   <img src="/linky_img.png" alt="image" width="50" />
                </Grid>
                <Grid xs={11} item>
                  <Typography variant="body2" gutterBottom>
                    Les données de ce capteur proviennent de l'appel aux API Enedis pour Linky. 
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Si aucune donnée pour la journée d'hier n'apparait, vous pouvez forcer la récupération des données auprès d'Enedis
                  </Typography>            
                  <Button
                    variant="contained"
                    color="default"
                    onClick={ForceLinkySynchroRest}
                    className={classes.button}
                    startIcon={<GetApp />}
                  >
                    Forcer
                  </Button>                       
                </Grid>                        
            </Grid>  
          </MyForm>
        }

        <MyForm title={t('screen.device-admin.export-title')} edit action={handleUpdateExport}>
            <Typography variant="body2" gutterBottom>
            {t('screen.device-admin.export-desc')}
          </Typography>
          <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">{t('screen.device-admin.export-to')}</InputLabel>
                {!isEmptyList(appList) &&
                    <Select
                        labelId="apps-to-export-label"
                        id="apps-to-export"
                        multiple
                        value={appIds}
                        onChange={handleExportChange}
                        className={classes.textFieldExport}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((item) => (
                              <Chip key={item} label={getApp(item).name} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >

                      {appList.map((app,index) => (
                        <MenuItem key={index} value={app.id} style={getStyles(app.id, appIds, theme)}>
                          {app.name} 
                        </MenuItem>
                      ))}
                    </Select>
                  }
              </FormControl>
            </div>  

        </MyForm>



        <Button
            onClick={handleRemoveService}
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DeleteIcon />}
        >
        {t('screen.device-admin.button.del-service')}
      </Button>
      <Button
          /*   disabled */
            onClick={handleSwapService}
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<SwapIcon />}
        >
        {t('screen.device-admin.button.device-exchage')}
      </Button>      
      <AreYouSure open={openServiceDeleteConfirm} title={t('screen.device-admin.warning')} onClose={removeServiceProcessing} >    
            <div>{t('screen.device-admin.areyoushure-text1')}</div>
            <div>{t('screen.device-admin.areyoushure-text2')}</div>           
      </AreYouSure>
      <SwapDeviceDialog open={openSwapDeviceDialog} service={service} onClose={swapDeviceSuccess} />        
    </div>
    );
}

