import React, { useContext, useState, useEffect , Component} from 'react'


import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { deepOrange, deepPurple } from '@material-ui/core/colors';

import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { mapToArray, round } from '../../Common';

const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);
  
  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);

const useStyles = makeStyles(theme => ({
    root: {
     marginTop: "25px"
    },
    theValue: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    theUnit: {
        fontSize: "smaller"
    },
    thewidget: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
    },
    serviceValue: {
        anchorOriginTopRightCircular: {
            right: "20%"
        },
        width: theme.spacing(7),
        //height: theme.spacing(7),
    },
    serviceName: {
        fontSize: "smaller",
        backgroundColor: "grey",
        color: "white",
        textAlign:"center"
    }
}));

export default function ServiceBadge(props) {
    // Affichage d'une valeur (sonde) de lastValue du service

    const { service, onClick: onClickCallback } = props;

    const [ probeToDisplay, setProbeToDisplay ] = useState();

    const classes = useStyles();

    const buildeStyle = (config) => {
      const x = config.position.x+"%";
      const y = config.position.y+"%";
      return { top: y, left: x}
    }

    useEffect( () => {
      const probeIdToDisplay = service.display.probeId;
      const probes = JSON.parse(service.lastValue.probes);
      const probeToDisplay = probes[probeIdToDisplay];
      console.log("probe a afficher sur le badge:",probeToDisplay);
      setProbeToDisplay(probeToDisplay);
      //TODO aller chercher un icone en fonction du type de la sonde (ex Temp)

    }, [service])
  

    return (

/*         <Avatar style={ buildeStyle(service.display) } variant="square"  className={classes.serviceValue} onClick={() => onClickCallback(service)} >
            {probeToDisplay?.value}  {probeToDisplay?.unit}
        </Avatar>
 */
/*         <StyledBadge
            style={ buildeStyle(service.display) }  className={classes.serviceValue} onClick={() => onClickCallback(service)} 
            overlap="circular"
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            variant="dot"
            >
            <Avatar >
                {probeToDisplay?.value}  {probeToDisplay?.unit}
            </Avatar>
        </StyledBadge>
 */
        <div  style={ buildeStyle(service.display) }  className={classes.thewidget} >
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Badge
                  className={classes.serviceValue} onClick={() => onClickCallback(service)} 
                  overlap="circular"
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  badgeContent={<SmallAvatar alt="Remy Sharp" className={classes.theUnit} >{probeToDisplay?.unit}</SmallAvatar>}
                  >
                  <Avatar className={classes.theValue} >
                      {isNaN(probeToDisplay?.value) ? probeToDisplay?.value : round(probeToDisplay.value)}  
                  </Avatar>
              </Badge>  
            </Grid>   
            <Grid item xs={10}>
              <div className={classes.serviceName}>
                  {service.name}
              </div>
            </Grid>         
        </Grid>      
        </div>
    )
}