import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";

import GraphQlApi from '../mobile/MyGraphql';

import { API } from "aws-amplify";

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import ProbeIcon from '@material-ui/icons/FilterTiltShift';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import EditIcon from '@material-ui/icons/Edit';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';


import {dateUtcToLocal, lockActionToText, toSnack} from '../Common';
import LockAdminAccessAdd from './LockAdminAccessAdd'


const useStyles = makeStyles(theme => ({
    root: {
        margin: "20px"
    },
    paragraph: {
        margin: 20,
        padding: 5
    },    
    card: {
        maxWidth: "800px"
    },
    loading: {
        marginTop: 50
    },    
    fab: {
        position: 'absolute',
        top: 70,
        //bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    noAlert: {
        marginTop: 30
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

export default function LockAccessDisplay(props) {
    const { data } = props;

    let { id: serviceId, probe: probeId } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [historyList, setHistoryList] = useState([]);

    const [openAccess, setOpenAccess] = useState(false);
    const [accessMode, setAccessMode] = useState();
    
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [access, setAccess] = useState();

    useEffect(() => {
        console.log("data:",data)
    }, [data]);

    // recuperation du service concerné
    useEffect(() => {
        let ignore = false;
        async function fetchData(serviceId) {
            console.log("getting lock access for %s ...", serviceId);   
            const history = await getLockRest(serviceId);
            console.log("lock history:",history);
            if (history) {
                setHistoryList(history.data);
            }
        }  
        fetchData(serviceId);
        return () => { ignore = true; }

    }, [serviceId]);     

    // Appels aux API REST
    async function getLockRest(lockId) {
        console.log("getting Lock access history ... ", lockId);
        setLoading(true);
        setErrorMsg("");
        let myInit = {
            queryStringParameters: {
            type: "access" // liste des autorisations en cours
            }
        }    
        try {
            const response = await API.get("iotLockAdmin", "/lock/" + lockId, myInit)
            //console.log("api:", response)
            setLoading(false);
            if (response.error) {
                setErrorMsg("Erreur lors de la récupération des information de la serrure : "+response.message);
                
            } else {
                return response;
            }
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            setLoading(false);
            setErrorMsg("Erreur lors de la récupération des information de la serrure : "+error.message)
        }
    }

    const handleAccess =  (async (mode, accessObj) => {
        console.log("Handle access: ", mode, accessObj)

        accessObj["serviceID"] = serviceId;

        if (mode==='new') {
            setAccessMode(mode)
            setAccess(accessObj)
            setOpenAccess(true);    

        } else {
            setAccessMode(mode)
            setAccess(accessObj)
            setOpenAccess(true); 
        }
    })

    const classes = useStyles();
    return (
        <div className={classes.root}>
                <div className={classes.alignItemsAndJustifyContent}>
                    {loading &&
                        <div  className={classes.loading}>
                            <CircularProgress size={40} />
                        </div>
                    }
                </div>        

                {!loading &&  
                <>
                    <div> Liste des accès</div>

                    {historyList.map( (item, key) => 
                            <Grid container direction="row" spacing={0} alignItems="stretch" justifyContent="flex-start" key={key} >
                                <Grid item xs={4}  >
                                    <div>{dateUtcToLocal(item.date)}</div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        {item.name? item.name : "Manuellement (bouton)"}
                                    </div>
                                </Grid>                              
                                <Grid item xs={2}>
                                    <div>
                                        {item.action === 1 && 
                                            <LockOpenIcon/>
                                        }
                                        {item.action === 2 && 
                                            <LockIcon/>
                                        }
                                        {lockActionToText(item.action, item.state)}                                    
                                    </div>
                                </Grid>                    
                            </Grid> 
                    )}
                    {historyList.length===0 &&
                        <div className={classes.noAlert} >Pas encore d'accès</div>
                    } 
                </> 
                }          
        </div>
  );
}
    
