import React from 'react';

import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import { round} from '../Common';
import MyLink from './MyLink';


import { createTheme, withStyles, makeStyles, ThemeProvider  } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    sensorSub: {
        // backgroundColor: "grey"
        paddingRight: "10px"
    }, 
    sensorValue: {
        fontSize: "24px"
    },
    sensorMinmax: {
       width: 100
    },
    sensorMore: {
        fontSize: "13px"
    }, 
    linkAlert: {
        textDecoration: 'none!important',
        //color: 'green',
        '&:focus': {
            textDecoration: 'none'
        } ,  
        '&:active': {
            textDecoration: 'none'
        },
        '&:hover': {
            textDecoration: 'none'
        },
        '&:visited': {
           textDecoration: 'none'
        },                          
        '&:link': {
            textDecoration: 'none'
        }               
    }, 
    computingNote: {
        fontStyle: "italic",
        fontSize: "13px"
    },

}));
const theme = createTheme({
    palette: {
      //primary: green,
    },
  });
  

export default function ProbeDisplayETo({ serviceId, probe }) {

    const classes = useStyles();

    const { t } = useTranslation();

    // si decimal existe dans le template, on affiche le nombre de chiffre après la virgule souhaité
    function displayVal(value, decimal) {
        if (isNaN(value)) {
            return "?"
        } else {
            return Number.isInteger(decimal)?Number.parseFloat(value).toFixed(decimal):value;
        }
    }

    return (
    <>
        <Grid container direction="row" justifyContent="space-around" alignItems="stretch"  spacing={1} className={classes.sensorSub}>
            <Grid item xs>
                <MyLink to={"/service/"+serviceId+"/"+probe.id}  style={{ textDecoration: 'none' }}>
                    <div>
                        <div>
                            {probe.name} 
                        </div>
                        <div className={classes.sensorValue}>
                            {displayVal(probe.value, probe.decimal)}   {probe.unit} {/* EVOL1 */}
                        </div>  
                    </div>  
                </MyLink>                           
            </Grid>
        </Grid>  
        { probe.more &&
        <>
            <Grid container direction="row" justifyContent="space-around" alignItems="stretch"  spacing={1} className={classes.sensorSub}>
                <Grid item  className={classes.sensorMore}>                                      
                    <Grid container justifyContent="space-between">
                        <Grid item>T° Min</Grid>
                        <Grid item>&nbsp;</Grid>
                        <Grid item>{probe.more.Tmin} °C </Grid>
                    </Grid>                                    
                    <Grid container justifyContent="space-between">
                        <Grid item>T° Max</Grid>
                        <Grid item>&nbsp;</Grid>
                        <Grid item>{probe.more.Tmax} °C </Grid>
                    </Grid>
                </Grid>
                <Grid item  className={classes.sensorMore}>                                    
                    <Grid container justifyContent="space-between">
                        <Grid item>H% Min</Grid>
                        <Grid item>&nbsp;</Grid>
                        <Grid item>{probe.more?.HRmin} % </Grid>
                    </Grid>                                    
                    <Grid container justifyContent="space-between">
                        <Grid item>H% Max</Grid>
                        <Grid item>&nbsp;</Grid>
                        <Grid item>{probe.more.HRmax} %</Grid>
                    </Grid>
                </Grid>  
                <Grid item  className={classes.sensorMore}>                                    
                    <Grid container justifyContent="space-between">
                        <Grid item>Pyra</Grid>
                        <Grid item>&nbsp;</Grid>
                        <Grid item>{round(probe.more.Pyra,2)} W/m2 </Grid>
                    </Grid>                                    
                    <Grid container justifyContent="space-between">
                        <Grid item>Vent</Grid>
                        <Grid item>&nbsp;</Grid>
                        <Grid item>{probe.more.Wind} km/h</Grid>
                    </Grid>
                </Grid>                                                               
            </Grid> 
            <div className={classes.computingNote}>(calcul sur les données d'hier)</div>
        </>  
        }                         
    </>    
    );
}
