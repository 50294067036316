import React from "react";
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ProbeIcon from '@material-ui/icons/FilterTiltShift';

import BarChartIcon from '@material-ui/icons/BarChart';
import LineChartIcon from '@material-ui/icons/ShowChart';

import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { mapToArray } from '../Common';

const useStyles = makeStyles({
    root: {
    },
});

export default function ProbeSelector({ dataSets, changeDatasetCallback }) {
    //const classes = useStyles();
    // gestion d'un changement de l'affichage des courbes
    const { t } = useTranslation();
    const theme = useTheme();
    const isWideScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const updateDataSets = ((dataSet) => {
        changeDatasetCallback(dataSet)
    })

    const handleDisplayLine = ((event, dataChoice)=> {
        const radioChoice = event.target.value;

        const currentDataSets =  JSON.parse(JSON.stringify(dataSets));

        const id = dataChoice.id;
        const dataSet = currentDataSets[id]

        if (radioChoice==='no') {
        dataSet.show = "no"

        } else if (radioChoice==='left') {
        dataSet.show = "left"

        } else if (radioChoice==='right') {
        dataSet.show = "right"
        }

        currentDataSets[id] = dataSet;

        //console.log("dataSets:",currentDataSets);
        updateDataSets(currentDataSets);
    })

    const handleChangeGraphType = ((dataChoice) => {
        //console.log("dataChoice:", dataChoice);
    
        const currentDataSets =  JSON.parse(JSON.stringify(dataSets));
        const id = dataChoice.id;
        const dataSet = currentDataSets[id];
    
        if (!dataSet.graphType || dataSet.graphType==='line') {
          dataSet.graphType = 'bar';
        } else {
          dataSet.graphType = 'line';
        }
        currentDataSets[id] = dataSet;
    
        updateDataSets(currentDataSets);
        //console.log("dataSets:",currentDataSets);
    })

    return (
        <List component="nav" aria-label="customers">
        <Divider/>
            {mapToArray(dataSets).map( (data,index) => 
                <div  key={index}  >
                    <ListItem dense={true} divider>
                        <ListItemIcon><ProbeIcon fontSize="small"/></ListItemIcon>
                        <ListItemText primary= {data.name} /> 
                        <ListItemText primary= {data.type.substring(0, 4)} />
                        <FormControl component="fieldset">
                        <RadioGroup row={isWideScreen}  aria-label="displaychart" name="displaychart" value={data.show || "no"} onChange={(event) => handleDisplayLine(event, data)}>
                            <FormControlLabel value="no" control={<Radio />} label={t('global.button.hide')} />
                            <FormControlLabel value="left" control={<Radio />} label={t('global.button.left')} />
                            <FormControlLabel value="right" control={<Radio />} label={t('global.button.right')} />
                        </RadioGroup>
                        </FormControl>
                        <ListItemIcon>
                        <>
                        {(!data.graphType || data.graphType==='line') && 
                            <BarChartIcon onClick={() => handleChangeGraphType(data)} />
                        }
                        {(data.graphType==='bar') && 
                            <LineChartIcon onClick={() => handleChangeGraphType(data)}/>
                        }
                        </>
                        </ListItemIcon>
                    </ListItem>               
                </div>
            )}
        </List> 
  );
}

