import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import AddAlertIcon from '@material-ui/icons/AddAlert';

import MyLink from './MyLink';
import TrafficLight from './TrafficLight';
//import { mapToArray, round, toSnack} from '../Common';

import { makeStyles  } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    sensorSub: {
        // backgroundColor: "grey"
        paddingRight: "10px"
    }, 
    sensorValue: {
        fontSize: "24px"
    },
    sensorMinmax: {
       width: 100
    },
    sensorMore: {
        fontSize: "13px"
    }, 
    linkAlert: {
        textDecoration: 'none!important',
        //color: 'green',
        '&:focus': {
            textDecoration: 'none'
        } ,  
        '&:active': {
            textDecoration: 'none'
        },
        '&:hover': {
            textDecoration: 'none'
        },
        '&:visited': {
           textDecoration: 'none'
        },                          
        '&:link': {
            textDecoration: 'none'
        }               
    },       
}));
  
export default function ProbeDisplayDefault({ serviceId, probe }) {

    const classes = useStyles();
    const { t } = useTranslation();

    // si decimal existe dans le template, on affiche le nombre de chiffre après la virgule souhaité
    function displayVal(value, decimal) {
        if (isNaN(value)) {
            return "?"
        } else {
            return Number.isInteger(decimal)?Number.parseFloat(value).toFixed(decimal):value;
        }
    }

    return (
    <Grid container direction="row" justifyContent="center" alignItems="center"  spacing={1} className={classes.sensorSub}>        
        <Grid item xs>
            <MyLink to={"/service/"+serviceId+"/"+probe.id}  style={{ textDecoration: 'none' }}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} >
                    {probe.ledThresholds &&
                    <Grid item>
                        <TrafficLight value = {probe.value}  thresholds={probe.ledThresholds} />
                    </Grid>  
                    }
                    <Grid item>
                        <div>
                            {probe.name} {/* / {probe.type}  */}
                        </div>
                        <div className={classes.sensorValue}>
                            {displayVal(probe.value, probe.decimal)}  {probe.unit} {/* EVOL1 */}
                        </div>  
                    </Grid> 
                </Grid>
            </MyLink>                            
        </Grid>
        <Grid item  className={classes.sensorMinmax}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>&nbsp;</Grid>
                <Grid item>
                    <MyLink to={"/service/"+serviceId+"/"+probe.id+ "/alerts"} className={classes.linkAlert} >
                    {probe.isAlarm &&
                        probe.isAlarm.map( (status,key) => 
                            <span key={key} >
                            {/* une alarme est déclenchée pour cette sonde  */}
                            {status === "triggered" &&
                                <NotificationsActiveIcon fontSize='small'/>
                            }
                            {/* une alarme est en fonction mais non déclenchée */}
                            {status === "active" &&
                                <NotificationsNoneIcon fontSize='small'/>
                            }  
                            {/* une alarme est définie mais inactivée par l'utilisateur */} 
                            {status === "inactive"  &&
                                <NotificationsOffIcon fontSize='small'/>
                            }  
                            </span>
                        )                                              
                    } 
                    {/* pas alarme de definie  */} 
                    {!probe.isAlarm &&
                        <AddAlertIcon fontSize='small'/>
                    }                                                                                                               
                   </MyLink>
                </Grid>
            </Grid>                                       
            <Grid container justifyContent="space-between">
                <Grid item>{t('screen.device-display.min')}</Grid>
                <Grid item>{displayVal(probe.stats?.minmax?.min, probe.decimal)} {probe.unit} </Grid> {/* EVOL1 */}
            </Grid>                                    
            <Grid container justifyContent="space-between">
                <Grid item>{t('screen.device-display.max')}</Grid>
                <Grid item>{displayVal(probe.stats?.minmax?.max, probe.decimal)} {probe.unit} </Grid> {/* EVOL1 */}
            </Grid>
        </Grid>
    </Grid>   
    );
}
