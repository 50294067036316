import React from 'react';
import ReactDOM from 'react-dom';
//import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
    //<React.StrictMode>
        <App />  
    //</React.StrictMode>
    , 
    document.getElementById('root'));

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
    <App />
    </React.StrictMode>
); */


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    // This will skip waiting and then refresh the page once the update has been applied.
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting
      if (waitingServiceWorker) {
        console.log("SW waitingServiceWorker");
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            console.log("SW RELOOOOAAAAaaDiiiINGggg");
            window.location.reload()
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    }
  });
//reportWebVitals();