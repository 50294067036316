import React from 'react'
import {Link} from "react-router-dom";
import GraphQlApi from '../mobile/MyGraphql';


export default function MyLink({to, ...props}) {
    function checkToAddToken() {
        const isMobile = GraphQlApi.getInstance().isMobileFct();
        if (isMobile) {
            const token = GraphQlApi.getInstance().getToken();
            return (to + "?token="+token);
        } else {
            return to;
        }
    }

    return (
        <Link to={checkToAddToken}  {...props} ></Link>
    );
}


/*
export default function MyLink({to, id, ...props}) {

    const [ url, setUrl ] = useState(to);

    useEffect(() => {
        const isMobile = GraphQlApi.getInstance().isMobileFct();
        if (isMobile) {
            const token = GraphQlApi.getInstance().getToken();
            setUrl(to + "?token="+token) 

        } else {
            setUrl(to);
        }
    }, []);

    return (
        <>
        <div>{to}/{url}</div>
        <Link id={id} to={url}  {...props} ></Link>
        </>
    );
}
*/