import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { API } from "aws-amplify";
import QrReader from 'react-qr-reader'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


//import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

import GeneralContext from '../components/GeneralContext'
import QrSampleImg from '../media/qrCode.svg'
import DeviceDetail from '../components/DeviceDetail';
import { askForRefreshingCustomer, toSnack } from '../Common';
import beep from '../media/beep.wav'; 
//import { ReactComponent as Logo } from '../qrCode.svg';

const useStyles = makeStyles(theme => ({
    root: {
      paddingBottom: 0,
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
        
      },
    },
    textFieldBig: {
        width: '40ch',
    },
    checkbox: {
        padding: 5,
        marginBottom: 5
    },
    colRight: {
       // width: "10%",
    },
    colLeft: {
        //width: "90%",
    },
    deveuiFileds: {
        paddingRight: 5,
    },
    networkFormControl: {
        margin: theme.spacing(1),
    },
    radio: {
        padding: 5,
        marginBottom: 5
    }    

}));

export default function ScanNewDeviceV1(props) {

    const { openState, toClose } = props;
    const { t } = useTranslation();

    const { user, customer } = useContext(GeneralContext);

    const [deveuiField, setDeveuiField] = useState("");

    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [localization, setLocalization] = useState(false);

    //const [ deviceIsFound, setDeviceIsFound] = useState(false);
    const [ deviceInfo, setDeviceInfo ] = useState();
    const [messageToDisplay, setMessageToDisplay] = useState("");

    const [ serviceName, setServiceName ] = useState();

    const [ legalOk, setLegalOk ] = useState(false);
    const [ geolocOk, setGeolocOk ] = useState(false);
    const [ devicePos, setDevicePos ] = useState();
    const [ geolocNotAvailable, setGeolocNotAvailable] = useState(false);
    //const [openDialogState, setOpenDialogState] = useState(true);
    const [ network, setNetwork ] = useState('private');
    const [ networkId, setNetworkId ] = useState();
    const [ networkName, setNetworkName ] = useState();

    const [isDemoDevice, setIsDemoDevice] = useState(false);

    const [audio] = useState(new Audio(beep));
 
    const classes = useStyles();


    async function toCloseLocal(data) {
        console.log("local closed en phase : ", step, data);
        if ( step >= 1 && deviceInfo) {
            if ( !data |  !data.action | data.action !== "attached" ) {
                // on est en phase 1 : visualisation du détail du capteur
                // ou phase 2 : case a cocher
                // deviceInfo existe : en retour de l'api call GET /iotAttachDevice
                // il faut aussi que l'attachement n'est pas reussi (<>attached)
                // alors si deviceInfo.dataSource === CLONE, il  s'agit d'un device de demo 
                // --> effacement du deveui
                if (deviceInfo.dataSource && deviceInfo.dataSource==='CLONE') {
                    console.log("Effacement....");
                   const response = await deleteDeveuiRest(deviceInfo.id);
                   console.log(response);
                }
            }
        }
        toClose(data);
    }

    function deveuiIsFound(deveui) {
        if (deveui) {
            console.log("deveuiIsEntered : ",deveui)
            setDeviceInfo(null)
            setStep(1); // préparation de l'affichage des info
            findingDeviceInfo(deveui); // lancement de la rercher du deveui
        }
    }

    async function findingDeviceInfo(deveui) {
        const response = await getDeviceInfoRest(deveui.trim());
        if (response.error) {
            if (response.error==="UserQuotaDemoDeviceReached") {
                setMessageToDisplay("Attention, le nombre maximum de capteur de démonstration que vous pouvez créer est atteint. Veuillez contacter le support pour pouvoir en activer d'autres.")
 
            } else if ((typeof response.message === 'string' || response.message instanceof String)) {
                setMessageToDisplay(response.message)

            } else {
                setMessageToDisplay("Erreur inconnue")
            }
        } else {
            const deviceInfo = response.data;
            console.log("deviceInfo:",deviceInfo);
            setDeviceInfo(deviceInfo) // affichage des info et du bouton 'Continuer'
            if (deviceInfo.dataSource && deviceInfo.dataSource==="CLONE") {
                console.log("c'est un device de démo");
                setIsDemoDevice(true);
            }   
            if (deviceInfo.radioType && deviceInfo.radioType==='zwave') {
                console.log("device de type zwave (pas de lora)");
                // pas de choix de réseau a proposer
                setNetworkId();
                setNetworkName();

            } else {
                // A priori en LORA
                if (deviceInfo.networkStatus && ( deviceInfo.networkStatus==="ACTIVATED" || deviceInfo.networkStatus==="PENDING")) { //UNKNOWN / ACTIVATED / PENDING / FAILED 
                    console.log("Le capteur est déja activé sur un réseau");
                    //TODO lire le reseau déja actif (pb d'id operateur ?)
                    //setNetworkId();
                    //setNetworkName();
    
                } else {
                    // construction du ou de la liste des réseaux possible pour ce capteur (issue de la config du retailer)
                    if (deviceInfo.networkChoices && deviceInfo.networkChoices.length>0) {
                        if (deviceInfo.networkChoices.length==1) {
                            // si un seul reseau dans la liste des choix
                            // il n'est pas affiché et indiqué lors de l'activation
                            const theNetwork = deviceInfo.networkChoices[0];
                            setNetworkId(theNetwork.id);
                            setNetworkName(theNetwork.displayName);
    
                        } else {
                            // il y a plusieurs réseaux,
                            // on se positionne pour l'instant sur le premier en attendant de récupérer un default de la config chez le retailer
                            const theNetwork = deviceInfo.networkChoices[0];
                            setNetworkId(theNetwork.id);
                            setNetworkName(theNetwork.displayName);
                            console.log("Positionnement par defaut sur le premier réseau:", theNetwork)
                        } 
                    }
                }
            }      
        }
    }

    useEffect( () => {
        if ("geolocation" in navigator) {
            console.log("Geolocation Available");
          
          } else {
            console.log("Geolocation Not Available");
            setGeolocNotAvailable(true)
          }
    },[]);

    function personalizeService() {
        setServiceName(deviceInfo.template.productName + deviceInfo.id.substr(deviceInfo.id.length - 4))
        setStep(2);
    }

    // appelé par le bouton Associer
    const processActivation = async () => {
        const probesDetail = buildProbesDetail(deviceInfo);
 
        var network = networkId;

        console.log("Appel de WS")
        setLoading(true);
        try {
            const response = await associateDeviceStationRest({ name : serviceName, deveui: deviceInfo.id , probes: probesDetail, networkId: network});
            console.log("associateDeviceStationRest:",response)
            if (response) {
                if (response.success) {
                    //toSnack("DeviceLink", t('screen.scan-device.well-link'));
                    toSnack("DeviceLink", response.message); // TODO pour la traduction, gérer un code d'erreur dans l'api et retrouver le code dans la traduction
                    askForRefreshingCustomer(user.ownCustomer.id);
                    toCloseLocal({action:"attached"});
                } else {
                    //response.error
                    toSnack("DeviceLink", response.message);
                    toCloseLocal({action:"error"})
                }
            } else {
                toSnack("DeviceLink", t('screen.scan-device.error-whilelink'));
                toCloseLocal({action:"error"})
            }
        }
        catch (error) {
            toSnack("DeviceLink", t('screen.scan-device.error-whilelink')+error);
            toCloseLocal({action:"error"})
        }
        setLoading(false);
        setStep(0);
    };    

    const buildProbesDetail = ((deviceInfo) => {
        const probesDisplay  = JSON.parse(deviceInfo.template.probesDisplay); 
        var probesDetail = {};
        for(const key in probesDisplay) {
            const probe = probesDisplay[key];
            probesDetail[key] = { name : t("sensor.probe-type."+probe.type)} // recuperation du nom de la sonde dans le language actuel de l'utilisateur
        }
        return probesDetail;
    })

    function onEnteredDialog() {
        console.log("onEntered Dialog");
        //setDeveuiField("020000ffff00b923");
        setDeveuiField("");
        setMessageToDisplay("");
        setLegalOk(false)
        setGeolocOk(false)
        setStep(0)
    }

    function onExitDialog() {
        console.log("Exit Dialog");
    }

    
    const handleNetworkChange = choice => {
        console.log("choice:", choice)
        const retailerNetwork = deviceInfo.networkChoices;
        const network = (retailerNetwork.find( item => item.id=== choice));
        setNetworkId(choice);
        setNetworkName(network.displayName);
    };


    async function getDeviceInfoRest(id) {
        var response;

        let myInit = { 
          headers: {}, 
          queryStringParameters: {  
              servicetype: 'standard' // ont filtre sur le type de service demandé pour ce device
          }
        }  
        try {
          setLoading(true);
          response = await API.get("iotAttachDevice", "/devices/" + id, myInit);
          console.log("responseRest:", response);
        }
        catch (error) {
          console.log(error)
          response = { error : "ERROR", message : "Unkwnon ERROR" }
        }  
        setLoading(false);
        return response;
    }

    async function deleteDeveuiRest(id) {
        var response;
        let myInit = { 
          headers: {}, 
          queryStringParameters: {  
              servicetype: 'standard' // ont filtre sur le type de service demandé pour ce device
          }
        }  
        try {
          //response = await API.del("iotAttachDevice", "/devices/demo/" + id, myInit);
          response = await API.del("iotAttachDevice", "/devices/" + id, myInit);
          //console.log("responseRest:", response);
        }
        catch (error) {
          console.log(error)
          response = { error : "ERROR", message : "Unkwnon ERROR" }
        }  
        return response;
    }    

    // appel du service REST de creation sur service avec maj du serviceId dans la table device
    // service de type STANDARD
    async function associateDeviceStationRest(service) {
        console.log("Service activation  : ", service);

        //TODO : pas très securisé, on deduit le customer du user et on le donne dans le body pour l'activation
        // on peut imaginer un user qui met un autre customer, et peut potentiellement accéder au d'autres capteurs d'autres clients
        // il serait preferable de deduire le customer coté back ...

        let myInit = {
        body: {
            name: service.name, // le nom du capteur indiqué par l'utilisateur
             forService: "STANDARD"
        }, 
        headers: {} // OPTIONAL
        }    

        if(devicePos) {
            myInit.body['location'] = devicePos;
        }


        if(service.probes) {
            myInit.body['probes'] = service.probes; // nom des sondes dans la langue actuelle de l'utilisateur
        }

        if (service.networkId) {
            myInit.body['network'] = service.networkId;
        }

        if (customer.id) {
            myInit.body['customerId'] = customer.id; 
        }
        
        console.log("Activation- appel REST : ",myInit)
        var response;
        try {
            response = await API.post("iotAttachDevice", "/devices/" + service.deveui+ "?q=test", myInit);

        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
        return response;
    } 

    function handleCheckboxGeoloc(checkbox) {
        console.log("check:",checkbox)
        if (checkbox) {
            setGeolocOk(true)
            setLocalization(true);
            navigator.geolocation.getCurrentPosition(function(position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                setDevicePos({ lon : position.coords.longitude, lat: position.coords.latitude} )
                setLocalization(false);
              });  
        }
    }

    //callback issue de DeviceDetails
    const handleDeviceDialog = value => {
        console.log("handleBackDialog:", value);
    }

    const handleQrScan = async data => {
        if (data) {
          console.log("Scan:",data);
          audio.play() // beep
          deveuiIsFound(data)
        }
    }
    const handleQrError = err => {
        console.error("qrcode error : ",err)
    }  

    return (
    <Dialog open={openState} onClose={toCloseLocal} aria-labelledby="form-dialog-title" TransitionProps={{onEntered: onEnteredDialog, onExit: onExitDialog }}  >
        <DialogTitle id="form-dialog-title" classes={{ root: classes.root}} > {t('screen.scan-device.linking')}</DialogTitle>
        <DialogContent>
        {!user.ownCustomer &&
            <div>
                {t('screen.scan-device.linking-text')}
            </div>
        }
        {user.ownCustomer &&
        <>
            <div className={classes.alignItemsAndJustifyContent}>
                {loading && <CircularProgress size={40} />}
            </div>
            {step===0 &&
                <div>
                    <Grid container direction="row"  justifyContent="space-between" alignItems="center">
                       <Grid item xs={10} className={classes.colLeft}>
                        <Typography variant="body1">
                            {t('screen.scan-device.scan-qr')} 
                        </Typography>    
                        </Grid> 
                        <Grid item xs={2} className={classes.colRight}>
                            <img src={QrSampleImg} width="50px" alt="Qr Sample" />
                        </Grid>
                    </Grid>
  
                    <QrReader delay={300} onError={handleQrError} onScan={handleQrScan} style={{ width: '100%' }} />    

                    <Typography variant="body2">
                        {t('screen.scan-device.type-code')}
                    </Typography>   
                    <div>
                    <Grid container direction="row" alignItems="flex-end" justifyContent="flex-start">
                        <Grid item className={classes.deveuiFileds}>
                        <TextField
                            value= {deveuiField}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                console.log("enter:", event.target.value)
                                deveuiIsFound(event.target.value);
                                }
                            }}
                            onChange={e => setDeveuiField(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Deveui"
                            type="text"
                        /> 
                        </Grid>
                        <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon />}
                            onClick={() => deveuiIsFound(deveuiField)}
                        >
                        </Button> 
                        </Grid>
                    </Grid>                     
                    </div>             
                </div>
            }
            {step===1 &&
                <div>
                    <div>
                        {messageToDisplay}
                    </div>
                    {deviceInfo &&
                        <DeviceDetail device={deviceInfo} isDemoDevice={isDemoDevice} callBackFunction={handleDeviceDialog}/>   
                    }
                </div>
            }
            {step===2 &&
                <div>

                    <TextField 
                        value={serviceName}
                        className={classes.textFieldBig}
                        onInput={ e=>setServiceName(e.target.value)}                    
                        id="sensorName" 
                        label={t('screen.scan-device.device-name')} />

                    <Card className={classes.checkbox}>
                        <FormControlLabel
                                control={
                                <Checkbox 
                                    checked={geolocOk} 
                                    disabled={geolocNotAvailable}
                                    onChange={(event)=>handleCheckboxGeoloc(event.target.checked)} 
                                    color="primary" 
                                    name="checkedA" />}
                                label={t('screen.scan-device.using-currentpos')}
                            />
                            {devicePos &&
                                <Container>
                                   [{devicePos.lat}, {devicePos.lon}]
                                </Container>
                            }
                            {localization && <LinearProgress />}
                    </Card>
                    {deviceInfo.networkChoices && deviceInfo.networkChoices.length>1 &&
                       <DisplayNetworkChoices choices={deviceInfo.networkChoices} isDemoDevice={isDemoDevice} defaultNetworkId={networkId} networkChangeCallback={handleNetworkChange} />
                    }                     
                    <Card className={classes.checkbox}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={legalOk} 
                                    onChange={(event)=>setLegalOk(event.target.checked)} 
                                    color="primary" 
                                    name="checkedB" />}
                            label={t('screen.scan-device.accept-conditions')}
                            />                        
                    </Card>   
                    {t('screen.scan-device.device-customer')} : {customer.name}
                    { networkName &&
                    <div>{t('screen.scan-device.device-network-name')} : {isDemoDevice?"flux de démo":networkName} </div>
                    }

               </div>
            }            
        </>
        }        
        </DialogContent>
        <DialogActions>
                <Button onClick={()=>toCloseLocal({action:"cancel"})} color="primary">
                    {t('global.button.cancel')}
                </Button>
            {step===1 && deviceInfo &&
                <Button onClick={personalizeService} color="primary">
                    {t('global.button.continue')}
                </Button>
            }
            {step===2 && legalOk &&
                <Button onClick={processActivation} color="primary">
                    {t('global.button.activate')}
                </Button>
            }                        
        </DialogActions>
    </Dialog>
    )
}


function DisplayNetworkChoices({ defaultNetworkId, choices, isDemoDevice, networkChangeCallback}) {

    //const [ networkId, setNetworkId ] = useState(defaultNetworkId);
    const [ defaultNetId, setDefaultNetId ] = useState(defaultNetworkId);
    
    const classes = useStyles();
    console.log("defaultNetworkId init :",defaultNetworkId);

/*     useEffect(() => {
        setNetworkId(defaultNetworkId);
        console.log("defaultNetworkId effect:",defaultNetworkId);
    }, [defaultNetworkId])  */

    function handleNetworkChange(event) {
        const network = event.target.value;
        networkChangeCallback(network);
    }
    return(
        <div>
            <Card className={classes.radio}>
                <FormControl component="fieldset" className={classes.networkFormControl}>
                    <FormLabel component="legend">Choix du réseau de communication</FormLabel>
                    <RadioGroup  aria-label="network" name="network" defaultValue={defaultNetId} onChange={handleNetworkChange}>
                        {choices?.map((net, index) => 
                            <FormControlLabel key={index} value={net.id} disabled={isDemoDevice}  color="primary" control={<Radio />} label={net.displayName+" ("+net.description+")"} />
                        )}
                        
                    </RadioGroup>
                </FormControl>                                               
            </Card> 
        </div>
    )
}