import React, {useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory} from "react-router-dom";
import GeneralContext from './GeneralContext'

import ProbesDisplay from './ProbesDisplay'
 
const useStyles = makeStyles(theme => ({
    root: {
    }
  }));

export default function Service( {data:service} ) {

    //console.log("data:service:",service);
    const { user, customer } = useContext(GeneralContext);

    const classes = useStyles();
    let history = useHistory()

    return (
        <> 
            <ProbesDisplay 
                serviceId={service.id}
                date={service.lastValue.dateEvent}
                struct={service.device.template.probesDisplay} 
                values={service.lastValue.probes} 
                stats={service.dailyStats.items}
                probesCfg={service.probesConfig}
            /> 
        </>
    );
}


