import React, { useContext, useState, useEffect , MouseEvent} from 'react'

import { API, Storage } from "aws-amplify";
import GraphQlApi from '../../mobile/MyGraphql';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';

//import {JSONViewer} from 'react-json-editor-viewer';
//import {JSONEditor} from 'react-json-editor-viewer';
//import TextareaAutosize from "react-autosize-textarea"
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

import { useTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';

import GeneralContext from '../../components/GeneralContext';
import { mapToArray, askForRefreshingCustomer, toSnack, isEmptyList, round } from '../../Common';

import ServiceDetailDialog from './ServiceDetailDialog';
import ServiceBadge from './ServiceBadge';
import UploaddImages from '../../components/UploadImages';
import ServicesGridCustom from '../../pages/ServicesGridCustom'

const useStyles = makeStyles(theme => ({
  root: {
   marginTop: "25px"
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },    
  conteneur: {
    
    position: "relative",
    display: "inline-block"
  },
  device1: {
    position: "absolute",
    top: '26%',
    left: '85%',
    transform: "translate(-50%, -50%)",
    backgroundColor: "red"
  },
  serviceValue: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: "red"
  },
  apart: {
    height: "auto",
    width: "100%",
    //display: "block"
  },
  serviceDetail: {

  },
  jsoneditor: {
    height: "500px",
  },
  centerPage: {
    justifyContent: 'center'
  }

}));

export default function HomePageApartment({ config: pageConfigInit, customerID}) {

  const [ editMode, setEditMode ] = useState(false);
  const { portal, user, customer: customerCtx } = useContext(GeneralContext);

  const [customer, setCustomer] = useState(); 

  const [pageConfig, setPageConfig] = useState(); // ensemble des infos pour l'affichage de la page
  const [servicesToDisplay, setServicesToDisplay] = useState([]); // les services complets  a afficher avec la config d'affichage
  const [backgroundImage, setBackgroundImage ] = useState();

  const [services, setServices] = useState([]); // tous les services complets 
  const [serviceDetail, setServiceDetail] = useState(); // un fois un service selectionné

  const [openServiceDetail, setOpenServiceDetail] = useState(false); // dialogBox détail d'un service

  const [ cursor, setCursor ] = useState({});

  const [ binaryImage, setBinaryImage ] = useState(); 

  const [loading, setLoading] = useState(false);
  const classes = useStyles();


  //Chargement des devices/services du customer
  useEffect( ()=> {
    async function fetchData(customerId) {
      console.log("Devices of customers loading for (%s) ...",customerId);
      const getQuery = `query GetCustomer($id: ID!) {
        getCustomer(id : $id) {
          id
          homePage,
          homePageConfig,
          services {
              items {
                  id
                  name
                  serviceType
                  globalConfig
                  device {
                      id
                      manufacturer
                      operator
                      status
                      dataSource
                      networkStatus
                      template {
                          id
                          probesDisplay
                      }          
                  }
                  lastValue {
                      id
                      dateEvent
                      probes
                      slidingCumul
                      commOperator
                      customerId
                  }
                  probesConfig
                  dailyStats(limit:1, sortDirection:DESC) {
                    items {
                        id
                      eventDay
                      values
                    }
                  }   
                  alerts {
                      items {
                        id
                        probeID
                        status
                      }
                  }                                                  
              }
          }                                                                                                
        }
      }
      `;
      try {
          //const result = await API.graphql(graphqlOperation(getQuery, { id: customerId }));
          const result = await GraphQlApi.getInstance().graphqlOperation( getQuery, {  id: customerId });
          if (result.data && result.data.getCustomer) {
              console.log("Result customer:", result.data.getCustomer);
              const customer = result.data.getCustomer;
              setCustomer(customer);
              // les services du customer (avec les lastValues)
              const services = customer.services?.items;
              setServices(services);

          } else {
              console.log("Data not found (Services)");
          }
      } 
      catch(resultErrors) {
        console.log("resultErrors:",resultErrors);
        const errors = resultErrors.errors?resultErrors.errors:(resultErrors.graphQLErrors?resultErrors.graphQLErrors:null);
        console.log("Graphql errors: ",errors);                
      }
    }
    if (customerCtx) {
      fetchData(customerID)
    }
  },[customerID])



  // il faut l'iamge de fond (api get binary)
  // et la liste des services
  // la config arrive en l'init du composant
  useEffect( () => {
    async function backGroundProcessing() {
      // dés que les services sont arrivés ()
      console.log("displayPageConfig:",pageConfigInit)

      if (!isEmptyList(services) && pageConfigInit.hasOwnProperty('CUSTOM_APART')) {

        setPageConfig(pageConfigInit); // avec le 'CUSTOM_APART'

        // Recuperation et affichage de l'image de fond
        const backgroundImageFileName = pageConfigInit?.CUSTOM_APART?.backgroundImage;
        if (backgroundImageFileName) {
          console.log("backgroundImageFileName:",backgroundImageFileName);
          await getImageFromCloud(backgroundImageFileName);


          // une fois les services arrivés, 
          // affichage des badges sur l'images
          // en fonction de la  config qui contient une description (xy) pour chaque service
          if (pageConfigInit.CUSTOM_APART.services) {
            const servicesConfig = pageConfigInit.CUSTOM_APART.services;
            console.log("servicesConfig:",servicesConfig); // les positions de services (xy) sur le fond

            // a chaque service à afficher, on rattache les data du service (lastVal)
            const servicesToDisplay = addConfigToDisplayServices(servicesConfig, services)
            setServicesToDisplay(servicesToDisplay);
            console.log("services à afficher enrichis avec la config d'affichage (xy):",servicesToDisplay)
          }
        } else {
          console.log("Pas d'image dans la config > config initiale")
        }

      } else {
        console.log("Attention, pas de config CUSTOM_APART (ou pas encore de services):",pageConfigInit, services)
      }  
    }
    backGroundProcessing();
  },[services, pageConfigInit]);

  const addConfigToDisplayServices = (servicesConfigArray, servicesArray) => {
    var servicesToDisplay= [];
    for(const key in servicesConfigArray) {
      // les infos pour l'affichage (badge et dialogbox)
      const serviceCfg = servicesConfigArray[key];
      // detail d'un service (dont le lastValue)

      //on recherche dans les services du customer, le service de la config
      const serviceData = getServiceData(key, servicesArray)
      // on attache un noeud 'display' pour contenir les elements d'affichage pour ce service
      if (serviceData) {
        serviceData['display'] = serviceCfg;
        servicesToDisplay.push(serviceData)
      } else {
        console.log("ServiceId a afficher (%s) non trouvé dans la liste des services du customer:", key, servicesArray);
      }
    }
    return servicesToDisplay;
  }

  async function homepageSettingSave() {
      console.log("sauvegarde de la config de page personalisée:",pageConfig);
      
      const customerObj = { 
        homePageConfig: pageConfig
      }
      setLoading(true);
      console.log("customerObj : ",customerObj)
      try {
        await updateCustomerMutation(customer.id, customerObj)
        toSnack("CustomerUpdated","La personalisation a bien été modifiée");
        setEditMode(false);

      } catch (error) {
        console.log("Erreur:,error");
        toSnack("CustomerUpdated","Erreur lors de la sauvegarde de la personalisation de la page");
      }
      setLoading(false);
  } 

  async function homepageSettingCancel() {
    //Todo raz du json de config
    setEditMode(false)

  }

  async function updateCustomerMutation(customerId, customer) {
      console.log("customerMutation:",customer);
      const toUpdate = {
          id: customerId
      };
      if (customer.homePage) { toUpdate["homePage"] = customer.homePage }
      if (customer.homePageConfig) { toUpdate["homePageConfig"] = JSON.stringify(customer.homePageConfig)}

      console.log("toUpdate:",toUpdate);
      const updateMutation = `mutation updateCustomer($input: UpdateCustomerInput!) {
        updateCustomer( input: $input) {
            id
        }
      }`;
      //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
      const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
      console.log("customer updated", response.data.updateCustomer);
  }

  const editPage = () => {
    console.log("Edit Page");
    setEditMode(true)

  }

  const getImageFromCloud = async (filename) => {
    setLoading(true);
    await getImageRest(filename);
    setLoading(false);
  }

  async function getImageRest(filename) {

    console.log("GET - appel REST : ",filename)

    let myInit = {
      responseType: 'blob', //"arraybuffer"
      headers: {
        'Content-Type': 'image/jpeg'
      }
    }

    var response;
    try {
        response = await API.get("apiGetImage", "/customer/"+customerCtx.id+"/image/"+filename, {});
        //response = await API.get("apiGetImage", "/customer/12345/image/maison-en-coupe1.png", {});
        if (response.error) {
            console.log("Error (test1):",response.error, response.message )
            throw response.message;
        } else {
            console.log("apiGetImage:ok")
            setBinaryImage(response);
        } 
    }
    catch (error) {
        console.log(error)
        throw error;
    }
  }

/*   const toServiceDetail = (serviceConfig) => {
    console.log("service:",serviceConfig);
    const detail = getServiceData(serviceConfig.id);
    setServiceDetail(detail);
    setOpenServiceDetail(true);
  } */

  const getServiceData = (id, servicesArray) => {
    console.log("get service %s in :",id, servicesArray);
    return servicesArray.find( item => {
      return item.id ===id
    })
  }

  const serviceDetailCallback = (action) => {
    console.log("serviceDetailCallback:", action);
    setOpenServiceDetail(false);
  }

  const clickBadgeCallback = (service) => {
    console.log("clickBadge with service :", service);
    setServiceDetail(service)
    setOpenServiceDetail(true);
  }

  async function onUploadChange(e) {
    const file = e.target.files[0];
    try {
      await Storage.put(file.name, file, {
        contentType: "image/png", // contentType is optional
        level: "private"
      });
      console.log("upload ok");
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  const clickImage = (e) => {
    //e.persist();
    //console.log("Event:",e)
    //console.log("Client x:%s, y%s",e.clientX,e.clientY);
    const el=e.target

    const xImage=e.clientX-el.getBoundingClientRect().left
    const yImage=e.clientY-el.getBoundingClientRect().top

    const width = el.getBoundingClientRect().width;
    const height = el.getBoundingClientRect().height;

    console.log("x:%s, y:%s:",round(xImage/width*100),round(yImage/height*100));
    setCursor( { x: round(xImage/width*100), y:round(yImage/height*100) })
  }

  const onJsonEditorChange = (data) => {
    console.log("onJsonEditorChange:", data);
    setPageConfig(data);
  }

  return(
      <div className={classes.root}>



        <div>
          <div className={classes.alignItemsAndJustifyContent}>
            {loading && <CircularProgress size={40} />}
          </div>

          {!editMode &&
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#"  onClick={()=> setEditMode(true)}>Modifier</Link>
              </Grid>
            </Grid>
          }
          {editMode &&
            <Grid container justifyContent="flex-end"  spacing={2}>
              <Grid item>
                <Link href="#"  onClick={homepageSettingCancel}>Annuler</Link>
              </Grid>
              <Grid item>
                <Link href="#"  onClick={homepageSettingSave}>Sauvegarder</Link>
              </Grid>
            </Grid>
          }
        </div>

        {!pageConfigInit?.CUSTOM_APART?.backgroundImage &&
              <div>Pas encore de configuration (faites 'modifier' pour commencer)</div>
        }     

        {customerCtx.homePageConfig &&
          <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Grid   item>
              <Grid container justifyContent="center">
              <div className={classes.conteneur}  onMouseDown={clickImage}>
                {binaryImage &&
                /*  <img src={backgroundImage} alt="image"  className={classes.apart} onMouseDown={clickImage} /> */
                  <img src={"data:image/jpeg;base64,"+binaryImage+""} className={classes.apart} onMouseDown={clickImage}  />
                }
                {servicesToDisplay.map( (service, index) =>
                /*   <div  key={index} className={classes.serviceValue}  style={ buildeStyle(service) } onClick={()=>toServiceDetail(service)} > TheService{index} </div> */
                  <ServiceBadge service={service} onClick={clickBadgeCallback}  key={index}  ></ServiceBadge>
                )}
              </div>
              </Grid>
            </Grid>  
            <Grid  item>
              <div>
                <ServicesGridCustom customerID={customerID} />
              </div>
              <ServiceDetailDialog service={serviceDetail} openState={openServiceDetail} toClose={serviceDetailCallback}  />
            </Grid>
          </Grid>
        }

        {editMode &&
          <div>
            <div >Modification de la page personalisée</div>
            <Grid container justifyContent="flex-end">
              <Grid item>x:{cursor.x} y:{cursor.y}</Grid>
            </Grid>
            <Grid container justifyContent="space-around"  spacing={2}>
                <Grid xs={6} item>

                  <Editor
                        value={pageConfig}
                        onChange={onJsonEditorChange}
                        mode={'code'}
                        //tag={'div'}
                        allowedModes={[ 'code','tree', 'form', ]}
                        ace={ace}
                        theme="ace/theme/github"
                        //schema={yourSchema}                        
                        //navigationBar={false}
                        //statusBar={false}
                        //search={false}
                        //expandAll={true}
                    /> 

                
 {/*                  <JSONEditor 
                    data={pageConfig}
                    collapsible
                    onChange={onJsonEditorChange}
                  /> */}               
                </Grid>
                <Grid  xs={6} item>
                  <div>Liste de l'ensemble des capteurs</div>
                  <Grid container justifyContent="flex-start" >
                    <Grid xs={1} item ></Grid>
                    <Grid xs={3} item>ServiceId</Grid>
                    <Grid xs={4} item>deveui</Grid>
                    <Grid xs={4} item>Nom</Grid>
                  </Grid>            
                {services.map( (service, index) => (
                  <Grid container key={index} justifyContent="flex-start" >
                    <Grid xs={1} item >{index}</Grid>
                    <Grid xs={3} item>{service.id}</Grid>
                    <Grid xs={4} item>{service.device.id}</Grid>
                    <Grid xs={4} item>{service.name}</Grid>
                  </Grid>
                ))}  
                {isEmptyList(services) &&
                    <div>Pas encore de capteurs</div>
                }             
                </Grid>                
            </Grid>
  {/*           <input type="file" onChange={onUploadChange} />; */}

            <hr />

            <div>
              <UploaddImages customerId={customerCtx.id}/>
            </div>
     {/*        <AmplifyS3ImagePicker level="private" /> */}


 
        </div>   

        }
        
     </div>
    )
  }


