import React, {useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
    root: {
    },

}));

export default function AreYouSure(props) {
    const { open, title, onClose, children } = props 

    const { t } = useTranslation();
    const [openState, setOpenState] = React.useState(false);

    const classes = useStyles();

    useEffect(() => {
        setOpenState(open)
    }, [open])

    const handleCancel = () => {
        setOpenState(false);
        onClose(false)
    };  
    const handleConfirm = async () => {
        setOpenState(false);
        onClose(true)
    };    


    return (
        <Dialog
            open={openState}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
             <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                {children}
            </DialogContent>   
            <DialogActions>
                <Button onClick={handleCancel} color="primary" autoFocus>
                    {t('global.button.cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" >
                    {t('global.button.confirm')}
                </Button>
            </DialogActions>
        </Dialog>  
    )
}