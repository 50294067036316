
import { createContext } from 'react'

const GeneralContext = createContext({
    
  user: {},
  customer: {},
  portal:{},

  setUserDetails: userDetails => {},
  setCustomerDetails: customerDetails => {},
  setPortalDetails: portalDetails => {}
})

export default GeneralContext