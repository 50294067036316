import React, { useEffect, useState, useContext } from 'react';
import { useParams} from "react-router-dom";

import GraphQlApi from '../mobile/MyGraphql';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { CSVLink } from "react-csv";

import ChartMultiAxes from '../components/ChartMultiAxes'
import MyDateRangePicker from '../components/MyDateRangePicker'
import ProbeSelector from '../components/ProbeSelector'

import { getProbeDetail, isEmptyObject } from '../Common';
import chartConfig from '../chart-config'; // configuration générale du graphique (noms possibles des axes Y (droite/gauche) couleurs)
import GeneralContext from '../components/GeneralContext';

const useStyles = makeStyles(theme => ({  
  appBarParam: {
    position: 'relative',
  },
  titleParam: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  csvLine: {
    paddingRight: 40
  } 
})
)

export default function ServiceGraphPage(props) {
    const { onClose } = props;

    let { id: serviceId } = useParams();
    const { user, customer } = useContext(GeneralContext);

    const [service, setService] = useState({});
    const [loading, setLoading] = useState(false);

    const [defaultRangeType, setDefaultRangeType] = useState(); // elle doit dabord venir du service (si présent)

    //const [histoDateRange, setHistoDateRange] = useState(getPeriodUntilNow("today"));
    const [histoDateRange, setHistoDateRange] = useState();

    const [csvData, setCsvData] = useState([]);
    const [csvFileName, setCsvFileName] = useState("csvfile");

    const [graphData, setGraphData] = useState(); // données formattées pour l'affichage

    const [isDataSetChanged, setIsDataSetChanged] = useState(false); 
    
    //const [graphConfig, setGraphConfig] = useState({});

    const classes = useStyles();

    useEffect(() => {
      //moment.locale('fr');
      let ignore = false;
      async function fetchData(serviceId) {
          console.log("Service is loading for %s ...", serviceId);
          setLoading(true);
          const getServiceQuery = `query GetService($id: ID!) {
              getService(id: $id) {
                id
                name
                device {
                  id
                  manufacturer
                  status
                  template {
                    id
                    probesDisplay
                    typeBusiness
                  }         
                }
                probesConfig
                customer {
                  id
                }
                location
                status
              }
            }`;
          //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
          const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery, { id: serviceId });
          setLoading(false);
          if (result.data.getService) {
              const theService = result.data.getService
              //console.log("service :", theService);
              if (!ignore) {
                  setService(theService);

                  const defaultPeriod = getDefaultPeriod(theService); // today / yesterday / last7days / last30days / custom
                  console.log("defaultPeriod:",defaultPeriod);
                  setDefaultRangeType(defaultPeriod);    

              }
          } else {
              console.log("User non trouvé en base");
          }

      }      
      fetchData(serviceId);
      return () => { ignore = true; }

    }, [serviceId]);

    // le service est maintenant disponible
    useEffect(() => {
      if (service) {
        console.log("service availlable", service)
      }
    }, [service])

    useEffect(() => {
      var size;
      if (graphData) {
        size = graphData.length
      }
      console.log("graphData changed ... ", size)
    }, [graphData])
    
    const dataSetChanged = ((dataSet) => {
      console.log("DataSet changed : ", dataSet);
      setIsDataSetChanged(true); // si l'utilisateur a changer l'affichage des sondes, il faut le savoir pour ne pas perdre ce choix lors du refresh rawData
      setGraphData(dataSet);
    })

    // il sera possible d'indiquer une periode d'affichage du graphique par defaut (globalConfig du template : { display: { defaultPeriod: "last7days" })
    // pour l'instant si le BusinessType de capteur est ET0, on passe en mode last7days
    function getDefaultPeriod(service) {
      var defaultPeriod;
      if (service.device.template.typeBusiness && service.device.template.typeBusiness ==="ET0") {
        defaultPeriod = "last7days";
  
      } else {
        defaultPeriod = "today"
      }
      return defaultPeriod;
    }

    function extractDataForCsv(service, rawData) {
      var csvData = [];

      var headerLine = [];
      var isFirstLine = true;

      var addRawValue = false;
      if (customer.isUserAdmin) addRawValue= true;

      // pour chaque mesures de l'historique
      rawData.map( item => {
        const probes = JSON.parse(item.values);

        var newLine = [];

        if (isFirstLine) {
          headerLine.push("time")
        }
        newLine.push(moment(item.dateEvent).format("DD/MM/YYYY HH:mm:ss"));

        // parcours de chacune des probes de la mesure
        for(const key in probes) {
          const probe = probes[key];
          var rawValue;
          if (probe.more && probe.more.rawValue) {
            rawValue = probe.more.rawValue;
          }

          if (isFirstLine) {
            headerLine.push(key+"_"+probe.type)
            if (rawValue && addRawValue) {
              headerLine.push(probe.type+"_brut")
            }

          }
          newLine.push(String(probe.value).replace('.',','));
          if (rawValue && addRawValue) {
            newLine.push(String(rawValue).replace('.',','));
          }
        }
        // 
        if (isFirstLine) {
          csvData.push(headerLine)
          isFirstLine = false;
        }
        csvData.push(newLine)
      })
      console.log("csvData:",csvData);
      setCsvData(csvData);
      setCsvFileName(generateFileName(service,histoDateRange))
    }

    function generateFileName(service, histoDateRange) {
      // le nom du service sans blanc sur 10
      const name = (service.name).substring(0,10).replace(' ','_');
      // la période
      const periode = moment(histoDateRange.start).format("YYYY-MM-DD_HH")+"-"+moment(histoDateRange.end).format("YYYY-MM-DD_HH")
      // le deveui
      const deveui = service.device.id
      //return "data_export_allProbes.csv"
      return name+"_"+periode+"_"+deveui+".csv"
    }

    async function getHistoBetween(serviceId, startDate, endDate) {
      console.log("getHistoBetween Start: %s,End:%s ",startDate,endDate);
        var measures;

        const getMeasureBetween = `query getMeasureFromDay($id: ID!, $startDate: String, $endDate:String) {
            listMeasures(id: $id, dateEvent: { between : [ $startDate, $endDate ]}
            limit:1000) {
              items {
                dateEvent
                values
              }
            }
          }`;
        //const response = await API.graphql(graphqlOperation(getMeasureBetween, { "id": serviceId,"startDate": startDate, "endDate": endDate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( getMeasureBetween, { "id": serviceId,"startDate": startDate, "endDate": endDate });
        measures = response.data.listMeasures.items;
        console.log("data loaded size:",measures.length)
    
        //this.dataProbe1 = this.extractProbe(this.measures, probeName);
        return measures;
    }

    /**
     * Conversion des mesures dans en x,y pour être représenté sur le graphique
     * On associe a chaque sonde un type de valeur en rapport avec un affichage possible sur une axe Y
     * via un dictionnaire (actuellement en fonction de l'unité de la mesure)
     * 
     * chaque unité possède dans le dictionnaire un couleur
     * mais on peut associer explicitement une couleur pour une sonde
     * 
     * chaque template contiendra un affichage par defaut du service (ex 2 courbes de temp a gauche et 2 hum a droite)
     * 
     * @param {*} rawData 
     * @param {*} service 
     */
    //TODO Traitement très similaire dans ProbePage.dataForGraph(rawData, service) a factoriser
    function dataByProbeArray(rawData, service) {
      console.log("rawData:",rawData);

      var byProbes = {};
      rawData.map( item => {
        const probes = JSON.parse(item.values);
        for(const key in probes) {
          const probe = probes[key];

          const detail = getProbeDetail(service, key);
          var probeValue = probe.value;

           //pour les sondes de type Entrée binnaire
          if (probe.type==="STATE_IN") {
            probeValue = probe.state;
            /*            
             // Pour une inversion uniquement à l'affichage
            // actuellement l'inversion est lors du décodage
            // l'etat 'on' correspond t-il à O ou 1 ?
            if (detail.hasOwnProperty("stateOn") && detail.stateOn===0) {
              console.log("Inversion des etats")
              // etat on correspond 
              //probeValue = !probeValue;
            }
            */
          } 

          var probeData = byProbes[key]
          if (!probeData) {
            // get depuis in template  by probe
            var dataSerie = { 
              name: detail.name, 
              type : detail.type, 
              unit : detail.unit, //{/* EVOL1 */}
              //data : [{ x: new Date(probe.timeEvent), y:probe.value }] 
              data : [{ x: probe.timeEvent, y:probeValue }] 
            }
            if (probe.type==="STATE_IN") {
              dataSerie["graphType"] = "SteppedLine"
            }
            if (detail.color) {
              dataSerie["color"] = detail.color;
            }
            if (detail.graph) {
              dataSerie["graphCfg"] = detail.graph;
            }     
            // si une        
            // PROVISOIRE : pour chaque service, si présent
            // on prends le temperarure a gauche et le humidy a droite
            // par defaut
            if (detail.type==="TEMPERATURE") {
              dataSerie["show"] = 'left';
            } else if (detail.type==="HUMIDITY") {
              dataSerie["show"] = 'right';
            }
            byProbes[key] = dataSerie;

          } else {
            // si E/S : on ajoute un event pour faire une courbe en escalier
            if (probe.type==="STATE_IN") {
              if(probeValue===1) {
                byProbes[key].data.push({ x: probe.timeEvent, y:0 })
                byProbes[key].data.push({ x: probe.timeEvent, y:probeValue })
              } else {
                //0
                byProbes[key].data.push({ x: probe.timeEvent, y:1 })
                byProbes[key].data.push({ x: probe.timeEvent, y:probeValue })
              }

            } else {
              // type de probe autre que E/S, on l'ajoute normalement
              byProbes[key].data.push({ x: probe.timeEvent, y:probeValue })
            }            
          }
        }
      })
      // dans certain cas l'ordre temporel des valeurs de la probe n'est pas respecté
      // Pour être plus sur que l'affichage se fasse dans l'ordre temporel,
      // on tri sonde par sonde (pas très bon au niveau perf !)
      sortingEveryProbeData(byProbes);
      // si il n'y a qu'une seule probe, et que ce n'est pas TEMPERATURE/HUMIDITY
      // alors on affiche la sonde sur l'axe de gauche
      // si une deuxieme alors a droite
      const probesNb = Object.keys(byProbes).length;
      if (probesNb===1 || probesNb===2) {
        const probeKey = Object.keys(byProbes)[0];
        const probeDetail = byProbes[probeKey];
        if (probeDetail.type!=="TEMPERATURE" && probeDetail.type!=="HUMIDITY") {
          byProbes[probeKey].show = 'left';
        }
      }
      if (probesNb===2) {
        const probeKey = Object.keys(byProbes)[1];
        const probeDetail = byProbes[probeKey];
        if (probeDetail.type!=="TEMPERATURE" && probeDetail.type!=="HUMIDITY") {
          byProbes[probeKey].show = 'right';
        }        
      }
      console.log("byProbes (après):",byProbes)
      /*
      si length<3
      pour chcune des key

      */
      return byProbes; 
      // une map de probe contenant un objet avec le detail de la probe et un Array d'objets contenant les valeurs de la sonde en y
      /*
          {
            probe1: { 
              name : "1 - temp",
              show: "left", / right, no
              type: "TEMPERATURE",
              unit: "°C",
              data: [
                {x:"2017-01-06 18:39:30",y:"10"},
                ...
              ]
            },
            ....
          }
      */
  }

  function sortingEveryProbeData(byProbes) {
    for(const key in byProbes) {
      byProbes[key].data.sort((a, b) => a.x - b.x);
    }
  }
 // avec data directement
 function sortingEveryDataProbe(dataByProbes) {
  for(const key in dataByProbes) {
    dataByProbes[key].sort((a, b) => a.x - b.x);
  }
}

  // MAJ des data dans les dataSets en preservant les choix de l'utilisateur sur l'aff des sondes
  // sans toucher à la config de chaque set/probe (en particulier si view=no,left,right et le mode histo/line?)
  const dataSetUpdateData = ((rawData) => {
    console.log("new rawData:",rawData);
    var dataByProbes = {};
    rawData.map( item => {
      const probes = JSON.parse(item.values);
      for(const key in probes) {
        const probe = probes[key];

        if (!dataByProbes[key]) {
          dataByProbes[key] = [{ x: probe.timeEvent, y:probe.value }] ;

        } else {
          dataByProbes[key].push({ x: probe.timeEvent, y:probe.value })
        }
      }
    })
    sortingEveryDataProbe(dataByProbes)
    console.log("dataByProbes (sorted):",dataByProbes)
    console.log("graphData:",graphData)
    const currentDataSets =  graphData ? JSON.parse(JSON.stringify(graphData)) : null;
    // pour chaque set du graph, si il y a des nouvelles pour cette sonde, on maj les data
    for(const probeKey in currentDataSets) {
      const dataSet = currentDataSets[probeKey]
      if (dataByProbes[probeKey]) {
        dataSet.data = dataByProbes[probeKey];
      }
    }
    return currentDataSets;
  })

  useEffect(() => {
    console.log("Histo date changed:", histoDateRange);
    async function getNewHisto(serviceId, start, end) {
      const rawData = await getHistoBetween(serviceId, start, end);

      var data;
      if (isDataSetChanged) {
        console.log("Regeneration d'un dataSet SANS MAJ des choix utilisateur sur l'affichage des sondes")
        data = dataSetUpdateData(rawData);

      } else {
        console.log("Regeneration d'un dataSet AVEC MAJ des choix utilisateur sur l'affichage des sondes")
        data = dataByProbeArray(rawData, service);
      }
      setGraphData(data);
      //console.log("data:",JSON.stringify(data))
      //console.log("avant setGraphData:",data);
      // pour lancer l'affichage du grapahique
      extractDataForCsv(service, rawData)
    }
    if (histoDateRange && !isEmptyObject(service)) {
      getNewHisto(serviceId, histoDateRange.start, histoDateRange.end);
    }
  }, [histoDateRange, service])

  async function newDateRangeReceived(rangeType, start, end) {
      console.log("Received histo rangeType :",rangeType, start, end);
      setHistoDateRange({start: start, end: end})
  }

  return (
      <div>
        <AppBar className={classes.appBarParam}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <BackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.titleParam}>
            {service.name}
            </Typography>
          </Toolbar>
        </AppBar>        

        <div>
          <MyDateRangePicker  onChange={newDateRangeReceived} defaultRangeType={defaultRangeType} />
        </div>
        {graphData && 
          <div>
            <ChartMultiAxes dataSets={graphData} config={chartConfig} />
            <ProbeSelector dataSets={graphData} changeDatasetCallback={dataSetChanged} />





            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
    
                    <Grid  item>
                      <CSVLink                 
                        filename={csvFileName} 
                        separator={";"}
                        data={csvData}
                        >
                        <CloudDownloadIcon fontSize="large" />
                        <div>Export</div>
                        <div>CVS </div>
                      </CSVLink> 
                    </Grid>
                    <Grid  item>

                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
      </div>

  );
}

