import React, { useState, useEffect, useContext } from 'react';
import { Auth } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import {useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';

import GeneralContext from './GeneralContext'
import { truncate, askForRefreshingCustomer } from '../Common';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    mainRoot: {
      display: 'flex',
      flexGrow: 1,
    },
    buttonLogin: {
      flexGrow: 4,
      //margin: theme.spacing(0), 
      //padding: theme.spacing(0), 
      margin: 0, 
      padding: 0
    }
  }));

export default function UserHeader() {

    const { user, setUser, customer } = useContext(GeneralContext)
    const { signOut } = useAuthenticator((context) => [context.user]);

    const { t } = useTranslation();
    const classes = useStyles();
    //const theme = useTheme();
    const greaterBig = useMediaQuery('(min-width:800px)');
    const greaterMedium = useMediaQuery('(min-width:650px)');
    const greaterDrawerBrkPt = useMediaQuery('(min-width:600px)');
    const greaterSmall = useMediaQuery('(min-width:500px)');


    const [anchorEl, setAnchorEl] = useState(null);
    
    const [userName, setUserName] = useState(user.id);

    const open = Boolean(anchorEl);
    let history = useHistory()

    useEffect( () => {
      //console.log("breakpoint changed : ", greaterBig, greaterMedium, greaterDrawerBrkPt, greaterSmall)
      // vide > tronqué (drawer) vide > tronqué > complet 
      if (greaterBig) {
        setUserName(user.id);
      } else if (greaterMedium) {
        setUserName(truncate(user.id,16));
      } else if (greaterDrawerBrkPt) {
        setUserName('');          
      } else if (greaterSmall) { // drawer hidden
        setUserName(truncate(user.id,16));          
      } else { // <smaller
        setUserName('');
      }      
    },[user, greaterBig, greaterMedium, greaterDrawerBrkPt, greaterSmall]);

    const handleClose = () => {
        setAnchorEl(null);
      };

      const handleMenu = event => {
        setAnchorEl(event.currentTarget);
      };
      
      function handleProfile() {
        setAnchorEl(null);
        history.push("/userProfile");
      }

      function gotoCamera() {
        setAnchorEl(null);
        history.push("/camera");
      }

      function handleRefreshDevice() {
        askForRefreshingCustomer(customer.id);
      }

      function handleLogout() {
        console.log("signOut ok : ")
        setUser({}); // suppression du user dans le context
        console.log("user context deleted")
        signOut();

/*         Auth.signOut()
          .then(data => {
            console.log("signOut ok : ", data)
            setUser({}); // suppression du user dans le context
            console.log("user context deleted")
            window.location.reload();
          })
          .catch(err => console.log(err)); */
      }     
    return (
        <div className={classes.buttonLogin}>
          <Grid container direction="column" justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  >
                  <AccountCircle />
              </IconButton>
            </Grid>
            <Grid item>
              {(user !== null)  && (
              <div>{userName}</div>
              )} 
            </Grid>
          </Grid>
          <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
            {user.id!=='demo' &&
              <MenuItem onClick={handleProfile}> {t('global.menu.profil')} </MenuItem>
            }
            {user.isBetaTester &&
                <MenuItem onClick={handleRefreshDevice}> {'MAJ'} </MenuItem>
            }            

           {/*  <MenuItem disabled onClick={handleClose}>My account</MenuItem> */}
            <MenuItem onClick={handleLogout}> {t('global.menu.logoff')} </MenuItem>                  
          </Menu>
      </div>
    )
}