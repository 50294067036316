import React, { useEffect, useState, useContext } from 'react';

import { API } from "aws-amplify";
import GraphQlApi from '../mobile/MyGraphql';

import { AmplifyS3ImagePicker } from '@aws-amplify/ui-react';
import Storage from "@aws-amplify/storage";

import { useTranslation} from 'react-i18next';
import { useLocation, useHistory } from "react-router-dom";

import MyForm from '../components/MyForm'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import GeneralContext from '../components/GeneralContext'
import { askForRefreshingCustomer, toSnack, validateEmail} from '../Common';
import AdminNewLinky from './AdminNewLinky'
import AdminNuki from './AdminNuki'

const useStyles = makeStyles(theme => ({
    root: {
    },
    minHeight: {
        minHeight: 20, // ou 66
    },    
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    textFieldLogo: {
        width: '70ch',
    },   
    customerLogo: {
        maxWidth: 46,
        flexGrow: 1,
        marginTop: 7
    },   
    button: {
        margin: theme.spacing(1),
    },
    userList: {

    },
    cardHeader: {
        //backgroundColor: "lightskyblue",
        //color: "white",
        margin: "20px 20px 0px 20px",
        padding: "10px",
        fontWeight: "bold",
        fontSize: "18px"
    },  
    InviteMailTextField: {
        minWidth: '26ch',
    }             
  }));

export default function AdminCustomerMain(props) {

    const { openDialog } = props;

    const { user, customer: customerCtx } = useContext(GeneralContext);


    const { t }  = useTranslation();

    const [companyLogo, setCompanyLogo] = useState("");

    const [groupName, setGroupName] = useState("");

    const [backdropNewUserOpen, setBackdropNewUserOpen] = useState(false);
    const [urlHash, setUrlHash] = useState();

    const [customer, setCustomer] = useState(); //init avec customerCtx

    const [linkyServices, setLinkyServices] = useState([]);
    const [linkyForUpdate, setLinkyForUpdate ] = useState();
    
    const [measureReportEmail, setMeasureReportEmail] = useState(""); 
    const [measuresReportActivation, setMeasuresReportActivation] = useState(false); 
    const [measureReportConfig, setMeasureReportConfig] = useState(); 

    const [defaultHomePage, setDefaultHomePage] = useState('none'); 
    const [defaultPages, setDefaultPages] = useState([]);
    
    const [openNewLinky, setOpenNewLinky] = useState(false);
    const [newLinkyStep, setNewLinkyStep] = useState();
    
    const [openNewNuki, setOpenNewNuki] = useState(false);
    const [newNukiStep, setNewNukiStep] = useState();

    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    let history = useHistory();
    let location = useLocation();

    useEffect( () => {
        console.log("openDialog:",openDialog);
        // ouverture de la dialogbox AddNewLinky, en arrivant à letape 'retour du GET Enedis
        if (openDialog && openDialog==="newLinky") {
            setNewLinkyStep("fromEnedisGet");
            setOpenNewLinky(true);

        } else if (openDialog && openDialog==="newNuki") {
            setNewNukiStep("fromNukiGet");
            setOpenNewNuki(true);
        }
    },[openDialog]);


    useEffect(() => {
        async function fetchData(customerId) {
            console.log("Customer loading for (%s) ...",customerId);
            const getQuery = `query GetCustomer($id: ID!) {
                getCustomer(id: $id) {
                    id   
                    name
                    logoUrl 
                    accessType
                    invitationCode
                    isBetaTester
                    devicesCounter
                    measuresReportEnable
                    measuresReportConfig
                    homePage
                    users {
                        id
                        user {
                            id
                            email
                        }
                        userRole
                    }
                    guests {
                        id
                        owner
                        userRole
                        user {
                          id
                          email
                        }
                    }                     
                    groupsSvc {
                        items {
                          id
                          name
                        }
                    }                                                                                                       
                }
            }
            `;
            try {
                //const result = await API.graphql(graphqlOperation(getQuery, { id: customerId }));
                const result = await GraphQlApi.getInstance().graphqlOperation( getQuery, {  id: customerId });
                if (result.data && result.data.getCustomer) {
                    console.log("Result customer:", result.data.getCustomer);

                    const customer = result.data.getCustomer;
                    if (customer && customer.logoUrl) setCompanyLogo(customer.logoUrl)
                    setCustomer(customer);

                    if (customer.homePage) {
                        setDefaultHomePage(customer.homePage);
                    }
                    // la fonction mail avec rapport quotidien est déja activé
                    if (customer.measuresReportEnable && customer.measuresReportConfig) {
                        const measuresReportConfig = JSON.parse(customer.measuresReportConfig);
                        setMeasureReportConfig(measuresReportConfig);
                        // on initialise les champs
                        if (measuresReportConfig.email) {
                            // si pas de mail alors que activé n'est pas possible
                            setMeasureReportEmail(measuresReportConfig.email)
                            setMeasuresReportActivation(true)
                        }

                    } else {
                        // pas encore de configuration pour l'envoi de rapport quotidien de mesures
                        // on cherche un email à proposer par defaut
                        setMeasureReportConfig({});
                        if (validateEmail(customer.owner)) {
                            setMeasureReportEmail(customer.owner)
                        } else if (user.email) {
                            setMeasureReportEmail(user.email)
                        } else if (validateEmail(user.id)) {
                            setMeasureReportEmail(user.id)
                        }
                    }


                } else {
                    console.log("Data not found");
                }
            } 
            catch(resultErrors) {
                const errors = resultErrors.errors?resultErrors.errors:(resultErrors.graphQLErrors?resultErrors.graphQLErrors:null);
                console.log("Graphql errors: ",errors);                
            }
        }
       
        if (customerCtx) {
             // chargement initial à partir du context
            if (customerCtx.logoUrl) { 
                setCompanyLogo(customerCtx.logoUrl)
            } 
            console.log("Charge Customer from db for", customerCtx.id);
            fetchData(customerCtx.id);    
            return function cleanup() {
                console.log(`useEffect() cleanup.`)
            };            
        }  
    },[customerCtx])

    useEffect( () => {
        async function fetchLinkyServices(customerId) {
            const linkyServices = await getLinkyServices(customerId);
            console.log("Existing registred linkys:", linkyServices);
            setLinkyServices(linkyServices);
        }
        if (customerCtx) {
            // le customer est ready,
            // on recherche l'existant de linky déjà enregistrés
            fetchLinkyServices(customerCtx.id);
        }
    },[customerCtx]);   

    useEffect( () => {
        console.log("LOCATION:",location);
        setUrlHash(location.hash);
        if (location.hash=="#newuser") {
            setBackdropNewUserOpen(true);
        }
    },[location]);

    useEffect( () => {
        async function fetchData() {
            const homePages = await getHomePages();
            console.log("homePages choice:",homePages)
            const miniList = [{name: 'CUSTOM_APART', __typename: '__EnumValue'}]
            setDefaultPages(miniList);
        }
        fetchData();
    },[]);
    

    async function customerEdit() {
        console.log("modification du customer")
        const customerObj = { logo: companyLogo}
        setLoading(true);
        await updateCustomerMutation(customerCtx.id, customerObj)
        setLoading(false);
        toSnack("CustomerUpdated","La société a bien été modifiée");
    }  

    async function reportSettingEdit() {
        console.log("modification des options du rapport")
        console.log("config avant : ",measureReportConfig)
        const newReportConfig = measureReportConfig;
        newReportConfig["email"] = measureReportEmail;
        const customerObj = { 
            measuresReportConfig: JSON.stringify(newReportConfig), 
            measuresReportActivation: measuresReportActivation}
        setLoading(true);
        console.log("customerObj : ",customerObj)
        await updateCustomerMutation(customerCtx.id, customerObj)
        setLoading(false);
        toSnack("CustomerUpdated","La société a bien été modifiée");
    } 

    async function handleHomePageChange(e) {
        console.log("modification de la page par defaut :", e.target.value)
        setDefaultHomePage(e.target.value);
    }

    async function homepageSettingEdit() {
        console.log("sauvegarde de la page par defaut");
        if (customer.homePage === defaultHomePage) {
            return;
        }
        const customerObj = { 
            homePage: defaultHomePage
        }
        setLoading(true);
        console.log("customerObj : ",customerObj)
        await updateCustomerMutation(customerCtx.id, customerObj)
        setLoading(false);
        toSnack("CustomerUpdated","La société a bien été modifiée");
    }     

    async function updateCustomerMutation(customerId, customer) {
        console.log("customerMutation:",customer);
        const toUpdate = {
            id: customerId
        };
        if (customer.logo) { toUpdate["logoUrl"] = customer.logo }

        if (customer.homePage) { toUpdate["homePage"] = customer.homePage }

        if (customer.measuresReportConfig) { toUpdate["measuresReportConfig"] = customer.measuresReportConfig }
        if (customer.hasOwnProperty("measuresReportActivation")) { toUpdate["measuresReportEnable"] = customer.measuresReportActivation }

        console.log("toUpdate:",toUpdate);
        const updateMutation = `mutation updateCustomer($input: UpdateCustomerInput!) {
          updateCustomer( input: $input) {
              id
          }
        }`;
        //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
        console.log("customer updated", response.data.updateCustomer);
    }   

    async function handleAddGroup() {
        if (groupName) {
            setLoading(true);
            await addGroupMutation(groupName)
            askForRefreshingCustomer(customerCtx.id);
            setGroupName("");
            setLoading(false);
        }
    }
    async function handleGroupDelete(groupId) {
        console.log("delete:",groupId);
        setLoading(true);
        const members  = await getGroupMembersMutation(groupId)
        if (members && members.length>0) {
            console.log(members);
            toSnack("CustomerUpdated","Impossible de supprimer le groupe. Il doit être vide.");
        } else {
            await deleteGroupMutation(groupId);
            askForRefreshingCustomer(customerCtx.id);
        }
        setLoading(false);
    }

    const handleCheckboxMeasureReport = (checkbox) => {
        console.log("check:",checkbox)
        setMeasuresReportActivation(checkbox);
    }
   
    async function handleTestRest() {
        // effacement du user dans la table de liaison n-n entre user et customer
        const userId = "45678";
        console.log("GET - User - appel REST : ")
        var response;
        try {
            response = await API.get("apiRestCustomer", "/customers/"+customer.id+"/invitation/"+userId+"", {});
             if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    }

    function addLinkyCallBack(response) {
        console.log("AddLinkyCallBack (Cancel/attached) : ", response)
        setOpenNewLinky(false);
        if (response && response.action && response.action==="attached") {
          history.push("/services/all");
        }
    }
    function addNukiCallBack(response) {
        console.log("AddNukiCallBack (Cancel/attached) : ", response)
        setOpenNewNuki(false);
        if (response && response.action && response.action==="attached") {
          history.push("/services/all");
        }
    }
      
    async function handleTestProxyRest() {

        console.log(" appel REST : ")
        let myInit = {
            body: {
              code: "4widRBaLBqPphokvJ0p1zqSSKTgSmv",
            }, 
            headers: {} // OPTIONAL
          }
        var response;
        console.log("myInit:", myInit)
        try {
            response = await API.post("iotCorsProxy", "/mytest", myInit);
             if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response.message;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            console.log(error)
            throw error;
        }
    }
    const addLinkyDevice = () => {
        console.log("Ajout d'un compteur linky");
        setLinkyForUpdate()
        setOpenNewLinky(true);
    }
    const updateLinkyDevice = (service) => {
        console.log("Demande de MAJ d'un compteur linky for service : ", service);
        setLinkyForUpdate({
            devEui: service.device.id,
            serviceId: service.id,
            serviceName: service.name
        })
        setOpenNewLinky(true);
    }

    
    const manageNukiAuth = () => {
        console.log("Page d'authent Nuki");
        setOpenNewNuki(true);
    }    
    

    async function addGroupMutation(groupName) {

        //TODO verifier que le user est bien admin ?

        const entry = {
            name: groupName,
            customerID: customerCtx.id,
            groupSvcOwnerId: user.id
        };
        console.log("entry:",entry);

        const addMutation = `mutation CreateGroup($input: CreateGroupSvcInput!) {
            createGroupSvc(input: $input) {
              id
            }
          }
          `;           

        try {
          //const response = await API.graphql(graphqlOperation(addMutation, { input: entry }));
          const response = await GraphQlApi.getInstance().graphqlOperation( addMutation, { input: entry });
          console.log("Add group ok :",response.data.createGroupSvc);
        }
        catch (error) {
          //console.log(error.response)
          console.log(error)
        }  
    }
        
    async function deleteGroupMutation(id) {
        const deleteMutation = `mutation deleteGroup($input: DeleteGroupSvcInput!) {
          deleteGroupSvc( input: $input) {
              id
          }
        }`;
        const toDelete = {
            id: id,
        };
        console.log("deleting group id", id);

        //const response = await API.graphql(graphqlOperation(deleteMutation, { input: toDelete }));
        const response = await GraphQlApi.getInstance().graphqlOperation( deleteMutation, { input: toDelete });
        console.log("group deleted", response.data.deleteGroupSvc);
    }

    async function getGroupMembersMutation(groupId) {
        console.log("Fetching for services of Group %s is processing ...", groupId);
        var groupMembers = [];
        const queryServicesFromGroup = `query getGrpLoc($id: ID!) {
            getGroupSvc(id : $id) {
                id
                name
                services {
                    items {
                    service {
                        id
                        name                                                
                    }
                }
              }
            }
        }`;
        //const result = await API.graphql(graphqlOperation(queryServicesFromGroup,  { id: groupId }));
        const result = await GraphQlApi.getInstance().graphqlOperation( queryServicesFromGroup, { id: groupId });
        if (result.data.getGroupSvc) {
            const group = result.data.getGroupSvc
            console.log("group info from Db:", group);
                if (group && group.services && group.services.items) {
                    groupMembers = group.services.items;
                } else {
                    console.log("pas de service dans ce groupe");
                }
        } else {
            console.log("Groupe non trouvé en base");
        }
        return groupMembers
    }

    async function getLinkyServices(customerId) {
        console.log("Fetching for linky services of customer %s ...", customerId);
        const query = `query getServices($id: ID!) {
            getCustomer(id : $id) {
                id
                name
                services(filter: {serviceType: {eq: LINKY}}) {
                  items {
                    name
                    id
                    device {
                        id
                    }
                  }
                }
              }            
        }`;
        const result = await GraphQlApi.getInstance().graphqlOperation( query, { id: customerId });
        var services = [];
        if (result && result.data && result.data.getCustomer) {
            const customer = result.data.getCustomer
            //console.log("customer info from Db:", customer);
            if (customer.services && customer.services.items) {
                services = customer.services.items;
            } else {
                console.log("pas de service dans ce customer");
            }
        } else {
            console.log("Customer not found");
        }
        return services
    }

    const getHomePages = (async () => {
        var homePages;
        const enumQuery = `query getCustomerHomePages {
            __type(name: "CustomerHomePages") {
              name
              enumValues {
                name
              }
            }
          }`;

        const result = await GraphQlApi.getInstance().graphqlOperation(enumQuery);
        //console.log("enum:",result);
        if (result && result.data) {
            homePages = result.data.__type.enumValues
        }
        return homePages;
    })

    function sortByName(templates) {
        return templates.sort((a, b) => a.id.localeCompare(b.id));       
    }

    function backdropClose() {
        setBackdropNewUserOpen(false)
        history.push("/home");
    }  
      

    return (
        <div>
            <div className={classes.minHeight}></div>      
            <div className={classes.alignItemsAndJustifyContent}>
              {loading && <CircularProgress size={40} />}
            </div>

            <Card className={classes.cardHeader} variant="outlined" >
                <Grid container   direction="row" justifyContent="space-between" alignItems="center" > 
                    <Grid item>{customerCtx.name}</Grid>
                </Grid>
            </Card>
 
            <MyForm title={t('screen.customer-admin.detail')} edit action={customerEdit}>
                <div>
                    <div>{t("screen.customer-admin.logo-detail")}</div>
                    <div>
                        {customerCtx.logoUrl &&
                            <div >
                                <img src={customerCtx.logoUrl} alt="logo" className={classes.customerLogo} />
                            </div>
                        }
                    </div>  
                    <div>{t("screen.customer-admin.logo-you-can")}</div>
                    <div>{t("screen.customer-admin.logo-you-must")}</div>
                    <TextField 
                        value={companyLogo}
                        onInput={ e=>setCompanyLogo(e.target.value)}                    
                        id="standard-disabled" 
                        className={classes.textFieldLogo}
                        label={t("screen.customer-admin.logo-helper")} />
                </div>
            </MyForm>

            <MyForm title={t("screen.customer-admin.device-group")} >
                <div>
                {!customerCtx.groupsSvc &&
                    <div>{t('screen.customer-admin.no-device-yet')}</div>
                }
                 <List component="nav" className={classes.root} aria-label="groups">
                    {customerCtx.groupsSvc?.items?.map( (group, key) => 
                        <div  key={key}  >
                            <ListItem button>
                                <ListItemText primary= {group.name}  />
                                <ListItemIcon>
                                    <DeleteIcon onClick={()=>{handleGroupDelete(group.id)}} />
                                </ListItemIcon>
                            </ListItem>                              
                        </div>
                    )}
                </List>
                </div>
                <Divider/>
                <div>
                    <TextField  
                            value={groupName}
                            onChange={(e) => {setGroupName(e.target.value)}}
                            id="standard-disabled" 
                            label={t("screen.customer-admin.new-group")} />

                    <IconButton size="medium"  aria-label="add" onClick={handleAddGroup}>
                        <AddIcon />
                    </IconButton>
                    <div>{t("screen.customer-admin.group-limit")}</div>
                </div>
            </MyForm>

            <MyForm title={"Rapport journalier"} edit action={reportSettingEdit} >
                <Typography variant="body2">
                    Vous avez la possibilité de recevoir chaque jour un mail avec 
                    en piece jointe les mesures du jour de tous les capteurs de cette organisation.
                </Typography>
                Activation du rapport
                <Checkbox 
                    checked={measuresReportActivation} 
                    onChange={(event)=>handleCheckboxMeasureReport(event.target.checked)} 
                    color="primary" 
                    name="checkedA" />

                <TextField 
                        value={measureReportEmail}
                        disabled={!measuresReportActivation}
                        onInput={ e=>setMeasureReportEmail(e.target.value)}                    
                        id="standard-disabled" 
                        className={classes.textFieldLogo}
                        label={"email du destinataire des rapports quotidiens"} />

            </MyForm> 
            { user.isBetaTester &&            
                <MyForm title={"Page d'accueil"} edit action={homepageSettingEdit} >
                    <Typography variant="body2">
                        Vous pouvez changer la page d'accueil par defaut
                    </Typography>
                    <Select
                        labelId="change-homepage"
                        id="homepage-select"
                        value={defaultHomePage}
                        displayEmpty
                        //autoWidth
                        onChange={handleHomePageChange}
                        className={classes.custSelect}
                    >
                        <MenuItem value="none" > Aucune </MenuItem>
                        {defaultPages.map( (item, key) => 
                            <MenuItem  key={key} value={item.name} >{item.name}</MenuItem>   
                        )}      
                    </Select>                    
                </MyForm>  
            }           
            { user.isBetaTester &&
                <MyForm title={"Consommation Linky (API Enedis)"} >
                    <Typography variant="body2">
                        Si vous possédez un compteur electrique Linky, vous allez pouvoir intégrer vos données de consommation electrique issue de Enedis dans myIOTplatform.
                    </Typography>
                    <Typography variant="body2">
                    
                    </Typography>                 
                    <Grid container  direction="row" justifyContent="space-between" alignItems="center">
                        <Grid xs={4} item>
                            Appuyez sur le compteur pour commencer
                        </Grid>
                        <Grid xs={8} item>
                            <a hre="#"  onClick={addLinkyDevice} >
                                <img src="/linky_img.png" alt="image" width="50" />
                            </a>
                        </Grid>
                    </Grid>
                    {linkyServices.length>0 &&
                    <div>
                        <div>Vous avez déjà un ou plusieurs compteurs associés.</div>
                        <div>Cliquez sur le nom pour refaire l'association avec Enedis</div>
                        <ul>
                        {linkyServices?.map( (service, key) => 
                            <li key={key} onClick={() => updateLinkyDevice(service)} >{service.name}</li>
                        )}
                        </ul> 

                    </div>
                    }

                  
                </MyForm>  
            }
            { user.isBetaTester && user.retailer &&
                <MyForm title={"Admin Nuki"} >
                    <Typography variant="body2">
                       Demande d'autorisation
                    </Typography>
                    <Typography variant="body2">
                    
                    </Typography>                 
                    <Grid container  direction="row" justifyContent="space-between" alignItems="center">
                        <Grid xs={4} item>
                            Appuyez sur l'image pour commencer
                        </Grid>
                        <Grid xs={8} item>
                            <a hre="#"  onClick={manageNukiAuth} >
                                <img src="/nuki_img.jpeg" alt="image" width="50" />
                            </a>
                        </Grid>
                    </Grid>
                  
                </MyForm>  
            }            

            <AdminNewLinky  openState={openNewLinky} currentStep={newLinkyStep} toClose={addLinkyCallBack} registredLinkys={linkyServices} linkyForUpdate={linkyForUpdate} />
            <AdminNuki  openState={openNewNuki} currentStep={newNukiStep} toClose={addNukiCallBack}  />
      </div>

  );
}

