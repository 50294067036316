// la couleur peut servir pour le titre sur l'axe des y ou la couleur par defaut d'une se
// la couleur de fond peut servir pour la courbe

const config = {
    probesType : {
      "TEMPERATURE" : {
      color: "orange",
      backgroundColor: "#ffd699",       
      unit: "°C" 
    },
    "HUMIDITY" : {
        color: "blue",
       backgroundColor: "#cacffc",
       unit: "%" 
    },
    "CO2" : {
      color: "orange",
      backgroundColor: "#cacffc",
      unit: "ppm" 
    },  
    "IAQ" : { // Indoor Air Quality / Qualité de l’Air Intérieur
      color: "blue",
      backgroundColor: "#9abbf9",
      unit: "VOC"  // Volatil Organic Compounds / (COV) Composés Organiques Volatiles
    },    
    "IZIAIR" : { // Indoor Air Quality / Qualité de l’Air Intérieur
      color: "blue",
      backgroundColor: "#9abbf9",
      unit: ""  // valeur 0: Excellent  1: Good 2: Fair  3: Poor 4: Bad  5…6: Reserved  7: Error
    },      
    "SOILELEC" : {
      color: "green",
      backgroundColor: "#c3f7c1",
      unit: "dS/m" 
    },
    "RADIATION" : {
      color: "#f5c542",
      backgroundColor: "#f5f1ae",
      unit: "W/m2" 
    },
    "COUNTER" : {
      color: "#",
      backgroundColor: "#",
      unit: "l"
    },           
    "WIND" : {
      color: "#",
      backgroundColor: "#",
      unit: "km/h"
    },           
    "RAIN" : {
      color: "black",
      backgroundColor: "#",
      unit: "mm"
    },           
    "LITRE" : {
      color: "black",
      backgroundColor: "#",
      unit: "l"
    },  
    "m3" : {
      color: "black",
      backgroundColor: "#",
      unit: "m3"
    },             
    "IO" : {
      color: "black",
      backgroundColor: "#",
      unit: "E/S"
    },
    "PRESSURE" : {
      color: "black",
      backgroundColor: "#",
      unit: "hPa"
    } ,
    "LEVEL" : {
      color: "black",
      backgroundColor: "#",
      unit: "m"
    },
    "ET0" : {
      color: "black",
      backgroundColor: "#",
      unit: "mm"
    },
    "STATE_IN" : {
      color: "green",
      backgroundColor: "#",
      unit: "O_1"
    },
    "STATE_OUT" : {
      color: "green",
      backgroundColor: "#",
      unit: "O_1"
    },    
    "STATE_OUT_IN" : {
      color: "red",
      backgroundColor: "#",
      unit: "0_1"
    },
    "WEIGHT" : {
      color: "blue",
      backgroundColor: "#",
      unit: "kg"
    },  
    "BATTERY" : {
      color: "orange",
      backgroundColor: "#",
      unit: "%"
    },    
    "POWER" : {
      color: "orange",
      backgroundColor: "#",
      unit: "W"
    },
    "VOLTAGE" : {
      color: "orange",
      backgroundColor: "#",
      unit: "V"
    },
    "ENERGY" : {
      color: "orange",
      backgroundColor: "#",
      unit: "kWh"
    },
    "ILLUMINATION" : {
      color: "orange",
      backgroundColor: "#",
      unit: "lux"
    },
    "PM1" : {
      color: "orange",
      backgroundColor: "#",
      unit: "mg/m3"
    },
    "PM25" : {
      color: "orange",
      backgroundColor: "#",
      unit: "mg/m3"
    },
    "PM10" : {
      color: "orange",
      backgroundColor: "#",
      unit: "mg/m3"
    },
    "NOISE" : {
      color: "orange",
      backgroundColor: "#",
      unit: "dBa"
    },
    "VOC" : {
      color: "orange",
      backgroundColor: "#",
      unit: "mg/m3"
    },
    "OCCUPANCY" : {
      color: "orange",
      backgroundColor: "#",
      unit: "%"
    },  
    "TIME" : {
      color: "orange",
      backgroundColor: "#",
      unit: "s"
    }, 
    "DATA" : {
      color: "orange",
      backgroundColor: "#",
      unit: "json"
    },                               
  }
}
export default config;
