import React , { useState, useContext, useEffect } from 'react';

import { useLocation, useHistory  } from "react-router-dom";
import queryString from 'query-string'

import { API } from "aws-amplify";

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import awsconfig from '../aws-exports';
import GeneralContext from '../components/GeneralContext'
import { askForRefreshingCustomer, toSnack, isEmptyList } from '../Common';

const { exe_env_name } = awsconfig;

const DURATION = "P6M";
var CLIENT_ID, CLIENT_SECRET, REDIRECT_URI, ENEDIS_ENDPOINT_AUTHORIZE, ENEDIS_ENDPOINT_TOKEN;

const hostname = window.location.hostname;
console.log("hostname:",hostname);

if (hostname.endsWith("ngrok.io") || hostname.endsWith("localhost")) {
    console.log("Execution en mode LOCAL");
    CLIENT_ID = "c5240b63-8c8c-42b3-892b-5133fc05f59d";
    CLIENT_SECRET = "c6e515ef-315b-4d3f-8df8-c5758713baa1";
    REDIRECT_URI = "https://9ce2-2a01-e0a-28d-73b0-b931-2288-9473-2d62.ngrok.io/withenedis";
    ENEDIS_ENDPOINT_AUTHORIZE = "https://gw.hml.api.enedis.fr";
    ENEDIS_ENDPOINT_TOKEN = "https://gw.hml.api.enedis.fr";


} else {
    if (exe_env_name && exe_env_name === 'dev') {
        console.log("Execution en DEV");
        CLIENT_ID = "960f873c-1088-4211-b647-d5b13bbf03d7";
        CLIENT_SECRET = "c50a29ca-1c13-4957-9604-9567b5506c55";
        REDIRECT_URI = "https://test.myiotplatform.com/withenedis";
        ENEDIS_ENDPOINT_AUTHORIZE = "https://gw.hml.api.enedis.fr";
        ENEDIS_ENDPOINT_TOKEN = "https://gw.hml.api.enedis.fr";
     
    } else if (exe_env_name && exe_env_name === 'prod') {
        console.log("Execution en PROD");
        CLIENT_ID = "fce6650e-acf0-49a8-9e08-21205c0f2ada"; 
        CLIENT_SECRET = "2d4e09c2-f48c-4676-bfbd-4d80bab54c13"; 
        REDIRECT_URI = "https://home.myiotplatform.com/withenedis";
        // a remplacer quand prod validée
        ENEDIS_ENDPOINT_AUTHORIZE = "https://mon-compte-particulier.enedis.fr";
        ENEDIS_ENDPOINT_TOKEN = "https://gw.prd.api.enedis.fr";    
    } 
}
const LINKY_API_AUTHORIZE = ENEDIS_ENDPOINT_AUTHORIZE+"/group/espace-particuliers/consentement-linky/oauth2/authorize";
const LINKY_API_AUTHORIZE_V1 = ENEDIS_ENDPOINT_AUTHORIZE+"/dataconnect/v1/oauth2/authorize";

const LINKY_API_TOKEN = ENEDIS_ENDPOINT_TOKEN+"/v1/oauth2/token";

const useStyles = makeStyles(theme => ({
    root: {
    },
    textNote: {
        fontStyle: "italic"
    }
}));

export default function AdminNewLinky(props) {

    const { openState, toClose, currentStep, linkyForUpdate, registredLinkys  } = props;
    const { user, customer } = useContext(GeneralContext);

    const pathname = useLocation().pathname;
    const { search } = useLocation();
    let history = useHistory();
    const query = queryString.parse(search)


    const { t } = useTranslation();

    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [messageToDisplay, setMessageToDisplay] = useState("");
    const [ legalOk, setLegalOk ] = useState(false);
    const [ enedisConfig, setEnedisConfig ] = useState({});

    const [ deviceInfo, setDeviceInfo ] = useState();
    const [ serviceName, setServiceName ] = useState("Mon compteur Linky");

    const [ linkyToUpdate, setLinkyToUpdate ] = useState();
    
    const [ linkyCounterId, setLinkyCounterId ] = useState(); // laj suite au retour du get Enedis

    const classes = useStyles();

    useEffect(() => {
        console.log("currentStep:",currentStep);
        if (currentStep && currentStep === "fromEnedisGet") {
            setStep(3)
        } else {
            setStep(0)
        }
    },[currentStep]);

    
    useEffect(() => {
        console.log("linky to update from parent:",linkyForUpdate);
    },[linkyForUpdate]);   

    useEffect(() => {
        console.log("services linky existant et numero de compteur connu :",registredLinkys, linkyCounterId);
        // registredLinkys arrive qaund le cutomer est loaded
       //  linkyCounterId arrive quand on reçoit le get d'Enedi
        if (linkyCounterId && registredLinkys) {
            const activatedLinky = registredLinkys.find( item => {
                return item.device.id === linkyCounterId
            }); 
            if (activatedLinky) {
                // si le compteur renvoyé par enedis correspond à un des compteurs déja enregistré
                // sur la platform, on en déduit que c'est une mise a jour du service
                // et non une création d'un attachement                
                console.log("Le linky %s est déja activé. Il s'agit d'un update de service")
                setLinkyToUpdate({
                    devEui: linkyCounterId,
                    serviceId: activatedLinky.id,
                    serviceName: activatedLinky.name
                })
            } else {
                console.log("Bizare : le compteur Linky n'est pas trouvé dans services les linky existant")
            }
        }
    },[registredLinkys, linkyCounterId]);   

    useEffect(() => {
        console.log("step:",step);
        async function postProcessing() {
            console.log("Appel du POST Enedis (avec les params issues du GET) ...");
            let usage_point_id = query.usage_point_id;  
            setLinkyCounterId(usage_point_id);
            let code = query.code;  

            console.log("code:",code);
            console.log("usage_point_id:",usage_point_id);

            const postData = 'grant_type=authorization_code&client_id='+CLIENT_ID+'&client_secret='+CLIENT_SECRET+'&code='+code

            const url = LINKY_API_TOKEN+`?redirect_uri=${ REDIRECT_URI }`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: postData
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();
            if (data.error) {
                console.log("Erreur from POST Enedis : ", data)
                setMessageToDisplay("Erreur lors de l'appel à Enedis : "+data.error_description)

            } else {
                setStep(4);
                console.log("Response positive du POST Enedis:",data);
                if (data && data.refresh_token) {

                    const expiresAt = new Date(parseInt(data.expires_in, 10) * 1000 + parseInt(data.issued_at, 10));

                    setEnedisConfig({ 
                        usage_point_id: usage_point_id,
                        refresh_token: data.refresh_token, 
                        refresh_token_issued_at: data.refresh_token_issued_at, //peut-être pertinent ?
                        access_token: data.access_token,
                        access_token_expires_at: expiresAt.getTime().toString()
                    } );
                }
 
            }
        }

        if (step===3) {
            // Arrivée depuis le GET d'enedis avec leCode
            // appel du Post Enedis avec le Code
            // pour avoir les tokens            
            let currentState = query.state;  
            //TODO reprendre le constrole de state
            const originaltSessionState = sessionStorage.getItem("sessionState");
            if (currentState !== originaltSessionState) {
                console.log("Pb de secu:", currentState, originaltSessionState);
                //throw("Error");
            } else {
               
            }
            postProcessing(); // appel du post Enedis

        } else if (step===4) {
            // retour du post (avec ou sans token)

        }
    },[step]);


    async function toCloseLocal(data) {
        console.log("local closed en phase : ", step, data);
        if ( step >= 1 && deviceInfo) {
            if ( !data |  !data.action | data.action !== "attached" ) {
                // on est en phase 1 : visualisation du détail du capteur
                // ou phase 2 : case a cocher
                // deviceInfo existe : en retour de l'api call GET /iotAttachDevice
                // il faut aussi que l'attachement n'est pas reussi (<>attached)
                // alors si deviceInfo.dataSource === CLONE, il  s'agit d'un device de demo 
                // --> effacement du deveui
                if (deviceInfo.dataSource && deviceInfo.dataSource==='CLONE') {
                    console.log("Effacement....");
                   //const response = await deleteDeveuiRest(deviceInfo.id);
                   //console.log(response);
                }
            }
        }
        toClose(data);
    }

    const processActivation = async () => {

        var probesDetail;
 
        console.log("Appel de WS")
        setLoading(true);
        try {
            const response = await associateDeviceStationRest({ name : serviceName, probes: probesDetail, config: enedisConfig});
            console.log("associateDeviceStationRest:",response)
            if (response) {
                if (response.success) {
                    //toSnack("DeviceLink", t('screen.scan-device.well-link'));
                    toSnack("DeviceLink", response.message); // TODO pour la traduction, gérer un code d'erreur dans l'api et retrouver le code dans la traduction
                    askForRefreshingCustomer(user.ownCustomer.id);
                    toCloseLocal({action:"attached"});
                    history.push("/services/all");
                } else {
                    //response.error
                    toSnack("DeviceLink", response.message);
                    toCloseLocal({action:"error"});
                    history.push("/adminCustomer");
                }
            } else {
                toSnack("DeviceLink", t('screen.scan-device.error-whilelink'));
                toCloseLocal({action:"error"})
                history.push("/adminCustomer");
            }
        }
        catch (error) {
            toSnack("DeviceLink", t('screen.scan-device.error-whilelink')+error);
            toCloseLocal({action:"error"})
            history.push("/adminCustomer");
        }
        setLoading(false);
        setStep(0);
    }; 

/*     const processUpdateServiceTest = async () => {
        const enedisConfig = {
            usage_point_id:"22516914714270",
            refresh_token:  "ahHgI5Imf0eu8ZJRxYDnB0FHv7OfDXNmaRMJbB6xraKnxa_test",
            refresh_token_issued_at: "1664443750000",
            access_token: "zNB8VzBIKcK2U3NUzm7AELhrSpM47uYVwZS8CHB6fNEDy2wgoeVtSl_test",
            access_token_expires_at: "1664443750000"
        }
        console.log("Appel de WS", existingLinky.devEui, existingLinky.serviceId, enedisConfig)
        const response = await updateLinkyServiceRest(existingLinky.devEui, existingLinky.serviceId, enedisConfig);
        console.log("response de WS",response)
    }
 */
    const processUpdateService = async () => {

        console.log("Appel de WS")
        setLoading(true);
        try {
            const response = await updateLinkyServiceRest(linkyToUpdate.devEui, linkyToUpdate.serviceId, enedisConfig);
            console.log("updateLinkyServiceRest:",response)
            if (response) {
                if (response.success) {
                    toSnack("DeviceLink", response.message); // TODO pour la traduction, gérer un code d'erreur dans l'api et retrouver le code dans la traduction
                    askForRefreshingCustomer(user.ownCustomer.id);
                    toCloseLocal({action:"attached"});
                    history.push("/services/all");
                } else {
                    //response.error
                    toSnack("DeviceLink", response.message);
                    toCloseLocal({action:"error"});
                    history.push("/adminCustomer");
                }
            } else {
                toSnack("DeviceLink", t('screen.scan-device.error-whilelink')); //TODO remplacer attachement par mise à jour
                toCloseLocal({action:"error"})
                history.push("/adminCustomer");
            }
        }
        catch (error) {
            toSnack("DeviceLink", t('screen.scan-device.error-whilelink')+error); //TODO remplacer attachement par mise à jour
            toCloseLocal({action:"error"})
            history.push("/adminCustomer");
        }
        setLoading(false);
        setStep(0);
    }

    async function associateDeviceStationRest(service) {
        console.log("Service activation  : ", service);

        let myInit = {
            body: {
                name: service.name, // le nom du capteur indiqué par l'utilisateur
                forService: "LINKY"
            }
        }    

        if(service.probes) {
            myInit.body['probes'] = service.probes; // nom des sondes dans la langue actuelle de l'utilisateur
        }

        if (service.config) {
            myInit.body['usage_point_id'] = service.config.usage_point_id;
            myInit.body['refresh_token'] = service.config.refresh_token;
            myInit.body['refresh_token_issued_at'] = service.config.refresh_token_issued_at;
            myInit.body['access_token'] = service.config.access_token;
            myInit.body['access_token_expires_at'] = service.config.access_token_expires_at;
        }

        if (customer.id) {
            myInit.body['customerId'] = customer.id; 
        }
        
        console.log("Activation- appel REST : ",myInit)
        var response;
        try {
            response = await API.post("iotAttachDevice", "/devices/" + service.deveui, myInit);

        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
        return response;
    }

    async function updateLinkyServiceRest(deveui, serviceId, enedisConfig) {
        console.log("Service update with config : ", deveui, serviceId, enedisConfig);

        if (!deveui || !serviceId || !enedisConfig) {
            console.log("Parametre manquant pour appel du service Rest")
            throw "missing parameters for api call";
        }
  
        const newConfig = {
            usage_point_id:enedisConfig.usage_point_id,
            refresh_token:  enedisConfig.refresh_token,
            refresh_token_issued_at: enedisConfig.refresh_token_issued_at,
            access_token: enedisConfig.access_token,
            access_token_expires_at: enedisConfig.access_token_expires_at
        }

        let myInit = {
            body: newConfig
        }  
        
        console.log("update- appel REST : ",myInit)
        var response;
        try {
            response = await API.put("iotAttachDevice", "/devices/" + deveui+"/"+serviceId, myInit);
        }
        catch (error) {
            console.log(error)
            throw error;
        }
        return response;
    }

    const askForEnedisConsent = () => {

        console.log("Etape 1 - Envoi du GET vers Enedis");
        setStep(1); // avant envoi du GET à Enedis
        let testClientId = query.testClientId; 

        const newSessionState = (Math.random() + 1).toString(36).substring(7);
        var sessionState;
        if (testClientId) {
            sessionState = newSessionState + testClientId;
        } else {
            sessionState = newSessionState+'0';
        }
        sessionStorage.setItem("sessionState", sessionState);

        const redirectUrl =
            LINKY_API_AUTHORIZE_V1 +
            '?' +
            `client_id=${CLIENT_ID}` +
            `&state=${sessionState}` +
            `&duration=${DURATION}` + // duration est la durée du consentement que vous souhaitez obtenir : cette durée est à renseigner au format ISO 8601 (exemple : « P6M » pour une durée de 6 mois),
            '&response_type=code' +
            `&redirect_uri=${REDIRECT_URI}`;

        console.log('sessionState: ' + sessionState);
        console.log('Redirect URL : ' + redirectUrl);

        // demande d'autorisation à Enedis
        window.location.href=redirectUrl;
        setStep(2); // en attente réponse GET
        console.log("Etape 2 - en attente de réponse du GET ");
    }


    function onEnteredDialog() {
        console.log("onEntered Dialog");
        //setDeveuiField("020000ffff00b923");
        //setDeveuiField("");
        setMessageToDisplay("");
        setLegalOk(false)
        //setGeolocOk(false)
        //setStep(0)
    }

    function onExitDialog() {
        console.log("Exit Dialog");
        setStep(0)
    }

    return (
        <Dialog open={openState} onClose={toCloseLocal} aria-labelledby="form-dialog-title" TransitionProps={{onEntered: onEnteredDialog, onExit: onExitDialog }}  >
        <DialogTitle id="form-dialog-title" classes={{ root: classes.root}} > 
            Associer un compteur Linky à myIOTplatform
        </DialogTitle>
        <DialogContent>
        {!user.ownCustomer &&
            <div>
               
            </div>
        }
        {user.ownCustomer &&
        <>
            <div className={classes.alignItemsAndJustifyContent}>
                {loading && <CircularProgress size={40} />}
            </div>
            {step===0 &&
                <div>
                    <Grid container  direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid xs={9} item>
                        Pour intégrer vos données de consommation électrique issue de Enedis dans myIOTplatform, 
                        vous devez au préalable autoriser myIOTplatform à appeler, en votre nom, les api Enedis.
                        </Grid>
                        <Grid xs={3} item>
                            <img src="/enedis.png" alt="image" width="90" />
                        </Grid>
                    </Grid>
                    {linkyForUpdate &&
                        <div>
                            <hr />
                            Attention, si vous avez plusieurs compteurs, vous devez faire l'association avec le compteur possédant le numéro (PDL) : {linkyForUpdate.devEui}
                            <hr />
                        </div>  
                    }
                    <Grid container  direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid xs={5} item>
                            <a hre="#"  onClick={askForEnedisConsent} >
                                <img src="/vert-enedis.png" alt="image" width="200" />
                            </a>
                        </Grid>
                        <Grid xs={7} item>
                            En cliquant sur ce bouton, vous allez accéder à votre compte personnel sur le site Enedis 
                            où vous pourrez donner votre accord pour qu’Enedis nous transmette vos données.                        
                        </Grid>                        
                    </Grid>   
                    <br />                                  
                    <div className={classes.textNote}>
                        Attention, pour donner votre autorisation, vous devez posséder un compte personnel Enedis. 
                        Ce compte vous permet également de suivre et gérer vos données de consommation d’électricité. 
                        Munissez-vous de votre facture d’électricité pour créer votre espace si vous n'avez pas encore de compte personnel Enedis.            
                    </div>
                    <br />
                    <div>
                        <div>
                            Nous ne stockons ni n'aurons accès à aucune autre information que votre numéro de compteur et votre consommation électrique Linky.
                            Vous pourrez à tout moment résilier cette autorisation directement chez Enedis.

                        </div>
                        <div></div>
                    </div>
                    <br/>
                    <div>
                        <div>
                            Nous appellerons Enedis 1 fois par jour pour récupérer vos données de la veille et les intégrer à la plateforme.
                        </div>
                    </div> 
              
                </div>
            }
            {step===3 &&
                <div>
                    Retour de l'appel au service d'Enedis : 
                </div>
            }
            {step===4 &&
                <div>
                    <div>
                        Félicitations, la demande d'autorisation a été acceptée.
                    </div>
                    <br/>
                    {linkyToUpdate &&
                        <div>
                            Lancement de la maj du capteur : {linkyToUpdate.serviceName}
                        </div>
                    }

                    {!linkyToUpdate &&
                    <div>
                        <div>
                            Vous allez maintenant créer un capteur virtuel qui recueillera les données de votre compteur
                        </div>
                        <br/>
                        <Divider />
                        <TextField 
                            value={serviceName}
                            className={classes.textFieldBig}
                            onInput={ e=>setServiceName(e.target.value)}                    
                            id="sensorName" 
                            label={t('screen.scan-device.device-name')} />

                        <Card className={classes.checkbox}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={legalOk} 
                                        onChange={(event)=>setLegalOk(event.target.checked)} 
                                        color="primary" 
                                        name="checkedB" />}
                                label={t('screen.scan-device.accept-conditions')}
                                />                        
                        </Card>
                    </div>
                    } 
               </div>
            } 
            <div>
                {messageToDisplay}
            </div>           
        </>
        }        
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>toCloseLocal({action:"cancel"})} color="primary">
                {t('global.button.cancel')}
            </Button>
            {step===1 && deviceInfo &&
                <Button  color="primary">
                    {t('global.button.continue')}
                </Button>
            }
            {step===4 && legalOk &&
                <Button onClick={processActivation} color="primary">
                    {t('global.button.activate')}
                </Button>
            }
            {step===4 && linkyToUpdate &&
                <Button onClick={processUpdateService} color="primary">
                    Mise à jour du compteur
                </Button>
            }                      
        </DialogActions>
    </Dialog>
    )
}