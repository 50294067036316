import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays, addDays, startOfDay, endOfDay } from 'date-fns'
import fr from "date-fns/locale/fr"; // the locale you want
import Box from '@material-ui/core/Box';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { getPeriodUntilNow, getPeriodFromStart, toSnack } from '../Common';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  rangeButton: {
      color: "white",
      backgroundColor: "#3f51b5",
      margin: "2px"
  },
  inputDate: {
      width: '85px'
  },
  daysCount: {
    marginLeft: "5px"
  }
}));



export default function MyDateRangePicker(props) {

    const { onChange: toParent, defaultRange} = props;

    const [startDate, setStartDate] = useState(startOfDay(new Date()));
    const [endDate, setEndDate] = useState();

    const [daysCount, setDaysCount] = useState();

    const [currentRangeType, setCurrentRangeType] = useState();

    const handleRangeType = (event, newRangeType) => {
        setCurrentRangeType(newRangeType);
    };

    const { t } = useTranslation();
    registerLocale("fr", fr); 

    const classes = useStyles();
    const today = new Date().toISOString();

    useEffect(() => {
        // le range type est obligatoire, le default Start optionnel
        if (defaultRange && defaultRange.type) {
            console.log("new rangeType:",defaultRange.type);
            setCurrentRangeType(defaultRange.type);

            var range;
            if (defaultRange.start) {
                // en plus du range , on a un start
                range = getPeriodFromStart(defaultRange.type, defaultRange.start);

            } else {
                range = getPeriodUntilNow(defaultRange.type);
            }
            console.log("new range with start:%s and type:%s ",defaultRange.start, defaultRange.type);
            console.log("--> range:",range);
            setStartDate(new Date(range.start));
            setEndDate(new Date(range.end));  
        }
        
    }, [defaultRange]);

    useEffect(() => {
        if (startDate && endDate) {
            if (endDate >= startDate) {
                console.log("date range not null or changed ",currentRangeType);
                console.log("start ",startDate);
                //const startDay = subDays(startDate, 1)
                //console.log("startDay ",startDay);
                console.log("end ", endDate);
                setDaysCount(moment(endDate).diff(moment(startDate), 'days')+1);
                toParent(currentRangeType, startDate,endDate);
            } else {
                console.log("periode impossible", startDate, endDate);
                toSnack("MyDateRangePicker","Attention, les dates pour la période ne sont pas cohérentes")
            }
        }

    }, [startDate, endDate]);

    function setPeriod(rangeType) {
        setCurrentRangeType(rangeType);
        const range = getPeriodUntilNow(rangeType);
        setStartDate(new Date(range.start));
        setEndDate(new Date(range.end));
    }
  

    function newPeriod(period) {
        console.log("Period:",period)
        var start, end;
        switch (period) {
            case 'today':              
                setPeriod('today')
                break;

            case 'yesterday':
                setPeriod('yesterday')   
                break;
          
            case 'last7days': 
                setPeriod('last7days')          
                break;
          
            case 'last30days':
                 setPeriod('last30days')               
               break;
              
            // case 'custom':
            //     setPeriod('custom',[start, end])
            //     break;   

            default:

        }
    }

    return (
        <div>
            <Container maxWidth="xl">
            <div></div>
            <div className={classes.root}> 

            <ToggleButtonGroup
                value={currentRangeType}
                exclusive
                onChange={handleRangeType}
                aria-label="current Range Type"
                >
                <ToggleButton value="last30days" aria-label="last30days"  onClick={()=> newPeriod("last30days")} className={classes.rangeButton} >
                    {t('screen.graph.button.j-30')}
                </ToggleButton>                    
                <ToggleButton value="last7days" aria-label="last7days"  onClick={()=> newPeriod("last7days")} className={classes.rangeButton} >
                    {t('screen.graph.button.j-7')}
                </ToggleButton>
                <ToggleButton value="yesterday" aria-label="yesterday" onClick={()=> newPeriod("yesterday")} className={classes.rangeButton} >
                    {t('screen.graph.button.j-1')}                  
                </ToggleButton>
                <ToggleButton value="today" aria-label="today" onClick={()=> newPeriod("today")}  className={classes.rangeButton} >
                    {t('screen.graph.button.today')}
                </ToggleButton>
            </ToggleButtonGroup>

            <div>  
                <Box display="flex" alignItems="center" >
                    <Box alignSelf="flex-start">
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(startOfDay(date))}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            minDate={subDays(new Date(), 90)}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Début de période"
                            className={classes.inputDate}
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(endOfDay(date))}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={addDays(new Date(), 1)}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Fin de période"
                            className={classes.inputDate}
                        /> 
                    </Box>   
                    {daysCount>1 &&
                        <Box className={classes.daysCount} >
                            ({daysCount} jours)
                        </Box>  
                    }
                </Box>            
                </div>
            </div>   
            </Container>             
      </div>

  );
}

