import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";

import GraphQlApi from '../mobile/MyGraphql';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import ProbeIcon from '@material-ui/icons/FilterTiltShift';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import EditIcon from '@material-ui/icons/Edit';

import { toSnack } from '../Common';

import ServiceProbeAlertEdit from './ServiceProbeAlertEdit';


const useStyles = makeStyles(theme => ({
    root: {
        margin: "20px"
    },
    paragraph: {
        margin: 20,
        padding: 5
    },    
    card: {
        maxWidth: "800px"
    },
    loading: {
        marginTop: 50
    },    
    fab: {
        position: 'absolute',
        top: 70,
        //bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    noAlert: {
        marginTop: 30
    }
}));

export default function ServiceProbeAlerts(props) {
    const { data } = props;

    let { id: serviceId, probe: probeId } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [service, setService] = useState({});
    //const [probeTmplt, setProbeTmplt] = useState(); // template.probesDisplay
    const [probeSvc, setProbeSvc] = useState({});

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMode, setAlertMode] = useState();
    const [alert, setAlert] = useState();
    
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log("data:",data)
    }, [data]);

    // recuperation du service concerné
    useEffect(() => {
        let ignore = false;
        async function fetchData(serviceId) {
            console.log("Service is loading for %s ...", serviceId);
            setLoading(true);
            const getServiceQuery = `query GetService($id: ID!) {
                getService(id: $id) {
                    id
                    name
                    serviceType
                    probesConfig    
                    device {
                        id
                        manufacturer
                        status
                        template {
                            id
                            probesDisplay
                            probesStruct
                        }         
                    }
                    customer {
                        id
                        name
                    }
                    alerts {
                        items {
                            id
                            name
                            status
                            probeID
                            probeName
                            probeUnit
                            threshold
                            condition
                            action
                            triggerType
                        }
                    }                  
                }
              }`;
            //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
            const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery, { id: serviceId });
            setLoading(false);
            if (result.data.getService) {
                const theService = result.data.getService
                console.log("service :", theService);
                setService(theService)       
                // c'est le template.probesDisplay. A priori le template.probesStruct n'est pas visible/accessible par l'admin ?
                setProbeSvc(buildCurrentProbe(theService.device.template.probesDisplay,theService.probesConfig, probeId, theService.alerts))
            } else {
                console.log("Service non trouvé en base");
            }
        }      
        fetchData(serviceId);
        return () => { ignore = true; }

    }, [serviceId]);     


    const buildCurrentProbe = ((templateProbesRaw, serviceProbesRaw, probeId, allAlerts) => {

        // c'est le template.probesDisplay. A priori le template.probesStruct n'est pas visible/accessible par l'admin ?
        const templateProbes = JSON.parse(templateProbesRaw);
        const probeTmplt = templateProbes[probeId];

        const serviceProbes = JSON.parse(serviceProbesRaw);
        var probeSvc   = serviceProbes[probeId];


        probeSvc["id"] = probeId;
        probeSvc["type"] = probeTmplt.type;
        probeSvc["unit"] = probeTmplt.unit;

        // a la cible, le nom de la sonde est créé dans le service, dans la langue de l'utilisateur, à l'activation du capteur
        // si ce n'est pas le cas ?
        if (!probeSvc.name) {
            probeSvc['name'] = probeTmplt.name // à la cible, il n'y a plus de name dans probesDisplay
        } else {
            probeSvc['name'] = t("sensor.probe-type."+probeTmplt.type)
        }

        // ax+b (valeur sous la responsabilité de l'admin du capteur) ça ecrase le ax+b du template.probesStruct

        // graph min/max

        //Si type ONOFF : stateOn:0

        // si type=eto ...

        // si alerte
        if (allAlerts ) {
            // extrait dans les alertes de cette probe (plusiers possible pour un user ex min, max)
            probeSvc['alerts'] = allAlerts.items.filter( alarm => { return alarm.probeID === probeId })
        } 

        console.log("probeSvc:",probeSvc)
        return probeSvc;
    })
 
    const handlePropertySave =  (async (property) => {
        //console.log(key, probe)
        console.log("update %s dans : ",property)
        try {
         setLoading(true);
         //await updateProbeProperty(probesInput[key], property);
         toSnack("ServiceUpdated",t('screen.probes-admin.well-updated'));
        }
        catch (error) {
         toSnack("ServiceUpdated",t('screen.probes-admin.update-error'));
        }
        setLoading(false);
    })

    const handleAlert =  (async (mode, alertObj) => {
        console.log("Handle alert: ", mode)

        // on ajoute le service et la sonde concerné par l'alerte
        alertObj["serviceID"] = serviceId;
        alertObj["serviceName"] = service.name;
        alertObj["customerId"] = service.customer.id;
        alertObj["probeID"] = probeId;
        alertObj["unit"] = probeSvc.unit;
        alertObj["probeName"] = probeSvc.name;
        alertObj["customerName"] = service.customer.name;


        if (mode==='new') {
            setAlertMode(mode)
            setAlert(alertObj)
            setOpenAlert(true);            
        } else {
            setAlertMode(mode)
            setAlert(alertObj);
            setOpenAlert(true);
        }
    })
    
    const classes = useStyles();
    return (
        <div className={classes.root}>
                <div >
                    {loading &&
                        <div  className={classes.loading}>
                            <CircularProgress size={40} />
                        </div>
                    }
                </div>        

                <div>
                    <ProbeIcon /> 
                    Gestion des alertes sur la sonde {probeSvc.name}</div>
                <div>du capteur {service.name}</div>

                {probeSvc.alerts?.map( (alert, key) => 
                    <div  key={key}>
                        <Grid container   direction="row" justifyContent="space-between" alignItems="center">
                            
                            <Grid item xs={1}>
                                <div onClick={()=>{handleAlert('edit', alert)}}> 
                                    <EditIcon/>   
                                </div>                                
                                                            
                            </Grid>
                            <Grid item xs={5}>
                                <div onClick={()=>{handleAlert('edit', alert)}}> 
                                    {alert.name}
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                {alert.status==='inactive' &&  
                                    <NotificationsOffIcon fontSize='small'/>
                                }
                                {alert.status==='active' &&  
                                    <NotificationsNoneIcon fontSize='small'/>
                                }
                                {alert.status==='triggered' &&  
                                    <NotificationsActiveIcon     fontSize='small'/>
                                }                                 
                            </Grid>
                            <Grid item xs={4}>Si val {alert.condition} {alert.threshold} {alert.probeUnit}</Grid>
                            <Grid item xs={1}>{alert.action}</Grid>
                        </Grid>
                    </div>
                )}
                {probeSvc.alerts?.length===0 &&
                    <div className={classes.noAlert} >Pas encore d'alerte (bouton (+) pour en créer une)</div>
                }
            <Fab 
                onClick={()=>{handleAlert('new', {})}}
                color="primary" 
                aria-label="Add" 
                className={classes.fab}>
                    <AddIcon />
            </Fab>  
            <ServiceProbeAlertEdit isVisible={openAlert} alert={alert} title="new" mode={alertMode} onClose={()=> setOpenAlert(false)} />              
        </div>
  );
}

