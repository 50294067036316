import React, { useState, useEffect } from 'react'
import { Analytics } from "aws-amplify";

import { useHistory } from "react-router-dom";

import { Hub } from '@aws-amplify/core';

import GraphQlApi from './mobile/MyGraphql';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';



import MyAppNew from './MyAppNew'

/**
 * Chargement du user puis du customer sur reception du userCognito
 * Sur reception d'un hubEvent : rechergement du user ou du customer
 * MAJ du context avec le user et le customer
 * 
 */

 const useStyles = makeStyles({
    loading: {
        marginTop: 90,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'  
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    errorMessage: {
        marginTop: 40,
        color: "red"
    }
})


export default function GlobalContextProcessing({ portalCfg, fromMobile, userName, userRights, customerId: customerIdFromToken, pageToGo}) {

    // issue de propriétés générales
 //   const { setUser, setCustomer, customer } = useContext(GeneralContext);

    const [errorMessage, setErrorMessage] = useState();

    const [appUser, setAppUser] = useState();
    const [appCustomer, setAppCustomer] = useState();

    const [loading, setLoading] = useState(false);

    let history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        console.log("CtxLoad-Init- Init subscriptions to Listen events")
        Hub.listen('toRefreshUser', handleEventUpdateUser)
        Hub.listen('toRefreshCustomer', handleEventRefreshCustomer)

        return function cleanup() {
            console.log(`CtxLoad-cleanup- GlobalContextProcessing unmounting subscriptions to listen events`)
            console.log(`CtxLoad-cleanup- User useEffect() cleanup.`)
            Hub.remove('toRefreshUser', handleEventUpdateUser)
            Hub.remove('toRefreshCustomer', handleEventRefreshCustomer)
        };
    },[]);

/*     useEffect(() => {
        if (customerIdFromToken) {
            console.log("TAP1 Setting customerId from customerIdFromToken:", customerIdFromToken); 
            return function cleanup() {
                console.log("TAP1 Cleaning customerIdFromToken")
            }
        }
    },[customerIdFromToken]);  */

    useEffect(() => { 
        async function processAsync(userName,  customerIdFromToken) {
            setLoading(true);
            console.log("CtxLoad- Chargement du user for %s (cust: %s) ", userName, customerIdFromToken);
            const user = await userFetching(userName, customerIdFromToken);
            console.log("User loaded from db :",user);
            if (user) {
                if (userName !== user.email) {
                    //TODO si le mail est different en cognito et la bd, maj de la bd
                    console.log("Attention email cognito (userName) et celui en base sont differents : ", userName, user.email);
                }
                user["isNew"]  = false; 
                setAppUser(user);
                
            } else {
                console.log("Attention: User non trouvé en base pour : %s (Le creer ?)", userName);
                // le user est peut-être maintenant systematiquement crée à l'enregistrement ?
                //console.log("User non trouvé en base  - Creation");
                //user = await userCreation(userName);
                //user["isNew"]  = true;                
            }
            //TODO si pas de customerId et que le user en contient un, on get ce customer
            // ca ou le customer n'est pas encore dans le token
            setLoading(false);
        }
        if (userName) processAsync(userName,customerIdFromToken);
    },[userName]);

    useEffect(() => { 
        if (customerIdFromToken) { 
            console.log("Processing customerIdFromToken with %s ...", customerIdFromToken);
            loadingCustomerFromDb(customerIdFromToken);
        } 
    },[customerIdFromToken]);

    useEffect(() => { 
        if (appUser && !customerIdFromToken) {
            console.log("TAP1 user formdb :%s, customerIdFromToken:%s",appUser.id, customerIdFromToken,);

            console.log("User Loaded but no customerIdFromToken");
            if (appUser.ownCustomer) {
                console.log("On prends le user.ownCustomer.id");
                loadingCustomerFromDb(appUser.ownCustomer.id);

            }  else {
                console.log("Attention pas de possibilité de trouver un customerId !");
            }
        }
    },[customerIdFromToken, appUser]);


    async function loadingCustomerFromDb(customerId) {
        setLoading(true);
        console.log("CtxLoad-Chargement du customer for ", customerId);
        const customer = await customerFetching(customerId);
        if (customer) { 
            setAppCustomer(customer);
            
        } else {
            console.log("Attention: customer non trouvé en base pour : %s ", customerId);
        }
        setLoading(false);
    }


    useEffect(() => {
        if (appCustomer) {
            console.log("Customer loaded>Loading:false", appCustomer); 
            // if (appCustomer.name) {
            //     console.log("Analytics.record");
            //     Analytics.record({ "name": "customer-loaded", "attributes": {  "name": +appCustomer.name}}).then( (evt) => {
            //        console.log("Event Submitted" + JSON.stringify(evt));
            //     });
            // }  
            setLoading(false)
        }
        return function cleanup() {
          console.log("Cleanup Customer loading:true",appCustomer)
          setLoading(true)
        }
    },[appCustomer]); 

    useEffect(() => { 
        if (userRights && appCustomer) {
            console.log("Updatting userRights");
            userRights.isCustAdmin? appCustomer["isUserAdmin"] = true : appCustomer["isUserAdmin"] = false;
            userRights.isCustOwner? appCustomer["isUserOwner"] = true : appCustomer["isUserOwner"] = false;
        }
    },[userRights, appCustomer]);

    useEffect(() => {
        if (appCustomer && appCustomer.id===customerIdFromToken && history) {
            if (pageToGo) {
                console.log("PAGETOGO from switch customer event : ",pageToGo, customerIdFromToken, appCustomer.id, history.location.pathname);
                if (pageToGo==='fromCustomerSwitchWithoutLink') {
                    // il n'y avait pas de pageToGo au moment du changement customer
                    // on regarde si il y une homepage custom pour ce customer
                    if (appCustomer.homePage) { 
                        console.log("Ce customer posséde une page custom")
                        history.replace({ pathname: '/custom', state:{isActive: true}});
                    } else {
                        console.log("Ce customer ne posséde pas de page custom. Redirection : vers /services/all")
                        history.replace({ pathname: '/services/all', state:{isActive: true}});
                        //history.replace({ pathname: '/services/all', search: '?query=abc', state:{isActive: true}});
                    }
                } else  {
                    // on redirige vers la page souhaitée au moment du changement de cusotmer  (ex HomePage+Consulter vos capteurs)
                    console.log("Redirection : vers la page > ", pageToGo)
                    history.replace({ pathname: pageToGo, state:{isActive: true}});
                }                
            } else {
                console.log("PAGETOGO from direct url (%s) for customer : %s: ", history.location.pathname, appCustomer.name);
                // a priori, il ne s'agit pas d'un switch de customer
                // on ne change pas le routage
                // on est dans une saisie directe d'url ou un refresh
                console.log("Pas de changement du path initial")                
            }
        }
    },[customerIdFromToken, appCustomer, history, pageToGo]);

    // useEffect(() => {
    //     console.log("PAGETOGO from switch customer event : ",pageToGo);
    // },[pageToGo]);

    async function handleEventUpdateUser(data) {
        const newUserId = data.payload.event.userId;
        const customerId = data.payload.event.customerId;
        console.log("CtxLoad- EVENT REFRESHING USER for ...", newUserId, customerId);
        const user = await userFetching(newUserId, customerId);
        setAppUser(user);
    }

    async function handleEventRefreshCustomer(data) {
        const newCustomerId = data.payload.event.customerId;
        // lancement du rechargement du customer
        console.log("CtxLoad-Event REFRESHING customer for ...",newCustomerId);
        const customer = await customerFetching(newCustomerId);
        setAppCustomer(customer);
        console.log("CtxLoad-Event Customer is ready");

    }



    async function userCreation(cognitoUser) {
        var user;

        const toCreate = {
            id: cognitoUser.username,
        };
        if (cognitoUser.attributes) {
            if (cognitoUser.attributes.email) {
                toCreate['email'] = cognitoUser.attributes.email;
            }
            if (cognitoUser.attributes.phone_number) {
                toCreate['phone'] = cognitoUser.attributes.phone_number;
            }
        }
        console.log("CtxLoad- user to create:",toCreate);

        const mutation = `mutation CreateUser($input: CreateUserInput!) {
            createUser(input: $input) {
                id
                email
                phone
            }
        }`
        //const result = await API.graphql(graphqlOperation(mutation, { input: toCreate }));
        const result = await GraphQlApi.getInstance().graphqlOperation( mutation, { input: toCreate });

        if (result && result.data && result.data.createUser) {
            user = result.data.createUser
            user["isNew"]  = true;
        } else {
            console.log("CtxLoad- Impossible de créer le user en base")
        }
        return user;
    }

    async function userFetching(userId, currentCustomerId) {
        var user
        const getUserQueryWithCust = `query GetUser($id: ID!, $custId: ID!) {
            getUser(id: $id) {
              id
              email
              phone
              langue
              country
              groups
              isBetaTester
              ownCustomer {
                id 
                name
                devicesCounter            
              }
              customers {
                id
                customer {
                  id
                  name
                }
                userRole
              }
              retailer {
                id
              }               
              favorites(
                  limit:10,
                    filter : { 
                        customerID: { eq: $custId}
                    }
                ) {
                items {
                    id
                    service {
                        id
                    }
                }
              }                  
            }
          }`; 
          const getUserQuery = `query GetUser($id: ID!) {
            getUser(id: $id) {
              id
              email
              phone
              groups
              isBetaTester
              ownCustomer {
                id  
                devicesCounter           
              }
              customers {
                id
                customer {
                  id
                  name
                }
                userRole
              }
              retailer {
                  id
              }                 
            }
          }`;               
        var result;
        try {
            //console.log("currentCustId:",currentCustId)
            if (currentCustomerId) { // issue du token
                //result = await API.graphql(graphqlOperation(getUserQueryWithCust, { id: userId, custId: currentCustId }));
                result = await GraphQlApi.getInstance().graphqlOperation( getUserQueryWithCust,  { id: userId, custId: currentCustomerId });

            } else {
                // pas encore de customer créé
                // l'utilisateur vient de se creer, il pas pas crée ou rejoint de customer
                // pb pour les favoris
                //result = await API.graphql(graphqlOperation(getUserQuery, { id: userId}));
                result = await GraphQlApi.getInstance().graphqlOperation( getUserQuery, { id: userId});
            }
        } 
        catch(resultErrors) {
            console.log("RESULTAT :",JSON.stringify(resultErrors))
            const errors = resultErrors.errors?resultErrors.errors:(resultErrors.graphQLErrors?resultErrors.graphQLErrors:null);
            if (errors) {
                console.log("graphql Error (remote) :",errors)
            } else {
                console.log("graphql Error (local)  :",resultErrors)
                //console.log("graphql Error (local)  :",error.message)
            }
        }
        if (result?.data?.getUser) {
                console.log("CtxLoad- User trouvé en base");
                user = result.data.getUser
        }
        return user;      
    }

    async function customerFetching(id) {
        var customer;
        if (id) {
            console.log("CtxLoad-Customer loading for (%s) ...",id);
            const getQuery = `query GetCustomer($id: ID!) {
                getCustomer(id: $id) {
                    id   
                    name
                    logoUrl 
                    accessType
                    devicesCounter
                    isBetaTester
                    homePage
                    homePageConfig
                    groupsSvc {
                        items {
                          id
                          name
                        }
                    }                                                                   
                }
            }
            `;   
            try {
                //const result = await API.graphql(graphqlOperation(getQuery, { id: id }));
                const result = await GraphQlApi.getInstance().graphqlOperation( getQuery,  { id: id });
                if (result?.data?.getCustomer) {
                    //console.log("Result:", result.data.getCustomer);
                    customer = result.data.getCustomer;
                } else {
                    console.log("CtxLoad-Customer not found", result);
                }
            } 
            catch(resultErrors) {
                const errors = resultErrors.errors?resultErrors.errors:(resultErrors.graphQLErrors?resultErrors.graphQLErrors:null);
                console.log("Graphql errors: ",errors);      
            }
        }
        return customer;
    }

/*     async function upLoadUserAttributes(newCustomerId) {
        // n'est utilisé que dans le cas ou on arrive avec le customer propre au user et que le token n'est pas encore associé
        console.log("UpLoadUserAttributes in token: ", newCustomerId);

        function attributesToObject(attributes) {
            const obj = {};
            if (attributes) {
                attributes.map(attribute => {
                    if (attribute.Value === 'true') {
                        obj[attribute.Name] = true;
                    } else if (attribute.Value === 'false') {
                        obj[attribute.Name] = false;
                    } else {
                        obj[attribute.Name] = attribute.Value;
                    }
                });
            }
            return obj;
        }         

        // 1- maj du userPropertie Cognito : reqCustomerId
        var cognitoUserAuth = await Auth.currentAuthenticatedUser({ bypassCache: false });
        var userProp = [];
        //userProp = await Auth.userAttributes(user)
        var prop1 = { Name: "custom:reqCustomerId", Value: newCustomerId };
        var attribute1 = new CognitoUserAttribute(prop1);
        userProp.push(attribute1)  
        const attributes = attributesToObject(userProp);
        await Auth.updateUserAttributes(cognitoUserAuth, attributes)
        console.log("updateUserAttributes:ok")
    
        // 2- forcage de recuperation des elements issue du token generé par cognito
        // pour relancer la lambda preTokenGeneration
        // qui va mettre a jour dans le token le nouveau currentCustomer (avec controle si c'est bien un de ses customers)
        //--
        try {
            const session = await AuthHelper.refreshCurrentSession()
            console.log("googd session:",session)
            const { idToken } = session;
            console.log('refreshed idToken', idToken.payload);
    
            console.log("MAJ du token pour les requettes graphql (mode changement de customer)");
            GraphQlApi.getInstance().setToken(idToken.jwtToken, false); // pas en mode mode mobile

            // on ajoute au user, ses droits sur le customer issue du token (validés par la lambda juste avant la creation du token dans cognito)
            const userRightsBefore = currentLogUser;
            addingRightsFromIdTokenToUser(idToken.payload,userRightsBefore);
            setCurrentLogUser(userRightsBefore);
            console.log("cognitoUser with his rights update for this new customer:",currentLogUser)

        } catch (e) {
            console.log("AuthHelper:",e)
        }
        console.log("Apres cognitoUserAuth.refreshSession : ");
    } 
 */
/*     async function changeCurrentCustomerNew(newCustomerId) {
        console.log("Update CognitoUser token attributes after current customer changed : ", newCustomerId);
        function attributesToObject(attributes) {
            const obj = {};
            if (attributes) {
                attributes.map(attribute => {
                    if (attribute.Value === 'true') {
                        obj[attribute.Name] = true;
                    } else if (attribute.Value === 'false') {
                        obj[attribute.Name] = false;
                    } else {
                        obj[attribute.Name] = attribute.Value;
                    }
                });
            }
            return obj;
        }  
        // 1- maj du userPropertie Cognito : reqCustomerId
        var cognitoUserAuth = await Auth.currentAuthenticatedUser({ bypassCache: true });
        console.log("cognitoUserAuth AVANT:",cognitoUserAuth);
        var userProp = [];
        var attribute1 = new CognitoUserAttribute({ Name: "custom:reqCustomerId", Value: newCustomerId });
        userProp.push(attribute1)  
        const attributes = attributesToObject(userProp);
        console.log("Maj des attribut du Cognito user");
        await Auth.updateUserAttributes(cognitoUserAuth, attributes)
        // -> entraine un changmt sur le cognitoUser ...
    console.log("Maj ok");
        var newCognitoUserAuth = await Auth.currentAuthenticatedUser({ bypassCache: true });
        console.log("cognitoUserAuth APRES:",newCognitoUserAuth);

         // 2 - MAJ du token de graphql pour l'accès au données
        console.log("MAJ du token pour les requettes graphql (mode changement de customer)");
        const userSession = newCognitoUserAuth.signInUserSession;
        const { idToken } = userSession;
        console.log('refreshed idToken', idToken.payload);
        GraphQlApi.getInstance().setToken(idToken.jwtToken, false); // pas en mode mode mobile

        // on ajoute dans le user, ses droits sur ce nouveau customer
        // on ajoute au user, ses droits sur le customer issue du token (validés par la lambda juste avant la creation du token dans cognito)
        const userRightsBefore = currentLogUser;
        addingRightsFromIdTokenToUser(idToken.payload, userRightsBefore);
        setCurrentLogUser(userRightsBefore);
        console.log("cognitoUser with his rights on customer:",currentLogUser)


        console.log("Apres cognitoUserAuth.refreshSession : ");
    }
 */
    return (
        <div>

            {loading &&
                <div  className={classes.loading}>
                    <CircularProgress size={40} />
                </div>
            }
            {!loading &&
                <>
                    <div  className={classes.errorMessage} >{errorMessage}</div>
                    {(appUser && appCustomer)  &&
                        <MyAppNew portal={portalCfg}  user={appUser} customer={appCustomer} fromMobile={fromMobile}/>
                    }
                    {(!appUser || !appCustomer)  &&
                        <div  className={classes.loading}>
                            <CircularProgress size={40} />
                        </div>
                    }
                </>
            }

        </div>
    )
}