//EX from https://dev.to/vcanales/using-chart-js-in-a-function-component-with-react-hooks-246l
import React, { useEffect, useRef, useState } from "react";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import Chartjs from "chart.js";
import 'chartjs-plugin-annotation';

import moment from 'moment'
import 'moment/min/locales';

import { buildAnnotations } from '../Common';

// affichage de 2 lignes horizontales
var MyAnnotationPlugin = { 
  id: 'myPluginAnnotationId',
  beforeInit: function(chart, options) {
    console.log("Dans le plugin : beforeInit", chart, options);
  },
  afterDraw: (chart, param) => {

        const pluginOptions = chart.options.plugins.myPluginAnnotationId;
        //console.log("Plugin options:", pluginOptions);

        // premier point de la serie
        //var x = meta.data[chart.chart.config.data.datasets[0].install]._model.x;
        //var y = meta.data[chart.chart.config.data.datasets[0].install]._model.y;

        //console.log("chart.scales:",chart.scales);

        var xaxis = chart.scales['x-axis-time']; 
        var yaxis = chart.scales[pluginOptions.yAxeId]; 

        if (!yaxis) return;

        //console.log("yaxis:",yaxis);
        var chartArea = chart.chartArea;
        
        chart.chart.ctx.restore();
        chart.chart.ctx.beginPath();
        chart.chart.ctx.setLineDash([5, 5]);
        chart.chart.ctx.strokeStyle = '#000000';

        if (pluginOptions.minVal) {
          const yPos = yaxis.getPixelForValue( pluginOptions.minVal)
          chart.chart.ctx.moveTo(chartArea.left, yPos);
          chart.chart.ctx.lineTo(chartArea.right, yPos);     
          chart.chart.ctx.stroke();

          chart.chart.ctx.textAlign = 'center';
          chart.chart.ctx.fillText("Minimum", yPos, yPos + 12);              
        }
        if (pluginOptions.maxVal) {
          const yPos = yaxis.getPixelForValue( pluginOptions.maxVal)
          chart.chart.ctx.moveTo(chartArea.left,yPos);
          chart.chart.ctx.lineTo(chartArea.right,yPos);     
          chart.chart.ctx.stroke();

          chart.chart.ctx.textAlign = 'center';
          chart.chart.ctx.fillText("Maximum", yPos, yPos + 12);              
        }              
    }
};

/* 
//
Chartjs.pluginService.register({
  id: 'threshold',
  afterDraw: (chart, easing) => {
  }
});
 */
const chartConfigInit = {
  plugins: [],  
  //plugins: [MyAnnotationPlugin],  
  type: "line",
  //data: dataset,
  options: {
    title: {
      display: false,
      text: 'Capteur machin'
    },      
    legend: { 
      display: true,
      labels: {
        boxWidth: 10,
        fontColor: 'black'
      }
    },
    responsive: true,
    //hoverMode: 'index',
    //stacked: true,    
    scales: {
      xAxes: [{
        xAxeId : "x-axis-time",
        type: 'time',
        time: {
          tooltipFormat:'MM/DD/YYYY HH:mm',
          displayFormats: {
              day: 'D MMM',
              hour: 'HH [h]',
              minute: 'H:mm'
          }
        },        
        //distribution: 'series',
        distribution: 'linear', // necessaire pour les affichages d'E/S en escalier        
        //offset: true,
        // time: {
        //   unit: 'minute'
        // },
        ticks: {
          major: {
            enabled: true,
            fontStyle: 'bold'
          },
          source: 'auto',
          autoSkip: true,
          autoSkipPadding: 75,
          maxRotation: 0,
          sampleSize: 100
        },
        afterBuildTicks: function(scale, ticks) {
          //console.log("scale:",scale);
          //console.log("ticks:",ticks);
          if (ticks && ticks.length>0) {
            var majorUnit = scale._majorUnit;
            var firstTick = ticks[0];
            var i, ilen, val, tick, currMajor, lastMajor;
  
            val = moment(ticks[0].value);
            if ((majorUnit === 'minute' && val.second() === 0)
                || (majorUnit === 'hour' && val.minute() === 0)
                || (majorUnit === 'day' && val.hour() === 9)
                || (majorUnit === 'month' && val.date() <= 3 && val.isoWeekday() === 1)
                || (majorUnit === 'year' && val.month() === 0)) {
              firstTick.major = true;
            } else {
              firstTick.major = false;
            }
            lastMajor = val.get(majorUnit);
  
            for (i = 1, ilen = ticks.length; i < ilen; i++) {
              tick = ticks[i];
              val = moment(tick.value);
              currMajor = val.get(majorUnit);
              tick.major = currMajor !== lastMajor;
              lastMajor = currMajor;
            }
          }
          return ticks;
        }                       
      }],      
      yAxes: []
    },
    plugins: {
      myPluginAnnotationId: {
          yAxeId : "y-axis-left",
          minVal: 15,
          maxVal: 20
      }
    },
    annotation: {
      annotations: [],
    },  
    // pour contourner le bug "Cannot read properties of null (reading 'transition')"
    // qui apparait lorsqu'on redemande un graph alors que le précedent n'est pas terminé d'être dessiné
    animation: {
      duration: 0
    },
    hover: {
        animationDuration: 0
    },
    responsiveAnimationDuration: 0               
  }
}


const ChartMultiAxes = (props) => {

  const {dataSets: dataSetsInit , config} = props;

  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const { t } = useTranslation();

  const [dataSets, setDataSets] = useState(dataSetsInit);

  const [chartConfig, setChartConfig] = useState(); // svg d'une config globale (utilisé par toolType)

  const [ leftAxe, setLeftAxe ] = useState();
  const [ rightAxe, setRightAxe ] = useState();

  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('sm'));


  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      console.log("Premier affichage du graphique")

      // CONSTRUCTION DU GRAPHIQUE
      // init des courbes des données
      chartConfigInit.data = { datasets : buildGraphDataSet(dataSetsInit)};
      // init des axes des Y droites et gauches
      chartConfigInit.options.scales.yAxes = buildGraphYAxes(dataSetsInit); 
      //console.log("chartConfig.options.scales.yAxes:",chartConfig.options.scales.yAxes);
      chartConfigInit.options.annotation.annotations = buildAnnotations(dataSetsInit)
      chartConfigInit.options.tooltips = buildTooltip(chartConfigInit);

      setChartConfig(chartConfigInit) // svg d'une config globale (utilisé par toolType)

      console.log("new Chartjs (avec data et les y Axes):",chartConfigInit)
      const newChartInstance = new Chartjs(chartContainer.current, chartConfigInit);

      //console.log("newChartInstance:",newChartInstance)
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    console.log("from external dataSets changed  :",dataSetsInit);
    setDataSets(dataSetsInit);
  }, [dataSetsInit]);

  useEffect(() => {
    if (chartInstance) {
      console.log("from internal dataSets changed, REDRAWING :",dataSets);
      chartInstance.options.scales.yAxes = buildGraphYAxes(dataSets);  
      chartInstance.options.annotation.annotations = buildAnnotations(dataSets)
      chartInstance.options.tooltips = buildTooltip(chartInstance);
      chartInstance.data.datasets = buildGraphDataSet(dataSets);
      console.log("chartInstance:",chartInstance);
      chartInstance.update();
    }
  }, [dataSets]);

  const buildGraphYAxes = ((dataSets) => {
    // première init des axes
    // si certaines sondes sont affichés par defaut

    var leftAxeCfg;
    var rightAxeCfg;

    // on parcours les data des probes (modifié a l'arrivée dans le composant ou suites ux chgmt dans les radio butons)
    var atLeastLeft = false;
    var atLeastRight = false;

    for(const key in dataSets) {
      const dataSet = dataSets[key];
      if (dataSet.show && dataSet.show !== "no") {

        const probeType = dataSet.type;
        const probeCfg = config.probesType[probeType];

        const title = t("sensor.probe-type."+probeType); // traduction du titre de l'axe

        if (dataSet.show === "left") {
          atLeastLeft = true;
          if (!leftAxe) { // variable globale
            // l'axe n'a pas encore été intialisé
            leftAxeCfg = buildYAxe('left', title, probeCfg.color, probeCfg.unit) //{/* EVOL1 */}
            setLeftAxe(leftAxeCfg)

          } else {
            // l'axe a déja été intialisé, on récupère la valeur
            leftAxeCfg = leftAxe;
          }

        } else  if (dataSet.show === "right") {
          atLeastRight = true;
          if (!rightAxe) { // variable globale
             // l'axe n'a pas encore été intialisé
            rightAxeCfg = buildYAxe('right', title, probeCfg.color, probeCfg.unit) //{/* EVOL1 */}
            setRightAxe(rightAxeCfg)

          } else {
            // l'axe a déja été intialisé, on récupère la valeur
            rightAxeCfg = rightAxe;
          }
        }
      } else {
      // hide line
      // on supprime la courbe d'un axe,
      // si il n'y a plus de courbe pour cet axe (cf dataSets), on supprime l'axe
      }
    }
    if (!atLeastRight) setRightAxe();
    if (!atLeastLeft)  setLeftAxe();

    var yAxes = [];
    if (leftAxeCfg) { 
      yAxes.push(leftAxeCfg)
    }
    if (rightAxeCfg) {
      yAxes.push(rightAxeCfg)
    }

    return yAxes; // (droite/gauche)
  })

  const buildTooltip = ((chartInstance) => {
    return {
      callbacks: {
          label: function(tooltipItem) {
            //console.log("tooltipItem:", tooltipItem);
            //console.log("chartInstance:", chartInstance);
            var unit = chartInstance.data.datasets[tooltipItem.datasetIndex].unit || ''; //{/* EVOL1 */}
            return  Number(tooltipItem.yLabel) + ' '+  unit;
          }
      } 
    }
  })


  // lecture des sets de data pour generer des sets de graph
  const buildGraphDataSet = ((dataSets) => {
    var graphDataSets = [];
    for(const key in dataSets) {
      const dataSet = dataSets[key];
      if (dataSet.show && dataSet.show !== "no") {
        const yAxexId = (dataSet.show === "left")?"y-axis-left":"y-axis-right";
        graphDataSets.push(buildYLine(dataSet.name, dataSet.type, yAxexId, dataSet.unit, dataSet.data, dataSet.graphType, dataSet.color)); //{/* EVOL1 */}
      }      
    }
    return graphDataSets;
  })

  // construction de l'axe des y (droite/gauche)
  const buildYAxe = ((position, title, color,  unit )=> {
    return  {
        type: 'linear', 
        display: true,
        position: position,
        color: color,
        pointRadius: 0,
        id: 'y-axis-'+position,          
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
            return value + " " + unit;
          },  
          //suggestedMin: -5       // SI temperature (peut-être si une des valeurs de la serie est negative)  
        },
        scaleLabel: {
          display: true,
          labelString: title,
          fontColor: color
        },
        gridLines: {
          color: "#ffd699",
          drawBorder: true,
          drawOnChartArea: true
        },                               
    }
  })


  // construction d'une serie de donnée
  const buildYLine = ((title, type, yAxexId, unit, data, graphType, color) => {

    // lecture d'une config en fonction du tye de probe (config du composant issue d'un fichier)
    const probeCfg = config.probesType[type];

    //recuperer une couleur par sonde et non pas par type de l'axe
    // si une couleur est présente dans le dataSet (issue du template dans probeDisplay), 
    // elle est utilisé à la place de la couleur par defaut issue du type de l'axe (chart-config)
    const dataSetColor = color?color:probeCfg.color;

    const graphDataSet = {
      label: title,
      unit: unit,
      data: data,
      borderWidth: 2,
      pointRadius: 1,
      borderColor: dataSetColor,
      fill: false,
      backgroundColor: probeCfg.backgroundColor,
      yAxisID: yAxexId,
      //showLine: false,
      pointRadius: 1,
      pointHitRadius: 40    
    }
    

    if (graphType) {
      if(graphType==="SteppedLine") {
        graphDataSet.type = "line";
        graphDataSet.tension=0; // ligne droite, non extrapolée
        // à la place des escalier, on ajoute des events dans probePage
        // et on met tension à 0 ci-dessus
        //graphDataSet.steppedLine = 'before'; // 'before' / 'after'
      } else {
        graphDataSet.type = graphType;
      }
    }
    if (color) graphDataSet.color = color;

    return graphDataSet;
  })

  const testButtonClick = (data) => {
    console.log("button click: ",data);
  }


  const lineSelected = ((show) => {
    return show?show:"no";
  })

  return (
    <div>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default ChartMultiAxes;
