import React, { useContext,  useEffect } from 'react'

import AppLayout from './layout/AppLayout'
import AppMain from './layout/AppMain'

import GeneralContext from './components/GeneralContext'

export default function MyAppNew({fromMobile, user: userCtx , customer: customerCtx, portal: portalCtx}) {

    const { setUser, setCustomer, setPortal } = useContext(GeneralContext);

    useEffect(() => {  
        setUser(userCtx);
        console.log("USER OK in context",userCtx);
    }, [userCtx]);

    useEffect(() => {  
        setCustomer(customerCtx);
        console.log("CUSTOMER OK in context, ",customerCtx);
    }, [customerCtx]);

    useEffect(() => {  
        setPortal(portalCtx);
        console.log("PORTAL OK in context : ",portalCtx);
    }, [portalCtx]);

    return (
        <div className="App">
            {/* appel depuis un mobile, on court-cuite  AppLayout */}
         
            {fromMobile &&
             <AppMain></AppMain>  
            }
    
            {!fromMobile  &&
              <AppLayout customerID={customerCtx.id} />   
            }

        </div>
      );
}