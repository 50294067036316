import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
    },
    redLight: {

    },
    light: {
        appearance: "none",
        //position: "relative",
        //left: "50%",
        width: 17,
        height: 17,
        marginTop: 1,
        //marginLeft: -40,
        //verticalAlign: "middle",
        borderRadius: "100%",
        display: "block"
    }
}));

export default function TrafficLight({value, thresholds}) {

    const classes = useStyles();

    const [ stateRed, setStateRed] = useState(true);
    const [ stateYellow, setStateYellow] = useState(false);
    const [ stateGreen, setStateGreen] = useState(false);
    
    useEffect(()=> {
        if (thresholds && !isNaN(value)) {
            if (value > thresholds.thresoldHigh) {
                setStateRed(true)
                setStateYellow(false)
                setStateGreen(false)

            } else if (value < thresholds.thresoldLow) {
                setStateRed(false)
                setStateYellow(false)
                setStateGreen(true)

            } else {
                setStateRed(false)
                setStateYellow(true)
                setStateGreen(false)
            }
        } else {
            setStateRed(false)
            setStateYellow(false)
            setStateGreen(false)
        }
    },[value]);

    return (
        <Grid container  direction="column" alignItems="center" >
            <Grid item><Light color={"red"} state={stateRed} /></Grid>
            <Grid item><Light color={"yellow"} state={stateYellow} /></Grid>
            <Grid item><Light color={"green"} state={stateGreen} /></Grid>
        </Grid>
    );
}

function Light({ color: toColor, state: toState}) {

    const [ color, setColor] = useState("grey");
    const [ state, setState] = useState(false);

    const classes = useStyles();

    useEffect( () => {
        setColor(toColor);
        setState(toState);
    });

    const getCssColor = (color, state) => {
        var cssColor;
        if (state) {
            if (color==="red") {
                cssColor = "#333333";

            } else if (color==="yellow") {
                cssColor = "yellow";

            } else if (color==="green") {
                cssColor = "chartreuse";

            } else {
                cssColor = "grey";
            }

        } else {
            cssColor = "grey";
        }
        return {
            backgroundColor: cssColor
        }
    }   

    return (
        <div className={classes.light} style={getCssColor(color, state)}> </div>
    )
}