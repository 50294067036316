import React, {useContext, useState, useEffect } from 'react';

import { API } from "aws-amplify";

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import InputIcon from '@material-ui/icons/Input';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CircularProgress from '@material-ui/core/CircularProgress';

import {dateUtcToLocal, lockActionToText} from '../Common';

import GeneralContext from './GeneralContext'
import ProbesDisplay from './ProbesDisplay'
import MyLink from './MyLink';
import ServiceCamerasView from './ServiceCamerasView';
 
const useStyles = makeStyles(theme => ({
    root: {
    },
    button: {
        margin: theme.spacing(1),
    },
    loading: {
        marginTop: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'      
    }    
  }));

export default function ServiceMediaServer( {data:service, customerID} ) {

    const { user, customer } = useContext(GeneralContext);

    const [deviceMqttId, setDeviceMqttId] = useState();
    const [mediaStreams, setMediaStreams] = useState();
    const [defaultStreamId, setDefaultStreamId] = useState();


    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");

    const classes = useStyles();
    let history = useHistory()

    useEffect(() => {
        let ignore = false;
        console.log("service:",service);
        async function processing(service) {
            setDeviceMqttId(service.device.idMqtt);
            setMediaStreams(service.globalConfig.media.streams)
            const defaultStream = getDefaultStreamId(service.globalConfig.media.streams);
            console.log("defaultStream:",defaultStream);
            if (defaultStream) setDefaultStreamId(defaultStream.id);
            setLoading(false);
        }
        if (service) {
            processing(service);
        }
        return () => { ignore = true; }
    }, [service]);  

    const getDefaultStreamId = (streams) => {
        return streams.find( item => item.default)
    };

    return (
        <> 
              {loading &&
                    <div  className={classes.loading}>
                        <CircularProgress size={40} />
                    </div>
                }  
                {!loading &&
                    <ServiceCamerasView serviceId={service.id} deviceMqttId={deviceMqttId} streamIdDefault={defaultStreamId} streams={mediaStreams} customerID={customerID} />
                }
        </>
    );
}

function MyButton(props) {
    const { state, displayOn, displayOff, onCallback, offCallback } = props;

    const classes = useStyles();

    useEffect(() => {
        console.log("button state:",state);
    }, [state]);  

    const handleClick = async ()  => {
        console.log("click");
        if (state==="on") {
            console.log("start Off")
            offCallback();

        } else if (state==="off") {
            console.log("start On")
            onCallback();

        } else {
            console.log("state not on/off : ", state)
        }
    };
    return (
        <>  
            <Button
                variant="contained"
                color="default"
                onClick={handleClick}
                className={classes.button}
                >
                {state==="on" &&
                    <div>{displayOn}</div>
                }
                {state==="off" &&
                    <div>{displayOff}</div>
                }
                {state==="processing" &&
                    <CircularProgress size={40} />
                } 
                {state==="error" &&
                   <div>Erreur</div>
                }                
            </Button> 
        </>
    );
}
