
import React, { useState } from 'react'

import GeneralContext from './GeneralContext'

const GeneralContextProvider = ({ children }) => {

    const [user, setUser] = useState({name:"init"});
    const [customer, setCustomer] = useState( {name: "init"});
    const [portal, setPortal] = useState( {name: "init"});
  
  return (
    <GeneralContext.Provider value={
        {
            user,
            customer,
            portal,
            setUser,
            setCustomer,
            setPortal
          }
    }>
      {children}
    </GeneralContext.Provider>
  )
}

export default GeneralContextProvider
