import React from 'react';

import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import AddAlertIcon from '@material-ui/icons/AddAlert';

import MyLink from './MyLink';
import { round} from '../Common';

import { makeStyles  } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    sensorSub: {
        // backgroundColor: "grey"
        paddingRight: "10px"
    }, 
    sensorAlarm: {
        marginRight: "10px"
    },
    sensorValue: {
        fontSize: "24px"
    },
    sensorCumul: {
        fontSize: "18px"
    },
    sensorMinmax: {
       width: 100
    },
    sensorMore: {
        fontSize: "13px"
    }, 
    linkAlert: {
        textDecoration: 'none!important',
        //color: 'green',
        '&:focus': {
            textDecoration: 'none'
        } ,  
        '&:active': {
            textDecoration: 'none'
        },
        '&:hover': {
            textDecoration: 'none'
        },
        '&:visited': {
           textDecoration: 'none'
        },                          
        '&:link': {
            textDecoration: 'none'
        }               
    },       
}));
  
export default function ProbeDisplayRain({ serviceId, probe }) {

    const classes = useStyles();
    const { t } = useTranslation();

    // si decimal existe dans le template, on affiche le nombre de chiffre après la virgule souhaité
    function displayVal(value, decimal) {
        if (isNaN(value)) {
            return "?"
        } else {
            return Number.isInteger(decimal)?Number.parseFloat(value).toFixed(decimal):value;
        }
    }

    return (
    <Grid container direction="column"  spacing={1} >
        <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent="space-around" spacing={1} >
                <Grid item xs={9}>
                    <MyLink to={"/service/"+serviceId+"/"+probe.id}  style={{ textDecoration: 'none' }}> 
                    {probe.name}
                    </MyLink>
                </Grid>
                <Grid item xs={3}>
                    <Grid container direction="row" justifyContent="flex-end" spacing={1} >
                        <Grid item>
                            <div className={classes.sensorAlarm}>
                            <MyLink to={"/service/"+serviceId+"/"+probe.id+ "/alerts"} className={classes.linkAlert} >
                                {probe.isAlarm &&
                                    probe.isAlarm.map( (status,key) => 
                                        <span key={key} >
                                        {/* une alarme est déclenchée pour cette sonde  */}
                                        {status === "triggered" &&
                                            <NotificationsActiveIcon fontSize='small'/>
                                        }
                                        {/* une alarme est en fonction mais non déclenchée */}
                                        {status === "active" &&
                                            <NotificationsNoneIcon fontSize='small'/>
                                        }  
                                        {/* une alarme est définie mais inactivée par l'utilisateur */} 
                                        {status === "inactive"  &&
                                            <NotificationsOffIcon fontSize='small'/>
                                        }  
                                        </span>
                                    )                                              
                                } 
                                {/* pas alarme de definie  */} 
                                {!probe.isAlarm &&
                                    <AddAlertIcon fontSize='small'/>
                                }                                                                                                               
                            </MyLink>   
                            </div>
                        </Grid>
                    </Grid>                 
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <MyLink to={"/service/"+serviceId+"/"+probe.id}  style={{ textDecoration: 'none' }}> 
            <Grid container direction="column" spacing={1}  className={classes.sensorCumul}>
                <Grid item>
                    <Grid container direction="row" justifyContent="space-evenly"  spacing={1} >
                        <Grid item>{probe.cumuls ? round(probe.cumuls["7j"],1) : ""} {probe.unit} /7j</Grid>
                        <Grid item>{probe.cumuls ? round(probe.cumuls["8h"],1) : ""} {probe.unit} /8h</Grid>
                    </Grid>                    
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="space-evenly"  spacing={1} >
                        <Grid item>{probe.cumuls ? round(probe.cumuls["24h"],1) : ""} {probe.unit} /24h</Grid>
                        <Grid item>{probe.cumuls ? round(probe.cumuls["1h"],1) : ""} {probe.unit} /1h</Grid>
                    </Grid>                      
                </Grid>
            </Grid>
            </MyLink>
        </Grid>
    </Grid>   
    );
}

