import React, { useEffect, useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs'
//import { mapToArray, round, toSnack} from '../Common';

import { createTheme, makeStyles} from '@material-ui/core/styles';

import GeneralContext from './GeneralContext'

import MyLink from './MyLink';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        fontWeight: 500
    },
    json: {
        fontStyle: 'italic',
        fontSize: 'smaller'
    }, 
    infoLine: {
        fontStyle: 'italic',
        fontSize: 'smaller'
    }   
}));
const theme = createTheme({
    palette: {
      //primary: green,
    }
});
  
export default function ProbeDisplayJson({ serviceId, probe, serviceType }) {

    const [ probeName, setProbeName ]   = useState("");
    const [ probeValue, setProbeValue ] = useState([]);

    const [ infoLine, setInfoLine ] = useState("");

    const { user, customer } = useContext(GeneralContext);

    useEffect(() => { 
        if (probe.value) {
            try {
                const probeVal = JSON.parse(probe.value);
                if (serviceType==="LOCK") {
                    setInfoLine("L'action '"+probeVal["action"]+"' sur la règle '"+probeVal["accessCodeName"]+"' a bien été prise en compte par la serrure.") ;
                } else {
                    // surement le service d'un SmartBroker
                    if (probeVal.hasOwnProperty("startedAt")) {
                        probeVal["startedAt"] = dayjs(probeVal.startedAt).format("DD/MM/YYYY HH:mm:ss");
                    }
                    setProbeValue(Object.entries(probeVal));
                }
            } catch (error) {
                console.log("Error : Value not json");
                setProbeValue([]);
            }
        }
        setProbeName(probe.name)
    }, [probe])  

    const classes = useStyles();

    // determine le nom de l'etat actuel de la  sonde 
    // soit le nom issue de lac traduction d'après le type de la sonde
    // soit un nom indiqué par l'utilisateur dans admin stocké dans le service
    function getStateName(probe) {

        var probeStateName;
        if (probe.state==='0') {
            if (probe.stateNameOff) {
                probeStateName = probe.stateNameOff;
                
            } else {
                probeStateName = t('screen.device-display.state-off')
            }
        } else if (probe.state==='1') {
            if (probe.stateNameOn) {
                probeStateName = probe.stateNameOn;
            } else {
                probeStateName = t('screen.device-display.state-on')
            }
        } else {
            //state === '?'
        }
        return probeStateName;
    }

    const { t } = useTranslation();

    return (
    <>
        <MyLink to={"/service/"+serviceId+"/"+probe.id}  style={{ textDecoration: 'none' }}>
            {serviceType==='LOCK' && customer.isUserAdmin  &&
            <Grid container direction="column" justifyContent="space-around" alignItems="stretch">
                <Grid item>
                    <div className={classes.infoLine}>{infoLine}</div>
               </Grid> 
            </Grid>                  
            }        
            {serviceType!=='LOCK' &&    
            <Grid container direction="column" justifyContent="space-around" alignItems="stretch">    
                <Grid item className={classes.title}>
                    {probeName}
                </Grid>  
                <Grid item>
                        <Grid container direction="column" justifyContent="space-around" alignItems="stretch">
                        {probeValue.map(([key, value]) => 
                            <Grid item key={key} className={classes.json}>{key}: {value}</Grid>
                        )}
                        </Grid>                    
               
               </Grid> 
            </Grid>
            }                      
        </MyLink>
    </>
    );
}
