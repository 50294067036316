import React, { useContext, useEffect } from 'react'

import {useLocation} from "react-router-dom";
import MyLink from '../components/MyLink';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import BuildIcon from '@material-ui/icons/Build';
import CategoryIcon from '@material-ui/icons/Category';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Divider from '@material-ui/core/Divider';

import AppsIcon from '@material-ui/icons/Apps';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import StarIcon from '@material-ui/icons/Star';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import GroupIcon from '@material-ui/icons/Group';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import GeneralContext from '../components/GeneralContext'

import HelpPage from '../pages/HelpPage'
import awsconfig from '../aws-exports';

import packageJson from '../../package.json';

global.appVersion = packageJson.version;

const useStyles = makeStyles(theme => ({
    version: {
        marginRight: 20,
        textAlign: "end",
        fontSize: 11
    },
    company: {
        //backgroundColor: "#d2f5f9"
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },     
}));
  
export default function AppDrawer(props) {
    const { openState, toClose } = props;
    const { customer } = useContext(GeneralContext)
    const { t } = useTranslation();

    const classes = useStyles();
    const { exe_env_name } = awsconfig;

    let location = useLocation();
    const [selectedIndex, setSelectedIndex] = React.useState(1); // 1: button 'home', 10: all, 11: favorites, groupId: 20+index, admin: 50, adminCustomerUsers: 51, 60: about

    const routes = [
/*         {
          path: '/about',
          sidebarName: t('global.menu.about'),
          navbarName: t('global.menu.about'),
          icon: InfoIcon,
          component: AboutPage,
          selIndex: 60
        }, */
        {
            path: '/help',
            sidebarName: t('global.menu.help'),
            navbarName: t('global.menu.help'),
            icon: HelpOutlineIcon,
            component: HelpPage,
            selIndex: 60
          }  
    ];

    // maj de l'activation du button en fonction de l'url actuelle
    //TODO si l'url=groupId, activer le bon groupId
    useEffect(() => {
        if (location.pathname.startsWith('/home')) { 
            //console.log("User set Location /home")
            setSelectedIndex(1)

        } else if (location.pathname.startsWith('/services/all')) {
            //console.log("User set Location /services/all")
            setSelectedIndex(10)

        } else if (location.pathname.startsWith('/services/favorites')) {
            //console.log("User set Location /favorites")
            setSelectedIndex(11)
        // admin est une popup (TODO mémoriser la position avant de lancer la popup ?)
        // } else if (location.pathname.startsWith('/admin')) {
        //     setSelectedIndex(50)
            
        } else {
            //console.log("from menu new location:",location)
            setSelectedIndex(0)
        }

    },['location']);

    function closeDrawer(newIndex) {
        console.log("new drawer Index:",newIndex)
        setSelectedIndex(newIndex)
        if (openState) {
            toClose();
        }
    }    
    return (
        <div>
            {/* <div>{customer.name}</div> */}
            <List>
                <MyLink to={"/home"} style={{ textDecoration: 'none' }}>
                    <ListItem button  selected={selectedIndex === 1} key="hometitle" onClick={(e) => closeDrawer(1)}>

                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary={t('global.menu.home')} />
                    </ListItem>  
                </MyLink>   
                </List>
                <Divider />    

                {customer.id &&    
                    <>                    
                    <List>   
                        {!customer.homePage &&
                            <ListItem  key="customertitle" className={classes.company} >
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>                            
                                <ListItemText primary={customer.name} />
                            </ListItem>  
                        }                          
                        {customer.homePage?.startsWith("CUSTOM") &&
                            <MyLink to={"/custom"} style={{ textDecoration: 'none' }}>
                                <ListItem  key="customertitle" className={classes.company} >
                                    <ListItemIcon>
                                        <BusinessIcon />
                                    </ListItemIcon>                            
                                    <ListItemText primary={customer.name} />                           
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="admin">
                                        <DashboardIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction> 
                                </ListItem>  
                            </MyLink> 
                        }    
                        <ListSubheader  key="devicetitle">
                            <ListItemText primary="Capteurs" />
                        </ListSubheader>                  
                        <MyLink to={"/services/all"} style={{ textDecoration: 'none' }}>
                            <ListItem button key="allservices"  selected={selectedIndex === (10)}  onClick={(e) => closeDrawer(10)} className={classes.nested}>
                                <ListItemIcon><AppsIcon/></ListItemIcon>
                                <ListItemText primary={t('global.menu.all')} />
                            </ListItem>  
                        </MyLink>
                        <MyLink to={"/services/favorites/"} style={{ textDecoration: 'none' }}>
                            <ListItem button key="favorites"  selected={selectedIndex === (11)} onClick={(e) => closeDrawer(11)}  className={classes.nested}>
                                <ListItemIcon><StarIcon/></ListItemIcon >
                                <ListItemText primary={t('global.menu.favorites')} />
                            </ListItem>  
                        </MyLink>                           
                        {customer.groupsSvc?.items.map((group, index) => 
                            <MyLink to={"/services/group/"+group.id} style={{ textDecoration: 'none' }} key={(100+index)}>
                            <ListItem button  selected={selectedIndex === (index+20)} onClick={(e) => closeDrawer(index+20)}  className={classes.nested}>
                                <ListItemIcon><GroupWorkIcon /></ListItemIcon>
                                <ListItemText primary={group.name} />
                            </ListItem>  
                            </MyLink>         
                        )}   
                        {customer.isUserAdmin &&
                        <>
                            <ListSubheader  key="admintitle">
                                <ListItemText primary="Admin" />
                            </ListSubheader>   
                            <MyLink to={"/adminCustomer"} style={{ textDecoration: 'none' }} key="adminmain">
                                {/* <Divider />   */}
                                <ListItem button selected={selectedIndex === (50)} onClick={(e) => closeDrawer(0)} className={classes.nested} >
                                    <ListItemIcon>
                                    <BuildIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('global.menu.admin-main')} />
                                </ListItem>
                            </MyLink> 
                            <MyLink to={"/adminCustomerUsers"} style={{ textDecoration: 'none' }} key="adminusers">
                                <ListItem button selected={selectedIndex === (51)} onClick={(e) => closeDrawer(0)} className={classes.nested} >
                                    <ListItemIcon>
                                    <GroupIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('global.menu.admin-users')} />
                                </ListItem>                                
                                <Divider />  
                            </MyLink> 
                        </>
                        }    
                        <Divider />   
                        {routes.map((prop, index) => {
                            return (
                                <MyLink to={prop.path} style={{ textDecoration: 'none' }} key={(200+index)}>
                                <ListItem button  /* selected={selectedIndex === (prop.selIndex)} */ onClick={(e) => closeDrawer(prop.selIndex)}>
                                    <ListItemIcon>
                                    <prop.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={prop.sidebarName} />
                                </ListItem>
                                </MyLink>
                            );
                        })}
                    </List>
                    <Divider />   
                    </>     
                }  
 
         {/*    <div className={classes.version}>v24-05-2020-a4 @ {exe_env_name}</div>        */}
            <div className={classes.version}>v{global.appVersion}</div>  
        </div>
    )
}