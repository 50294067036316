import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const options = {
  //order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  order: ['querystring','navigator'],
  caches: ['localStorage', 'cookie'],
  //caches: [ 'cookie'],
  lookupQuerystring: 'lng',
  pluralSeparator: '_'
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: options,
    fallbackLng: 'en',
    //supportedLngs: ['en','en-GB', 'en-US', 'it', 'it-IT', 'fr', 'fr-FR'],
    supportedLngs: ['en', 'it', 'fr'],
    //supportedLngs: ['en-US', 'it-IT', 'fr', 'fr-FR'],
    nonExplicitSupportedLngs: true,
    //ns: ['device'],
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: true
    }    
  });


export default i18n;

/*
Pour lire la langue : 

dans terminal  : defaults read com.google.Chrome AppleLanguages
--> c'est la langue des menu de chrome et de google search

dans chrome console (inspecter) : window.navigator.language
dans chrome (url) : chrome://settings/languages
--> c'est la langue detecter par i18n

Pour changer la langue
pour  chrome : 
avec terminal : defaults write com.google.Chrome AppleLanguages '(it, it-IT)' lou '(en, en-GB)'
--> ça change l'affichage mais pas la langue detecté par i18n.language

avec chrome : url : chrome://settings/languages
-> ça change bien la langue detecté par i18,.language



*/