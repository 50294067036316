//TODO tester la conformité des valeurs (ex 0-1, isNumber)

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import GraphQlApi from '../mobile/MyGraphql';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';


import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ProbeIcon from '@material-ui/icons/FilterTiltShift';
import BarChartIcon from '@material-ui/icons/BarChart';
import FunctionsIcon from '@material-ui/icons/Functions';
import OnOffIcon from '@material-ui/icons/HdrWeak';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';

import TextField from '@material-ui/core/TextField';

import { toSnack, arrayToMap } from '../Common';

const useStyles = makeStyles({
    root: {
        margin: "20px"
    },
    title: {
        margin: 2,
        padding: 4
    },
    paragraph: {
        margin: 5,
        padding: 5
    },    
    card: {
        maxWidth: "800px"
    },
    smallFieldText: {
        width: '7em',
        marginRight: 10
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },    
    smallFieldNumber: {
        // cadrage a droite
        '& .MuiInputBase-input': {
          //margin: theme.spacing(1),
          textAlign: 'end',
          width: '4em',
        }
    },
    mediumFieldNumber: {
        // cadrage a droite
        '& .MuiInputBase-input': {
          //margin: theme.spacing(1),
          textAlign: 'end',
          width: '7em',
        }
    },    
    inputCoef: {
        width: '4em',
        //marginRight: 8
    },    
});

export default function ProbesEditDetail(props) {
    const { data } = props;

    let { id: serviceId, probe: probeId } = useParams();
    const { t } = useTranslation();

    const [service, setService] = useState({});
    //const [probeTmplt, setProbeTmplt] = useState(); // template.probesDisplay
    const [probeProperties, setProbeProperties] = useState({});

    const [loading, setLoading] = useState(false);

    const [probeName, setProbeName] = useState("");

    const [functionCoefA, setFunctionCoefA] = useState("");
    const [functionCoefB, setFunctionCoefB] = useState("");

    const [graphLine1Name, setGraphLine1Name] = useState("");
    const [graphLine1Value, setGraphLine1Value] = useState("");

    const [graphLine2Name, setGraphLine2Name] = useState("");
    const [graphLine2Value, setGraphLine2Value] = useState("");

    const [probeStateValueOn, setProbeStateValueOn] = useState("");
 
    const [probeStateNameOn,  setProbeStateNameOn] = useState("");
    const [probeStateNameOff, setProbeStateNameOff] = useState("");

    const [probeButtonNameOn,  setProbeButtonNameOn] = useState("");
    const [probeButtoneNameOff, setProbeButtonNameOff] = useState("");
 
    const [buttonActions, setButtonActions] = useState([]);
    const [newButtonNames, setNewButtonNames] = useState({});

    useEffect(() => {
        console.log("data:",data)
    }, [data]);

    // recuperation du service concerné
    useEffect(() => {
        let ignore = false;
        async function fetchData(serviceId) {
            console.log("Service is loading for %s ...", serviceId);
            setLoading(true);
            const getServiceQuery = `query GetService($id: ID!) {
                getService(id: $id) {
                  id
                  name
                  serviceType
                  probesConfig    
                  device {
                    id
                    manufacturer
                    status
                    template {
                      id
                      probesDisplay
                      probesStruct
                    }         
                  }
                  customer {
                    id
                  }
                }
              }`;
            //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
            const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery, { id: serviceId });
            setLoading(false);
            if (result.data.getService) {
                const theService = result.data.getService
                console.log("service :", theService);
                setService(theService)     
                initProbeValuesDefault(theService.device.template.probesDisplay,theService.probesConfig, probeId);  
 
            } else {
                console.log("Service non trouvé en base");
            }
        }      
        fetchData(serviceId);
        return () => { ignore = true; }

    }, [serviceId]);     

    const initProbeValuesDefault = ((templateProbesRaw, serviceProbesRaw, probeId) => {
        // c'est le template.probesDisplay. A priori le template.probesStruct n'est pas visible/accessible par l'admin ?
        const templateProbes = JSON.parse(templateProbesRaw);
        const probeTmplt = templateProbes[probeId];
        console.log("probe from Template:",probeTmplt);

        const probeProperties = {};
        var serviceProbes;

        if (!serviceProbesRaw ) {
            // a la cible, ce cas ne drevait plus exister
            // il n'y a pas de probes config pour ce service
            // le nom de la sonde dans la langue de l'utilsateur devrait toujours exister

            setProbeName(t("sensor.probe-type."+probeTmplt.type))
           
            probeProperties["type"] = probeTmplt.type;
            probeProperties["unit"] = probeTmplt.unit; //{/* EVOL1 */}
            setProbeProperties(probeProperties);

            return;

        } else {
            // on a bien un probesConfig dans service
            serviceProbes = JSON.parse(serviceProbesRaw);
        }

        // on tente de recupérer la config de la sonde
        var probeSvc   = serviceProbes[probeId];
        if (!probeSvc) {
            // pas de config dans service pour cette probe
            setProbeName(t("sensor.probe-type."+probeTmplt.type))

            probeProperties["type"] = probeTmplt.type;
            probeProperties["unit"] = probeTmplt.unit; //{/* EVOL1 */}
            setProbeProperties(probeProperties);

            return;
        }
        console.log("probe from Service:",probeSvc);

        probeProperties["type"] = probeTmplt.type;
        probeProperties["unit"] = probeTmplt.unit; //{/* EVOL1 */}
        setProbeProperties(probeProperties);

       // a la cible, le nom de la sonde est créé dans le service, dans la langue de l'utilisateur, à l'activation du capteur
        // si ce n'est pas le cas ?
        if (!probeSvc.name) {
            //setProbeName(probeTmplt.name) // à la cible, il n'y a plus de name dans probesDisplay
            setProbeName(t("sensor.probe-type."+probeTmplt.type))
        } else {
            setProbeName(probeSvc.name)
        }

        if (probeSvc.fct) {
            setFunctionCoefA(probeSvc.fct.a);
            setFunctionCoefB(probeSvc.fct.b)
        } 

        if (probeSvc.graph && probeSvc.graph.notes) {

            const notes = probeSvc.graph.notes; // un array
            if (notes.length>0) {
                setGraphLine1Name(notes[0].text);
                setGraphLine1Value(notes[0].value);
            }
            if (notes.length>1) {
                setGraphLine2Name(notes[1].text);
                setGraphLine2Value(notes[1].value);
            }
        }
        if (probeSvc.hasOwnProperty("stateOn")) {
            setProbeStateValueOn(probeSvc.stateOn);
        } 
        if (probeSvc.stateNameOff) {
            setProbeStateNameOff(probeSvc.stateNameOff)
        }
        if (probeSvc.stateNameOn) {
            setProbeStateNameOn(probeSvc.stateNameOn)
        }  
        if (probeTmplt.buttons) {
            // init des libellés pour les boutons pour les modifier
            // si des libellés existe dans le service on par de cette liste
            // sinon on reprends les libelles du template (voir de la traduction en fonction du type)
            // dans le template : [{display: "Poussoir", action: "pulseOn", type: "pulse"} ]
            // dans service { "toOff": "Fermer", "toOn": "Ouvrir"}

            //creation d'un objt a partir de l'array
            const templateButtons = arrayToMap(probeTmplt.buttons, "action");

            // creation du liste des actions (issue du modèle) pour l'accés direct à la propriété
            const actionList = Object.keys(templateButtons);
            
            // pour chaque action, recup du libelle du service si il existe ou du libelle du template
            var newActionNamesObj = {};
            actionList.map( (action) => {
                // le libelle par defaut d'une action pour un bouton, se trouve dans la traduction
                const defaultName = t('actuator.defaultActionName.'+action+'');
                // si un nom existe dans le service on le prends, sinon on prends le nom du template
                newActionNamesObj[action] = (probeSvc.buttons && probeSvc.buttons[action])?probeSvc.buttons[action]:defaultName;
            })
            // objets manipulé par des set dans le champs de saisie qui contient les noms des boutons en construction
            // { "toOn": "nom en cours", "toOff": "nom en court"}
            setNewButtonNames(newActionNamesObj);
            // liste des actions
            setButtonActions(actionList);
        } 
    });

    const handlePropertySave =  (async (property) => {
        //console.log(key, probe)
        console.log("update : ",property)

        // pour maj probesConfig (AWSJSON) dans service et eviter/Limiter un ecrasement de l'ecriture par une ecriture concomitante
        // on faire une lecture juste avant l'ectiture
        // pour faire mieux : remonter nu numéde version et le comparer au moment de l'ecriture (lammda resolver) ou passer par un WS Rest

        // read probesConfig from base
        setLoading(true);
        var probesConfig = await getProbesConfigQuery(serviceId);
        // est-ce possible que ce soit vide ?
        // pour les capteurs nouvellement attachés non
        // mais oui pour les anciens (a maj)
        if (!probesConfig) {
            //probesConfig = {};
            probesConfig = buildInitProbeConfig()
        }
        // update probesConfig
        const probeToUpdate = probesConfig[probeId];
        if (!probeToUpdate) {
            probesConfig[probeId] = {};
        }

        if (property==="name") {
            probesConfig[probeId]["name"] = probeName;

        } else if (property==="axb") {
            probesConfig[probeId]["fct"] = {
                "a": Number(functionCoefA),
                "b": Number(functionCoefB)
            }

        } else if (property==="graphLines") {

            if (!probesConfig[probeId].graph) {
                probesConfig[probeId]["graph"] = {}
            }   

            var notes = [];
            if (graphLine1Value !== "") {
                notes.push({
                    "text": graphLine1Name,
                    "value": Number(graphLine1Value)
                })
            }
            if (graphLine2Value !== "") {
                notes.push({
                    "text": graphLine2Name,
                    "value": Number(graphLine2Value)
                })  
            }
            probesConfig[probeId].graph.notes = notes;
        } else if (property==="probeButtons") {
            console.log(newButtonNames)
            probesConfig[probeId]["buttons"] = newButtonNames;

        } else if (property==="probeState") {
            probesConfig[probeId]["stateOn"] = Number(probeStateValueOn);

            probesConfig[probeId]["stateNameOn"] = probeStateNameOn;
            probesConfig[probeId]["stateNameOff"] = probeStateNameOff;
        }
        console.log("New probesConfig:",probesConfig);

        try {

         await updateProbesConfigMutation(serviceId, probesConfig);
         toSnack("ServiceUpdated",t('screen.probes-admin.well-updated'));
        }
        catch (error) {
         toSnack("ServiceUpdated",t('screen.probes-admin.update-error'));
        }
        setLoading(false);
    })

    // initialisation de probesConfig avec le Nom de chaque sonde (fct de la langue de l'utilisateur)
    // en attendant que tous les noms soient maj
    const buildInitProbeConfig = (() => {
        const probesStruct = JSON.parse(service.device.template.probesDisplay);
        var probesConfig = {}
        for(const key in probesStruct) {
            const probe = probesStruct[key];
            const probeNameLocal = t("sensor.probe-type."+probe.type);
            probesConfig[key] = { name : probeNameLocal } 
        }
        return probesConfig
    })

    async function updateProbesConfigMutation(serviceId, probesConfig) {
        const probesConfigString = JSON.stringify(probesConfig);
        const toUpdate = {
            id: serviceId,
            probesConfig: probesConfigString
        };
        console.log("toUpdate:",toUpdate);
        const updateMutation = `mutation updateService($input: UpdateServiceInput!) {
          updateService( input: $input) {
              id
              probesConfig
          }
        }`;
        //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
        console.log("Service updated", response.data.updateService);
    } 
    async function getProbesConfigQuery(serviceId) {
        console.log("Fetching for service %s is processing ...", serviceId);
        var probesConfig;
        const getServiceQuery = `query GetService($id: ID!) {
            getService(id: $id) {
              id
              probesConfig                    
            }
          }`;
        //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
        const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery,  { id: serviceId });
        if (result.data.getService) {
            const service = result.data.getService
            console.log("Service trouvé en base:", service);
            if (service.probesConfig) {
                probesConfig = JSON.parse(service.probesConfig);
            }
        } else {
            console.log("Service non trouvé en base:", serviceId);
        }
        return probesConfig;
    }    

    function setSaisie(value, action) {
        //console.log("setSaisie:",value, action)
        newButtonNames[action] = value;
        setNewButtonNames(JSON.parse(JSON.stringify(newButtonNames)));
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
                <div className={classes.alignItemsAndJustifyContent}>
                {loading && <CircularProgress size={40} />}
                </div>       

                <div>
                    <Grid container direction="row" alignItems="center">
                        <ProbeIcon /> 
                        <Typography variant="h6" component="h2">
                            &nbsp; {probeName} 
                        </Typography>
                    </Grid>
                </div>
                <div className={classes.paragraph}>
                    type de sonde : {probeProperties.type} ({probeProperties.unit}) {/* EVOL1 */}
                </div>

                <Paper elevation={1}  className={classes.paragraph}>
                    <Grid container   direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item><SpellcheckIcon /></Grid>
                        <Grid item>Modification du nom de la sonde</Grid>
                        <Grid item>
                            <IconButton edge="end" aria-label="graphInfo"  onClick={()=>handlePropertySave("name")}  className={classes.saveButton}>
                                <SaveIcon fontSize='small'  color="primary" />
                            </IconButton>  
                        </Grid>
                    </Grid>
                    <form  noValidate autoComplete="off">
                        <TextField  
                                value={probeName}
                                onChange={(e) => {setProbeName(e.target.value)}}
                                id="probeName" 
                                label="Nouveau nom" />
                    </form>
                </Paper>

                <Paper elevation={1}  className={classes.paragraph}>
                    <Grid container   direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item><FunctionsIcon /></Grid>
                        <Grid item>Fonction de transformation</Grid>
                        <Grid item>
                            <IconButton edge="end" aria-label="graphInfo"  onClick={()=>handlePropertySave("axb")}  className={classes.saveButton}>
                                <SaveIcon fontSize='small'  color="primary" />
                            </IconButton>  
                        </Grid>
                    </Grid>
                    <form  noValidate autoComplete="off">
                        <div>
                            Fonction de transformation des mesures reçues
                            <div>Attention</div>
                            <div>les données sources, avant transformation avec ces coéficients, ne sont pas historisées.</div>
                            <div>Seules les données reçues après la modification seront traitées</div>
                            <div>(valeur décimale avec un point et non une virgule</div>
                        </div>

                            Nouvelle valeur

                            <DragHandleIcon/>

                            Valeur reçue X 
                            <TextField  
                                value={functionCoefA}
                                onChange={(e) => {setFunctionCoefA(e.target.value)}}
                                id="functionCoefA" 
                                className={classes.smallFieldNumber}
                                label="Coef A" />

                            <AddIcon/>

                            <TextField  
                                value={functionCoefB}
                                onChange={(e) => {setFunctionCoefB(e.target.value)}}
                                id="functionCoefB" 
                                className={classes.smallFieldNumber}
                                label="Coef B" />                            
                    </form>
                </Paper>


                <Paper className={classes.paragraph} >
                    <Grid container   direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item> <BarChartIcon /></Grid>
                        <Grid item>Repères sur le graphique</Grid>
                        <Grid item>
                            <IconButton edge="end" aria-label="graphInfo"  onClick={()=>handlePropertySave("graphLines")}  className={classes.saveButton}>
                                <SaveIcon fontSize='small'  color="primary" />
                            </IconButton>  
                        </Grid>
                    </Grid>                    
                    <form noValidate autoComplete="off">
                        <div>
                            Affichage de barre horizontale sur le grahique pour cette sonde
                        </div>

                        <div>
                            <div>Ligne 1</div>

                            <TextField  
                                value={graphLine1Name}
                                onChange={(e) => {setGraphLine1Name(e.target.value)}}
                                id="graphLine1Name" 
                                className={classes.smallFieldText}
                                label="Libellé" />   

                            <TextField  
                                value={graphLine1Value}
                                onChange={(e) => {setGraphLine1Value(e.target.value)}}
                                id="graphLine1Value" 
                                className={classes.mediumFieldNumber}
                                label="Valeur (axe Y)" />   


                        </div>
                        <div>
                            <div>Ligne 2</div>

                            <TextField  
                                value={graphLine2Name}
                                onChange={(e) => {setGraphLine2Name(e.target.value)}}
                                id="graphLine2Name" 
                                className={classes.smallFieldText}
                                label="Libellé" />   

                            <TextField  
                                value={graphLine2Value}
                                onChange={(e) => {setGraphLine2Value(e.target.value)}}
                                id="graphLine2Value" 
                                className={classes.mediumFieldNumber}
                                label="Valeur (axe Y)" />   
                        </div>                        
                    </form>
                </Paper>
                {probeProperties.type==='STATE_OUT' &&
                    <Paper elevation={1}  className={classes.paragraph}>
                        <Grid container   direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item><OnOffIcon/></Grid>
                            <Grid item>Actionneur</Grid>
                            <Grid item>
                                <IconButton edge="end" aria-label="graphInfo"  onClick={()=>handlePropertySave("probeButtons")}  className={classes.saveButton}>
                                    <SaveIcon fontSize='small'  color="primary" />
                                </IconButton>  
                            </Grid>
                        </Grid>
                        <form  noValidate autoComplete="off">
                            <div>
                                Nom des actions associé aux boutons
                            </div>
                                {buttonActions.map( (action, key )=> 
                                    <Grid key={key} container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                                        <Grid item> 
                                            Libellé de l'action {(key+1)} ({action})
                                        </Grid>
                                        <Grid item> 
                                                {newButtonNames[action]!=undefined && 
                                                    <div>    
                                                        <TextField  
                                                        value={newButtonNames[action]}
                                                        onChange={(e) => {setSaisie(e.target.value, action)}}
                                                        id="probeStateName{key}" 
                                                        label="Libellé" />  
                                                    </div>  
                                                }
                                        </Grid> 
                                    </Grid>                                        
                                )}                                            
                        </form>
                    </Paper>
                }
                {probeProperties.type==='STATE_IN' &&
                <Paper elevation={1}  className={classes.paragraph}>
                    <Grid container   direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item><OnOffIcon/></Grid>
                        <Grid item>Capteur d'état</Grid>
                        <Grid item>
                            <IconButton edge="end" aria-label="graphInfo"  onClick={()=>handlePropertySave("probeState")}  className={classes.saveButton}>
                                <SaveIcon fontSize='small'  color="primary" />
                            </IconButton>  
                        </Grid>
                    </Grid>
                    <form  noValidate autoComplete="off">
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                            <Grid item>
                                Valeur (0/1) pour l'état On (fonction du cablage)
                            </Grid>
                            <Grid item>
                                <TextField  
                                        value={probeStateValueOn}
                                        onChange={(e) => {setProbeStateValueOn(e.target.value)}}
                                        id="probeStateValueOn" 
                                        className={classes.mediumFieldNumber}
                                        label="0 ou 1" />   
                            </Grid> 
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                            <Grid item> 
                                Nom de l'etat On
                            </Grid>
                            <Grid item> 
                                <TextField  
                                        value={probeStateNameOn}
                                        onChange={(e) => {setProbeStateNameOn(e.target.value)}}
                                        id="probeStateNameOn" 
                                        className={classes.mediumFieldNumber}
                                        label="Nom etat si on" />  
                            </Grid> 
                        </Grid>  
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                            <Grid item> 
                                Nom de l'etat Off
                            </Grid>
                            <Grid item> 
                                <TextField  
                                        value={probeStateNameOff}
                                        onChange={(e) => {setProbeStateNameOff(e.target.value)}}
                                        id="probeStateNameOff" 
                                        className={classes.mediumFieldNumber}
                                        label="Nom etat si off" /> 
                            </Grid> 
                        </Grid>                                               
                    </form>
                </Paper>
                }
        </div>
  );
}

