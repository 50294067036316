import React, { useEffect, useState, useContext } from 'react';

import { API } from "aws-amplify";
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import {useLocation, useHistory } from "react-router-dom";

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';


import {askForRefreshingUser, toSnack, isEmptyObject} from '../Common';
import GeneralContext from '../components/GeneralContext'

const useStyles = makeStyles({
    root: {
    },
    card: {
        marginTop: "60px",
        maxWidth: "800px",
        //display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',    
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },    
    success: {
        fontWeight: "bold",
    },  
});

function theQuery(search) {
    return new URLSearchParams(search);
}

export default function InvitationPage() {

    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [displayBadUserMessage, setDisplayBadUserMessage] = useState(false);
    const [displayOkMessage, setDisplayOkMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [isInvitationProcessed, setIsInvitationProcessed] = useState(false);
    
    const [emailInvitation, setEmailInvitation] = useState();
    const [customerName, setCustomerName] = useState();
    

    const { user } = useContext(GeneralContext);
    //let { code } = useParams()
    const { t } = useTranslation();
    
    const classes = useStyles();
    let history = useHistory()
    let location = useLocation();

    const [loading, setLoading] = useState(true);

    // suite à un click sur un lien pour accepter une invitation à rejoindre une organisation
    useEffect( () => {
        console.log("location:",location);
        //--> invitation depuis un lien dans un mail envoyer par le owner du customer
        // http://localhost:3000/invitation/1245678654345676543?email=test&@test.com&customer=NomCusto
        // --> customer privé, c'est lde code qui déterminera l'accès
        // http://localhost:3000/invitation/private?code=1234
        // --> customer public (ouvert à tout le monde : ex demo)
         // http://localhost:3000/invitation/1245678654345676543?code=PUBLIC
        async function processing() {
            const pathInfo = location.pathname.split('/');
            const params = new URLSearchParams(location.search);
            if (pathInfo.length>2 ) {
                const action = pathInfo[1];
                const customerId = pathInfo[2]; // id ou private

                var invitation = { customerId : customerId};
                const code = params.get('code');
                setCustomerName(params.get('customer'));
                // si accès OPEN via code, on ne connait pas le nom du customer

                if (code) {
                    // mode d'accès PUBLIC ou OPEN
                    invitation['activationCode'] = code;

                } else {
                    // mode d'accès PRIVATE
                    // Controle que le mail de l'utilisateur courant
                    // est bien le même que le mail pour laquelle l'invitation à été préparée

                    const emailInvitation = params.get('email');
                    setEmailInvitation(emailInvitation);
 
                    if (emailInvitation !== user.email) {
                        console.log("Invitation not for you -> invitation:%s , you:%s ",emailInvitation,user.email)
                        setLoading(false);
                        setDisplayBadUserMessage(true)
                        return;
                    }
                }

                setLoading(true);
                try {
                    console.log("PROCCESSING Customer Invitation : ", invitation);
                    await joinCustomerRequestRest(invitation)
                    console.log("Rest Customer Invitation processiong ok" );
                    setLoading(false);
                    setDisplayOkMessage(true)
                    askForRefreshingUser(user.id);   
                    toSnack("UserInvitation",t('screen.customer-invitation.well-processed'));
                    // setTimeout(() => {
                    //     history.replace('/home');
                    //   }, 5000);
                    //history.push("/services/all")
                } 
                catch (responseError) {
                    console.log("Error while joinCustomerRequestRest ", responseError );
                    var message;
                    if (responseError.error==='InvitationNotFound') {
                        message = t('screen.customer-invitation.invite-notfound')
                    } else {
                        message = responseError.message
                    }
                    //toSnack("UserInvitation",message);
                    setErrorMessage(message);
                    setDisplayErrorMessage(true)
                    //history.push("/home")
                }
                setIsInvitationProcessed(true)
                setLoading(false);
            } else {
                console.log("format non exploitable")
            }
        }
        if (!location.search) {
            // bug aléatoire sur safari 
            toSnack("UserInvitation","Sorry, error while processing your invitation. Please contact support");
            setErrorMessage("Veuillez nous excuser. Pouvez-vous cliquer à nouveau sur le lien du mail sinon merci de contacter le support");
            setDisplayErrorMessage(true);
            return;
        }
        if (!isEmptyObject(user) && !isInvitationProcessed) {
            // il faut que le user soit disponible pour comparer le mail du user avec le mail de l'invit
            processing();
        }
    },[location, user]);

    async function joinCustomerRequestRest(data) {
        console.log("joinCustomerRequestRest:", data);

        var apiPath = "/customers/"+data.customerId+"/joinrequest"

        if (data.activationCode) {
            apiPath = apiPath+"?code="+data.activationCode
        }
          
        console.log("API REST - Join request :",apiPath)
        var response;
        try {
            response = await API.put("apiRestCustomer", apiPath, {});
            console.log("response:",response);
            if (response.error) {
                console.log("Error:",response.error, response.message )
                throw response;
            } else {
                console.log("api:", response)
            } 
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
    }



    return (
        <div>
            <Card  className={classes.card}>
                    <Typography  variant="h6">
                        Invitation à consulter les capteurs de l'organisation : {customerName}
                    </Typography>
                    <div>
                   
                    </div>
                    <div>
                        {loading && 
                            <div>
                                <CircularProgress size={40} className={classes.alignItemsAndJustifyContent} />
                                {t('screen.customer-invitation.processing')}
                            </div>
                        }
                        {displayBadUserMessage &&
                            <div>
                                <div>Erreur lors du traitement de l'invitation</div>
                                <hr/>
                                <div>L'invitation est préparée pour un utilisateur s'etant enregistré avec le mail '{emailInvitation}'</div>
                                <div> alors que votre email est '{user.email}'. </div>
                                <div>Veuillez vous reconnecter avec le bon utilisateur.</div>
                            </div>
                        }
                        {displayErrorMessage &&
                            <div>
                                <div>Erreur lors du traitement de l'invitation</div>
                                <hr/>
                                <div>{errorMessage}</div>
                            </div>
                        }                        
                        {displayOkMessage &&
                            <div>
                                <br/>
                                <div className={classes.success}>Félicitations</div>
                                <hr/>
                                <div>Vous avez maintenant accès à l'organisation {customerName}</div>
                                <div>en la selectionnant dans la liste déroulante au centre supérieur de l'ecan</div>
                               {/*  <div><a href="/about">{t("screen.home.for-more")}</a></div> */}
                               <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<TouchAppIcon />}
                                    onClick={() =>  history.replace('/home')}
                                >
                                    Commencer
                                </Button>
                            </div>
                        }                        
                    </div>
            </Card>
        </div>
  );
}

