import React, { Suspense, useEffect}  from 'react'

import Amplify, { Auth } from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';

import { BrowserRouter as Router, Route } from "react-router-dom";

import GeneralContextProvider from './components/GeneralContextProvider'
import MyPortal from './MyAuthentication'; 
import FromMobile from './mobile/FromMobile';

//const serviceWorker = new ServiceWorker();

const MyLoading = (()=> {
    return (
      <div>Loading/Chargement</div>
    )
})

//const serviceWorker = new ServiceWorker();

function App(props) {

    useEffect(() => {
        async function processing() {
            // Register the service worker with `service-worker.js` with service worker scope `/`.
            //console.log("App processing (serviceWorker.register) ...")
            // Register the service worker with `service-worker.js` with service worker scope `/`.
            //const registeredServiceWorker = await serviceWorker.register('/service-worker-amplify.js', '/');
            //console.log("[SW-app.js] v7 in app : REGISTRED ServiceWorker:",registeredServiceWorker);
        }
        processing();
    });

    //const location = useLocation();
    /*
        1) sous domaine chez nous (on voit l'url, pas de demande sur le domaine partenaire)
        home.myiotplatform.com
        visiogreenafrica.myiotplatform.com

        2) domaine partenaire (demande sur le DNS du partenaire)  
        home.visiogreenafrica.com
        iot.ludophone.com (xxx.nomdedomaine.com)

    */
    function handleDomain(route) {
        console.log("route:",route)
        console.log("route.location.pathname:",route.location.pathname)
        console.log("route.location.key:",route.location.key)
        console.log("hostname:",window.location.hostname)

        const defaultPath = "home";
        const params = new URLSearchParams(route.location.search);
        const testPortal = params.get('portal'); // pour test local
        if (testPortal) {
            console.log("Test portal : ",testPortal)
        }
        const withToken = params.get('token'); // appel depuis l'application mobile
        const accountRequest = params.get('newaccount'); // pour arriver directement sur l'inscription 
        
        const theUrl = window.location.hostname.split("."); 
        const parsedData = theUrl.reverse(); // pour que parsedData[1] sit tjrs le domaine (com/domain/sousdomaine)
        console.log("url inversed :",parsedData, parsedData.length)
        var portal;
        if (parsedData.length>1) {
            // si l'url est un sous domaine de myiotplatform.com, on extrait ce sous domaine
            if (parsedData[1]==='myiotplatform') {
                //https://test.myiotplatform.com 
                // le sous domaine
                portal = parsedData[2]

                if (portal === "test" || portal === "dev" ) {
                    console.log("mode test/dev")
                    if (testPortal) { // pour tester 
                        portal = testPortal;
                    } else {
                        portal = "home";
                    }                    
                }
            } else if (parsedData[1]==='amplifyapp') {
                //https://develop.d1d8wldgxu8j4t.amplifyapp.com 
                console.log("via amplify raw")
                portal = "home";

            } else if (parsedData[1]==='ngrok' && testPortal ) {
                portal = testPortal;

            } else {
                // l'url contient le nom du domaine du portail, on extrait le domaine
                portal = parsedData[1]
            }
        } else {
            // localhost pour test local
            //portal = parsedData[0]
            if (testPortal) {
                portal = testPortal;
            } else {
                portal = defaultPath; //home
            }
        }
        console.log("portal from domain:",portal)
        if (portal==="dashboard") {
            console.log("Redirection vers kibana dashboard");
            window.location  = "https://search-iot-access-s3ar2c7nn7ndzkhgviuuimfhke.eu-west-1.es.amazonaws.com/_plugin/kibana/";
        }        
        // maj des metadata dans Amplify.Auth.config() pour l'envoyer à cognito lors du setup pour charge le détail du portail
        updateAuthConfigData(portal)

        if (withToken) {
            return <FromMobile portal = {portal} token = {withToken} />

        } else {
            return  <MyPortal portal = {portal} accountRequest={accountRequest} />
        }
    }

    function updateAuthConfigData(portalName) {

        const currentAuthConfig = Auth.configure();
        console.log("Auth.conf:",currentAuthConfig);

        var metaData = currentAuthConfig.clientMetadata;
        if (metaData) {
            metaData["portal"] = portalName;
            metaData["language"] = "fr"
        } else {
            metaData = {
                portal: portalName,
                language: "fr"
            } 
        }

        Amplify.configure({
            Auth: {
                // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
                mandatorySignIn: false,
                clientMetadata: metaData
            }
        });    
    }

    return (
        <Suspense fallback={<MyLoading/>} > 
            <Authenticator.Provider>
                <GeneralContextProvider>
                    <Router>   
                        <Route 
                            path="/" 
                            render={props =>handleDomain(props)} /> 
                    </Router>
                </GeneralContextProvider>  
            </Authenticator.Provider>
        </Suspense>
    )
}

export default App;

/*
<Route  path='/truc'  render={(props) => (
  <Truc {...props} propsTruc={extraProps}/>
)}/>

window.location.pathname.split("/"); 
*/