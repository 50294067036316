// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobileDev = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6478868f-ae8f-4783-9539-7e4aec8499d2",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_5F4qTcKm9",
    "aws_user_pools_web_client_id": "36tumrk33a0gkd896ovtc4kb3h",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://p5gihld2xnaariceyfklp2jcxy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    'aws_appsync_apiKey': 'da2-qlde2tagufhwfj4vky2gskh5cq',
    "aws_cloud_logic_custom": [
        {
            "name": "iotRestInput",
            "endpoint": "https://8t8bbjjgm2.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "iotAttachDevice",
            "endpoint": "https://09y0445e7b.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "apiRestCustomer",
            "endpoint": "https://wnzmdc7w78.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "apiRestExport",
            "endpoint": "https://8qiwh4j9w4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "apiSendToDevice",
            "endpoint": "https://j0yhb0776c.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "iotLockAdmin",
            "endpoint": "https://me8yfqwlq2.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "apiGetImage",
            "endpoint": "https://sdtokgbj9g.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "apiS3files",
            "endpoint": "https://go3xmmb1u6.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "iot-platform-filesdev-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_content_delivery_bucket": "iot-project2-20200217132611-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://iot-project2-20200217132611-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com",
    "aws_mobile_analytics_app_id": "dba39bed030e4fcabf35b81fe5d6119e",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "exe_env_name": "dev"
};

const awsmobileProd = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:13b4d24c-8132-4f44-8b26-7023dd8ce81a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_nWKmttmcL",
    "aws_user_pools_web_client_id": "45jnk06d1rjj3stf1t103eolfm",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://thmnnbi4qzaathq2scoiiqddd4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    'aws_appsync_apiKey': 'da2-oqplmf2xwne7reovjbo6v5kfey',
    "aws_cloud_logic_custom": [
        {
            "name": "iotRestInput",
            "endpoint": "https://w2kmlmhgm7.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "iotAttachDevice",
            "endpoint": "https://nn0u658u60.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "apiRestCustomer",
            "endpoint": "https://yo4x46fgb6.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "apiRestExport",
            "endpoint": "https://85una0zfjh.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "apiSendToDevice",
            "endpoint": "https://deg5iwm63i.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "iotLockAdmin",
            "endpoint": "https://ih9p5y1vi2.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "apiGetImage",
            "endpoint": "https://ka74ayiz6k.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "apiS3files",
            "endpoint": "https://igdgtagdt5.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }                       
    ],
    "aws_user_files_s3_bucket": "iot-platform-filesprod-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_content_delivery_bucket": "iot-project2-20200217132611-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://iot-project2-20200217132611-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com",
    "aws_mobile_analytics_app_id": "9bf3ec81b54b4980a8ae1dfbbdd48ead",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "exe_env_name": "prod",
};


export default awsmobileProd;
