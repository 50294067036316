import React, { useState } from 'react';
import { API } from "aws-amplify";

import { useTranslation } from 'react-i18next';
import { toSnack} from '../Common';
import Button from '@material-ui/core/Button';

import { createTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));
const theme = createTheme({
    palette: {
      //primary: green,
    },
  });
  

export default function ProbeDisplayStateOut({ serviceId, probe }) {

    const [stateButtons, setStateButtons] = useState({});

    const classes = useStyles();

    async function actuatorChange(probe, action) {
        console.log(probe.id, action)
        changeStateButton(probe,true); // disabled
        try {
            const response = await sendNewStateToDeviceRest({ probeId: probe.id, action: action});
            console.log(response);
            if (response.success) {
                toSnack("ActuatorButtonUse","La commande a été correctement envoyée à l'opérateur ("+response.message+")");
            } else {
                toSnack("ActuatorButtonUse","Erreur lors de l'envoi de la commande à l'opérateur :"+response.message);
            }
        }
        catch (error) {
            console.log(error)
            toSnack("ActuatorButtonUse","Erreur lors de l'envoi de la commande à l'opérateur ("+error+")");
        }
        finally {
            changeStateButton(probe,false); // re-activate button
        }
    }

    function changeStateButton(probe, newState) {
        const allStates = stateButtons;
        allStates[probe.id] = newState
        setStateButtons(JSON.parse(JSON.stringify(allStates))); // pour forcer la maj de l'etat
    }

    async function sendNewStateToDeviceRest(data) {
        console.log("sendNewStateToDeviceRest:", data);

        var apiPath = "/device/"+serviceId;

        let myInit = {
            body: {
                probe: data.probeId, 
                action: data.action
            }, 
            headers: {} // OPTIONAL
        }  

        /*         
        if (data.activationCode) {
            apiPath = apiPath+"?code="+data.activationCode
        }
        */

        console.log("API REST - device actuator change request :",apiPath)
        var response;
        try {
            response = await API.put("apiSendToDevice", apiPath, myInit);
            //console.log("response:",response);
            return response; 
        }
        catch (error) {
            //console.log(error.response)
            console.log(error)
            throw error;
        }
    }

    function getStateButton(probe) {
        return stateButtons[probe.id]
    }

    function changeStateButton(probe, newState) {
        const allStates = stateButtons;
        allStates[probe.id] = newState
        setStateButtons(JSON.parse(JSON.stringify(allStates))); // pour forcer la maj de l'etat
    }

    function testButton(probe) {
        changeStateButton(probe,stateButtons[probe.id]?false:true );
    }

    const { t } = useTranslation();

    return (
        <>
        <div>
        {probe.buttons && 
            probe.buttons.map( (button,key) => 
                <Button 
                    classes={{root: classes.actionButton}}
                    disabled={getStateButton(probe)}
                    key={key}
                    onClick={() => actuatorChange(probe,button.action)}
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button} >
                {button.display}    
                </Button>                               
        )}
        </div>
        { probe.hasOwnProperty("state") &&
            <div>({probe.state})</div>
        }
    </>
    );
}
