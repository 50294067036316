//fro mhttps://dev.to/woile/simplest-react-hook-component-for-pwa-install-button-2die
// pour ios : https://medium.com/swlh/a-simple-react-hook-to-prompt-ios-users-to-install-your-wonderful-pwa-4cc06e7f31fa
import React, { useEffect, useState, Suspense } from "react";

import { useTranslation } from 'react-i18next';


const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("PWA : we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    console.log("PWA event listener loaded");
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <Suspense fallback="loading">
      <button
        className="link-button"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        {t('screen.app-to-homescreen.adding')}
      </button>
    </Suspense>
  );
};

export default InstallPWA;
