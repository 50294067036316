import React, { useContext, useState, useEffect , Component} from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { mapToArray } from '../../Common';
import ServiceDisplay from '../../components/ServiceDisplay'
import GeneralContext from '../../components/GeneralContext'

const useStyles = makeStyles(theme => ({
    root: {
     marginTop: "25px"
    },
    serviceValue: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      backgroundColor: "red"
    },
    serviceDetailContent: {
      margin: "3px",
      padding: "5px"
    },
    service: {
      height: "100%",
      paddingRight: "2px",
      backgroundColor: "#fff"
  }
}));

export default function ServiceDetailDialog(props) {
    const { openState, service, toClose } = props;

    //const [openState, setOpenState] = useState(false);
    const [probesToDisplay, setProbesToDisplay] = useState([]);
    const [dateLastValue, setDateLastValue] = useState();


    const { user, customer } = useContext(GeneralContext);
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect( () => {
      console.log("openState:", openState)
    }, [openState])

    useEffect( () => {
      console.log("service:", service)
      if (service) {
        var probesRaw;
        // bizarement au premier click, probes est une stringet les fois suivants, c'est un objet!
        if (typeof service.lastValue.probes === 'string' || service.lastValue.probes instanceof String) {
          probesRaw = JSON.parse(service.lastValue.probes)
        } else {
          probesRaw = service.lastValue.probes;
        }
        const probes = mapToArray(probesRaw);
        //const probes = mapToArray(service.lastValue.probes);
        setProbesToDisplay(probes);
        setDateLastValue(service.lastValue.dateEvent); //TODO convertir le format
      }
    }, [service])

    const handleClose = (action) => {
      console.log("handle close")
      toClose()
    };

    return (
      <Dialog  open={openState}  onClose={handleClose} aria-labelledby="service-dialog-title" >
        <DialogContent className={classes.serviceDetailContent}>
          <div className={classes.service}>
            <ServiceDisplay customerId={customer.id} data={service} /> 
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose({action:"cancel"})} color="primary">
              {t('global.button.cancel')}
          </Button>                       
        </DialogActions>          
      </Dialog> 
    )
} 