import React, { useContext, useState, useEffect } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import GeneralContext from '../../components/GeneralContext'
import Grid from '@material-ui/core/Grid';

import CenterInPage from '../../components/CenterInPage';

import HomePageApartment from './HomePageApartment';
import HomePageHouse from './HomePageHouse';
import HomePageOffice from './HomePageOffice';
import HomePageNotFound from './HomePageNotFound';

const useStyles = makeStyles(theme => ({
    root: {
      marginTop: "25px"
    }
}));

function Loading() {
  return(
    <div>
       <br></br><br></br>
       Loading ...
    </div>
  )
}

const components = {
  apart: HomePageApartment,
  house: HomePageHouse,
  office: HomePageOffice,
  notfound: HomePageNotFound,
  loading: Loading
}; 

export default function CustomHomePage({customerID}) {

  const { portal, user, customer } = useContext(GeneralContext);

  const [ componentName, setComponentName ] = useState('loading');
  const [ pageConfig, setPageConfig ] = useState();
  
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  useEffect( () => {
    console.log("Customer :",customerID, customer.id);

    if (customer.homePageConfig) {
      setPageConfig(JSON.parse(customer.homePageConfig))
    } else {
      // pas encore de config, c'est la première fois 
      const displayPageConfig = buildEmptyPageConfig();
      setPageConfig(displayPageConfig);
    } 

    if (customer.homePage) {
        console.log("Customer.homePage:", customer.homePage);
        if (customer.homePage === "CUSTOM_APART") {
          setComponentName('apart');
      
        } else if (customer.homePage === "CUSTOM_HOUSE") {
          setComponentName('house');
      
        } else if (customer.homePage === "CUSTOM_OFFICE") {
          setComponentName('office');
      
        } else {
          setComponentName('notfound');
        }
    }
  }, [customerID]) 

  const buildEmptyPageConfig = () => {
    return {
      "CUSTOM_APART": {
      "backgroundImage": "",
        "services": {
          "1234": {
            "probeId": "probe1",
            "position": {
              "x": 50,
              "y": 50
            }
          }
        }
      }
    };
  }
  const TagName = components[componentName];
  return (
    <TagName config={pageConfig} customerID={customerID}/>
  )
}
