import React, { useEffect, useState } from 'react';
import { useHistory, useParams} from "react-router-dom";

import GraphQlApi from '../mobile/MyGraphql';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import ServiceDisplay from '../components/ServiceDisplay'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    serviceBox: {
        margin: 50,
        padding: 10
    },
    service: {
        height: "100%",
        width: "80%",
        marginTop:20,
        paddingRight: "10px",
        backgroundColor: "#fff",
        textAlign: "center"
    },
    appBarParam: {
        position: 'relative',
    },
    mainErrorText: {
        textAlign: 'center',
        padding: '10px'
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    textDetail: {
        fontStyle: 'italic'
    }    
}));

export default function ServiceViewPage(props) {

    let { id: serviceId } = useParams();

    const [service, setService] = useState();
    const [unauthorizedError, setUnauthorizedError] = useState(false);

    const [loading, setLoading] = useState(false);

    let history = useHistory()
    const classes = useStyles();

// recuperation du service concerné
    useEffect(() => {
          let ignore = false;
          async function fetchData(serviceId) {
              console.log("Service is loading for %s ...", serviceId);
              setLoading(true);
              const getServiceQuery = `query GetService($id: ID!) {
                    getService(id: $id) {
                        id
                        name
                        serviceType
                        globalConfig                        
                        device {
                            id
                            manufacturer
                            operator
                            status
                            template {
                                id
                                probesDisplay
                            }          
                        }
                        lastValue {
                            id
                            dateEvent
                            probes
                            commOperator
                            slidingCumul
                            customerId
                        }
                        probesConfig
                        dailyStats(limit:1, sortDirection:DESC) {
                            items {
                                id
                                eventDay
                                values
                            }
                        } 
                    }
                }`;
                // il s'agit d'un service unitaire. Si il y a une erreur, on ne s(interesse pas aux valeurs du result)
                try {
                    //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
                    const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery, { id: serviceId });
                    console.log(result);
                    if (result.errors) {
                        const error = result.errors[0]; // a revoir, on ne considere que le première erreur
                        if (error.errorType==="Unauthorized") {
                            console.log("reponse : Unauthorized : le capteur n'est pas visible par l'utilisateur");
                            setUnauthorizedError(true)
                        } else {
                            console.log("Il y a des erreurs : ", result.errors);
                        }
                    }
                    if (result.data) {
                        if (result.data.getService) {
                            const theService = result.data.getService
                            console.log("service :", theService);
                            setService(theService)

                        } else {
                             //getService est null
                             console.log("Le service est null, il y a surement des erreurs");
                        }
                    }
                }
                catch(resultErrors) {
                    console.log("Errors:", JSON.stringify(resultErrors));
                    // petites differences entre API.graphql et AwsAppSyncClient
                    const errors = resultErrors.errors?resultErrors.errors:(resultErrors.graphQLErrors?resultErrors.graphQLErrors:null);
                    if (errors) {
                        // API.graphql renvoi une liste d'erreur
                        const error = errors[0]; // il y a forcement au minimum une erreur
                        if (error.errorType==="Unauthorized") {
                            // Le user, pour le customer actuel, n'a pas accès à ce service
                            setUnauthorizedError(true)
                        } else {
                            console.log("Service non trouvé en base");
                            console.log("Erreurs:",errors);
                        }
                    } else {
                        // AppSyncClient ne renvoit qu'une erreur
                        const errorMsg = resultErrors;
                        if (errorMsg.includes("Not Authorized")) {
                            setUnauthorizedError(true)
                        } else {
                            console.log("Service non trouvé en base");
                            //console.log("Erreurs:",resultErrors);
                        }

                    }
                }
                setLoading(false);
  
          }      
          fetchData(serviceId);
          return () => { ignore = true; }
  
    }, [serviceId]);   

    function handleClose() {
        history.goBack()
    }

    return (
        <div>
            <AppBar className={classes.appBarParam}>
                <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <BackIcon />
                </IconButton>
                <Typography variant="h6" className={classes.titleParam}>
                    Visualisation
                </Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.alignItemsAndJustifyContent}>
                {loading && <CircularProgress size={40} />}
            </div>


            {service &&
                <Container className={classes.service}>
                    <ServiceDisplay data={service} />
                </Container>
            }
            {unauthorizedError &&
                <div className={classes.mainErrorText}>
                    <div className={classes.alignItemsAndJustifyContent}>Vous n'avez pas accès à ce capteur.</div>
                    <div className={classes.alignItemsAndJustifyContent}>Il dépend d'un autre client que votre client actuel.</div>
                    <div className={classes.alignItemsAndJustifyContent, classes.textDetail}>(Vous pouvez peut-être selectionner le bon client par la liste déroulante dans le bandeau supérieur)</div>
                </div>
            }
        </div>
    )
}