import React, { useEffect, useState, useRef, useContext } from 'react';
import Amplify, { PubSub, Auth, Storage, API, graphqlOperation, Hub } from 'aws-amplify';
import { AWSIoTProvider} from '@aws-amplify/pubsub';
//import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
//import { MqttOverWSProvider } from "@aws-amplify/pubsub/lib/Providers";

import {IoTClient, ListAttachedPoliciesCommand, AttachPolicyCommand} from "@aws-sdk/client-iot";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';

import '@aws-amplify/ui-react/styles.css';
import GeneralContext from './GeneralContext'

import { nanoid } from 'nanoid'

const APP_NAME = "smarthomes-v1";
const EVENT_TYPE_WEBRTC = "webrtc"; // pour la demande au broker d'un démarrage  flux webrtc via janus
const EVENT_TYPE_IMAGE = "image";   // pour la demande au broker de lastImage (ffmpeg)

const debug=true;
const info=true;


const ICE_SERVERS = [ 
  /*           {
              urls: 'stun:stun.voip.eutelia.it'
            },       */     
            {
              urls: 'stun:stun.l.google.com:19302'
            },  
            /*             
            {
              urls: 'stun:stun.etoilediese.fr:3478'
            },
            {
              urls: "turn:numb.viagenie.ca",
              username: "marc.bordessoule@strategie-numerique.fr",
              credential: "EJC9CUEdZ25T!nN",
            }            

            {
              urls: 'stun:stun.ippi.fr:3478'
            },       
            {
              urls: 'stun:stun.liveo.fr:3478'
            },  
            {
              urls: 'stun:stun.avigora.fr:3478'
            },   
            {
              urls: 'stun:stun.freecall.com:3478'
            },  
            {
              urls: 'stun:stun.stunprotocol.org:3478'
            },
  */        /* 
          {
            urls: "stun:openrelay.metered.ca:80",
          },
          {
            urls: "turn:openrelay.metered.ca:80",
            username: "openrelayproject",
            credential: "openrelayproject",
          },
          {
            urls: "turn:openrelay.metered.ca:443",
            username: "openrelayproject",
            credential: "openrelayproject",
          },
          {
            urls: "turn:openrelay.metered.ca:443?transport=tcp",
            username: "openrelayproject",
            credential: "openrelayproject",
          },   */
        ];

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    identityPoolId: "eu-west-1:13b4d24c-8132-4f44-8b26-7023dd8ce81a"
  }
})

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'eu-west-1',
  aws_pubsub_endpoint: 'wss://a1b66uhqp1opks-ats.iot.eu-west-1.amazonaws.com/mqtt',
}));


let mySub;

const useStyles = makeStyles(theme => ({
  root: {
  },
  video: {
   // borderStyle: "solid"
   display: "block"
  }

}));

export default function ServiceCamerasView({serviceId, deviceMqttId, streams, streamIdDefault, customerID }) {


  let timestampPrev;
  let bytesPrev;

  const subscribePathImage = APP_NAME+"/"+deviceMqttId+"/"+EVENT_TYPE_IMAGE+"/camera_1/Last_Frame/ack"; // envoyé après la copie dans S3


// smarthomes-v1/chezMarc/janus01/webrtc

  const { user, customer } = useContext(GeneralContext);

  const [step, setStep] = useState();

  const [status, setStatus] = useState("Démarrage");

  const [transactions, setTransactions] = useState([]);

  const [message, setMessage] = useState();
  const [messageImg, setMessageImg] = useState();

  const [streamId, setStreamId] = useState();
  const [streamName, setStreamName] = useState();

  const [sessionId, setSessionId] = useState();
  const [handleId, setHandleId] = useState();

  const [userIdentityId, setUserIdentityId] = useState();
  
  const [webRtcBitrate, setWebRtcBitrate] = useState("");
  const [webRtcStats, setWebRtcStats] = useState("");

  const mqttSub =  useRef();
  const remoteVideo = useRef(null);
  const streamingPeerConnection = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const [ publishPathWebrtc, setPublishPathWebrtc] = useState();

  const [lastFrameImage, setLastFrameImage] = useState("/camera-start.webp");  

  const [switchChecked, setSwitchChecked] = React.useState(false);

  const [loading, setLoading] = useState(false);
  const [webRtcUp, setWebRtcUp] = useState(false);
  const webRtcUpRef = useRef(webRtcUp);

  const classes = useStyles();


  useEffect(() => {
    console.log("init Camera");

/*     Hub.listen('api', (data) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState;
        console.log(connectionState);
      }
    }); */

    mqttEnable() 
  }, []);

  useEffect(() => {
    console.log("deviceMqttId:",deviceMqttId)

    const publishPathWebrtc = APP_NAME+"/to/"+deviceMqttId+"/"+EVENT_TYPE_WEBRTC;
    setPublishPathWebrtc(publishPathWebrtc);

  }, [deviceMqttId]);

  useEffect(() => {
    webRtcUpRef.current = webRtcUp;
  }, [webRtcUp]);

  useEffect(() => {
    if (streamIdDefault && streams) {
      console.log("streamIdDefault changed", streamIdDefault)
      const currentStream = streams.find( item => item.id === streamIdDefault )
      setStreamName(currentStream.name)
      setStreamId(currentStream.id)
    }
  }, [streamIdDefault, streams]);


  useEffect(() => {
    if (streamId) {
      console.log("streamId has changed:", streamId)
    }
  }, [streamId]);



  useEffect(() => {
    async function processing() {
      //console.log("new message:", message)
      messageProcessing(JSON.parse(message));
    }
    if (message) processing();
  }, [message]);
  
  useEffect(() => {
    async function processing() {
      const message = JSON.parse(messageImg);
      console.log("new image message from the box :", message);
      if (message.filename) {
        await getImageFromS3(message.filename);
      } else {
        console.log("Erreur : le nom du fichier pur recupt dans s3 est absent")
      }

      //messageProcessing(JSON.parse(message));
    }
    if (messageImg) processing();
  }, [messageImg]);  //  reception d'un message MQTT  

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log('This will run every 2 second!');
      showWebRtcStats();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  // Souscription à la reception de changement sur  LastValue
  useEffect(() => {
    // subscription possible alors que les droits sont en read dans graphql : 
    // https://github.com/aws-amplify/amplify-cli/issues/2636#issuecomment-567286984i
    var subscription;
    function subscribeForCustomerEvent(customerId) {
        console.log("SUBSCRIPTION SUR LASTVALUE pour service (Caméra)  ", serviceId)
        // Subscribe to creation of Todo
        const onUpdateLastValue = /* GraphQL */ `
        subscription OnUpdateLastValue($customerId: String!) {
            onUpdateLastValue(customerId: $customerId) {
                id
                event
                customerId       
            }
        }
        `;
        subscription = API.graphql(
            graphqlOperation(onUpdateLastValue,{customerId : customerId})
        ).subscribe({
            next: ({ provider, value }) => {
                const lastValEvent = value.data.onUpdateLastValue;
                // chaque service reçoit tous les events sur lastValue pour le customer
                // si c'est pour lui, il traite ...
                if (lastValEvent && lastValEvent.id == serviceId) {
                    console.log("************ (Caméra) NewValue for service %s *************************", lastValEvent.id);
                    // la service dans la table LastValue a été modifié
                    // il y a un event de type newLastImage
                    // c'est qu'une image à été uploadée dans S3 (private/ ... / lastImage/ ...)
                    // suite à l'arrivée dans iot.Rules d'un message mqtt avec bimage binaire 
                    // en provenance de la smartbox
                    console.log("event:", lastValEvent);
                    if (lastValEvent.event) {
                      const mqttEvent = JSON.parse(lastValEvent.event)
                      if (mqttEvent.action === "newLastImage") {
                        if (mqttEvent.fileName && mqttEvent.camera) {
                          console.log("LastImage is ready in s3, Getting image %s ", mqttEvent.fileName)
                          getImageFromS3(serviceId, mqttEvent.camera, mqttEvent.fileName);
                          console.log("image ok");
                        } else {
                          console.log("Error : File name not found in mqttEvent");
                        }
                      } else {
                        console.log("Error : unkwnown action in mqttEvent ", mqttEvent.action);
                      }
                    } else {
                      console.log("Error : Subscription event without mqttEvent");
                    }
                } 
            }
        });
    }

    if (serviceId && customer) {
        subscribeForCustomerEvent(customer.id);
        return function cleanup() {
            console.log("cleanup - UNSUBSCRIBE pour service (Caméra) ", serviceId);
            setLastFrameImage("/camera-start.webp");  
            subscription.unsubscribe();
        };
    }
  },[serviceId]);

  useEffect(() => {
    if (userIdentityId) {
      // userIdentityId is ready
      console.log("userIdentityId is Ready", userIdentityId); 
      console.log("askingForCameraLastFrame (%S / %s)...",deviceMqttId, serviceId); 
      askingForCameraLastFrame(userIdentityId, serviceId, deviceMqttId, customerID);
      return function cleanup() {
        console.log("cleanup %s - askingForCameraLastFrame ",deviceMqttId)
      }
    }
  },[serviceId,userIdentityId]);

  async function getImageFromS3(serviceId, camId, filename) {
    const service_cam = serviceId+"_"+camId;
    const filePath = customer.id+"/last_frame/"+service_cam+"/"+filename; // +".jpeg";
    console.log("getImage from S3 with :",filePath);
    const signedURL = await Storage.get(filePath, { level: 'private'});
    console.log("signedURL:",signedURL);
    setLastFrameImage(signedURL);
  }


  async function mqttEnable() {
    console.log("Gestion des autorisations mqtt");

    const credentials = await Auth.currentCredentials();
    //console.log("credentials:",credentials);
    const userIdentityId = credentials.identityId;
    console.log("User Cognito Identity Id:",userIdentityId);
    setUserIdentityId(userIdentityId);

    const iot = new IoTClient({
      region: 'eu-west-1',
      credentials: Auth.essentialCredentials(credentials)
    });

    const policyName = 'amplify-client-iot-policy';
    
    const listAttachedPolicies = new ListAttachedPoliciesCommand({ target: userIdentityId });
    const attachPolicy = new AttachPolicyCommand({policyName: policyName, target: userIdentityId});

    console.log("Rechercher des authorisations mqtt ...");
    const  { policies }  = await iot.send(listAttachedPolicies);
    console.log("user policies:", policies);

    if (!policies.find(policy => policy.policyName === policyName)) {
      console.log("Policy not found for this user. Attaching to him ...")
      await iot.send(attachPolicy);
      console.log("Mqtt authorizations ok now ");
    } else {
      console.log("Policy already found for this user.")
    }    
    // Mqtt ok, on peut s'abonner au flux de reception d'une image
    //subscribeCameraLastImageReceived(userIdentityId);
  }

  // souscription aux message venant de la smartbox
  // async function subscribeCameraLastImageReceived(userIdentityId) {
  //   console.log("subscribing to %s",subscribePathImage)
  //   mqttSub.current = await PubSub.subscribe(subscribePathImage/* , {parseJSON:false} */).subscribe({
  //     next: data => setMessageImg(JSON.stringify(data.value, null, 2)),
  //     error: error => {
  //       console.log("Error while PubSub.subscribe ", error);
  //       setMessageImg(JSON.stringify(error, null, 2))
  //     },
  //     close: () => setMessageImg('Done'),
  //   })
  //   console.log("subscribe for lastFrame from camera : ok"); 
  // }

  // on demande pour lastFrameImage à la smatbox dés que 
  // mqtt est dispo
  // la subscription graphQl est ok
  // le userIdentityId est dispo
  async function askingForCameraLastFrame(userIdentityId, serviceId, deviceMqttId, customerId) {
    console.log("Asking Camera LastFrame from smartbox:", userIdentityId, serviceId, deviceMqttId, customerId);
    const message = {
      "customerId": customer.id,
      "userIdentityId": userIdentityId,
      "serviceId": serviceId
    }
    const publishPathImage = APP_NAME+"/to/"+deviceMqttId+"/"+EVENT_TYPE_IMAGE+"/camera_1/Last_Frame";
    //                   smarthomes-v1/to/chezNobody       / janus_01/image     /camera_1/Last_Frame

    console.log("mqtt publish : ", publishPathImage, message);
    try {
      await PubSub.publish(publishPathImage, message);
    } catch (error) {
      if (error.code==="AMQJS0011E") {
        console.log("MQTT ERROR : AMQJS0011E Invalid state not connected. Trying Amplify.addPluggable again ");
        Amplify.addPluggable(new AWSIoTProvider({
          aws_pubsub_region: 'eu-west-1',
          aws_pubsub_endpoint: 'wss://a1b66uhqp1opks-ats.iot.eu-west-1.amazonaws.com/mqtt',
        }));
        
      } else {
        console.log("MQTT ERROR : ",error);
        //throw error
      }
    }

  }
  

  function findTransaction(payload) {
    if (payload.transaction) {
      return transactions.findIndex( item => item.transactionId === payload.transaction); 
    }
  }

  function transactionUpdate(payload) {

    var pluginStatus, errorMsg;
    if (payload.janus==='event') {
      
      if (payload.plugindata.data) {
        const pluginResponse = payload.plugindata.data;
        if (pluginResponse.result && pluginResponse.result.status) {
          pluginStatus = payload.plugindata.data.result.status;
        } else {
          //payload.plugindata.data->error:Already watching mountpoint 456, error_code 460
          console.log("payload.plugindata.data unkwnow:",payload.plugindata.data)
        }
      }
    }
    if (payload.janus === 'error') {
      errorMsg = payload.error.reason;
    }

    const transactionIndex = findTransaction(payload);
    if (transactionIndex>-1) {
      if (transactions[transactionIndex].response) {
        // si il y a déja une réponse pour cette transaction, on créé une ligne supplémentaire

        const existingResponse = JSON.parse(JSON.stringify(transactions[transactionIndex])); 
        existingResponse['response'] = payload.janus;

        if (payload.janus==='event') {
          existingResponse['response'] = pluginStatus;

        } else if (payload.janus==='error') {
          existingResponse['response'] = pluginStatus + errorMsg;
        }


        setTransactions( old => [...old, existingResponse ]);

      } else {
        transactions[transactionIndex]['response'] = payload.janus;
        setTransactions( old => transactions);
      }

    } else {
      console.log("payload sans transaction status:[%s] :", payload.janus); // on ajoute au tableau
      const janusStatus = payload.janus;
      var responseWithoutTransac;
      if (pluginStatus) {
        // forcement payload.janus = event
        // ex starting, started
        responseWithoutTransac = { transactionId: "", action: "Statut srv flux: ", response: pluginStatus};

      } else if (payload.janus==='webrtcup') {
        responseWithoutTransac = { transactionId: "", action: "Statut caméra: ", response:payload.janus};
        setWebRtcUp(true);
        // dans certain cas il y a un delai entre webrtcup et le unmute : le sablier sera effacer trop top, sauf si un onMute arrive vite
        // il se peut qu'il n'y ai pas de onUnmute (qui arrete le sablier) après le webrtcup
        // pour ce cas on l 'arrete ici
        setLoading(false);
        unSubscribeToBoxEvent();

      } else if (payload.janus==='timeout') {
        responseWithoutTransac = { transactionId: "", action: "Video session", response:payload.janus};
        setLoading(false);
        unSubscribeToBoxEvent();

      } else if (payload.janus==='detached') {
        responseWithoutTransac = { transactionId: "", action: "Video session", response:payload.janus};
        setLoading(false);
        unSubscribeToBoxEvent();
        
      } else if (payload.janus==='hangup') {
        console.log("hangup for reason : ", payload.reason);
        responseWithoutTransac = { transactionId: "", action: "Video session", response:payload.reason};
        setLoading(false);
        unSubscribeToBoxEvent();        
                
      } else {
        responseWithoutTransac = { transactionId: "", action: "", response: payload.janus};
      }
      setTransactions( old => [...old, responseWithoutTransac ]);
    }
  }
  function transactionUpdateOutsideJanus(transactionId, response) {
    const transactionIndex = transactions.findIndex( item => item.transactionId === transactionId); 
    if (transactionIndex>-1) {
      transactions[transactionIndex]['response'] = response;
    } else {
      console.log("Transaction %s introuvable !", transactionId, transactions);
    }
  }

  async function messageProcessing(payload) {
    console.log("RESPONSE with message for (transaction:%s)  step:%s ",  payload.transaction, step, payload)

    transactionUpdate(payload); // associer si possible la transaction de la réponse avec la request

    if (step=="session") {

      if (payload.janus==='success') {

        const sessionId = payload.data.id;
        console.log(">sessionId:",sessionId);
        setSessionId(sessionId);
        await sendAskForPluginAttachement(sessionId)

      } else {
        console.log("Receiving session without success : ", payload);
        setStep('sessionError')
      }

    } else if (step=="attach") {
      if (payload.janus==='success') {

        // l'attachement avec le plugin stream a reussi
        // on enrichi la transaction avec le handle id pour le plugin
        const handleId = payload.data.id
        console.log(">handleId:",handleId);

        setHandleId(handleId)
        await sendToPluginWatchAStream(handleId)

      } else {
        console.log("Receiving attach response with no success answer : ");
        setStep('attachError')
      }

    } else if (step=="watch") {
      if (payload.janus==='ack') {

        //if (debug) console.log('watch ack');

      } else if (payload.janus==='event') {

        console.log('watch response with OFFER received from remote jsep -> sdp:', payload.jsep.sdp);
        try {
          const answer = await doAnswerWebRTC(payload.jsep, sessionId, handleId);
          console.log('doAnswer terminé');
          console.log("sending  jsep ANSWER to plugin > sdp : ",answer.sdp);
          sendStartToPlugin({ jsep: answer,  sessionId: sessionId, handleId: handleId});
        } catch (e) {
          console.log('Receiving error during streaming setup', e);
          stopAllStreams();
          closePC();
        }        

      } else {
        console.log("Receiving watch request response with unknown answer");
        setStep('watchError')
      }

    } else if (step=="trickle") { 
      //console.log("Trickle response for %s :", payload.transaction, payload);
      if (payload.janus==="sucess") {
        
      } else if (payload.janus==="error") {

      } else if (payload.janus==="webrtcup") {
        console.log("WEBRTC UP ----------------")
      }
      
    } else if (step==='start') {
      //console.log("pugin Start response:", payload);

    } else if (step==='stop') {
      console.log("plugin Stop response:", payload);

    } else {
      console.log("Unkwnon step response : ", payload);
    }
  }

  function addToTransactions(newTransaction) {
    setTransactions(oldTansac => [...oldTansac, newTransaction]);
  }

  async function connect(deviceMqttId) {

    const connectTransactionId = "0000001";
    
    const newTransaction = {transactionId:connectTransactionId, action: "Connecting to the cloud"}
    setTransactions(oldTansac =>  [...oldTansac, newTransaction]);

    setStep("connect")
    setStatus("connected")
    if (mqttSub.current) {
      console.log('unsubscribed before')
      mqttSub.current.unsubscribe();
    }

    const subscribePathWebrtc = APP_NAME+"/"+deviceMqttId+"/"+EVENT_TYPE_WEBRTC;
    console.log("subscribing to %s",subscribePathWebrtc)

    mqttSub.current = await PubSub.subscribe(subscribePathWebrtc).subscribe({
      next: data => setMessage(JSON.stringify(data.value, null, 2)),
      error: error => setMessage(JSON.stringify(error, null, 2)),
      close: () => setMessage('Done'),
    })
    console.log("subscribe: ok1", transactions);


    //transactionUpdateOutsideJanus(connectTransactionId, "success") 
    const newTransaction1 = {transactionId:connectTransactionId, action: "Connecting to the cloud", response: "success"}
    setTransactions(oldTansac =>  [...oldTansac, newTransaction1]);    
  };

  function unSubscribeToBoxEvent() {
    console.log('unsubscribed from smartBox')
    mqttUnSubscribe();
  }

  async function startWatching() {
    console.log("connecting to cloud (AWS)");
    setLoading(true);
    setWebRtcUp(false);
    setWebRtcBitrate("");
    setWebRtcStats("");

/*     const localStream = await navigator.mediaDevices.getUserMedia({video: true,  audio: true});
    const configuration = {};
    const pc1 = new RTCPeerConnection(configuration);    
    localStream.getTracks().forEach(track => {
      console.log('adding track', track);
      pc1.addTrack(track, localStream);
    }); */


    const codecs = RTCRtpSender.getCapabilities('video');
    console.log("codecs:",codecs)

    await connect(deviceMqttId);
    console.log("starting watching (%s)", deviceMqttId)
    await sendAskingForSession();
  };

  async function stopWatching() {
    console.log("stopping ");
    setWebRtcUp(false);
    setTransactions([]);
    setSessionId();
    setHandleId();
    await sendPluginDetachStream();
    stopAllStreams();
    streamingPeerConnection.current = null;
    mqttUnSubscribe();
  };



  function mqttUnSubscribe() {
    console.log("unsubscribing")
    if (mqttSub.current) {
      mqttSub.current.unsubscribe();
    }
  };

  const publish = async (message) => {
    //if (debug) console.log("publish message step:%s : ",step, message, publishPath);
    //message['opaque_id'] =  "_"+message.transaction; // certaines reponsse ne renvoient pas le transactionId
    await PubSub.publish(publishPathWebrtc, message);
  }


  const sendAskingForSession = async () => {
    setStep("session");
    setStatus("pendingSession");

    const transactionId = nanoid();
    const payload = {
      "janus" : "create",
      "transaction" : transactionId,
      "from": user.id
    }
    addToTransactions({transactionId:transactionId, action: "Box session"})

    console.log('asking for a new Janus session...', transactionId);
    await publish(payload);
  }

  const sendAskForPluginAttachement = async (sessionId) => {
    setStep("attach");
    setStatus("pendingAttach");

    const transactionId = nanoid();
    const payload = {
      "janus" : "attach",
      "plugin" : "janus.plugin.streaming",
      "session_id": sessionId,
      "transaction" : transactionId
    }
    addToTransactions({transactionId:transactionId, action: "Status init flux: "})

    console.log('askingForplugin attachment for session: %s (t:%s) ...', sessionId, transactionId);
    await publish(payload);    
  }

  const sendToPluginWatchAStream = async (handleId) => {
    setStep("watch");
    setStatus("pendingWatch");

    const transactionId = nanoid();
    const payload = {
      "janus" : "message",
      "session_id": sessionId,
      "handle_id" : handleId,
      "transaction" : transactionId,
      "body": {
        "request": "watch",
        "offer_audio": false,
        "id": streamId,
        refresh: true // ice restart
      }
    }
    addToTransactions({transactionId:transactionId, action: "Statut flux video:"})

    console.log('askingForStreamingWatch stream %s :  for session:%s, handle:%s  (t:%s) ...', streamId, sessionId, handleId, transactionId);
    await publish(payload);
  }

  async function sendTrickleToPlugin({ candidate, sessionId, handleId}) {

    setStep("trickle");
    setStatus("pendingTrickle");

    const transactionId = nanoid();

    const payload = {
      "janus": "trickle",
      "candidate": candidate,
      "transaction": transactionId,
      "handle_id": handleId,
      "session_id": sessionId
    }

    // trop verbeux pour l'ihm, et pluto fiable
    addToTransactions({transactionId:transactionId, action: "TrickleSendCandidate"})

    console.log('(DoOffer) trickle - send candidate (t:%s) session:%s, handle:%s ...', transactionId, sessionId, handleId);
    await publish(payload);      
  }

  async function sendStartToPlugin({ jsep, sessionId, handleId } = {}) {
    console.log("start request to janus Stream plugin"); 
    setStep("start");
    setStatus("pendingStart");

    const transactionId = nanoid();

    const payload = {
      "janus" : "message",
      "session_id": sessionId,
      "handle_id" : handleId,
      "transaction" : transactionId,
      "body": {
        "request": "start",
      },
      "jsep": jsep,
      "bitrate": 120
      }
    addToTransactions({transactionId:transactionId, action: "Statut flux vidéo:"})

    console.log('Requesting a start to janus stream plugin (%s/%s) (t:%s) ...', sessionId, handleId, transactionId);
    await publish(payload);    
  } 

  const sendPluginDetachStream = async () => {
    setStep("stop");
    setStatus("pendingStop");

    const transactionId = nanoid();

    const payload = {
      "janus" : "detach",
      "transaction" : transactionId,
      "handle_id": handleId,
      session_id: sessionId
    }
    addToTransactions({transactionId:transactionId, action: "AskJanusDetachPlugin"})

    console.log('askingForDetachingStreaming (t:%s) ', transactionId);
    await publish(payload);
  }
  
  // creation d'une réponse à l'offre (avec creation/rechercher de la liaison peer to peer tricle to plugin webRTC)
  async function doAnswerWebRTC(offer, sessionId, handleId) {
      console.log("doAnswer (webrtc) (v3)...",sessionId, handleId);
      console.log("ICE_SERVERS:",ICE_SERVERS);
      if (!streamingPeerConnection.current) {
        if (debug) console.log("new RTCPeerConnection()");
        const pc = new RTCPeerConnection({
          'iceServers': ICE_SERVERS
          //'sdpSemantics': 'unified-plan',
        });

        pc.onnegotiationneeded = event => console.log('pc.onnegotiationneeded', event);
        pc.onicecandidate = event => {
          //console.log('pc.onicecandidate -> candidate:');
          console.log('pc.onicecandidate -> candidate:',event.candidate);
          if (event.candidate) sendTrickleToPlugin({ candidate: event.candidate, sessionId: sessionId, handleId: handleId})
        };
        pc.oniceconnectionstatechange = () => {
          console.log('pc.oniceconnectionstatechange => ' + pc.iceConnectionState);
          //TODO afficher un statut à l'utilisateur
          if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'closed') {
            setLoading(false)
            stopAllStreams();
            closePC();
          } else if (pc.iceConnectionState === 'disconnected' ) {
            setLoading(false)
            stopAllStreams();
            closePC();
          } else if (pc.iceConnectionState === 'connected' ) {
            if (debug) console.log("Connected")

            //console.log("*******",pc.getSenders()[0].getParameters().codecs)
          }
        };
        pc.ontrack = event => {
          if (debug) console.log('pc.ontrack (get remoteStream) +', event);
    
          event.track.onunmute = evt => {
            if (debug) console.log('track.onUNmute', evt);
            // l'event onunmute apparait en cours de transaction
            // il ne faut pas stopper l'icone de loading si la transactionn'est pas terminée
            if (webRtcUpRef.current) setLoading(false);
            /* TODO set srcObject in this callback */
          };
          event.track.onmute = evt => {
            if (debug) console.log('track.onMute', evt);
            setLoading(true);

          };
          event.track.onended = evt => {
            if (debug) console.log('track.onended', evt);
            setLoading(false);
          };
    
          const remoteStream = event.streams[0];
          setVideoElement(remoteStream);
        };
    
        pc.icecandidateerror = event => {
          console.log('pc.icecandidateerror:', event);
        }

        streamingPeerConnection.current = pc;
      }

      const sdpOfferBefore = offer.sdp;
      const sdpOfferAfter = updateBandwidthRestriction(sdpOfferBefore, 45);
      offer.sdp = sdpOfferAfter
      console.log("SDP in OFFER with bandwith:",offer.sdp);

      await streamingPeerConnection.current.setRemoteDescription(offer, onSdpSuccess, onSdpFailure);
      if (debug) console.log('set remote sdp OK');

      const answer = await streamingPeerConnection.current.createAnswer();
      console.log('create answer OK :',answer);

      const sdpAnswerBefore = answer.sdp;
      const sdpAnswerAfter = updateBandwidthRestriction(sdpAnswerBefore, 45);
      answer.sdp = sdpAnswerAfter
      console.log("SDP in ANSWER with bandwith:",answer.sdp);

      streamingPeerConnection.current.setLocalDescription(answer);
      if (debug) console.log('set local sdp OK');
      return answer;
    }
   
    // webRtc functions / connection pair à pair
    function onSdpSuccess() {
      if (debug) console.log('setRemoteDescription - onSdpSuccess');
    }
    function onSdpFailure(e) {
      if (debug) console.log('setRemoteDescription - onSdpFailure',e);
    } 
    function setVideoElement(stream) {
      if (debug) console.log("attach  stream to navigator");
      if (!stream) return;
      remoteVideo.current.srcObject = stream;
      //remoteVideoSaf.current.srcObject = stream;
    }
    function closePC() {
      console.log('stopping pc ', streamingPeerConnection.current);
      const pc = streamingPeerConnection.current;
      if (!pc) {
        if (streamingPeerConnection.current) {
          pc = streamingPeerConnection.current
        } else {
          return;
        }
      }
      pc.onnegotiationneeded = null;
      pc.onicecandidate = null;
      pc.oniceconnectionstatechange = null;
      pc.ontrack = null;
      pc.close();
      if (pc === streamingPeerConnection.current) {
        streamingPeerConnection.current = null;
      }
    }

    function stopAllStreams() {
      setLoading(false)
      if (remoteVideo.current) {
        try {
          console.log('stopping all remote streams ',remoteVideo.current.srcObject);
          setWebRtcBitrate("");
          if (remoteVideo.current.srcObject && remoteVideo.current.srcObject.getTracks()) {
            remoteVideo.current.srcObject.getTracks().forEach(track => track.stop());
          }
          remoteVideo.current.srcObject = null;
        } 
        catch (e) {
          console.log("Error :",e);
        }
      }
    }

    async function myTest() {
      const remotePeerConnection = streamingPeerConnection.current;

      //const rmtVideo = remoteVideo.current;
      //console.log("rmtVideo:",rmtVideo);
      console.log("------------test----------------");

      if (remoteVideo.current && remoteVideo.current.srcObject && remoteVideo.current.srcObject.getTracks()) {
        console.log("getTracks:",remoteVideo.current.srcObject.getTracks());
      }

      if (remotePeerConnection) {
        console.log("getSenders:",remotePeerConnection.getSenders());

        const sender = remotePeerConnection.getSenders()[0];
        const parameters = sender.getParameters();
        console.log("parameters:",parameters);

        if (parameters && parameters.encodings && parameters.encodings.length>0) {
          parameters.encodings[0].maxBitrate = 1 * 1000 * 100;
          sender.setParameters(parameters);
          console.log("@@@@@@@@@@@@", parameters)
        }

      } else {
        console.log("remotePeerConnection is empty")
      }
      console.log("------------test fin----------------");
    }

    function updateBandwidthRestriction(sdp, bandwidth) {
      let modifier = 'AS';
      // if (adapter.browserDetails.browser === 'firefox') {
      //   bandwidth = (bandwidth >>> 0) * 1000;
      //   modifier = 'TIAS';
      // }
      if (sdp.indexOf('b=' + modifier + ':') === -1) {
        // insert b= after c= line.
        sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
      } else {
        sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
      }
      return sdp;
    }

    function showWebRtcStats() {
      if (!streamingPeerConnection || !streamingPeerConnection.current)  return;

      const remotePeerConnection = streamingPeerConnection.current

      //console.log(remotePeerConnection);
      //console.log("showRemoteStats")
      remotePeerConnection
      .getStats(null)
      .then(showRemoteStats, err => console.log(err));
    }

    function showRemoteStats(results) {
      results.forEach(report => {
        //console.log(report.type,report)
        //console.log(report.type)
        const now = report.timestamp;
    
        let bitrate;
        let bytes;
        if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
          //console.log("report:",report)
          setWebRtcStats(buildWrbrtcReportStats(report))
          bytes = report.bytesReceived;
          if (timestampPrev) {
            bitrate = 8 * (bytes - bytesPrev) / (now - timestampPrev);
            bitrate = Math.floor(bitrate);
          }
          bytesPrev = bytes;
          timestampPrev = now;
        }
        if (bitrate) {
          bitrate += ' kbits/sec';
          setWebRtcBitrate(bitrate);
          //bitrateDiv.innerHTML = `<strong>Bitrate : </strong>${bitrate}`;
          //console.log("bitrate:",bitrate);
        }
        if (report.type === 'certificate') {
          //console.log("1-",report.type)
        } else if (report.type === 'codec') {
          //console.log("2-",report.type)
        } else if (report.type === 'candidate-pair') {
          //console.log("3-",report.type)
       } else if (report.type === 'local-candidate') {
          //console.log("4-",report.type)
        } else if (report.type === 'remote-candidate') {
          //console.log("5-",report.type)

         } else if (report.type === 'inbound-rtp') {
          console.log("6-",report.type)
          //console.log("Decoder: id:%s, impl:%s",report.codecId,report.decoderImplementation ); 
          //console.log("Decoder: id:%s",report.codecId ); 
          // decoderImplementation ExternalDecoder (VDAVideoDecoder) / unknown
          // codecId : RTCCodec_v_Inbound_96
          console.log("STATS : %s - NACK remote:%s - picture loss:%s - packets lost:%s", report.codecId?"CODEC OK":"No codec", report.nackCount, report.pliCount, report.packetsLost);

          //console.log("Frames:", report.framesReceived,reportframesDecoded);          
        } else if (report.type === 'track' || report.type === 'stream'|| report.type === 'peer-connection' ) {
          //console.log("7-",report.type)

        } else if (report.type === 'transport') {
          console.log("8-",report.type)
         }  else {
          console.log("Unknwon type :",report.type)
        }              
      })
    }

    const buildWrbrtcReportStats = (stats) => {
      let report = [];

      if (!stats.codecId) report.push("NoCodec");
      if (stats.nackCount) report.push("nack:"+stats.nackCount);
      if (stats.packetsLost) report.push("packetsLost:"+stats.packetsLost);
      if (stats.pliCount) report.push("pli:"+stats.pliCount);    
      if (stats.packetsReceived) report.push("packetsReceived:"+stats.packetsReceived);
      if (stats.framesDecoded) report.push("framesDecoded:"+stats.framesDecoded)
      if (stats.framesDropped) report.push("framesDropped:"+stats.framesDropped);
      if (stats.framesPerSecond) report.push("framesPerSecond:"+stats.framesPerSecond);
      if (stats.framesReceived) report.push("framesReceived:"+stats.framesReceived);
      if (stats.codecId) report.push("codecId:"+stats.codecId);
      //if (stats.decoderImplementation) report.push("decoder:"+stats.decoderImplementation);

      return report.join("\n");
    }

    const handleVideoPress = () => {
      if (videoPlaying) {
        remoteVideo.current.pause();
        stopWatching();
        setVideoPlaying(false);
      } else {
        //remoteVideo.current.play();
        setVideoPlaying(true);
        startWatching();
      }
    };
    
    const toChangeStream = (stream) => {
      //console.log(stream);
      setStreamName(stream.name);      
      setStreamId(stream.id)
    }
    const handleSwitchChange = () => {
      setSwitchChecked((prev) => !prev);
    };

  return (
        <div>
            <Grid container direction="row" justifyContent="space-between"  alignItems="center">
                <Grid item xs={6}  >
                    <img src="/images/door-front.jpeg" alt="image" width="50" />
                </Grid>
                <Grid item xs={2}>
                    
                </Grid>
                <Grid item xs={4}>
                  {streams?.map( (item, key) => 
                    <Grid key={key} container justifyContent="flex-start">
                      <a href='#' onClick={() => toChangeStream(item)}>{item.name}</a>
                    </Grid>
                  )}
                </Grid>                    
            </Grid>
 
           <div>{streamName} ({streamId})</div>
             {loading && <CircularProgress size={30} />}

            <video  
              className={classes.video} 
              ref={remoteVideo}  
              //onClick={handleVideoPress}
              width="100%" 
              height="240"  
              autoPlay 
              playsInline
              muted
              //controls  
              poster={lastFrameImage} >

                    <source src="video/chrome.webm" type="video/webm"/>
                    <source src="video/chrome.mp4" type="video/mp4"/>
              </video>
            <div>
              <button  onClick={startWatching}>start</button>
              <button  onClick={stopWatching}>stop</button>
              <button  onClick={() => askingForCameraLastFrame(userIdentityId, serviceId, deviceMqttId, customer.id) }>(img)</button>
              <button  onClick={myTest}>test</button>
            </div>  
{/*             <div>
              <img src={lastFrameImage} />
            </div>    */}       
          
          <div>
            <div>
            Détail  de la connexion <Switch checked={switchChecked} onChange={handleSwitchChange} /> 
           
            </div>
            
            <Collapse in={switchChecked}>
              <div>
                <div>
                  {webRtcBitrate}
                </div>
                <hr />
                {transactions?.map( (item, key) =>      
                  <Grid key={key}  container justifyContent="flex-start">
                      <Grid item xs={1} >{key}</Grid>
                      <Grid item xs={7} >{item.action}</Grid>
                      <Grid item xs={4} >{item.response}</Grid>
                  </Grid>
                )}
                <hr />
                <div>
                  {webRtcStats}
                </div>      
                <hr />                          
              </div>
            </Collapse>
          </div> 
          <div>
          </div>           
          
        </div>

  );
}

