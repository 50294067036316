import React, { useEffect, useState, useContext } from 'react';
import GeneralContext from './GeneralContext'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ServiceStandard from './ServiceStandard'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        //minWidth: 275,
        paddingRight:"1px",
        height: "100%",
    },
    content: {
        height: "80%",
        padding: "0px",
        marginTop : "2px"
    },
    title: {
        // fontSize: 14,
        backgroundColor: "#87CEEB",
        fontWeight: "bold",
        margin:0
     },
    cardTop: {
        height: "80%"
    },
    headerIcon: {
        margin: "5px"
    },
    actions: {
        // display: "flex",
        // alignSelf: "end",
        // alignItem: "right",
        // align: "end",
        textAlign: "center",
        height: "20%",
    },
    headerDemoDevice: {
        backgroundColor: "#edbe3e",
        fontSize:"small"
    },
    headerNoNetwork: {
        backgroundColor: "red",
    },    
  }));

export default function ServiceDisplayTest(props) {
    const { data: rawService, customerId } = props;   

    const { user, customer } = useContext(GeneralContext);
    const [ service, setService] = useState(null);
    const [ isFavorite, setIsFavorite ] = useState(false);

    const [ isDemoDevice, setIsDemoDevice ] = useState(false);
    const [ isNoNetwork, setIsNotNetwork] = useState(false);

    const [ iconTypeUrl, setIconTypeUrl] = useState();
    const [ headerColor, setHeaderColor] = useState("#87CEEB"); // bleu clair


    const [flipstate, setFlipstate] = useState(false);

    const [batteryLevel, setBatteryLevel] = useState();

    const classes = useStyles();

    useEffect(() => {
        // l'objet service est different suivant sa provenance (fetchServicesAll vs fetchServicesGroup)
        // AWSAppSyncClient semble renvoyer directement le json alors que API.graphql renvoi une string ?
        const serviceObj = getServiceObj(rawService);
        if(serviceObj.globalConfig  && (typeof serviceObj.globalConfig === 'string')) {
            serviceObj.globalConfig = JSON.parse(serviceObj.globalConfig)
        }
        //conversion en json de lastValue.probes
        if(serviceObj.lastValue && (typeof serviceObj.lastValue.probes === 'string')) {
            serviceObj.lastValue.probes = JSON.parse(serviceObj.lastValue.probes)
        }
        //conversion en json de lastValue.slidingCumul
        // concerne les cumuls glissants de qte issue d'index comme la pluie pour une station météo
        if(serviceObj.lastValue && (typeof serviceObj.lastValue.slidingCumul === 'string')) {
            serviceObj.lastValue.slidingCumul = JSON.parse(serviceObj.lastValue.slidingCumul)
            // on dispach les cumul au niveau de chaque probe
            updateProbesWithCumuls(serviceObj)
        } 
        if (serviceObj.lastValue) {
            // rechercher si le device contient un sonde de type Battery
            const probes = serviceObj.lastValue.probes;
            Object.keys(probes).map( (key) => {
                const probe = probes[key];
                if ('BATTERY' === (probe.type).toUpperCase()) {
                    console.log("probe with battery:",probe)
                    //TODO eventuelelement 
                    if (!isNaN(probe.value)) {
                        setBatteryLevel(probe.value);
                    }
                  }                
            })
            //
        }       
        // si il existe des alertes pour ce service, on les dispatche dans les probes concernées
        if(serviceObj.alerts && serviceObj.alerts.items.length>0) {
            updateProbesWithAlerts(serviceObj)
        }
        if(serviceObj.device) {
            if (serviceObj.device.dataSource) {
                if (serviceObj.device.dataSource==="CLONE") {
                    console.log("*** Device de demonstration *******");
                    setIsDemoDevice(true);
                }
            }
            if (serviceObj.device.networkStatus) {
                if (!serviceObj.device.networkStatus || (serviceObj.device.networkStatus!=="ACTIVATED" && serviceObj.device.networkStatus!=="NONE" )) {
                    console.log("*** Device non activé sur un réseau *******")
                    setIsNotNetwork(true);
                }
            } else {
                // si pas d'info sur le statut, on n'affiche rien
                setIsNotNetwork(false);
            }
            // header background et icon du device tel que défini dans le template du device
            if (serviceObj.device.template && serviceObj.device.template.displayConfig) {
                //console.log("displayConfig:",serviceObj.device.template.displayConfig )
                const displayCfg = JSON.parse(serviceObj.device.template.displayConfig);
                if (displayCfg.iconUrl) {
                    setIconTypeUrl(displayCfg.iconUrl);
                }
                if (displayCfg.headerColor) {
                    setHeaderColor(displayCfg.headerColor);
                }                

            }
        }
        //console.log("serviceObj:",serviceObj);
        //console.log("rawService:",rawService);
        setService(serviceObj);
    },[rawService])

    function getServiceObj(service) {
        return service.hasOwnProperty('service')?service.service:service;
    }
    function updateProbesWithCumuls(serviceObj) {
        const probes = serviceObj.lastValue.probes;
        const cumuls = serviceObj.lastValue.slidingCumul;
        // on supprime le updatedAt pour pouvoir parcourir les probes
        if (cumuls.hasOwnProperty("updatedAt")) {
            delete cumuls.updatedAt;
        }
        for(const probeKey in cumuls) {
           // on a un cumul pour cette probe, on le ratache à la mêm probe dans lastValue.probes
           if (probes.hasOwnProperty(probeKey)) {
               probes[probeKey]["cumuls"] = cumuls[probeKey];
           } else {
               console.log("Il existe un cumul glissant (ex pluie 1/8/24h) impossible a ratacher à une probe de lastvalue")
           }
        }
    }
    // on parcours les alertes pour mettre un isAlarm avec son statut si necessaire dans la probe concernée
    function updateProbesWithAlerts(serviceObj) {
        const alerts = serviceObj.alerts.items;
        //console.log("serviceObj.lastValue:",serviceObj.lastValue);
        const probes = serviceObj.lastValue.probes;

        // raz des alertes
        Object.keys(probes).map( probeId => { probes[probeId].isAlarm = []})

        alerts.map( alert => {
            if (alert.probeID && alert.status) {
                // il peut y avoir plusieurs alertes pour une même probe pour un même user
                if (probes[alert.probeID].isAlarm) {
                    probes[alert.probeID].isAlarm.push(alert.status)
                } else {
                    probes[alert.probeID].isAlarm = [alert.status];
                }
            } 
        })
    }

    return (
        <>
        {service && 
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                {service.serviceType!=='ETO' && service.serviceType!=='LOCK' && service.serviceType!=='MEDIA_STREAM' &&
                    <ServiceStandard data={service} />
                }
                </CardContent>
            </Card>
        }
        </>
    );
}
