import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import BatteryGauge from 'react-battery-gauge'

import GeneralContext from './GeneralContext'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    card: {
        maxWidth: "800px"
    },
    contact: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'    ,    
        marginTop: 30
    },
    popover: {
        pointerEvents: 'none',
      },
      paper: {
        padding: theme.spacing(1),
      },
}));

export default function MyBatteryLevel( {value} ) {

    const { portal } = useContext(GeneralContext);

    const { t } = useTranslation();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
        < >

            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <BatteryGauge
                value={value}
                size="30"

                >
                    <defs>
                        <filter id="shadow">
                        <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" />
                        </filter>
                    </defs>
                </BatteryGauge>
            </div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{value}%</Typography>
            </Popover>            
        </>
    );
}

