import React, { useContext } from 'react'
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import GeneralContext from '../components/GeneralContext'
import UserHeader from '../components/UserHeader'
import SwitchCustomer from '../components/SwitchCustomer'

const useStyles = makeStyles(theme => ({
    mainTitle: {
      flexGrow: 3,
    },
    customerLogo: {
        //maxWidth: 46,
        maxHeight: 45,
        [theme.breakpoints.down('350')]: {
            maxHeight: 40,
          },        
        flexGrow: 1,
        marginTop: 7
      },
      centerItem: {
        textAlign: 'center'
      }     
  }));

export default function AppHeader() {

    const { user, customer, setCustomer } = useContext(GeneralContext);

    const classes = useStyles();

    return (
        <div className={classes.mainTitle}>
        <Grid container spacing={1} direction="row" justifyContent="flex-start"  alignItems="center">
            <Grid item xs={3}>
                {customer?.logoUrl &&
                    <div >
                         <img src={customer.logoUrl} alt="logo" className={classes.customerLogo} />
                    </div>
                }

            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6" className={classes.centerItem}>
                    {user.customers &&
                     <SwitchCustomer customers={user.customers} switchTo={setCustomer} defaultCustId={customer.id}/>  
                    }
                </Typography>  

            </Grid>
            <Grid item xs={3}>
                
                <UserHeader/>

            </Grid>                        
        </Grid>
        </div>
    )
}