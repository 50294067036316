//TODO indiquer le mail de reception de l'alerte (le mail du user qui créer l'alerte)
import React, { useEffect, useState, useContext } from 'react';

import GraphQlApi from '../mobile/MyGraphql';

import { useTranslation } from 'react-i18next';
import {useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import Typography from '@material-ui/core/Typography';

import GeneralContext from '../components/GeneralContext'
import { toSnack } from '../Common';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 700,
    margin: "20px"
  },
  card: {
    flexGrow: 1,
  },  
  appBarParam: {
    position: 'relative',
  },
  titleParam: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  loading: {
    marginTop: 50
  },
  button: {
    margin: theme.spacing(1),
    maxWidth: 300,
  }  
})
)


export default function ServiceProbeAlertEdit(props) {
  const classes = useStyles();
  const {  isVisible, mode, alert:alertObj, onClose, selectedValue,  noHeader} = props;

  const { user, customer } = useContext(GeneralContext);
  const [alert, setAlert] = useState(alertObj);
  const history = useHistory();

  const [name, setName] = useState("new");
  const [condition, setCondition] = useState(">");
  const [threshold,  setThreshold] = useState("");
  const [action,  setAction] = useState("");
  const [status,  setStatus] = useState("active");
  const [triggerType,  setTriggerType] = useState('EDGE');

  const [emailMessage,  setEmailMessage] = useState("");
  
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  //console.log("props:",props)

  const conditions = [
    {
      value: '>',
      label: 'Supérieur ou égale à',
    },
    {
      value: '<',
      label: 'Inférieur ou égale à ',
    }
  ];  

  const allStatus = [
    {
      value: 'inactive',
      label: 'Non active',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'triggered',
      label: 'Déclenchée',
    }    
  ];
  
  const triggerTypes = [
    {
      value: 'EDGE',
      label: 'Front montant et descendant',
    },
    {
      value: 'RISING_EDGE',
      label: 'Front montant',
    },
    {
      value: 'FALLING_EDGE',
      label: 'Front descendant',
    },
    {
      value: 'ALWAYS',
      label: 'A chaque valeur',
    }           
  ];

  //TODO a remplacer par getActionIds et la traduction pour le libelle
  const actions = [
    {
      value: 'EMPTY',
      label: 'Choisir une action',
      active: true
    },
    {
      value: 'MAIL',
      label: 'Email',
      active: true
    },
    {
      value: 'PUSH',
      label: 'Notification (sur téléphone)',
      active: false
    },
    {
      value: 'SMS',
      label: 'Envoyer un SMS',
      active: false
    },
    {
      value: 'HOOK',
      label: 'Appeler une API',
      active: false
    },
  ];  
  
  function processSetAction(value) {
    setAction(value);
    if (value==='MAIL') {
      setEmailMessage("Les alertes seront envoyées à "+user.email+". Pour modifier ce mail, allez dans votre profil utilisateur.");

    } else if (value==='PUSH') {
      setEmailMessage("Pour recevoir les notifications sur votre mobile, vous devez au préalable avoir activé l'application mobile.");

    } else {
      setEmailMessage("");
    }
  }



  useEffect(() => {
      console.log("alertObj:",alertObj)
      setAlert(alertObj)

      if (mode==="edit" && alertObj) {
        setName(alertObj.name)
        setCondition(alertObj.condition)
        setThreshold(alertObj.threshold)
        setAction(alertObj.action)
        setStatus(alertObj.status)
        setTriggerType(alertObj.triggerType)

      } else {
        setName("")
        setCondition(">")
        setThreshold("")
        setAction("EMPTY")
        setStatus("active")
        setTriggerType("EDGE")
      }
  }, [alertObj]);


  async function handleAlertCreation() {
    if (!name || !threshold  || action==='EMPTY') {
      toSnack("AlertError", "Attention, le nom de l'alerte, le seuil et l'action doivent être précisés");
      return;
    }
    console.log("adding new alert ..." )
    setLoading(true)
    const newAlert = {
      name: name,
      owner: user.id,
      serviceID: alertObj.serviceID,
      probeID: alertObj.probeID,
      threshold: Number(threshold),
      condition: condition,
      triggerType: triggerType,
      action: action,
      status: status,
      probeName: alertObj.probeName,
      serviceName: alertObj.serviceName,
      customerId: alertObj.customerId,
      customerName: alertObj.customerName,   
      unit: alertObj.unit,      
    }
    const isProcessed = await addAlertMutation(newAlert);
    setLoading(false)
    if (isProcessed) {
      toSnack("AlertNew","Création réussie de l'alerte");
      history.goBack(); // retour sur page des capteurs
    } else {
      toSnack("AlertError","Erreur lors de la création de l'alerte");
    }
     
  }
  async function handleAlertDelete() {
    console.log("deleting alert ...",alertObj.id, alertObj.name)
    setLoading(true)
    const isProcessed = await removeAlertMutation(alertObj.id);
    setLoading(false)
    if (isProcessed) {
      toSnack("AlertDelete","Suppression de l'alerte réussie");
    }  else {

      toSnack("AlertError","Erreur lors de la suppression de l'alerte");
    }
    //onClose(selectedValue); // fermeture fenetre edition
    history.goBack(); // retour sur page des capteurs
  }

  async function handleAlerEdit() {
    console.log("updating alert ...",alertObj.id, alertObj.name)
    setLoading(true)
    const isProcessed = await updateAlertMutation(alertObj.id);
    setLoading(false)
    if (isProcessed) {
      toSnack("AlertUpdate","Mise à jour de l'alerte réussie");
    }  else {
      toSnack("AlertError","Erreur lors de la modification de l'alerte");
    }
    //onClose(selectedValue); // fermeture fenetre edition
    history.goBack(); // retour sur page des capteurs
  }

  async function updateAlertMutation() {
    const toUpdate = {
      id: alertObj.id,
      name: name,
      owner: user.id,
      serviceID: alertObj.serviceID,
      probeID: alertObj.probeID,  
      threshold: Number(threshold),
      condition: condition,
      action: action,
      status: status,
      triggerType: triggerType,
      probeName: alertObj.probeName,
      serviceName: alertObj.serviceName,
      probeUnit: alertObj.unit,
      customerId: alertObj.customerId, // temporairement pour la maj des alertes existantes 
      customerName: alertObj.customerName   
    };

    console.log("toUpdate:",toUpdate);
    const updateMutation = `mutation updateAlert($input: UpdateAlertInput!) {
      updateAlert( input: $input) {
          id
          name
      }
    }`;   
    console.log("Updating alert: ", toUpdate);

    try {
      //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
      const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
      console.log("alert updated", response.data.updateAlert);
    }
    catch (error) {
      //console.log(error.response)
      console.log(error)
      return false;
    }
    return true;
  } 

  async function addAlertMutation(newAlert) {

      const entry = {
        name : newAlert.name,
        owner : newAlert.owner,
        serviceID: newAlert.serviceID,
        probeID: newAlert.probeID,
        threshold: newAlert.threshold,
        condition: newAlert.condition,
        triggerType: newAlert.triggerType,
        action: newAlert.action,
        status: newAlert.status,
        triggerType: newAlert.triggerType,
        // pour permettre l'affichage de la liste des alertes de tous les customer
        // sans être "switché" à chacun des customers
        probeName: newAlert.probeName,
        probeUnit: newAlert.unit,   
        serviceName: newAlert.serviceName,
        customerName: newAlert.customerName,
        customerId: newAlert.customerId
      };
      console.log("addAlertMutation:",entry);

      const createMutation = `mutation CreateNewAlert($input: CreateAlertInput!) {
        createAlert(input: $input) {
          id
        }
      }
      `;       

      console.log("addAlertMutation:",entry);
      try {
        //const response = await API.graphql(graphqlOperation(createMutation, { input: entry }));
        const response = await GraphQlApi.getInstance().graphqlOperation( createMutation, { input: entry });
        console.log("Adding alert ok :",response.data.createAlert);
      }
      catch (error) {
        //console.log(error.response)
        console.log(error)
        return false;
      }  
      return true;
  }
  async function removeAlertMutation(alertId) {

      const deleteMutation = `mutation DeleteAlert($input: DeleteAlertInput!) {
        deleteAlert(input: $input) {
          id
        }
      }
      `;      
      try {
        // suppression de la liaison n-n entre service et application
        //const response = await API.graphql(graphqlOperation(deleteMutation, { input: { id: alertId } }));
        const response = await GraphQlApi.getInstance().graphqlOperation( deleteMutation, {input: { id: alertId }});
        console.log("remove ok :",response.data.deleteAlert);
        return true;
      }
      catch (error) {
        //console.log(error.response)
        console.log(error)
      } 
  }  

  const getActionIds = (async () => {
    var actionIds;
    const enumQuery = `query getActions {
        __type(name: "alertActions") {
          name
          enumValues {
            name
          }
        }
      }`;
    //TODO AVOIR
    //const result = await API.graphql(graphqlOperation(enumQuery));
    const result = await GraphQlApi.getInstance().graphqlOperation(enumQuery);
    //console.log("enum:",result);
    if (result && result.data) {
      actionIds = result.data.__type.enumValues
    }
    return actionIds;
  })

  const handleClose = () => {
    console.log("handle close")
    onClose(selectedValue);
  };

  return (
    <Dialog  fullScreen={fullScreen} className={classes.root}  fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={isVisible}>
      {!noHeader &&
        <AppBar className={classes.appBarParam}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <BackIcon />
              </IconButton>
              <Typography variant="h6" className={classes.titleParam}>
              {mode==="new" && 
                <div>Nouvelle alerte</div>
              }
              {mode==="edit" && 
                <div>{alert?.name}</div>
              }              
              </Typography>
            </Toolbar>
          </AppBar>
        }

        <div >
            {loading &&
                <div  className={classes.loading}>
                    <CircularProgress size={40} />
                </div>
            }
        </div>   

        <div>
{/*           Alerte sur la sonde : {alert?.name}
          Si [Temperature] superieur ou égale à [valeur]
          alors Envoyer un SMS/ me prevenir par mail (adresse).... */}

        </div>
      <div className={classes.root}>
        <Grid container   direction="row" justifyContent="space-evenly" alignItems="center">             
            <Grid item xs={6}>
              Nom 
            </Grid>
            <Grid item xs={6}>
              <TextField  
                  value={name}
                  onChange={(e) => {setName(e.target.value)}}
                  id="alertName" 
                  label="Nom de l'alerte" />
            </Grid>            
        </Grid>
        <Grid container   direction="row" justifyContent="space-around" alignItems="center">             
            <Grid item xs={6}>
              Condition 
            </Grid>
            <Grid item xs={6}>
              <TextField
                  id="select-condition"
                  select
                  label="Condition"
                  value={condition}
                  onChange={e => setCondition(e.target.value)} 
                >
                  {conditions.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </TextField> 

            </Grid>            
        </Grid>
        <Grid container   direction="row" justifyContent="space-around" alignItems="center">             
            <Grid item xs={6}>
              Seuil ({alertObj?.unit})
            </Grid>
            <Grid item xs={6}>
              <TextField  
                  value={threshold}
                  onChange={(e) => {setThreshold(e.target.value)}}
                  id="alertThreshold" 
                  label="Seuil" />
            </Grid>       
        </Grid>
        <Grid container   direction="row" justifyContent="space-around" alignItems="center" >             
            <Grid item xs={6}>
              Action 
            </Grid>
            <Grid item xs={6}>
              <TextField
                  id="select-action"
                  select
                  label="Action"
                  value={action}
                  onChange={e => processSetAction(e.target.value)} 
                >
                  {actions.map(item => (
                    <MenuItem disabled={!item.active} key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </TextField> 
              <div style={{ width: "200px" }} >{emailMessage}</div>
            </Grid>            
        </Grid>                        
        <Grid container   direction="row" justifyContent="space-around" alignItems="center" >             
            <Grid item xs={6}>
              Statut 
            </Grid>
            <Grid item xs={6}>
              <TextField
                  id="select-status"
                  select
                  label="Statut"
                  value={status}
                  onChange={e => setStatus(e.target.value)} 
                >
                  {allStatus.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </TextField> 
            </Grid>            
        </Grid>
        <Grid container   direction="row" justifyContent="space-around" alignItems="center">             
            <Grid item xs={6}>
              Type de déclenchement 
            </Grid>
            <Grid item xs={6}>
              <TextField
                  id="select-trigger"
                  select
                  label="Declenchement"
                  value={triggerType}
                  onChange={e => setTriggerType(e.target.value)} 
                >
                  {triggerTypes.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </TextField> 

            </Grid>            
        </Grid>
      </div>

      {mode==="new" && 
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleAlertCreation}
              startIcon={<SaveIcon />}
          >
            Créer
          </Button>
      }
      {mode==="edit" && 
        <div>
          <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleAlerEdit}
              startIcon={<SaveIcon />}
          >
              Modifier
          </Button>


            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleAlertDelete}
                startIcon={<DeleteIcon />}
            >
                Suppression
            </Button> 
        </div>         
      }
    </Dialog>
  );
}

ServiceProbeAlertEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  //  openBis: PropTypes.bool.isRequired,
  //selectedValue: PropTypes.string.isRequired,
};
