//TODO indiquer le mail de reception de l'alerte (le mail du user qui créer l'alerte)
import React, { useEffect, useState, useContext } from 'react';

import GraphQlApi from '../mobile/MyGraphql';
import { API } from "aws-amplify";

import { useTranslation } from 'react-i18next';
import {useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme, createTheme, ThemeProvider } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';

import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import GeneralContext from '../components/GeneralContext'
import { toSnack, localDateTimeIsoString, hhmmStringToSecond, minutesToHHmm } from '../Common';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
//import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc)
//dayjs.extend(timezone)

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 700,
    margin: "20px"
  },
  card: {
    flexGrow: 1,
  },  
  appBarParam: {
    position: 'relative',
  },
  titleParam: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  loading: {
    marginTop: 50
  },
  button: {
    margin: theme.spacing(1),
    maxWidth: 300,
  },
  inputLine: {
    marginTop: "10px;"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    color: theme.palette.getContrastText(deepOrange[500]),
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
  },
  buttonColor: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: deepOrange[500],
      color: 'white'
    }
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }, 
})
)

export default function LockAdminAccessAdd({ isVisible, mode, access:accessObj, onClose, selectedValue,  noHeader}) {
  const classes = useStyles();

  const { user, customer } = useContext(GeneralContext);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [processing, setProcessing] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  //console.log("props:",props)

  const [withPeriod, setWithPeriod] = useState(false);

  const [accessId, setAccessId] = useState();
  const [accessName, setAccessName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessPeriodStart, setAccessPeriodStart] = useState(localDateTimeIsoString());
  const [accessPeriodEnd, setAccessPeriodEnd] = useState("2050-01-01T00:00");
  const [accessWeekFromTime, setAccessWeekFromTime ] = useState("00:00");
  const [accessWeekUntilTime, setAccessWeekUntilTime ] = useState("00:00");


  
  const [authorizedDays, setAuthorizedDays] = React.useState(() => ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']);


  useEffect(() => {
      console.log("Access management:",accessObj)
      if (accessObj && mode==='edit') {
        setAccessId(accessObj.id); // identifiant propre à Nuki
        setAccessName(accessObj.name);
        setAccessCode(accessObj.code.toString());

        if (accessObj.allowedFromDate) {
          // hyp; si allowedFromDate est présent, tout le reste l'est aussi
          setWithPeriod(true);

          if (accessObj.allowedFromDate) {
            // la date issue de nuki est en utc, on l'affiche en local
            setAccessPeriodStart(dayjs(accessObj.allowedFromDate).utc(true).toISOString().slice(0,16));
          }
          if (accessObj.allowedUntilDate) {
            setAccessPeriodEnd(dayjs(accessObj.allowedUntilDate).utc(true).toISOString().slice(0,16));
          }
          
          if (accessObj.allowedFromTime) {
            setAccessWeekFromTime(minutesToHHmm(accessObj.allowedFromTime));
          }
          if (accessObj.allowedUntilTime) {
            setAccessWeekUntilTime(minutesToHHmm(accessObj.allowedUntilTime));
          }
  
          if (accessObj.allowedWeekDays) {
            setAuthorizedDays(weekDaysToArray(accessObj.allowedWeekDays))
          }  

        }
      }
  }, [accessObj]);


  const handleWeekButton = (event, authorizedDays) => {
    if (authorizedDays.length) {
        setAuthorizedDays(authorizedDays);
    }
  };

  async function handleAccessCreation() {

    if (!formControl()) {
      console.log("creation canceled");
      return;
    }

    const newAccess = {
        "name": accessName,
        "code": Number(accessCode),
    };
    if (withPeriod) {
        newAccess['allowedFromDate'] = new Date(accessPeriodStart).toISOString();
        newAccess['allowedUntilDate'] = new Date(accessPeriodEnd).toISOString();

        newAccess['allowedWeekDays'] =  weekDaysCompute(authorizedDays);
        newAccess['allowedFromTime'] = hhmmStringToSecond(accessWeekFromTime);
        newAccess['allowedUntilTime'] = hhmmStringToSecond(accessWeekUntilTime);
    }
    console.log("adding new access : ",newAccess)
    setLoading(true)

    const isProcessed = await addAccessRest(newAccess);
    //const isProcessed = true;
    setLoading(false)
    if (isProcessed) {
      toSnack("AccessNew","Création réussie de l'accès");
      history.goBack(); // retour sur page des capteurs
    } else {
      toSnack("AlertError","Erreur lors de la création de l'accès");
    }
     
  }

  const weekDaysCompute = (authorizedDays) => {
    var total = 0;
    authorizedDays.forEach(element => {
        if (element==="MONDAY") total = total + 64
        else if (element==="TUESDAY") total = total + 32
        else if (element==="WEDNESDAY") total = total + 16
        else if (element==="THURSDAY") total = total + 8
        else if (element==="FRIDAY") total = total + 4
        else if (element==="SATURDAY") total = total + 2
        else if (element==="SUNDAY") total = total + 1
    });
    return total;
  }

  const weekDaysToArray = (weekdays) => {

    const weekArray = [];
    const binaryWeek = Number(weekdays).toString(2).padStart(7,"0").slice(-7)
  
    if (binaryWeek.substring(0,1)==="1") {
        weekArray.push('MONDAY')
    }
    if (binaryWeek.substring(1,2)==="1") {
        weekArray.push('TUESDAY')
    }
    if (binaryWeek.substring(2,3)==="1") {
        weekArray.push('WEDNESDAY')
    }
    if (binaryWeek.substring(3,4)==="1") {
        weekArray.push('THURSDAY')
    }
    if (binaryWeek.substring(4,5)==="1") {
        weekArray.push('FRIDAY')
    }
    if (binaryWeek.substring(5,6)==="1") {
        weekArray.push('SATURDAY')
    }
    if (binaryWeek.substring(6,7)==="1") {
        weekArray.push('SUNDAY')
    }
  return weekArray;
}

  async function handleAccessDelete() {
    console.log("deleting access ...",accessObj.id, accessObj.name)
    setLoading(true)
    const isProcessed = await removeAccessRest(accessObj.id);
    setLoading(false)
    if (isProcessed) {
      toSnack("AccessDelete","Suppression de l'accès réussie");
    }  else {

      toSnack("AccesError","Erreur lors de la suppression de l'accès");
    }
    //onClose(selectedValue); // fermeture fenetre edition
    history.goBack(); // retour sur page des capteurs
  }

  async function handleAccessEdit() {
    console.log("updating access ...",accessObj.id, accessObj.name)

    if (!formControl()) {
      console.log("edit canceled");
      return;
    }
 
    setLoading(true)
    const isProcessed = await updateAccessRest(accessObj.id);
    setLoading(false)
    if (isProcessed) {
      toSnack("AccessUpdate","Mise à jour de l'accès réussie");
    }  else {
      toSnack("AccessError","Erreur lors de la modification de l'accès");
    }
    //onClose(selectedValue); // fermeture fenetre edition
    history.goBack(); // retour sur page des capteurs
  }

  const updateAccessRest = async (id) => {

    var accessPeriod;

    if (withPeriod) {

      accessPeriod = {};
      accessPeriod['allowedFromDate'] = new Date(accessPeriodStart).toISOString();
      accessPeriod['allowedUntilDate'] = new Date(accessPeriodEnd).toISOString();

      accessPeriod['allowedWeekDays'] =  weekDaysCompute(authorizedDays);
      accessPeriod['allowedFromTime'] = hhmmStringToSecond(accessWeekFromTime);
      accessPeriod['allowedUntilTime'] = hhmmStringToSecond(accessWeekUntilTime);
    }


    let myInit = {
        body: {
            code: accessCode, 
            name: accessName,
            access: accessPeriod
        }, 
        headers: {} // OPTIONAL            
    } 
    if (accessPeriod)  myInit.body['access'] = accessPeriod;

    try {
        setProcessing(true);
        const response = await API.put("iotLockAdmin", "/lock/" + accessObj.serviceID+"/accesscode/"+id, myInit)
        console.log("api:", response)
        setLoading(false);
        if (response.error) {
            setErrorMsg("Erreur lors de l'envoi de la modification de code à la serrure : "+response.message);
            
        } else {
            return response;
        }
    }
    catch (error) {
    //console.log(error.response)
    console.log(error)
    setLoading(false);
    setErrorMsg("Erreur lors de l'envoi de la modification de code à la serrure : "+error.message)
    } 
  }

    
    const addAccessRest = async (accessData) => {
        let myInit = {
            queryStringParameters: {  
                mode: 'standard', // standard, advanced
            },            
            body: {
                code: accessCode, 
                name: accessName,
                access: accessData// accessPeriod
            }, 
            headers: {} // OPTIONAL            
        }    
        try {
            setProcessing(true);
            const response = await API.post("iotLockAdmin", "/lock/" + accessObj.serviceID+"/accesscode/", myInit)
            console.log("api:", response)
            setLoading(false);
            if (response.error) {
                setErrorMsg("Erreur lors de l'envoi des information à la serrure : "+response.message);
                
            } else {
                return response;
            }
        }
        catch (error) {
        //console.log(error.response)
        console.log(error)
        setLoading(false);
        setErrorMsg("Erreur lors de l'envoi des information à la serrure: "+error.message)
        }        
    }

    const formControl = () => {
      var isValid = false;

      if (!accessName.trim()) {
        toSnack("AccessError", "Attention, Le nom doit être précisé");
        return false;
      }
      if (accessName.length>15) {
        toSnack("AccessError", "Attention, Le nom est trop long");
        return false;
      }
      if (!accessName.match(/^[a-zA-Z0-9_]+$/)) {
        toSnack("AccessError", "Attention, Le nom ne doit contenir que des lettres");
        return false;
      }
      console.log("accessCode:",accessCode)
      if (!accessCode.trim() || accessCode.length != 6 || !Number.isInteger(Number(accessCode))) {
        toSnack("FormError", "Attention, le code d'accès doit être un nombre de 6 chiffres");
        return false;
      }  
      if (accessCode.includes('0')) {
        toSnack("FormError", "Le code ne doit pas contenir le chiffre 0 (zero)");
        return false;
      }
      console.log("form is valid");
      isValid = true;

      return isValid;
    }

    const removeAccessRest = async (id) => {
        let myInit = {
            headers: {} // OPTIONAL            
        }    
        try {
            setProcessing(true);
            const response = await API.del("iotLockAdmin", "/lock/" + accessObj.serviceID+"/accesscode/"+id, myInit)
            console.log("api:", response)
            setLoading(false);
            if (response.error) {
                setErrorMsg("Erreur lors de l'envoi de la supression du code à la serrure : "+response.message);
                
            } else {
                return response;
            }
        }
        catch (error) {
        //console.log(error.response)
        console.log(error)
        setLoading(false);
        setErrorMsg("Erreur lors de l'envoi de la supression du code à la serrure : "+error.message)
        }         
    }    

    const handleChangeSwitchPeriod = () => {
        setWithPeriod(!withPeriod);

    }

    const handleClose = () => {
        console.log("handle close")
        onClose(selectedValue);
    };

  return (
    <Dialog  fullScreen={fullScreen} className={classes.root}  fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={isVisible}>
      {!noHeader &&
        <AppBar className={classes.appBarParam}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <BackIcon />
              </IconButton>
              <Typography variant="h6" className={classes.titleParam}>
              {mode==="new" && 
                <div>Nouvel accès</div>
              }
              {mode==="edit" && 
                <div>{accessObj?.name}</div>
              }              
              </Typography>
            </Toolbar>
          </AppBar>
        }

        <div className={classes.alignItemsAndJustifyContent} >
            {loading &&
                <div  className={classes.loading}>
                    <CircularProgress size={40} />
                </div>
            }
        </div>   

        <div>

        </div>
      <div className={classes.root}>
        <Grid container   direction="row" justifyContent="flex-start" alignItems="center" className={classes.inputLine} >             
            <Grid item xs={2}>
              Nom 
            </Grid>
            <Grid item xs={10}>
              <TextField  
                  value={accessName}
                  onChange={(e) => {setAccessName(e.target.value)}}
                  id="accessName" 
                  label="Nom pour l'accès" />
            </Grid>            
        </Grid>
        <Grid container   direction="row" justifyContent="flex-start" alignItems="center" className={classes.inputLine} >             
            <Grid item xs={2}>
              Code 
            </Grid>
            <Grid item xs={10}>
              <TextField  
                  value={accessCode}
                  onChange={(e) => {setAccessCode(e.target.value)}}
                  id="accessCode" 
                  label="Code d'accès" />
            </Grid>            
        </Grid>
        <Grid container   direction="row" justifyContent="flex-start" alignItems="center" className={classes.inputLine} >             
            <Grid item xs={2}>
              Restreindre la période d'accès 
            </Grid>
            <Grid item xs={10}>
            <Switch
                checked={withPeriod}
                onChange={handleChangeSwitchPeriod}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            </Grid>            
        </Grid>        

        { withPeriod &&
        
        <>


            <Grid container   direction="row" justifyContent="flex-start" alignItems="center" className={classes.inputLine} >             
                <Grid item xs={2}>
                Période 
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        id="datetime-local"
                        value={accessPeriodStart}
                        onChange={(e) => {setAccessPeriodStart(e.target.value)}}
                        label="Début de période"
                        type="datetime-local"
                        className={classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                </Grid>                 
                <Grid item xs={5}>            
                    <TextField
                        id="datetime-local"
                        value={accessPeriodEnd}
                        onChange={(e) => {setAccessPeriodEnd(e.target.value)}}                    
                        label="Fin de période"
                        type="datetime-local"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid> 
            </Grid>  

            <Grid container   direction="row" justifyContent="flex-start" alignItems="center" className={classes.inputLine} >             
                <Grid item xs={2}>
                Chaque semaine 
                </Grid>                
                <Grid item xs={10}>   


                <StyledToggleButtonGroup  size="large" value={authorizedDays} onChange={handleWeekButton} aria-label="text formatting">
                    <ToggleButton value="MONDAY" aria-label="monday" classes={{ selected: classes.buttonColor }}>
                        LUN
                    </ToggleButton>
                    <ToggleButton value="TUESDAY" aria-label="tuesday"  classes={{ selected: classes.buttonColor }} >
                        MAR
                    </ToggleButton>
                    <ToggleButton value="WEDNESDAY" aria-label="wednesday" classes={{ selected: classes.buttonColor }} >
                        MER
                    </ToggleButton>
                    <ToggleButton value="THURSDAY" aria-label="thursday"  classes={{ selected: classes.buttonColor }}>
                       JEU
                    </ToggleButton>
                    <ToggleButton value="FRIDAY" aria-label="friday"  classes={{ selected: classes.buttonColor }} >
                       VEN
                    </ToggleButton>
                    <ToggleButton value="SATURDAY" aria-label="saturday" classes={{ selected: classes.buttonColor }}  >
                       SAM
                    </ToggleButton>
                    <ToggleButton value="SUNDAY" aria-label="sunday"  classes={{ selected: classes.buttonColor }} >
                       DIM
                    </ToggleButton>                                                                               
                </StyledToggleButtonGroup>
    
                </Grid> 
            </Grid> 

            <Grid container   direction="row" justifyContent="flex-start" alignItems="center" className={classes.inputLine} >             
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={5}>

                    <TextField
                        id="time"
                        label="De"
                        type="time"
                        value={accessWeekFromTime}
                        onChange={(e) => {setAccessWeekFromTime(e.target.value)}}      
                        className={classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />

                </Grid>                 
                <Grid item xs={5}>            
                    <TextField
                            id="time"
                            label="à"
                            type="time"
                            value={accessWeekUntilTime}
                            onChange={(e) => {setAccessWeekUntilTime(e.target.value)}}      
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                        />
                </Grid> 
            </Grid>  
        </>    




        }
      
      </div>

      {mode==="new" && 
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleAccessCreation}
              startIcon={<SaveIcon />}
          >
            Créer
          </Button>
      }
      {mode==="edit" && 
        <div>
          <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleAccessEdit}
              startIcon={<SaveIcon />}
          >
              Modifier
          </Button>


            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleAccessDelete}
                startIcon={<DeleteIcon />}
            >
                Suppression
            </Button> 
        </div>         
      }
    </Dialog>
  );
}

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
      margin: theme.spacing(0.5),
      border: 'none',
      '&:not(:first-child)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-child': {
        borderRadius: theme.shape.borderRadius,
      },
    },
 }))(ToggleButtonGroup);