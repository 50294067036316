import React, {useState, useContext, useEffect} from 'react';
import {Workbox} from 'workbox-window';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import InfoIcon from '@material-ui/icons/Info';
import DraftsIcon from '@material-ui/icons/Drafts';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import EmailIcon from '@material-ui/icons/Email';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import { useHistory } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import ScanNewDevice from './ScanNewDevice'


import LanguageSwitcher from '../components/LanguageSwitcher'
import GeneralContext from '../components/GeneralContext'
import { publicCustomerRequest, askForChangingCustomer, isEmptyObject } from '../Common';

const useStyles = makeStyles({
  root: {
    //width: "98%",
    //margin: "5px"
    maxWidth: "800px",
    margin: "0 auto"
  },
  minHeight: {
    minHeight: 45, // ou 66
  },
  media: {
    height: 140,
  },
  paper: {
      marginTop: "5px",
      margin: 2,
      padding: 8,
      minWidth: 275,
  },
  bullet: {
    marginTop: 0,
    marginBottom: 0,
  },
  fab: {
    //margin: theme.spacing.unit,
    margin: 0,
    top: 'auto',
    //left: 20,
    bottom: 20,
    right: 'auto',
    position: 'fixed',      
  },
  myName: {
    fontWeight: "bold",
  },
  warningDemo: {
    marginTop: 5,
    fontStyle: "italic",
  },
  cardContent: {
    padding: 5
  },
  languageSwitcher: {
    textAlign: 'center'
  },
  titleIcon: {
    marginBlock: -5,
    marginRight: 5
  },
  deviceNumber: {
    fontStyle: "italic"
  }
});

const ListItemWithWiderSecondaryAction = withStyles({
  secondaryAction: {
    paddingRight: 80
  }
})(ListItem);



export default function HomePage() {

const [openDeviceScan, setOpenDeviceScan] = useState(false);
const [demoUser, setDemoUser ] = useState(false);
const { portal, user, customer } = useContext(GeneralContext);
const { t, i18n } = useTranslation();

const [isOwnCustomer, setIsOwnCustomer] = useState(false);
const [customerDeviceNb, setCustomerDeviceNb] = useState(0);

  watchSWevents()

  const classes = useStyles();
  let history = useHistory();

  useEffect(()=> {
    if (user.id==='demo') {
      setDemoUser(true);
    }
    console.log("user:",user)
    console.log("portal:",portal)
  
  },[user])

  useEffect(()=> {
    //watchSWevents();
  
  },[])  

  useEffect(()=> {
    console.log("Customer changed:",customer)  
    if (!isEmptyObject(customer)) {
      if (user.ownCustomer && user.ownCustomer.devicesCounter) {
        setCustomerDeviceNb(user.ownCustomer.devicesCounter);
      }
      // le customer courant et le customer dont le user est le owner
      if (user.ownCustomer && customer.id===user.ownCustomer.id) {
        setIsOwnCustomer(true);
      }
    }
  },[customer])
  
  function scanDeviceCallBack(response) {
    console.log("ScanDeviceCallBack (Cancel/attached) : ", response)
    setOpenDeviceScan(false);
    if (response && response.action && response.action==="attached") {
      history.push("/services/all");
    }
  }

  async function getServiceWorkerVersion() {
    console.log('Service Worker version ...');
    // const wb = new Workbox('/sw.js');
    //const swVersion = await wb.messageSW({type: 'GET_VERSION'});
    //console.log('Service Worker version:', swVersion);
    // wb.register();
    navigator.serviceWorker.controller.postMessage({
      type: 'GET_VERSION',
    });    
  }
  function getServiceWorkerVersionChannel() {
    return new Promise(function(resolve, reject) {
       var messageChannel = new MessageChannel();       
       messageChannel.port1.onmessage = function(event) {
         console.log("SW client received from SW :", event.data);
         if (event.data.error) {
           reject(event.data.error);
         } else {
           resolve(event.data);
         }
       };
      navigator.serviceWorker.controller.postMessage({type: 'GET_VERSION'}, [messageChannel.port2]);
    });
  }  

  function watchSWevents() {
    if ('serviceWorker' in navigator) {
      //console.log("SW windows : watchSWevents")
      const wb = new Workbox('/sw.js');
      // wb.setConfig({
      //   debug: true,
      // });
      window.addEventListener('waiting', event => {
        console.log(
          `A new service worker has installed, but it can't activate` +
            `until all tabs running the current version have fully unloaded.`
        );
      });
      window.addEventListener('message', event => {
        //console.log("SW message event", event);
        if (event.data.type === 'CACHE_UPDATED') {
          const {updatedURL} = event.data.payload;
      
          console.log(`A newer version of ${updatedURL} is available!`);
        }
      });      
      window.addEventListener('activated', event => {
        console.log("SW Activated event", event);
        // Get the current page URL + all resources the page loaded.
        // const urlsToCache = [
        //   location.href,
        //   ...performance.getEntriesByType('resource').map(r => r.name),
        // ];
        // // Send that list of URLs to your router in the service worker.
        // wb.messageSW({
        //   type: 'CACHE_URLS',
        //   payload: {urlsToCache},
        // });
      });
      window.addEventListener('installed', event => {
        console.log("SW installed event", event);
        if (!event.isUpdate) {
          // First-installed code goes here...
        }
      });     
      
      window.addEventListener('load', function () {
        console.log("SW load event");
      });

      //wb.register();
    } else {
      console.log("unable watcihing sw events")
    }
  }

  function goToViewMySensors() {
    // pour aller sur le customer dont le user est le owner, il faut peut-être changer de customer
    // si le customer current est son propre customer, on va directement sur la page
    // sinon, il faut changer le customer current
    if (customer.id===user.ownCustomer.id) {
      history.push("/services/all")

    } else {
      askForChangingCustomer(user.ownCustomer.id, "/services/all");
    }    
  }

  function goToAdminUserNew() {
    // permettre a l'utilisateur (owner d'un customer) d'inviter des utilisateurs
    // si le customer current est son propre customer, on va directement sur l'admin
    // sinon, il faut changer le customer current
    if (customer.id===user.ownCustomer.id) {
      history.push("/adminCustomerUsers#newuser")

    } else {
      askForChangingCustomer(user.ownCustomer.id, "/adminCustomerUsers#newuser");
    }
  }

  function refreshPage(){ 
    window.location.reload(true); 
    }

  return (

    <div className={classes.root}>
    <div className={classes.minHeight}>
    </div>
    <Card >
        <CardMedia
          className={classes.media}
          image={"https://device-pictures.s3-eu-west-1.amazonaws.com/iot-market.png"}
          title="myIOTplatform"
        />
        <CardContent className={classes.cardContent} >

        <Container>
            <Typography gutterBottom variant="h5" component="h2">
              {t("screen.home.welcome")}{portal.title}
              <InfoIcon onClick={() => history.push("/about")} />
            </Typography>          
        </Container>

        <div className={classes.paper}>
        {t('screen.home.hello')} <span className={classes.myName}>{user.id}</span>
          {demoUser &&
            <div className={classes.warningDemo}>
              <InfoIcon/>
              {t('screen.home.demo-mode')}
            </div>
          }
        </div>

    <Paper className={classes.paper} align='left' >

        <div className={classes.deviceNumber}>
          {customerDeviceNb>0 &&
            <div>
              {t('screen.home.owndevice', { count: customerDeviceNb })}
            </div>
          } 
          {customerDeviceNb===0 &&
            <div>
               {t('screen.home.notyetdevice')}
          </div>
          }
        </div>

        {user.ownCustomer &&     
          <>          
            {/*     <div>{t('screen.home.you-config-org')} <span className={classes.myName}>{user.ownCustomer.name}</span>,</div> */}
            {/*      <div>qui possède actuellement X capteurs</div>   */}
            <br/>
            <Typography variant="h6" >
            {t('screen.home.you-can-now')}
            </Typography>
          </>
        }
        {user.id && !user.ownCustomer && 
          <>  
          <br/>
            <Typography variant="body2" gutterBottom>
              Pour voir les capteurs de  {customer.name}, cliquez sur ce bouton 
              <Button
                      variant="contained"
                      disabled={demoUser}
                      color="primary"
                      className={classes.button}
                      onClick={() => history.push("/services/all")}
                    >
                    {customer.name}
              </Button>
            </Typography>    
          <br/>
            <Typography variant="body2" gutterBottom>
            {t('screen.home.you-now-add')}
            </Typography>    
            <Typography variant="body2" gutterBottom>
            {t('screen.home.you-should-before')}
            </Typography>   
              <Button
                      variant="contained"
                      disabled={demoUser}
                      color="primary"
                      className={classes.button}
                      startIcon={<BuildIcon />}
                      onClick={() => history.push("/userProfile#newcustomer")}
                    >
                    {t('screen.home.you-org-configure')}
              </Button> 
              <br/>
              <Typography variant="body2" gutterBottom>
              {t('screen.home.you-could-then')}
            </Typography>            
          </>
        }
          <List>

            {customerDeviceNb>0 &&
              <ListItemWithWiderSecondaryAction>
                <ListItemAvatar>
                  <Avatar>
                    <ViewModuleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={t('screen.home.devices-consult')}
                  secondary={""}
                />
                
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    disabled={!user.ownCustomer || demoUser}
                    color="primary"
                    className={classes.button}
                    startIcon={<VisibilityIcon />}
                    onClick={goToViewMySensors}
                  >
                    {/*   Ajout */}
                </Button>  
                </ListItemSecondaryAction>
              </ListItemWithWiderSecondaryAction>
            }

            <ListItemWithWiderSecondaryAction>
              <ListItemAvatar >
                <Avatar>
                  <SettingsInputAntennaIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('screen.home.add-your-own')}
                secondary={""}
              />
              <ListItemSecondaryAction>
                <Button
                variant="contained"
                disabled={!user.ownCustomer}
                color="primary"
                className={classes.button}
                startIcon={<PlaylistAddIcon />}
                onClick={() => setOpenDeviceScan(true)}
                >
                {/*   Ajout */}
                </Button> 
              </ListItemSecondaryAction>
            </ListItemWithWiderSecondaryAction>

            <ListItemWithWiderSecondaryAction>
              <ListItemAvatar>
                <Avatar>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t('screen.home.invite-users')}
                secondary={""}
              />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  disabled={!user.ownCustomer || demoUser}
                  color="primary"
                  className={classes.button}
                  startIcon={<PersonAddIcon />}
                  onClick={goToAdminUserNew}
                >
                  {/*   Ajout */}
              </Button>  
              </ListItemSecondaryAction>
            </ListItemWithWiderSecondaryAction>

          </List>
        </Paper>

        <Paper className={classes.paper} align='left' >
          <Typography variant="subtitle1">
            <BarChartIcon  className={classes.titleIcon} />
            {t('screen.home.you-can-consult')}
          </Typography>

          <List>
              <ListItemWithWiderSecondaryAction>
                <ListItemAvatar>
                  <Avatar>
                    <DraftsIcon />
                  </Avatar>
                </ListItemAvatar>                
                <ListItemText primary={t('screen.home.you-should-be')} />
                <ListItemSecondaryAction/>
              </ListItemWithWiderSecondaryAction>

              <ListItemWithWiderSecondaryAction>
                <ListItemAvatar>
                  <Avatar>
                    <ConfirmationNumberIcon />
                  </Avatar>
                </ListItemAvatar>                     
                <ListItemText 
                  primary={t('screen.home.you-get-code')}
                  secondary={t('screen.home.to-activate-here')} />
                <ListItemSecondaryAction>
                  <Button
                  variant="contained"
                  disabled={demoUser}
                  color="primary"
                  className={classes.button}
                  startIcon={<TouchAppIcon />}
                  onClick={() => history.push("/userProfile#orgaccess")}
                >
               {/*  Activer */}
              </Button>                   
                </ListItemSecondaryAction> 
              </ListItemWithWiderSecondaryAction>              
            </List>            
        </Paper>
    
        <Paper className={classes.paper} align='left' >
          <Typography variant="subtitle1">
            <VisibilityIcon className={classes.titleIcon}/>
            {t('screen.home.to-start-demo1')}
          </Typography>
{/* 
          <Typography variant="body2">
            Thématiques variées (offres de partenaires)
          </Typography>
 */}
            <List >
              <ListItemWithWiderSecondaryAction>
                <ListItemAvatar>
                  <Avatar>
                    <LocalFloristIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={t('screen.home.demo-title-farm')} secondary={t('screen.home.demo-farm')}  />
                <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      disabled={demoUser}
                      color="primary"
                      className={classes.button}
                      startIcon={<LinkIcon />}
                      onClick={() => publicCustomerRequest(history, "farm")}
                    >
                   {/*  Accès */}
                  </Button>  
                  </ListItemSecondaryAction> 
              </ListItemWithWiderSecondaryAction>
              <ListItemWithWiderSecondaryAction>
                <ListItemAvatar>
                  <Avatar>
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={t('screen.home.demo-title-office')} secondary={t('screen.home.demo-airquality')} />
                <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      disabled={demoUser}
                      color="primary"
                      className={classes.button}
                      startIcon={<LinkIcon />}
                      onClick={() => publicCustomerRequest(history, "office")}
                    >
                     {/*  Accès */}
                  </Button>  
                  </ListItemSecondaryAction> 
              </ListItemWithWiderSecondaryAction>
              <ListItemWithWiderSecondaryAction>
                <ListItemAvatar>
                  <Avatar>
                    <HomeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={t('screen.home.demo-title-home')} secondary={t('screen.home.demo-home')} />
                <ListItemSecondaryAction>
                    <Button
                      variant="contained"
                      disabled={demoUser}
                      color="primary"
                      className={classes.button}
                      startIcon={<LinkIcon />}
                      onClick={() => publicCustomerRequest(history,"home")}
                    >
                      {/*  Accès */}
                    </Button>                   
                  </ListItemSecondaryAction>                 
              </ListItemWithWiderSecondaryAction>
            </List>
  
        </Paper>
        <div className={classes.languageSwitcher}>
          <LanguageSwitcher/> [{i18n.language}]
        </div>

{/*         <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={getServiceWorkerVersion}
        >
        getVersion
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={getServiceWorkerVersionChannel}
        >
        getVersionChannel
        </Button>   */}      
 
{/*             <button type="button" onClick={ refreshPage }> 
              <div>Reload</div> 
            </button>  */}

        </CardContent>
        {/* 
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
       */}
    </Card>

    <ScanNewDevice  openState={openDeviceScan}  toClose={scanDeviceCallBack}  />
    <Fab 
        onClick={()=>{setOpenDeviceScan(true)}}
        disabled={demoUser}
        color="primary" 
        aria-label="Add" 
        className={classes.fab}>
            <AddIcon />
    </Fab>
    </div>

  );
}
