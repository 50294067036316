import React, { useEffect, useState, useContext } from 'react';
import { Amplify, Auth, Analytics } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';

import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

import {  useHistory, useLocation } from "react-router-dom";
import { Authenticator, useAuthenticator, View, useTheme, Image, Text, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './MyAuthentication.css';

import { useTranslation } from 'react-i18next';

import { I18n } from 'aws-amplify';
import i18n from './i18n';

import { translations } from '@aws-amplify/ui-react';

import { makeStyles } from '@material-ui/core/styles';

import GlobalContextProcessing from './GlobalContextProcessing'
import InstallPWA from './components/InstallPWA'

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CircularProgress from '@material-ui/core/CircularProgress';

import GraphQlApi from './mobile/MyGraphql';

import generalConfig from './portal-config';
import awsconfig from './aws-exports';
import GeneralContext from './components/GeneralContext'

import awsExports from './aws-exports';
Amplify.configure(awsExports);
Amplify.configure({
    API: {
      graphql_headers: async () => {
        const currentSession = await Auth.currentSession();
        return { Authorization: currentSession.getIdToken().getJwtToken() };
      }
    }  
});
if (window.location.hostname !== 'localhost' ) {
  Analytics.autoTrack('pageView', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, the event name, by default is 'pageView'
    eventName: 'pageView',
    // OPTIONAL, the attributes of the event, you can either pass an object or a function 
    // which allows you to define dynamic attributes
    // attributes: {
    //     attr: 'attr'
    // },
    // when using function
    // attributes: () => {
    //    const attr = somewhere();
    //    return {
    //        myAttr: attr
    //    }
    // },
    // OPTIONAL, by default is 'multiPageApp'
    // you need to change it to 'SPA' if your app is a single-page app like React
    type: 'SPA',
    // OPTIONAL, the service provider, by default is the Amazon Pinpoint
    provider: 'AWSPinpoint',
    // OPTIONAL, to get the current page url
    getUrl: () => {
        // the default function
        return window.location.origin + window.location.pathname;
    }
  });
}


I18n.putVocabularies(translations);
I18n.setLanguage('fr');

const useStyles = makeStyles({
  root: {
  },
  card: {
      maxWidth: "800px"
  },
  contact: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'    ,    
      marginTop: 30
  },
  alignItemsAndJustifyContent: {
    marginTop: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }    
});

// recupere la config du portail (table grapql public)
export default function MyPortal({ portal: portalName, accountRequest }) {

    const [portalCfg, setPortalCfg] = useState();
    const classes = useStyles();

    useEffect(() => {    
        async function fetchRestData(domainName) {
          const partnerConfig = await fetchRestGraphQl(domainName);
          console.log("MyPortal-partner config pour %s :",domainName, partnerConfig)
          if (partnerConfig) {
            const brandCfg = JSON.parse(partnerConfig.brandConfig)
            const cfgPortal = {
              id: domainName,
              www: brandCfg.wwwUrl,
              logo: brandCfg.logoUrl,
              title: brandCfg.brandName,
              webUrl: brandCfg.wwwUrl,
              webName: brandCfg.wwwName,          
              authent: {
                logo: brandCfg.logoUrl,
                title: brandCfg.brandName,
                theme: {
                  formContainer: { 
                    marginTop: 0
                  },
                  formSection: {
                    marginTop: 20
                  }
                }
              },
            } 
            console.log("MyPortal-cfgPortal:",cfgPortal);
            setPortalCfg(cfgPortal)
          } else {
            // que faire si pas de réponse de l'api
            const cfgPortal = {
              www: "",
              logo: "/1x1-00000000.png",
              title: "partnerConfig.brandName",
              authent: {
                logo: "/1x1-00000000.png",
                title: "",
                theme: {
                  formContainer: { 
                    marginTop: 0
                  },
                  formSection: {
                    marginTop: 20
                  }
                }
              },
            } 
            console.log("MyPortal-default cfgPortal:",cfgPortal);
            setPortalCfg(cfgPortal)       
          }
    
        }
        //fetchData("myiotplatform")
        fetchRestData(portalName)
        // ou
        //const cfg = generalConfig[portalName]
        //console.log("Portal config (%s) :",portalName, cfg);
        //setPortal(cfg)
    }, [generalConfig, portalName])

    async function fetchRestGraphQl(domainName) {
        const variables = {
          domId: domainName
        }
        const query = `
          query partner($domId: String!) {
            getPartnerByDomain(domainId: $domId) {
                items {
                    id
                    domainId
                    languageDefault
                    brandConfig
                }
            }
          }
        `;
        const url = awsconfig.aws_appsync_graphqlEndpoint;
        const apiKey = awsconfig.aws_appsync_apiKey;
    
        const opts = {
          method: "POST",
          headers: { 
              "Content-Type": "application/graphql",
              'x-api-key': apiKey},
          body: JSON.stringify({query, variables})
        };
        //console.log("opts:",opts);
        const response = await fetch(url, opts)
        const result = await response.json();
        //console.log("result:",result);
        if (result && result.data && result.data.getPartnerByDomain) {
          const data = result.data.getPartnerByDomain;
          if (data && data.items && data.items.length>0) {
            return data.items[0]
          } else {
            return
          }
        } else {
          return 
        }
    }

    if (portalCfg) 
      return ( <MyAuthentication  portalCfg={portalCfg} accountRequest={accountRequest} /> )
    else 
      return ( 
        <div className={classes.alignItemsAndJustifyContent}>
          <CircularProgress size={40} />
        </div>
      )
}

// controle de l'authentification
function MyAuthentication({ portalCfg, accountRequest }) {

    const { route, signOut } = useAuthenticator(context => [context.route]);
    let location = useLocation();

    useEffect(() => {
        //console.log("Language detected :",i18n.language);
        I18n.setLanguage(i18n.language);
    }, [i18n.language])    

    useEffect(() => {
      if (location && location.search && location.search.includes('logout')) {
        console.log("logout trouvé dans l'url. Signout() : ",location)
        signOut();
      }
    }, [location])  
     
    return route === 'authenticated' ? <Authenticated  portalCfg={portalCfg} /> : <Login portalCfg={portalCfg} accountRequest={accountRequest} />;
}

I18n.putVocabulariesForLanguage('fr', {
/*     'Sign In': 'Loginqq', // Tab header
    'Sign in': 'Log inqq', // Button label
    'Sign in to your account': 'Welcome Back!', */
   // Username: 'Enter your email', // Username label
    //Password: 'Enter your password', // Password label
    //   'Forgot your password?': 'Reset Password',
  });

const Login = ({portalCfg: portal, accountRequest}) => {

    const [ loginMode, setLoginMode ] = useState();
    let history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    //const [initialState, setInitialState] = useState();
    const defaultAuthMode = accountRequest ? "signUp": "";

    useEffect(() => {
      //console.log("portal :", portal);
      if (portal) {
        if (portal.id==='visio-green-africa') {
          setLoginMode('username')
        } else {
          setLoginMode('email')
        }
      }
    }, [portal])    

    const goToSignup = () => {
      //history.push({ pathname: "/", search: '?portal=visio-green-africa&newaccount=true' })
      window.location.href='/?portal=visio-green-africa&newaccount=true';
    }

    const Header = () => {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
            <div>
            {portal?.authent?.logo &&
                <Image src={portal?.authent?.logo}  alt="login" height="100px"/> 
            }
            </div>
            <div>{portal?.authent?.title} </div>    
        </View>
      );
    };
  
    const Footer = () =>  {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={`${tokens.colors.neutral['80']}`}>
            &copy; {t('screen.authentication.all-rights-reserved')}
          </Text>
        </View>
      );
    };

    const componentsForEmail = {
        Header,
        Footer,
        SignUp: {
            FormFields() {
                const { validationErrors } = useAuthenticator();
                //console.log("validationErrors:",validationErrors)
                return (
                  <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />
                       
                    {/* Append & require Terms & Conditions field to sign up 
                    <CheckboxField
                      errorMessage={validationErrors.acknowledgement}
                      hasError={!!validationErrors.acknowledgement}
                      name="acknowledgement"
                      value="yes"
                      label={t('screen.authentication.i-agree-with-terms')}
                    />
                    */}
                  </>
                );
            },
            Header() {
                const { tokens } = useTheme();
        
                return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    {I18n.get('Create a new account')}
                </Heading>
                );
            },
            Footer() {
                const { toSignIn } = useAuthenticator();
        
                return (
                <View textAlign="center">
                    <Button
                    fontWeight="normal"
                    onClick={toSignIn}
                    size="small"
                    variation="link"
                    >
                        {I18n.get('Back to Sign In')}
                    </Button>
                </View>
                );
            },
        }
    };
    
    const componentsForUsername = {
      Header,
      Footer,
      SignIn: {
          Header() {
              const { tokens } = useTheme();
      
              return (
              <Heading
                  padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                  level={3}
              >
                  Connexion
              </Heading>
              );
          },
          Footer() {
              const { toResetPassword } = useAuthenticator();
      
              return (
                <>
                  <View textAlign="center">
                      <Button
                      fontWeight="normal"
                      onClick={goToSignup}
                      size="small"
                      variation="link"
                      >
                         <PermIdentityIcon />
                          S'inscrire
                      </Button>                
                  </View>
                  <View textAlign="center">
                      <Button
                      fontWeight="normal"
                      onClick={toResetPassword}
                      size="small"
                      variation="link"
                      >
                         <LockOpenIcon />
                          Mot de passe oublié
                      </Button>                  
                  </View>                              
                </>

              );
          },
      }
  };

    const services = {     
        async validateCustomSignUp(formData) {
            console.log("validateCustomSignUp...", formData);
            if (formData.username.startsWith("a")) {
              return {
                username: "pas correct"
              }
            }
            if (!formData.acknowledgement) {
                return {
                    acknowledgement: t('screen.authentication.you-must-agree'),
                };
            }          
        },
        async handleSignUp(formData) {
            let { username, password, attributes } = formData;
            console.log("handleSignUp...", formData);
            // custom username
            username = username.toLowerCase();
            //attributes.email = attributes.email.toLowerCase();
            return Auth.signUp({
              username,
              password,
              attributes,
            });
          },              
    };

    const formFields = {
      signIn: {
        username: {
          labelHidden: false,
          placeholder: 'Enter your email (ici)',
        },
      },
      signUp: {
        username: {
          labelHidden: false,
          placeholder: 'Enter your email (la)',
          isRequired: false,
        },
      }
    }
    if (loginMode) {
      if (loginMode==='username') {
        if (accountRequest) {
          // inscription (avec le email comme username) dorenavent tout le monde s'inscrit avec un email en username
          return (
            <Authenticator 
              initialState={defaultAuthMode} 
              components={componentsForEmail} 
              loginMechanisms={['email']}
            />
          )
        } else {
           // Login (avec le username qui n'est pas un mail (Mode antérieur de visio-green-africa))
          return (
            <Authenticator 
              components={componentsForUsername} 
              hideSignUp={true}
              loginMechanisms={['username']}
            />
          )
        }
      } else {
        return (
          // mode normal : login avec un email, inscription avec un email
          <Authenticator 
            components={componentsForEmail} 
            initialState={defaultAuthMode} 
            loginMechanisms={['email']}
          />
         )
      }
    }
    else 
      return ( 
        <div className={classes.alignItemsAndJustifyContent}>
            <CircularProgress size={40} />
        </div>
      )
}

const Authenticated = ({portalCfg}) => {

    const { user, signOut } = useAuthenticator((context) => [context.user]);
    return (
        <>
            <InstallPWA/>
            <TranformUser user={user} portalCfg={portalCfg} />
        </>
    );
};

//transformer le cognitoUser en LoginUser
const TranformUser = ({user: cognitoUser, portalCfg}) => {

   const { setCustomer, customer } = useContext(GeneralContext);

    const classes = useStyles();

    const [ userName, setUserName ] = useState();
    const [ customerId, setCustomerId ] = useState();
    const [ userRights, setUserRights ] = useState();
    const [ pageToGo, setPageToGo ] = useState();

    const [ idToken, setIdToken ] = useState();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
      console.log("Transf-Init subscriptions to Listen events on Customer")
      Hub.listen('toChangeCustomer', handleEventChangeCustomer)
        return function cleanup() {
            console.log(`GlobalContextProcessing unmounting changeCustomer subscriptions to listen events`)
            Hub.remove('toChangeCustomer', handleEventChangeCustomer)
        };
    },[]);
      
    useEffect(() => {
        async function asyncProcessing() {
          console.log("Transf-CognitoUsrChanged- *** cognitoUser is changing *** :",cognitoUser);
          // il y a bcp d'event qui maj le cognitoUser
          // ex Auth.currentAuthenticatedUser({ bypassCache: true });
          // on regarde si le userName, le idToken ou le customer dans le token

          // si le username est different, on le maj
          const newUserName = cognitoUser.username;
          if (newUserName && newUserName !==userName) {
            console.log("Transf-CognitoUsrChanged- userName is new : %s (old: %s)  : ", newUserName, userName);
            setUserName(newUserName);
          } else {
            console.log("Transf-CognitoUsrChanged- même userName");
          }

          // si le token est maj
          const newIdToken = cognitoUser.signInUserSession.idToken.jwtToken;
          if (idToken !== newIdToken) {
            console.log("Transf-CognitoUsrChanged- token is new", newIdToken);

            // il faut maj graphql
            // pour gérer correctement les droits sur les ressources
            console.log("Transf-CognitoUsrChanged- update graphqlToken");
            await updateGrapqlToken(newIdToken);  

            // le token peut contenir un customerId
            // toujours, sauf, la première fois, juste après le signin,login
            // si il est different on le maj
            const idTokenPayload = cognitoUser.signInUserSession.idToken.payload;
            const {currentCustomerId} = idTokenPayload;

            if (currentCustomerId) { //inToken
              if (currentCustomerId !== customerId) {
                console.log("Transf-CognitoUsrChanged- customerId is new  %s (old:%s). updating...", currentCustomerId, customerId);
                setCustomerId(currentCustomerId);
              } else {
                console.log("Transf-CognitoUsrChanged- le customerId n'a pas changé (%s)", currentCustomerId);
              }

              // Ajouts présents dans le token des droits du user sur le nouveau customer
              const userRights = {};
              const {currentCustomerIdAdmin, currentCustomerIdOwner} = idTokenPayload;
              userRights["isCustAdmin"] = (currentCustomerIdAdmin && currentCustomerId === currentCustomerIdAdmin) ? true : false;
              userRights["isCustOwner"] = (currentCustomerIdOwner && currentCustomerId === currentCustomerIdOwner) ? true : false;
              setUserRights(userRights);
              console.log("userRights:",userRights);

            } else {
              console.log("Attention le token ne contient pas encore de customer")
              // le token ne contient pas de customer 
              // c'est le cas ou le user vient de s'enregistrer
            }
            setLoading(false);
            console.log("fin loading?");

          } else {
            console.log("même token que précedement, RAF");
          }

        }
        asyncProcessing();
    },[cognitoUser]);

    // CHANGEMENT de customer
    async function handleEventChangeCustomer(data) {
      setLoading(true);
      //setCustomerId();
      console.log("setCustomer({}); et loading=true")
      setCustomer({});
      const newCustomerId = data.payload.event.customerId;
      const pageToGo = data.payload.event.pageToGo; // notament utilisé depuis la homePage 'consulter vos capteurs'
      console.log("Transf-EventChangeCust- EVENT ASK FOR CHANGING CUSTOMER for %s and go : %s ...", newCustomerId, pageToGo);

      function attributesToObject(attributes) {
        const obj = {};
        if (attributes) {
            attributes.map(attribute => {
                if (attribute.Value === 'true') {
                    obj[attribute.Name] = true;
                } else if (attribute.Value === 'false') {
                    obj[attribute.Name] = false;
                } else {
                    obj[attribute.Name] = attribute.Value;
                }
            });
        }
        return obj;
      } 

      // 1- maj du userPropertie Cognito : reqCustomerId
      var cognitoUserAuth = await Auth.currentAuthenticatedUser();
      console.log("Transf-EventChangeCust-Attributs du cognitoUser AVANT:",cognitoUserAuth.attributes);
      //en ajoutant { bypassCache: true } dans Auth.currentAuthenticatedUser(), on force une maj du cognitoUser
      var userProp = [];
      var attribute1 = new CognitoUserAttribute({ Name: "custom:reqCustomerId", Value: newCustomerId });
      userProp.push(attribute1)  
      const attributes = attributesToObject(userProp);
      console.log("Transf-EventChangeCust-Maj des attributs du Cognito user avec le nouveau customerId (entraine une maj du cognitoUser)...");
      await Auth.updateUserAttributes(cognitoUserAuth, attributes) // -> entraine un changmt sur le cognitoUser ...
      console.log("Transf-EventChangeCust-cognitoUser well updated (--> cognitoUser is Changing event)");

      // l'action de switch customer contenait un pageToGo (probablement la homePage/Consultez vos capteurs (/services/all) )
      if (pageToGo) {
        console.log("PageToGO:",pageToGo )
        setPageToGo(pageToGo);

      } else {
        // si pas de homePage, ont route vers '/services/all'
        setPageToGo("fromCustomerSwitchWithoutLink");
      }
    }

    const updateGrapqlToken = async (idTokenJwt) => {
      // maj de l'idToken  (contenant les droits) dans graphql graphql (en mode AWSAppSyncClient (apollo))
      //console.log("Transf-GraphUpdt- >>>>>>>>>>>idtoken", idTokenJwt);
      //console.log("Transf-GraphUpdt- MAJ du token pour les requettes graphql (mode authent via login ");
      GraphQlApi.getInstance().setToken(idTokenJwt);   
    }

    if (loading) {
      return (
        <div className={classes.alignItemsAndJustifyContent}>
          <CircularProgress size={40} />
        </div>
      )
    } else {
      return (userName)  ?  
      <GlobalContextProcessing userName={userName} customerId={customerId} portalCfg={portalCfg} userRights={userRights} pageToGo={pageToGo} /> 
      : 
      <div className={classes.alignItemsAndJustifyContent}>
        <CircularProgress size={40} />
      </div>
    }
}
