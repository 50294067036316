import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import { Image } from '@aws-amplify/ui-react';

import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import GeneralContext from '../components/GeneralContext'
import AppHeader from './AppHeader'
import AppDrawer from './AppDrawer'
import AppMain from './AppMain'

//import logo from '../media/logo.svg';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  appLogo: {
    marginTop: 5,
    textAlign: "center",
    height: "48px",
    pointerEvents: "none"
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing(1),
    padding: 4,
  },
  appTitle: {
    fontSize: "8px"
  },
  drawerHeader: {
    textAlign: "center"
  }
}));

export default function AppLayout(props) {
  const { container, customerID } = props;
  const { portal } = useContext(GeneralContext);

  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} >
        <div className={classes.drawerHeader} >
          <Image src={portal.logo} className={classes.appLogo} alt="logo" />
          {/*  <div className={classes.appTitle}>By MyIotPlatform</div> */}
        </div>
      </div>
      <Divider />
      <AppDrawer openState={mobileOpen} toClose={handleDrawerToggle}/>
    </div>
  );

  return (
    <div className={classes.root}>

      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>

        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          
          <AppHeader/>

        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="sensors folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
       {/*  <div className={classes.toolbar} /> */}
        <AppMain customerID={customerID}></AppMain>
      </main>

    </div>
  );
}

AppLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};


