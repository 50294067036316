import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GraphQlApi from './MyGraphql';
import jwt_decode from "jwt-decode";
import { addingRightsFromIdTokenToUser } from '../Common';

import MyApp from '../MyAppNew'

export default function FromMobile({ token: idToken, portal }) {

    //let { token: idToken } = useParams();

    //const { user, customer } = useContext(GeneralContext);

    //const [ idToken , setIdToken ] = useContext();
    const [ cognitoUser, setCognitoUser ] = useState();

    const { t } = useTranslation();

    async function fetch() {
      await getUser("userId");
    }

    useEffect(() => {
        if (idToken) {
          console.log("token available");

          // init du token pour les appels graphql
          console.log("MAJ du token pour les requettes graphql (Mode Mobile, token dans l'url)");
          GraphQlApi.getInstance().setToken(idToken, true); // le token issue de l'url

          // decodage du token
          try {
            var token = jwt_decode(idToken);
            console.log("token decoded:",token);
            var cognitoUser = {
              username: token['cognito:username'],
              currentCustomerId: token.currentCustomerId,
              attributes : {
                email: token.email,
                phone_number: token.phone_number
                // cognito:groups
              }
            }
            addingRightsFromIdTokenToUser(token,cognitoUser);
          }
          catch(e) {
            console.log("Bad token")

          }
          console.log("cognitoUser:",cognitoUser);
          //const test = fetch();
          setCognitoUser(cognitoUser);
        }

    },[idToken]);

    async function getUser(userId) {
            console.log("getUser...");
            const getQuery = `query MyQuery {
                listUsers {
                  items {
                    email
                    id
                  }
                }
              }`; 


              const result = await GraphQlApi.getInstance().graphqlOperation( getQuery, { id: "test" });

              if (result && result.data && result.data.listUsers) {
                  const users = result.data.listUsers;
                console.log("cool users:",users)
              } else {
                  console.log("Pas de resultat")
              }
    }

    return (
        <div>
            {cognitoUser &&
              <MyApp cognitoUser={cognitoUser} fromMobile={true} />
            }
            {!cognitoUser &&
              <div> waiting or not cognitoUser not </div>
            }
        </div>
    );
}