import React, { useEffect, useRef, useState } from "react";

import Chartjs from "chart.js";
import 'chartjs-plugin-annotation';

import { useTranslation } from 'react-i18next';
import moment from 'moment'
import 'moment/min/locales';

import { buildAnnotations } from '../Common';

/**
 * Affichage d'un graphique sur un seul axe (doite/gauche)
 * avec potentiellement plusieurs courbes sur le même axes (mêmes unités)
 * 
 * 
 * @param {*} dataSets 
 * 
 *      {
            probe1: { 
              name : "1 - temp",
              show: "left",
              type: "TEMPERATURE",
              unit: "°C",
              data: [
                {x:"2017-01-06 18:39:30",y:"10"},
                ...
              ]
            },
            ....
        }
 * 
 * @param {*} config 
 * 
 * issue d'un fichier (chart-config.js) configuration générale du graphique (noms possibles des axes Y (droite/gauche) couleurs)
 * 
 */

const chartConfigInit = {
    data: {
        datasets: []
    },
    plugins: [],  
    //plugins: [MyAnnotationPlugin],  
    type: "line",
    //data: dataset,
    options: {
      title: {
        display: false,
        text: 'Titre du graphique'
      },      
      legend: { 
        display: true,
        labels: {
          boxWidth: 10,
          fontColor: 'black'
        },
        // 'onClick' : function (evt, item) {
        //   console.log ('legend onClick', evt, item);
        // },
      },
      layout: {
        padding : {
          right: 10
        }
      },
      responsive: true,
      //hoverMode: 'index',
      //stacked: true,    
      scales: {
        xAxes: [{
          xAxeId : "x-axis-time",
          type: 'time',
          time: {
            tooltipFormat:'DD/MM/YYYY HH:mm',
            displayFormats: {
                day: 'D MMM',
                hour: 'HH [h]',
                minute: 'H:mm'
            }
          },        
          //distribution: 'series',
          distribution: 'linear', // necessaire pour les affichages d'E/S en escalier
          //'linear': data are spread according to their time (distances can vary)
          //'series': data are spread at the same distance from each other
          //offset: true,
          // time: {
          //   unit: 'minute'
          // },
          ticks: {
            major: {
              enabled: true,
              fontStyle: 'bold'
            },
            source: 'auto',
            autoSkip: true,
            autoSkipPadding: 75,
            maxRotation: 0,
            sampleSize: 100
          },
          afterBuildTicks: function(scale, ticks) {
            //console.log("scale:",scale);
            //console.log("ticks:",ticks);
            if (ticks && ticks.length>0) {
              var majorUnit = scale._majorUnit;
              var firstTick = ticks[0];
              var i, ilen, val, tick, currMajor, lastMajor;
    
              val = moment(ticks[0].value);
              if ((majorUnit === 'minute' && val.second() === 0)
                  || (majorUnit === 'hour' && val.minute() === 0)
                  || (majorUnit === 'day' && val.hour() === 9)
                  || (majorUnit === 'month' && val.date() <= 3 && val.isoWeekday() === 1)
                  || (majorUnit === 'year' && val.month() === 0)) {
                firstTick.major = true;
              } else {
                firstTick.major = false;
              }
              lastMajor = val.get(majorUnit);
    
              for (i = 1, ilen = ticks.length; i < ilen; i++) {
                tick = ticks[i];
                val = moment(tick.value);
                currMajor = val.get(majorUnit);
                tick.major = currMajor !== lastMajor;
                lastMajor = currMajor;
              }
            }
            return ticks;
          }                       
        }],      
        yAxes: []
      },
      annotation: {
        annotations: [],
      },
      // pour contourner le bug "Cannot read properties of null (reading 'transition')"
      // qui apparait lorsqu'on redemande un graph alors que le précedent n'est pas terminé d'être dessiné
      animation: {
        duration: 0
      },
      hover: {
          animationDuration: 0
      },
      responsiveAnimationDuration: 0,
      // onClick: (e, activeEls) => {
      //   console.log("activeEls:",activeEls);
      // }
    }
  }
  
const ChartMonoAxe = ({dataSets: dataSetsExternal , config, userOptions, onClickCallback}) => {

    // dataSets : les données. 
    //      elles peuvent être maj depuis l'exterieur du composant
    //      ou depuis le composant (ex cacher/montrer une courbe)

    // chartInstance : la config dont les données + scale, tooltip, annotation, yAxes ...
    // créé en même tant que le compsant et maj si necessaire

    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const { t } = useTranslation();
    const [dataSets, setDataSets] = useState(dataSetsExternal);

    // init du composant graphique avec les premières données
    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            console.log("Premier affichage du graphique:",dataSetsExternal)

            const chartConfig1 = buildGraphConfig(dataSetsExternal);
            console.log("Premiere chart config (data+param)", chartConfig1)


            const newChartInstance = new Chartjs(chartContainer.current, chartConfig1);
            newChartInstance.update();

            // pour test : 
            //setChartInstance(newChartInstance);

        }
    }, [chartContainer, dataSetsExternal, userOptions]);

    useEffect(() => {
      console.log("userOptions changed  :",userOptions);
    }, [userOptions]);
/* 
    Pour test
    useEffect(() => {
      console.log("ChartInstance changed  :",chartInstance);
      if (typeof chartInstance !== 'undefined') {
        //chartInstance.destroy() 
      }
    }, [chartInstance]);
 */

    // on recoit des données de l'exterieur du composant (après le première initialisation du graphique)
/*     useEffect(() => {
        console.log("from external dataSets changed  :",dataSetsExternal);
        setDataSets(dataSetsExternal);
    }, [dataSetsExternal]);
 */
    // dés que les données ont changées
    // on redessine le graphique
    //TODO quid si d'autre element sont changés ex u type de graphique ?
    //  
/*     
    useEffect(() => {
        if (chartInstance) {
          console.log("from internal dataSets changed, REDRAWING :",dataSets);
          updateGraphConfig(dataSets);
          console.log("chartInstance après prise en compte du dataset:",chartInstance);
          chartInstance.update();
        }
    }, [dataSets]);

 */
  const chartOnClickProv = (activeEls) => {

  }
    // construction de la premiere config du graph avec la config par defaut et les 1eres données
    const buildGraphConfig = ((dataSets) => {
        console.log("buildGraphConfig");
        // Je ne comprends pas :
        // chartConfigInit doit être instancié en dehors du composant
        // avec en particuiler l'axe des C
        const chartConfigInitProv = {
            type: "line",
            data: {
                datasets: []
            },
            options: {
                scales: {
                    xAxes: [],
                    yAxes: []
                },
                annotation: {
                    annotations: [],
                }
            }
        };
        //chartConfigInit.options.scales.xAxes = buildXAxe();

        chartConfigInit.options.scales.yAxes = buildGraphYAxes(dataSets);  
        chartConfigInit.options.annotation.annotations = buildAnnotations(dataSets)
        //chartConfigInit.options.tooltips = buildTooltip(chartInstance);

        chartConfigInit.data.datasets = buildGraphDataSet(dataSets);

        chartConfigInit.options.onClick = (e, activeEls) => {
          console.log("click on activeEls:",activeEls);
          if (activeEls.length>0) {
            //console.log(activeEls[0]._chart.data.labels[activeEls[0]._index])
            const chartElmt = activeEls[0];
            //console.log("chartElmt:",chartElmt);
            const datasetClicked = chartElmt._chart.data.datasets[0];
            if (datasetClicked) {
              //console.log("datasetsClicked:",datasetClicked); 
              const dataClicked = datasetClicked.data[chartElmt._index];
              console.log("dataClicked:",dataClicked); // {x: '2022-10-15T22:00:00.000Z', y: 0.628}
              if (dataClicked) {
                onClickCallback(dataClicked.x); // 
              }            
            }
          }          
        }; 

        return chartConfigInit
    })

/* 
    const updateGraphConfig = ((dataSets) => {
      console.log("updateGraphConfig");
      chartInstance.options.scales.yAxes = buildGraphYAxes(dataSets);  
      //chartInstance.options.annotation.annotations = buildAnnotations(dataSets)
      //chartInstance.options.tooltips = buildTooltip(chartInstance);
      chartInstance.data.datasets = buildGraphDataSet(dataSets);
    })
 */
    const buildGraphYAxes = ((dataSets) => {
        // première init des axes
        // si certaines sondes sont affichés par defaut
        console.log("buildGraphYAxes");

        var leftAxeCfg;
        var rightAxeCfg;
        
        for(const key in dataSets) {
          const dataSet = dataSets[key];
          if (dataSet.show && dataSet.show !== "no") {
    
            const probeType = dataSet.type;
            console.log("probeType:", probeType)
            const probeCfg = config.probesType[probeType]; // depuis la config generale du graphique

            const title = t("sensor.probe-type."+probeType); // traduction du titre de l'axe
    
            var probeColor, probeUnit;
            if (probeCfg) {
              probeColor = probeCfg.color;
              probeUnit = probeCfg.unit;
            } else {
              probeColor = 'red';
              probeUnit = "?";
            }

            if (dataSet.show === "left") {
                leftAxeCfg = buildYAxe('left', title, probeColor, probeUnit) //{/* EVOL1 */}
    
            } else  if (dataSet.show === "right") {
                rightAxeCfg = buildYAxe('right', title, probeColor, probeUnit) //{/* EVOL1 */}

            }
          } else {
          // hide line
          // on supprime la courbe d'un axe,
          // si il n'y a plus de courbe pour cet axe (cf dataSets), on supprime l'axe
          }
        }

        var yAxes = [];
        if (leftAxeCfg) { 
          yAxes.push(leftAxeCfg)
        }
        if (rightAxeCfg) {
          yAxes.push(rightAxeCfg)
        }
    
        return yAxes; // (droite/gauche)
    })

    const buildXAxe = (( )=> {
        return {
            xAxeId : "x-axis-time",
            type: 'time',
            time: {
              tooltipFormat:'DD/MM/YYYY HH:mm',
              displayFormats: {
                  day: 'D MMM',
                  hour: 'HH [h]',
                  minute: 'H:mm'
              }
            },        
  //          distribution: 'linear',
            //offset: true,
            // time: {
            //   unit: 'minute'
            // },
            ticks: {
              major: {
                enabled: true,
                fontStyle: 'bold'
              },
              source: 'auto',
              autoSkip: true,
              autoSkipPadding: 75,
              maxRotation: 0,
              sampleSize: 100
            },
            afterBuildTicks: function(scale, ticks) {
              //console.log("scale:",scale);
              //console.log("ticks:",ticks);
              if (ticks && ticks.length>0) {
                var majorUnit = scale._majorUnit;
                var firstTick = ticks[0];
                var i, ilen, val, tick, currMajor, lastMajor;
      
                val = moment(ticks[0].value);
                if ((majorUnit === 'minute' && val.second() === 0)
                    || (majorUnit === 'hour' && val.minute() === 0)
                    || (majorUnit === 'day' && val.hour() === 9)
                    || (majorUnit === 'month' && val.date() <= 3 && val.isoWeekday() === 1)
                    || (majorUnit === 'year' && val.month() === 0)) {
                  firstTick.major = true;
                } else {
                  firstTick.major = false;
                }
                lastMajor = val.get(majorUnit);
      
                for (i = 1, ilen = ticks.length; i < ilen; i++) {
                  tick = ticks[i];
                  val = moment(tick.value);
                  currMajor = val.get(majorUnit);
                  tick.major = currMajor !== lastMajor;
                  lastMajor = currMajor;
                }
              }
              return ticks;
            }                       
          }
    })

    // construction de l'axe des y (droite/gauche)
    const buildYAxe = ((position, title, color,  unit )=> {
        var beginAtZero;
        if (userOptions.hasOwnProperty('beginAtZero')) {
          beginAtZero = userOptions.beginAtZero ? true: false;
        } else {
          beginAtZero = true;
        }
        return  {
            type: 'linear', 
            display: true,
            position: position,
            color: color,
            pointRadius: 0,
            id: 'y-axis-'+position,          
            ticks: {
                beginAtZero: beginAtZero,
                callback: function(value, index, values) {
                    return value + " " + unit;
                },  
                //suggestedMin: -5       // SI temperature (peut-être si une des valeurs de la serie est negative)  
            },
            scaleLabel: {
                display: true,
                labelString: title,
                fontColor: color
            },
            gridLines: {
                color: "#ffd699",
                drawBorder: true,
                drawOnChartArea: true
            },                               
        }
    })

    // lecture des sets de data pour generer des sets de graph
    const buildGraphDataSet = ((dataSets) => {
        var graphDataSets = [];
        for(const key in dataSets) {
          const dataSet = dataSets[key];

          if (dataSet.show && dataSet.show !== "no") {
              const yAxexId = (dataSet.show === "left")?"y-axis-left":"y-axis-right";
              graphDataSets.push(buildYLine(dataSet.name, dataSet.type, yAxexId, dataSet.unit, dataSet.data, dataSet.graphType)); //{/* EVOL1 */}
          }      
        }
        return graphDataSets;
    })

    // construction d'une serie de donnée
    const buildYLine = ((title, type, yAxexId, unit, data, graphType) => {

        //TODO : recuperer une couleur par sonde et non pas par type de l'axe

        // lecture d'une config en fonction du tye de probe (config du composant issue d'un fichier)
        const probeCfg = config.probesType[type]; // config initiale du composant

        const graphDataSet = {
            label: title,
            unit: unit,
            data: data,
            borderWidth: 2,
            pointRadius: 1,
            borderColor: probeCfg?.color,
            fill: false,
            backgroundColor: probeCfg?.backgroundColor,
            yAxisID: yAxexId,
            //showLine: false,
            pointHitRadius: 40    
        }

        if (graphType) {
          if(graphType==="SteppedLine") {
            graphDataSet.type = "line";
            graphDataSet.tension=0; // ligne droite, non extrapolée
            // à la place des escalier, on ajoute des events dans probePage
            // et on met tension à 0 ci-dessus
            //graphDataSet.steppedLine = 'before'; // 'before' / 'after'
          } else {
            graphDataSet.type = graphType;
          }
        }
        return graphDataSet;
    })

    return (
        <div>

        <canvas ref={chartContainer} />
        </div>
    );
};

export default ChartMonoAxe;
