import React, { useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';

import GraphQlApi from '../mobile/MyGraphql';

import MyLink from '../components/MyLink';
import { useTranslation } from 'react-i18next';

import ProbeIcon from '@material-ui/icons/FilterTiltShift';
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton';

import InputIcon from '@material-ui/icons/Input';

import EditIcon from '@material-ui/icons/Edit';

import CircularProgress from '@material-ui/core/CircularProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';


//import Switch from '@material-ui/core/Switch';

import { toSnack } from '../Common';

const useStyles = makeStyles((theme) => ({ 
    root: {
    },
    card: {
        maxWidth: "800px"
    },
    probeDetail: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    inputWide: {
        width: '100%',
    },    
    inputSmall: {
        width: '3em',
        marginRight: 8
    },
    alignItemsAndJustifyContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },    
    saveButton: {
        paddingBottom: 0,

    }    
}));

export default function ProbesEditList({ service, probes }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [allOpen, setAllOpen] = React.useState([]);
    //const [probesInput, setProbesInput] = React.useState(initProbesInput(probes));
    const [probesInput, setProbesInput] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (probes) {
            console.log("Init(effect) probes:", probes);
            var all = []; 
            var probesValues = [];
            for(const key in probes) {
                const probe = probes[key];
                all.push(false); // par defaut, tous les probe sont repliées

                var probeValue = {id: probe.id, name: probe.name};
                if (probe.graph) probeValue["graph"] = probe.graph;
                if (probe.fct) probeValue["fct"] = probe.fct;
                if (probe.stateOn) probeValue["stateOn"] = probe.stateOn;

                probesValues.push(probeValue)
            }
            setAllOpen(all)
            setProbesInput(probesValues)
        }

    }, [probes]);

    const handleOpen = (toOpen, key, item) => {
        console.log("item:",toOpen, key, item)
        var newAllOpen = JSON.parse(JSON.stringify(allOpen)); // pour forcer un refresh !
        newAllOpen[key] = toOpen;
        setAllOpen(newAllOpen)
    };
  
    const [checked, setChecked] = React.useState(['wifi']);
    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    };

    const handleChangeName = (event) => {
        console.log(event.target.id, event.target.value);
        const key = event.target.id.split('-')[1];
        var probesInputNew = JSON.parse(JSON.stringify(probesInput))
        probesInputNew[key].name = event.target.value
        setProbesInput(probesInputNew)
    };

    const handleChangeGraph = (event) => {
        console.log(event.target.id, event.target.value);
        const keys = event.target.id.split('-');
        if (keys.length>1) {
            const probeNum = keys[2];
            const valX = keys[1];

            var probesInputNew = JSON.parse(JSON.stringify(probesInput))

            const probeObj = probesInputNew[probeNum]
            const graph = probeObj['graph']
            if (graph && graph.notes) {
                if (graph.notes[valX]) {

                    if (event.target.value !== "") {
                        graph.notes[valX].value = event.target.value;
                    } else {
                        graph.notes[valX] = {} ; // effacement de la valeur
                    }
                    

                } else {
                    graph.notes.push({ /* text: "", */ value: event.target.value})
                } 
                
            } else {
                const graph = { notes : [
                    { /* text: "", */ value: event.target.value}
                ]}
                probeObj['graph'] = graph;
            }
            setProbesInput(probesInputNew)

        } else {
            console.log("Impossible de trouver les identifiants des valeurs 1 et 2 id:",event.target.id);
        }
    };    

    const handlePropertySave =  (async (key, probe, property) => {
       //console.log(key, probe)
       console.log("update %s dans : ",property, probesInput[key])
       try {
        setLoading(true);
        await updateProbeProperty(probesInput[key], property);
        toSnack("ServiceUpdated",t('screen.probes-admin.well-updated'));
       }
       catch (error) {
        toSnack("ServiceUpdated",t('screen.probes-admin.update-error'));
       }
       setLoading(false);
    })
    
    // Maj de la proriété 'property' (name, graph, fct) pour la sonde probe.id dans probesConfig de service
    const updateProbeProperty =  (async (probe, property)  =>  {
        console.log("MAJ de propriété %s dans la probe ",property,probe);
        // pour maj probesConfig (AWSJSON) dans service et eviter/Limiter un ecrasement de l'ecriture par une ecriture concomitante
        // on faire une lecture juste avant l'ectiture
        // pour faire mieux : remonter nu numéde version et le comparer au moment de l'ecriture (lammda resolver) ou passer par un WS Rest

        // read probesConfig from base
        var probesConfig = await getProbesConfigQuery(service.id);
        //console.log("probeConfig en base : ",probeConfig);
        if (!probesConfig) {
            //probesConfig = {};
            probesConfig = buildInitProbeConfig(probes)
        }

        // update probesConfig
        const probeToUpdate = probesConfig[probe.id];
        if (!probeToUpdate) {
            probesConfig[probe.id] = {};
        }

        //var currentProp = probeToUpdate[property];
        //console.log("valeur précédente en base pour la probe %s et la propriété %s : ", probe.id, property, currentProp);

        probesConfig[probe.id][property] = probe[property];
        console.log("ProbeConfig avec nouvelle proriété %s :", property, probesConfig);

        await updateProbesConfigMutation(service.id, probesConfig)

        console.log("MAJ OK");

    })
   

    // initialisation de probesConfig avec le Nom de chaque sonde (fct de la langue de l'utilisateur)
    // en attendant que tous les noms soient maj
    const buildInitProbeConfig = ((probes) => {
        var probesConfig = {}
        for(const key in probes) {
            const probe = probes[key];
            probesConfig[probe.id] = { name : probe.name}
        }
        return probesConfig
    })

    async function updateProbesConfigMutation(serviceId, probesConfig) {
        const probesConfigString = JSON.stringify(probesConfig);
        const toUpdate = {
            id: serviceId,
            probesConfig: probesConfigString
        };
        console.log("toUpdate:",toUpdate);
        const updateMutation = `mutation updateService($input: UpdateServiceInput!) {
          updateService( input: $input) {
              id
              probesConfig
          }
        }`;
        //const response = await API.graphql(graphqlOperation(updateMutation, { input: toUpdate }));
        const response = await GraphQlApi.getInstance().graphqlOperation( updateMutation, { input: toUpdate });
        console.log("Service updated", response.data.updateService);
    } 
    async function getProbesConfigQuery(serviceId) {
        console.log("Fetching for service %s is processing ...", serviceId);
        var probesConfig;
        const getServiceQuery = `query GetService($id: ID!) {
            getService(id: $id) {
              id
              probesConfig                    
            }
          }`;
        //const result = await API.graphql(graphqlOperation(getServiceQuery, { id: serviceId }));
        const result = await GraphQlApi.getInstance().graphqlOperation( getServiceQuery, { id: serviceId });
        if (result.data.getService) {
            const service = result.data.getService
            console.log("Service trouvé en base:", service);
            if (service.probesConfig) {
                probesConfig = JSON.parse(service.probesConfig);
            }
        } else {
            console.log("Service non trouvé en base:", serviceId);
        }
        return probesConfig;
    }

    return (
        <div>
            <div className={classes.alignItemsAndJustifyContent}>
             {loading && <CircularProgress size={40} />}
            </div>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    {t('screen.probes-admin.probes-title')}
                    </ListSubheader>
                }
                className={classes.probes}
        >       

                {probes?.map( (probe, key) => 
                    <div  key={key}  >
                        <MyLink to={"/serviceAdmin/"+service.id+"/"+probe.id}  style={{ textDecoration: 'none' }}>
                            <ListItem dense={true} >
                                <ListItemIcon>
                                <Icon>
                                    {probe.type !== "STATE_OUT" &&
                                    <ProbeIcon />
                                    }
                                    {probe.type === "STATE_OUT" &&
                                    <InputIcon />
                                    } 
                                </Icon>                              
                                </ListItemIcon>
                                <ListItemText primary={probe.name} />
                                <ListItemText primary={"("+probe.type+")"} />
                                <IconButton edge="end" aria-label="probeName" onClick={()=>handlePropertySave(key,probe, "name")}  >
                                    <EditIcon fontSize='small'  color="primary" />
                                </IconButton >
                            </ListItem>
                        </MyLink>                        
                    </div>
                )}                                     
            </List> 
            
                        
        </div>
  );
}

/*


  "probesConfig": {
    "probe1": {
      "name": "Ma temp"        
      "fct": {
        "a": 1,
        "b": 0
      },
      "graph": {
        "color": "green",
        "notes": [
          {
            "text": "Min",
            "value": 12
          },
          {
            "text": "Max",
            "value": 28
          }
        ]
      }
    },
    "probe2": {
      "name": "Mon hygro"
    }
  }


*/