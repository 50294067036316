import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';


import GeneralContext from '../components/GeneralContext'

const useStyles = makeStyles({
    root: {
    },
    card: {
        maxWidth: "800px"
    },
    contact: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'    ,    
        marginTop: 30
    }
});

export default function AboutPage() {
    const { portal } = useContext(GeneralContext);

    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Card  className={classes.card}>
 
        <Container>

            <Typography gutterBottom variant="h5" component="h2"  gutterBottom>
            {portal.title} {t('screen.about.text1')}
            </Typography> 
    
            <Typography variant="body2" color="textSecondary" component="p"  gutterBottom>
                <ArrowRightIcon/>{t('screen.about.text2')} 
            </Typography>    
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p"  gutterBottom>
                <ArrowRightIcon/> {t('screen.about.text3')} 
            </Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p"  gutterBottom>
                <ArrowRightIcon/>{t('screen.about.text4')} 
            </Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p"  gutterBottom>
            <   ArrowRightIcon/>{t('screen.about.text5')} 
            </Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p"  gutterBottom>
            <   ArrowRightIcon/>{t('screen.about.text6')}
            </Typography>
            <Divider />            
            <Typography variant="body2"  gutterBottom className={classes.contact}>
                <ContactPhoneIcon fontSize="large" /> &nbsp; {t('screen.about.contact-us')} &nbsp; <a href={portal.webUrl} >{portal.webName}</a>
            </Typography>

        </Container>
           

        </Card>
  );
}

