//https://www.apollographql.com/docs/react/api/core/ApolloClient/
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import awsconfig from '../aws-exports';
//const gql = require('graphql-tag');
import gql from 'graphql-tag';

const cache = new InMemoryCache({
  //https://www.apollographql.com/docs/react/caching/cache-configuration/#configuration-options
  dataIdFromObject: object => {
    console.log('[InMemoryCache][dataIdFromObject][object]', object);
    return defaultDataIdFromObject(object);
  }
});

var GraphQlApi = (function () {

  var instance;

  var _token;
  var _isMobile = false;
  var appSynClient;

  function createInstance() {
      // private variables and methods

      var _privateVariable = 'I am a private variable';

      function _privateMethod() {
          console.log('I am a private method');
      }
      function refreshToken() {
        //console.log(")))))))))>token in use:",_token);
        return _token;
      }

      return {
          // public methods and variables
          setToken: function(token, isMobile) {
              console.log('GQL- Setting token');
              _token = token;
              _isMobile = isMobile?isMobile:false;
              if (!appSynClient) {
                appSynClient = new AWSAppSyncClient({
                  url: awsconfig.aws_appsync_graphqlEndpoint,
                  region: awsconfig.aws_appsync_region,
                  auth: { type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS, jwtToken: () => refreshToken() },
                  // auth: {
                  //     type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                  //     jwtToken: refreshToken(),
                  //   },
                  disableOffline: true, 
                },
                {
                  //cache: cache
                  //https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/197             
                });
                console.log("GQL- AWSAppSyncClientok");
              }
          },
          isMobileFct: function() {
            return _isMobile;
          },
          getToken: function() {
            return _token;
          },
          graphqlOperation: async function(query, variables) {
            //console.log('GQL-graphqlOperation...');
            await appSynClient.hydrated();
            var result;
            if (!_token ) {
              throw "graphgl token is missing";
            }
            //console.log("querymut",query);
            try {
                if (query.startsWith("mutation")) {
                  console.log("GQL-mutation");
                  //console.log("variables:",variables);
                  result = await appSynClient.mutate({
                    mutation: gql(query),
                    variables: variables
                  })
                  console.log("GQL-client.mutate result :",result);

                } else {
                  console.log("GQL-query")
                  //console.log("query:",query);
                  //console.log("variables:",variables);
                  result = await appSynClient.query({
                    query: gql(query),
                    variables: variables,
                    fetchPolicy: 'no-cache', 
                    //fetchPolicy: 'cache-first', // default
                    //fetchPolicy: 'cache-and-network', // prends depuis le cache, mais va maj le cache via le reseau
                    errorPolicy: 'all'
                    //fetchPolicy: 'cache-and-network'
                  })
                  //console.log("client.query result:",result.data);
                }
        
              } catch(err) {
                //console.log("Appsync query error : ",err)
                throw err;
            } 
            return result;
          },
          publicVariable: 'I am a public variable',
          isMobile: _isMobile
      };
  }
  return {
      getInstance: function () {
          if (!instance) {
            console.log("GQL-GraphQlApi getInstance...");
            instance = createInstance();
          }
          return instance;
      }
  };
})()



export default GraphQlApi;

/*

export default class MyGraphql {

  static myInstance = null;

   _idToken = "";


  static getInstance() {
    if (MyGraphql.myInstance == null) {
      MyGraphql.myInstance = new MyGraphql();
    }
    return this.myInstance;
  }

    getIdToken() {
      return this._idToken;
    }

    setIdToken(id) {
      this._idToken = id;
    }

    async graphqlOperation(query, variables) {
      var result;
      if (this._idToken = "") {
        throw "graphgl token is missing";
      }
      try {
          const client = new AWSAppSyncClient({
              url: awsconfig.aws_appsync_graphqlEndpoint,
              region: awsconfig.aws_appsync_region,
              auth: {
                  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                  jwtToken: this._idToken,
              },
          });
          console.log("AWSAppSyncClientok");
          result = await client.query({
            query: gql(query),
            variables: variables,
            //fetchPolicy: 'network-only',
            //fetchPolicy: 'cache-and-network'
          })
          console.log("client.query:",result.data);
  
        } catch(err) {
          console.log("Appsync query error : ",err)
  
      } 
      return result;
    }
}
*/